import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	Switch
} from 'react-router-dom';//-*/
import { CContainer, CFade } from '@coreui/react';
import { UFunc } from "../helpers/functions";

/*import {
	IProfile,
	ISetting,
	ILangs,
} from "../views/index";//-*/

const TheContent = (props) => {
	var uComponentName	= TheContent.name;
	const cDispatch	= useDispatch();
	const cRoutes	= useSelector(state => state.listRoute);
	const [uRoutes,setRoutes]	= React.useState(JSON.parse(localStorage.getItem("routes") || "[]"));

	//console.log("("+uComponentName+") uRoutes = "+JSON.stringify(uRoutes));

	return (
	<main className="c-main p-3">
	<CContainer fluid >
		<Switch>
		{uRoutes.map((route, idx) => {
			let TheComponent;
			try {
				TheComponent = require("../views/"+UFunc.capitalize(route.name)).default;
			} catch (ex) {
				//console.log("("+uComponentName+") route.name(ex) = ../views/"+route.name);
			}

			return TheComponent && (
			<Route
				key={idx}
				path={route.path}
				exact
				name={route.name}>
				<CFade>
					<TheComponent namaRoute={route.name} {...props} />
				</CFade>
			</Route>
			)
		})}
		<Redirect exacts from="/" to="/dashboard" />
		</Switch>
	</CContainer>
	</main>
	);
}

export default React.memo(TheContent);
	