import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CInput,
	CInputGroupPrepend,
	CInputGroup,
	CSelect,
	CLabel,
	CCollapse,
} from "@coreui/react";
import { CChartLine,CChartBar } from '@coreui/react-chartjs';
import CIcon from '@coreui/icons-react';
//import ReactExport from "react-export-excel";
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';

var pjson     = require('../../package.json');

const Lappendapatan = (props) => {
	const cComponentname 		= Lappendapatan.name;
	let cFuncname;
	const cDispatch				= useDispatch();
	const cHistory				= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});
	const {setLoading,showToast,prosesExpired}	= props;

	/*const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//-*/

  	const obBahasa 					= useSelector(state => state.listBahasa);
	const uHTMLstyleTabel			= useSelector(state => state.gHTMLstyleLaporan);
	const uHTMLstyleGrafik			= (useSelector(state => state.gHTMLstyleGrafik));
	const objToken					= JSON.parse(localStorage.getItem("token"));

	const uTglNowDT							= new Date();
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uBtnTampilDisabledBl,setBtnTampilDisabledBl]	= React.useState(false);

	const [uBulanObj,setBulanObj]	= React.useState([]);
	const [uTahunObj,setTahunObj]	= React.useState([]);
	const [uBarangObj,setBarangObj]	= React.useState([]);

	const [uInpJenis,setInpJenis]		= React.useState("TANGGAL");
	const [uInpTanggal,setInpTanggal] 	= React.useState(UFunc.DbDate(uTglNowDT));
	const [uInpBulanID,setInpBulanID] 	= React.useState(uTglNowDT.getMonth()+1);
	const [uInpTahun,setInpTahun] 		= React.useState(uTglNowDT.getFullYear());
	//const [uInpBarangID,setInpBarangID]	= React.useState(0);
	const [uFilterTitle,setFilterTitle]	= React.useState("");
	const [uFilterLabel,setFilterLabel]	= React.useState("");

	const [uTitleSet,setTitleSet]		= React.useState("");
	const [uLabelSet,setLabelSet]		= React.useState("");

	const [uInitGrafikShow,setInitGrafikShow]= React.useState(useSelector(state => state.gInitGrafikShow));
	const [uInitTampilkan,setInitTampilkan] = React.useState(false);

	const [uInitAwal,setInitAwal]			= React.useState("*) "+obBahasa.petunjuk_initlappendapatan+"..");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uElInpJenis 		= document.getElementById("inpjenis");
	const uElInpTanggal 	= document.getElementById("inptanggal");
	const uElInpBulanID 	= document.getElementById("inpbulanid");
	const uElInpTahun 		= document.getElementById("inptahun");
	const uElInpBarangID	= document.getElementById("inpbarangid");
	const uFrameExport 		= document.getElementById("ifmcontentstoprint");

	const uElFilterTanggal 	= document.getElementById("idfiltertanggal");
	const uElFilterBulan 	= document.getElementById("idfilterbulan");
	const uElFilterTahun 	= document.getElementById("idfiltertahun");

	React.useEffect(()=>{ svcInitData(); },[]);
	React.useEffect(()=>{ cDispatch({type: "set", gInitGrafikShow: uInitGrafikShow}); },[uInitGrafikShow]);
	React.useEffect(()=>{
		setDataObj([]);
		setInitAwal("*) "+obBahasa.petunjuk_initlappendapatan+"..");
	},[uInpJenis]);
	React.useEffect(()=>{ 
		setActivePage(1);
		setJmlData(uDataObj.length||0); 
	},[uDataObj]);
	React.useEffect(()=>{
		cFuncname 			= "useEffect[uJmlData]";
		/*const vObjFilter	= uFilterLaporanObj;
		vObjFilter.jml_data = uJmlData;
		cDispatch({type: "set", gFilterLaporanObj: vObjFilter});//-*/

		//console.log("("+cComponentname+"-"+cFuncname+") uJmlData = "+uJmlData);

		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
	},[uJmlData]);
	React.useEffect(()=>{
		if(UFunc.isEmpty(uElInpJenis)) {
			setFilterTitle("{UNDEFINED}");
			setFilterLabel("{UNDEFINED}");
			return;
		}

		let vTitle	= "<div>"+obBahasa["menus_"+props.namaRoute.toLowerCase()]+
			" "+UFunc.getOptionSelectTeks(uElInpJenis)+"</div>";

		if(uInpJenis == "TANGGAL")
			vTitle += (!UFunc.isEmpty(uElInpBulanID))
			? "<div>"+obBahasa.word_periode+": "+
				UFunc.HariAngka(uInpTanggal)+"</div>"
			: "";
		else {
			vTitle += (!UFunc.isEmpty(uElInpBulanID))
			? "<div>"+obBahasa.word_periode+": "+
				UFunc.getOptionSelectTeks(uElInpBulanID)+" "+
				UFunc.getOptionSelectTeks(uElInpTahun)+"</div>"
			: "";

			setFilterLabel(UFunc.getOptionSelectTeks(uElInpBulanID)+" "+
				UFunc.getOptionSelectTeks(uElInpTahun));
		}
		setFilterTitle(vTitle);
	},[uInpJenis,uInpTanggal,uInpBulanID,uInpTahun]);

	const hdlKlikReset=async()=>{
		cFuncname 			= hdlKlikReset.name;

		if((parseInt(uJmlData)||0) > 0) {
			if(await Konfirm(obBahasa.confirm_reset)) initReset();
		} else initReset();	//-*/
	}
	const hdlKlikTampil=()=>{
		cFuncname 			= hdlKlikTampil.name;
		
		//const vBarangID		= parseInt(uInpBarangID) || 0;
		const vJenis		= uInpJenis.toUpperCase();
		const vTanggal		= uInpTanggal;
		const vBulanID		= parseInt(uInpBulanID) || 0;
		const vTahun		= parseInt(uInpTahun) || 0;
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);

		if(vJenis=="TANGGAL") {
			if(UFunc.isEmpty(vTanggal)) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.caption_periodetanggal+" "+obBahasa.caption_invalid+"..");
				return;
			}
		} else {
			if(vBulanID <= 0) {
				if(!UFunc.isEmpty(uElInpBulanID)) uElInpBulanID.focus();
				showToast(obBahasa.word_bulan+" "+obBahasa.caption_mustchoosed+"..");
				return;
			}
			if(vTahun <= 0) {
				if(!UFunc.isEmpty(uElInpTahun)) uElInpTahun.focus();
				showToast(obBahasa.word_tahun+" "+obBahasa.caption_mustchoosed+"..");
				return;
			}
		}
		/*if(vBarangID <= 0) {
			if(!UFunc.isEmpty(uElInpBarangID)) uElInpBarangID.focus();
			showToast(obBahasa.caption_barangnama+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}//-*/

		svcLoadReport();
	}
	const hdlKlikCetak=()=>{
		cFuncname = hdlKlikCetak.name;

		var vContent 	= gnrPrintContent();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikCetakGrafik=()=>{
		cFuncname = hdlKlikCetakGrafik.name;

		//console.log("("+cComponentname+"-"+cFuncname+") chartURL : "+(chartURL));
		var vContent 	= gnrPrintGrafik();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		var vTimeout = setTimeout(function(){ 
			pri.focus();
			pri.print();
			clearTimeout(vTimeout);
		},300);
	}
	const hdlKlikExcel=()=>{

		cFuncname = hdlKlikExcel.name;
	}

	const initReset=()=> {
		cFuncname 			= initReset.name;
		
		//console.log("("+cComponentname+"-"+cFuncname+") uDataObj : "+JSON.stringify(uDataObj));
		setHTMLError500(null);
		setDataObj([]);
		setInpJenis("TANGGAL");
		setInpTanggal(UFunc.DbDate(uTglNowDT));
		setInpBulanID(uTglNowDT.getMonth()+1);
		setInpTahun(uTglNowDT.getFullYear());
		//setInpBarangID(0);
		setInitTampilkan(false);

		setInitAwal("*) "+obBahasa.petunjuk_initlappendapatan+"..");
	}
	const gnrPrintContent=()=>{

		var vHTMLs = uHTMLstyleTabel;

		vHTMLs += `
			<table id="idtabelhtml">
			<caption>`+uTitleSet+`</caption>
			<thead>
			<tr>
			<th width="5%">No</th>
		`;
		if(uInpJenis=="TANGGAL")
			vHTMLs += `
				<th align="left">`+obBahasa.word_keterangan+`</th>
				<th width="25%">`+obBahasa.word_nilai+` (Rp)</th>
			`;
		else 
			vHTMLs += `
				<th align="left">`+obBahasa.word_tanggal+`</th>
				<th width="18%">`+obBahasa.caption_pendapatantunai+` (Rp)</th>
				<th width="18%">`+obBahasa.caption_pendapatanpiutang+` (Rp)</th>
				<th width="20%">`+obBahasa.word_total+` (Rp)</th>
			`;

		vHTMLs += `
			</tr>
			</thead>
			<tbody>`;

		var vSumNilai = 0;
		var vSumTunai = 0;
		var vSumPiutang = 0;
		var vSumTotal = 0;
		uDataObj.map((vItems,vKey)=>{

			if(uInpJenis=="TANGGAL") {
				const { id,keterangan,nilai } = vItems;
				var vNilai 			= UFunc.formatAngka(nilai);
				var vKeterangan 	= (keterangan);
				vSumNilai	= vSumNilai + parseInt(nilai) || 0;

				vHTMLs 	+= `
					<tr key={vKey}>
						<td align="right">`+(vKey+1)+`.</td>
						<td align="left" style="font-style:italic">`+vKeterangan+`</td>
						<td align="right" style="font-weight:bolder;font-size:larger">`+vNilai+`</td>
					</tr>
				`;
			} else {
				const { tanggal,nilai_tunai,nilai_piutang } = vItems;

				var vTanggal 	= UFunc.HariAngka(tanggal);
				var vNilaiTunai	= UFunc.formatAngka(nilai_tunai);
				var vNilaiPiutang= UFunc.formatAngka(nilai_piutang);
				var vNilaiTotal	= UFunc.formatAngka(parseInt(nilai_tunai) + parseInt(nilai_piutang));
				vSumTunai	= vSumTunai + parseInt(nilai_tunai) || 0;
				vSumPiutang	= vSumPiutang + parseInt(nilai_piutang) || 0;

				vHTMLs 	+= `
					<tr key={vKey}>
						<td align="right">`+(vKey+1)+`.</td>
						<td align="left" >`+vTanggal+`</td>
						<td align="right">`+vNilaiTunai+`</td>
						<td align="right">`+vNilaiPiutang+`</td>
						<td align="right" style="font-weight:bolder;font-size:larger;">`+vNilaiTotal+`</td>
					</tr>
				`;
			}
		});
		vHTMLs 	+= `
			</tbody>
		`
		if(uInpJenis == "TANGGAL") {
			vHTMLs += `
				<tfoot>
				<tr>
				<td colSpan="2" align="center">`+obBahasa.word_jumlah+`</td>
				<td align="right" style="font-weight:bolder">`+UFunc.formatAngka(vSumNilai)+`</td>
				</tr>
				</tfoot>
			`;
		} else {
			vHTMLs += `
				<tfoot>
				<tr>
				<td colSpan="2" align="center">`+obBahasa.word_jumlah+`</td>
				<td align="right" style="font-weight:bolder">`+UFunc.formatAngka(vSumTunai)+`</td>
				<td align="right" style="font-weight:bolder">`+UFunc.formatAngka(vSumPiutang)+`</td>
				<td align="right" style="font-weight:bolder;font-size:larger;">`+UFunc.formatAngka(vSumTunai + vSumPiutang)+`</td>
				</tr>
				</tfoot>
			`;
		}
		vHTMLs 	+= `</table>`;

		return vHTMLs;
	}
	const gnrPrintGrafik=()=>{
		cFuncname 			= gnrPrintGrafik.name;

		var vContent 	= document.getElementById("idgrafiklaporan");
		var chartURL 	= vContent.querySelector("canvas").toDataURL("image/png");
		
		//console.log("("+cComponentname+"-"+cFuncname+") uHTMLstyleGrafik : "+(uHTMLstyleGrafik));
		
		var vHTMLs		= uHTMLstyleGrafik;
		vHTMLs			+= `
			<h1 style="font-size:150%" align="center">`+uTitleSet+`</h1>
			<center><img src="`+chartURL + `" width="90%" /></center>
		`;

		return vHTMLs;
	}
	const svcInitData=()=>{
		cFuncname = svcInitData.name;

		/*//--TESTING_FRONTEND--/
		//console.log("("+cComponentname+") "+cFuncname+" => cKeywordInit : "+cKeywordInit);
		let vTmpObj	= [
			{id:"1",caption:"JANUARI"},
			{id:"2",caption:"FEBRUARI"},
			{id:"3",caption:"MARET"},
			{id:"4",caption:"APRIL"},
			{id:"5",caption:"MEI"},
			{id:"6",caption:"JUNI"},
			{id:"7",caption:"JULI"},
			{id:"8",caption:"AGUSTUS"},
			{id:"9",caption:"SEPTEMBER"},
			{id:"10",caption:"OKTOBER"},
			{id:"11",caption:"NOVEMBER"},
			{id:"12",caption:"DESEMBER"},
		]
		setBulanObj(vTmpObj);

		vTmpObj	= [
			{value:"2021",caption:"2021"},
			{value:"2020",caption:"2020"},
			{value:"2019",caption:"2019"},
			{value:"2018",caption:"2018"},
			{value:"2017",caption:"2017"},
		]
		setTahunObj(vTmpObj);

		vTmpObj	= [
			{id:"1",caption:"Barang 01"},
			{id:"2",caption:"Barang 02"},
			{id:"3",caption:"Barang 03"},
			{id:"4",caption:"Barang 04"},
			{id:"5",caption:"Barang 05"},
			{id:"6",caption:"Barang 06"},
			{id:"7",caption:"Barang 07"},
			{id:"8",caption:"Barang 08"},
			{id:"9",caption:"Barang 09"},
		]
		setBarangObj(vTmpObj);

		return;
		//--END TESTING_FRONTEND--*/

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_lappendapatan/ld_init";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			//console.log("("+cComponentname+") "+cFuncname+" => output_string : "+JSON.stringify(output_string));
			setLoading(false);
			setBtnTampilDisabledBl(false);
			if(output_string.tampil) {
				setInitTampilkan(true);
				let vTmpObj = []
				if(output_string.bulanobj) {
					vTmpObj = JSON.parse(output_string.bulanobj);
					setBulanObj(vTmpObj);
				}
				if(output_string.tahunobj) {
					vTmpObj = JSON.parse(output_string.tahunobj);
					setTahunObj(vTmpObj);
				}
				/*if(output_string.barangobj) {
					vTmpObj = JSON.parse(output_string.barangobj);
					setBarangObj(vTmpObj);
				}//-*/
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
		});	
	}
	const svcLoadReport=()=>{
		cFuncname 			= svcLoadReport.name;
		setInitAwal(null);
		setHTMLError500(null);
		setDataObj([]);
		
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);
		/*//--TESTING_FRONTEND--/
		let vTmpObj = [];

		if(uInpJenis=="TANGGAL") {

		vTmpObj = [
		{id:"1",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"2",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"3",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"4",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"5",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"6",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"7",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"8",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"9",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"10",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"11",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"12",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"13",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"14",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"15",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"16",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"17",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"18",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"19",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"20",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		{id:"21",keterangan:"~Pendapatan Tunai Customer A (Nota:XX123)",nilai:"100000"},
		{id:"22",keterangan:"~Pendapatan Piutang Customer A (Nota:CC1223) BLABAL bla blab bla...",nilai:"450000"},
		];

		} else {

		vTmpObj = [
		{tanggal:"2021-05-01",nilai_tunai:"50000",nilai_piutang:"35000"},
		{tanggal:"2021-05-02",nilai_tunai:"60000",nilai_piutang:"35000"},
		{tanggal:"2021-05-03",nilai_tunai:"56000",nilai_piutang:"35000"},
		{tanggal:"2021-05-04",nilai_tunai:"70000",nilai_piutang:"35000"},
		{tanggal:"2021-05-05",nilai_tunai:"80000",nilai_piutang:"35000"},
		{tanggal:"2021-05-06",nilai_tunai:"90000",nilai_piutang:"35000"},
		{tanggal:"2021-05-07",nilai_tunai:"10000",nilai_piutang:"35000"},
		{tanggal:"2021-05-08",nilai_tunai:"20000",nilai_piutang:"35000"},
		{tanggal:"2021-05-09",nilai_tunai:"30000",nilai_piutang:"35000"},
		{tanggal:"2021-05-10",nilai_tunai:"40000",nilai_piutang:"35000"},
		{tanggal:"2021-05-11",nilai_tunai:"50000",nilai_piutang:"35000"},
		{tanggal:"2021-05-12",nilai_tunai:"60000",nilai_piutang:"35000"},
		{tanggal:"2021-05-13",nilai_tunai:"70000",nilai_piutang:"35000"},
		{tanggal:"2021-05-14",nilai_tunai:"80000",nilai_piutang:"35000"},
		{tanggal:"2021-05-15",nilai_tunai:"90000",nilai_piutang:"35000"},
		{tanggal:"2021-05-16",nilai_tunai:"10000",nilai_piutang:"35000"},
		{tanggal:"2021-05-17",nilai_tunai:"20000",nilai_piutang:"35000"},
		{tanggal:"2021-05-18",nilai_tunai:"30000",nilai_piutang:"35000"},
		{tanggal:"2021-05-19",nilai_tunai:"40000",nilai_piutang:"35000"},
		{tanggal:"2021-05-10",nilai_tunai:"40000",nilai_piutang:"35000"},
		{tanggal:"2021-05-21",nilai_tunai:"50000",nilai_piutang:"35000"},
		{tanggal:"2021-05-22",nilai_tunai:"60000",nilai_piutang:"35000"},
		{tanggal:"2021-05-23",nilai_tunai:"70000",nilai_piutang:"35000"},
		{tanggal:"2021-05-24",nilai_tunai:"80000",nilai_piutang:"35000"},
		{tanggal:"2021-05-25",nilai_tunai:"90000",nilai_piutang:"35000"},
		{tanggal:"2021-05-26",nilai_tunai:"10000",nilai_piutang:"35000"},
		{tanggal:"2021-05-27",nilai_tunai:"20000",nilai_piutang:"35000"},
		{tanggal:"2021-05-28",nilai_tunai:"30000",nilai_piutang:"35000"},
		{tanggal:"2021-05-29",nilai_tunai:"40000",nilai_piutang:"35000"},
		{tanggal:"2021-05-30",nilai_tunai:"50000",nilai_piutang:"35000"},
		{tanggal:"2021-05-31",nilai_tunai:"60000",nilai_piutang:"35000"},
		];

		}
		setDataObj(vTmpObj);
		setTitleSet(uFilterTitle);
		setLabelSet(uFilterLabel);
		return;
		//--END TESTING_FRONTEND--*/

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_jenis	: uInpJenis,
			send_tanggal: uInpTanggal,
			send_bulanid: uInpBulanID,
			send_tahun 	: uInpTahun,
			//send_barangid : uInpBarangID,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_lappendapatan/ld_report";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnTampilDisabledBl(false);

			/*var vObjFilter 			= uFilterLaporanObj;
			vObjFilter.isKlikTampil = false; 
			vObjFilter.isKlikReset = true; 
			cDispatch({type: "set", gFilterLaporanObj: vObjFilter});//-*/

			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setTitleSet(uFilterTitle);
				setLabelSet(uFilterLabel);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});	
	}

	const loadFilter=()=>{
		cFuncname = loadFilter.name;
		if(uBulanObj.length <= 0) return;

		return (
		<div className="classfakesubheader">
			<CForm className="form-horizontal py-0" id="idformlaporan">
			<CFormGroup row className="px-0 my-0 justify-content-center">
			<CCol sm="12" className="text-center">
			<div className="p-0 px-1 text-left align-top w-auto d-inline-block">
				<CSelect custom 	
					value={uInpJenis}
					onChange={(e)=>setInpJenis(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpbulanid")}
					id="inpjenis">
					<option value="TANGGAL">{obBahasa.caption_pertanggal}</option>
					<option value="BULAN">{obBahasa.caption_perbulan}</option>
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_periodelaporan}</div>
			</div>
			<div className={"p-0 px-1 text-left align-top w-auto"+(uInpJenis=="TANGGAL"?" d-inline-block":" d-none")}>
				<CInput
					type="date"
					value={uInpTanggal}
					onChange={(e)=>setInpTanggal(e.target.value)}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnTampilkan")}
					id="inptanggal"/>
				<div className="classpetunjuk">{obBahasa.caption_periodetanggal}</div>
			</div>
			<div className={"p-0 px-1 text-left align-top w-auto"+(uInpJenis!="TANGGAL"?" d-inline-block":" d-none")}>
				<CSelect custom 
					value={uInpBulanID}
					onChange={(e)=>setInpBulanID(e.target.value)}
					id="inpbulanid">
					{uBulanObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} 
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_periodebulan}</div>
			</div>
			<div className={"p-0 px-1 text-left align-top w-auto"+(uInpJenis!="TANGGAL"?" d-inline-block":" d-none")}>
				<CSelect custom 
					value={uInpTahun}
					onChange={(e)=>setInpTahun(e.target.value)}
					id="inptahun">
					{uTahunObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.value}  
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
			</div>
			{/*
			<div className="px-1 text-left align-top d-inline-block w-auto" style={{minWidth:"300px"}}>
				<CSelect custom 
					value={uInpBarangID}
					onChange={(e)=>setInpBarangID(e.target.value)}
					id="inpbarangid">
					<option value="0">---SILAHKAN PILIH NAMA BARANG</option>
					{uBarangObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} key={vKey}>{vItem.caption} ({vItem.id})</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_pilihbarang}</div>
			</div>
			*/}
			<div className="px-1 text-left align-middle d-inline-block ">
				<CButton type="button" color="secondary" 
					color="warning"
					className="text-white font-weight-bold py-1 pt-2"
					disabled={uBtnTampilDisabledBl}
					onClick={hdlKlikTampil} 
					id="btnTampilkan">
					<CIcon name="cil-magnifying-glass" />&nbsp;{obBahasa.word_tampilkan}
				</CButton>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
			&nbsp;&middot;&nbsp;&nbsp;
			<CTooltip content={obBahasa.caption_resetview}>
				<CLink 
					onClick={hdlKlikReset} 
					className="classikon classikonreset"
					id="idklikreset"/>
			</CTooltip>
			</div>
			</CCol>
			</CFormGroup>
			</CForm>
		</div>
		);
	}
	const loadContent=()=>{
		cFuncname = loadContent.name;

		if(uBtnTampilDisabledBl) return (
			<div className="text-center classpetunjuk">{obBahasa.pesan_mohontunggu}</div>
		);

		if(uHTMLError500) return (
			<>{UFunc.renderHTML(uHTMLError500)}</>
		);
		if(uInitAwal) return (
			<div className="font-italic classfontlarger text-info px-4">{UFunc.renderHTML(uInitAwal)}</div>
		);

		const loadHeaderTanggal=()=>{
			return (
				<>
				<th className="align-top text-left">{obBahasa.word_keterangan}</th>
				<th width="25%" className="align-top text-center">{obBahasa.word_nilai} (Rp)</th>
				</>
			);
		}
		const loadHeaderBulan=()=>{
			return (
				<>
				<th className="align-top text-left">{obBahasa.word_tanggal}</th>
				<th width="18%" className="align-top text-center">{obBahasa.caption_pendapatantunai} (Rp)</th>
				<th width="18%" className="align-top text-center">{obBahasa.caption_pendapatanpiutang} (Rp)</th>
				<th width="20%" className="align-top text-center">{obBahasa.word_total} (Rp)</th>
				</>
			);
		}
		return (
			<div className="table-responsive-sm" xs="12">
			<table 
				className="table table-borderless table-striped" 
				id="idtabeldata">
			<thead className="d-sm-down-none thead-light">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			{(uInpJenis=="TANGGAL" ? loadHeaderTanggal() : loadHeaderBulan())}
			</tr>
			</thead>
			<tbody>{loadBodyTabel()}</tbody>
			{loadFooterTabel()}
			</table>
			</div>
		)
	}
	const loadBodyTabel=()=>{
		cFuncname = loadBodyTabel.name;
		
		if(uJmlData <= 0) return (
			<tr>
			<td colSpan="7" className="text-center classpetunjuk">&middot;&middot;&middot;{obBahasa.caption_dataempty}&middot;&middot;&middot;</td>
			</tr>
		)

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		return (uDataObj.map((vItems,vKey)=>{
			if(vKey>=vMin && vKey<=vMax) {
				if(uInpJenis=="TANGGAL") {
					const { id,keterangan,nilai } = vItems;

					var vNilai 			= UFunc.formatAngka(nilai);
					var vKeterangan 	= UFunc.renderHTML(keterangan);

					return (
					<tr key={vKey}>
						<td align="right">{(vKey+1)+"."}</td>
						<td align="left" className="font-italic">{vKeterangan}</td>
						<td align="right" className="font-weight-bolder classfontlarger">{vNilai}</td>
					</tr>
					)
				} else {
					const { tanggal,nilai_tunai,nilai_piutang } = vItems;

					var vTanggal 	= UFunc.HariAngka(tanggal);
					var vNilaiTunai	= UFunc.formatAngka(nilai_tunai);
					var vNilaiPiutang= UFunc.formatAngka(nilai_piutang);
					var vNilaiTotal	= UFunc.formatAngka(parseInt(nilai_tunai) + parseInt(nilai_piutang));

					return (
					<tr key={vKey}>
						<td align="right">{(vKey+1)+"."}</td>
						<td align="left" className="">{vTanggal}</td>
						<td align="right" className="">{vNilaiTunai}</td>
						<td align="right" className="">{vNilaiPiutang}</td>
						<td align="right" className="font-weight-bolder classfontlarger">{vNilaiTotal}</td>
					</tr>
					)
				}
			}
		}));
	}
	const loadFooterTabel=()=>{
		cFuncname = loadFooterTabel.name;
		if(uJmlData <= 0) return null;

		var vColspan	= 2;
		if(uInpJenis=="TANGGAL") {
			const vSumNilai 	= Object.values(uDataObj).reduce((r, { nilai }) => r + (parseInt(nilai)||0), 0);
			return (
				<tfoot className="classtabeltfootlaporan">
				<tr>
				<td colSpan={vColspan} className="text-center">{obBahasa.word_jumlah}</td>
				<td className="text-right font-weight-bolder">{UFunc.formatAngka(vSumNilai)}</td>
				</tr>
				</tfoot>
			);
		} else {
			const vSumTunai 	= Object.values(uDataObj).reduce((r, { nilai_tunai }) => r + (parseInt(nilai_tunai)||0), 0);
			const vSumPiutang	= Object.values(uDataObj).reduce((r, { nilai_piutang }) => r + (parseInt(nilai_piutang)||0), 0);
			const vSumTotal		= vSumTunai + vSumPiutang;
			return (
				<tfoot className="classtabeltfootlaporan">
				<tr>
				<td colSpan={vColspan} className="text-center">{obBahasa.word_jumlah}</td>
				<td className="text-right">{UFunc.formatAngka(vSumTunai)}</td>
				<td className="text-right">{UFunc.formatAngka(vSumPiutang)}</td>
				<td className="text-right font-weight-bolder">{UFunc.formatAngka(vSumTotal)}</td>
				</tr>
				</tfoot>
			);
		}

		/*return (
			<tfoot>
			<tr>
			<td colSpan={vColspan}>{JSON.stringify(uDataObj)}</td>
			</tr>
			</tfoot>
		);//-*/
	}
	const loadGrafik=()=>{
		cFuncname = loadFooterTabel.name;
		if(uJmlData <= 0) return null;
		if(uInpJenis != "BULAN") return null;

		var vLabelX = new Array();
		var vValue	= new Array();
		Object.keys(uDataObj).forEach(function(key) {
			vLabelX.push(UFunc.TglAngka(uDataObj[key].tanggal));
			vValue.push((parseInt(uDataObj[key].nilai_tunai)||0) + (parseInt(uDataObj[key].nilai_piutang)||0));
		});

		//--->Tooltip::tooltipItem=>{"xLabel":"10/03/2021","yLabel":700000,"label":"10/03/2021","value":"700000","index":1,"datasetIndex":0,"x":233.41486979166666,"y":32}
		//--->Tooltip::tooltipItem=>{"xLabel":"10/03/2021","yLabel":700000,"label":"10/03/2021","value":"700000","index":1,"datasetIndex":0,"x":233.41486979166666,"y":32}

		return (
			<CRow className="justify-content-center">
			<CCol sm="12" md="10">
			<CCard>
			<CCardHeader>
				<strong>Grafik {obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
				<div className="card-header-actions">
				<CTooltip content={obBahasa.caption_cetaklaporangrafik}>
					<CLink onClick={hdlKlikCetakGrafik} 
						className={"classikon classikoncetak"+(uInitGrafikShow?"":" d-none")}/>
				</CTooltip>
				<span className={(uInitGrafikShow?"":" d-none")}>&nbsp;&middot;&nbsp;</span>
				<CLink className="card-header-action" onClick={() => setInitGrafikShow(!uInitGrafikShow)}>
				<CIcon name={uInitGrafikShow ? 'cil-chevron-top':'cil-chevron-bottom'} />
				</CLink>
				</div>
			</CCardHeader>
			<CCollapse show={uInitGrafikShow}>
			<CCardBody>
				<CChartLine
					datasets={[{
						label: uLabelSet,
						backgroundColor: 'rgb(228,102,81,1)',
						fill: false,
						data: vValue
					}]}
					options={{
						bezierCurve : true,
						responsive: true,
						maintainAspectRatio: true,
						animation: false,
						onAnimationComplete: ()=>{}, 
						tooltips: { 
							enabled: true, 
							callbacks: {
								title: function(tooltipItem, data) {
									return "Tgl: "+data['labels'][tooltipItem[0]['index']];
								},
								label: (tooltipItem, data) => {
									return "Rp"+UFunc.formatAngka(tooltipItem.value)
								},
								afterLabel: function(tooltipItem) {
								}
							}
						},
						scales:{
							yAxes: [{
								ticks : {
									beginAtZero: true,
									callback(value) {
										return UFunc.formatAngka(value)
									}
								},
								scaleLabel: {
									display: true,
									labelString: obBahasa.word_nilai
								},
							}],
							xAxes: [{
								ticks : {
									callback(value) {
										return (value).substr(0,5)
									}
								},
								scaleLabel: {
									display: true,
									labelString: obBahasa.word_tanggal
								},
							}],
						},
					}}
					labels={vLabelX}
				id="idgrafiklaporan"/>
			</CCardBody>
			</CCollapse>
			</CCard>
			</CCol>
			</CRow>
		);
	}

	return (
		<>
		{loadFilter()}
		{loadGrafik()}
		<CCard>
		<CCardHeader>
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
		<div className={"card-header-actions text-right"+(uJmlData<=0?" d-none":"")}>
			<CTooltip content={obBahasa.caption_cetaklaporan}>
				<CLink className="" onClick={hdlKlikCetak} className="classikon classikoncetak"/>
			</CTooltip>
			<span className="d-none">&nbsp;&middot;&nbsp;</span>
			<CTooltip content={obBahasa.caption_exportexcel} className="">
				<CLink className="" onClick={hdlKlikExcel} className="classikon classikonexcel d-none"/>
			</CTooltip>
			<span className="">&nbsp;&middot;&nbsp;</span>
			{"Jumlah: "}<strong id="idjmldata">{UFunc.formatAngka(uJmlData)}</strong>{" Data"}
		</div>
		</CCardHeader>
		<CCardBody>
		{loadContent()}		
		</CCardBody>
		{(uJmlData >= uMaxData) && (
		<CCardFooter>
			<CPagination
				activePage={uPageNow}
				pages={uJmlHal}
				onActivePageChange={(i) => setActivePage(i)}
			></CPagination>
		</CCardFooter>
		)}
		</CCard>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}

export default Lappendapatan;
	