import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CRow
} from '@coreui/react';
import PropTypes from 'prop-types';

const DialogForm = props => {
	const { show, toggle, renderContent, dialogHeader, onSimpan,options } = props;
	const obBahasa  = useSelector(state => state.listBahasa);

	/*const renderHTML = (escapedHTML: string) => 
		React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });//-*/

		//<CModalBody>{dialogContent}</CModalBody>
	return (
		<CModal color="secondary" 
			size={options.hasOwnProperty("size")?options.size:"lg"}
			centered={options.hasOwnProperty("centered")?options.centered:true}
			show={show} 
			onClose={toggle}>
		<CModalHeader closeButton>
			<CModalTitle>{dialogHeader}</CModalTitle>
		</CModalHeader>

		<CModalBody style={{maxHeight:(window.innerHeight - 185),overflowY:"auto",overflowX:"hidden"}}>{renderContent}</CModalBody>
		<CModalFooter>
		<CButton color="secondary" onClick={()=>onSimpan()}
			className="font-weight-bolder"
			id="btnDialogSimpan">
		{obBahasa.word_simpan}
		</CButton>{" "}
		<CButton color="secondary" onClick={toggle}>{obBahasa.word_batal}</CButton>
		</CModalFooter>
		</CModal>
	)
}
DialogForm.defaultProps = {
	show: false,
	renderContent: "{UNDEFINED}..",
	dialogHeader: "Form Data",
	options: {centered:true,size:"lg"},
};

DialogForm.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	renderContent: PropTypes.element, 
	dialogHeader: PropTypes.string,
	onSimpan: PropTypes.func,
	options: PropTypes.object,
}

export default DialogForm;
