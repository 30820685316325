import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CImg,
  CLink
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
  TheHeaderSubNav,
  TheHeaderUserprofile
}  from './index';//-*/

var pjson     = require('../../package.json');

// routes config
//import routes from '../routes';

const TheHeader = (props) => {
  const vFuncName     = TheHeader.name;

  const dispatch    = useDispatch();
  const sidebarShow = useSelector(state => state.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({type: 'set', sidebarShow: val})
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({type: 'set', sidebarShow: val})
  }

  /*const setLocalToast = (_TEXT) => { props.showToast(_TEXT) }
  React.useEffect(()=>{ setLocalToast(vFuncName); },[]);//-*/

  return (
    <CHeader withSubheader className="classheadermain">
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile} />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar} />

      <CHeaderBrand to="/" className="mr-auto font-weight-bold classtitle ml-2">
          <CIcon src={"images/logo192.png"} height="55" alt="Logo" className="mr-1" />{pjson.name}
      </CHeaderBrand>

      <CHeaderNav className="px-2">
        <TheHeaderUserprofile {...props} />
      </CHeaderNav>

      <TheHeaderSubNav {...props} />
    </CHeader>
  )
}

export default TheHeader;
