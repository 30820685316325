import React from "react";
import { useSelector,useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CRow,
	CCol,
	CBreadcrumbRouter,
	CSubheader,
	CForm,
	CFormGroup,
	CInput,
	CInputGroupPrepend,
	CInputGroup,
	CButton,
	CLink,
	CTooltip
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';
import { UFunc } from "../../helpers/functions";

const Subpiutang = (props) => {
	const cComponentName	= Subpiutang.name;
	const {setLoading,showToast,prosesExpired}	= props;

	var cFunction;
	const cDispatch					= useDispatch();
	const cHistory					= useHistory();
  	const obBahasa 					= useSelector(state => state.listBahasa);
	const uKeywordObj				= useSelector(state => state.listKeyword);

	const [uIsExecuteBl,setExecute]	= React.useState(useSelector(state => state.isHeaderExecute));
	const [uIsExecuteCetak,setExecuteCetak]	= React.useState(useSelector(state => state.isKlikCetak));
	const [uKeyword,setKeyword] 	= React.useState(uKeywordObj.kwd_piutang || "");

	React.useEffect(() => {
		const valObjKeyword	= { kwd_piutang : uKeyword };
		cDispatch({type: "set", listKeyword: valObjKeyword});
	},[uKeyword]);

	const hdlKlikEdit = () => { cHistory.push("/ipiutangedit"); }
	const hdlKlikReset = async() => {
		const prosesReset = () => {
			setKeyword('');
			cDispatch({type: 'set', isHeaderExecute: !uIsExecuteBl});
			setExecute(!uIsExecuteBl);
		}

		if(uKeyword) {
			if(uKeyword != "") {
				if(await Konfirm(obBahasa.confirm_reset)) prosesReset();
			} else prosesReset();
		} else prosesReset();	

		//console.log("("+cComponentName+") hdlKlikReset uKeyword=["+uKeyword+"]");
	}
	const hdlKlikCari = () => {
		cDispatch({type: 'set', isHeaderExecute: !uIsExecuteBl});
		setExecute(!uIsExecuteBl);
	}
	const hdlKeydownCari = (e) => {
		if(e.which==13) {
			e.stopPropagation();
			e.preventDefault();
			document.getElementById("btnCari").click();
		}
	}
	const hdlKlikCetak=()=>{
		cDispatch({type: 'set', isKlikCetak: true});
		setExecuteCetak(true);
	}

	return (
		<>
		<CSubheader className="px-3 justify-content-center">
		<CRow className="w-100 align-items-center">
		<CCol sm="12" lg="4" className="d-md-down-none " />

		<CCol sm="12" md="6" lg="4" className="justify-content-center">
			<CForm className="form-horizontal ">
			<CFormGroup row className="p-0 my-1">
			<CCol>
				<CInputGroup>
				<CInput id="inpkeyword" name="inpkeyword" 
					value={uKeyword} 
					onChange={(e)=>setKeyword(e.target.value)} 
					onKeyDown={(e) => hdlKeydownCari(e)} 
					size="sm" 
					style={{}} 
					placeholder={"--"+obBahasa.petunjuk_keyword+".."} />
				<CInputGroupPrepend>
					<CButton type="button" color="secondary" onClick={()=>hdlKlikCari()} id="btnCari" className="py-0"><CIcon name="cil-magnifying-glass" /></CButton>
				</CInputGroupPrepend>
				</CInputGroup>
			</CCol>
			</CFormGroup>
			</CForm>
		</CCol>
		<CCol sm="12" md="6" lg="4" className="d-flex flex-nowrap justify-content-end py-1">
			<CTooltip content={"--"+obBahasa.caption_editpembayaranpiutang}>
				<CLink className="" onClick={()=>hdlKlikEdit()} className="classikon classikonedit"/>
			</CTooltip>
			&nbsp;&middot;&nbsp;
			<CTooltip content={"--"+obBahasa.caption_cetakdata}>
				<CLink className="" onClick={()=>hdlKlikCetak()} className="classikon classikoncetak"/>
			</CTooltip>
			&nbsp;&middot;&nbsp;
			<CTooltip content={"--"+obBahasa.caption_resetview}>
				<CLink className="" onClick={()=>hdlKlikReset()} className="classikon classikonreset"/>
			</CTooltip>
			&nbsp;&middot;&nbsp;&nbsp;
			<span className="">
			{(obBahasa.word_jumlah || "Jumlah")+": "}<strong id="idjmldata">0</strong>{" "}{obBahasa.word_data||"Data"}
			</span>
		</CCol>
		</CRow>
		</CSubheader>
		</>
	);
}

export default Subpiutang;
