import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CBadge,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CCallout
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

const ISetting = (props) => {
  const cComponentName  = ISetting.name;
  const cDispatch       = useDispatch();
  cDispatch({type: "set", activeRoute: props.namaRoute});

  return (
    <div>
    <p>{cComponentName+", KOMPAS.com - Pelaksana Tugas Juru Bicara KPK Ali Fikri menanggapi wacana tuntutan hukuman mati untuk dua mantan menteri yakni eks Menteri Kelautan dan Perikanan, Edhy Prabowo serta mantan Menteri Sosial, Juliari Batubara. Edhy Prabowo merupakan tersangka penerima suap kasus dugaan suap izin ekspor benih lobster, sedangkan, Juliari Batubara tersangka kasus dugaan suap terkait bantuan sosial (bansos) untuk wilayah Jabodetabek tahun 2020."}</p>
<p>{"Ali mengatakan, KPK memahami harapan masyarakat mengenai tuntutan hukuman mati tersebut karena praktik korupsi itu dilakukan di tengah pandemi. “Kami tentu memahami harapan masyarakat terkait penyelesaian kedua perkara tersebut, termasuk soal hukuman bagi para pelakunya,” kata Ali dalam keterangan tertulis yang diterima Kompas.com, Rabu (17/2/2021)."}</p>
<p>{"Baca juga: Wamenkumham Sebut Edhy Prabowo dan Juliari Batubara Layak Dituntut Hukuman Mati Ali membenarkan bahwa secara normatif dalam Undang-Undang Tindak Pidana Korupsi, terutama Pasal 2 Ayat (2), hukuman mati diatur secara jelas dan dapat diterapkan. Akan tetapi, menurut dia, penerapan hukuman tersebut bukan hanya soal karena terbuktinya unsur ketentuan di dalam keadaan tertentu saja tetapi semua unsur dalam Pasal Ayat (1) UU Tipikor harus dipenuhi."}</p>
<p>{"Penanganan perkara oleh KPK dalam perkara dugaan suap benur di KKP dan bansos di Kemensos, saat ini pasal yang diterapkan terkait dengan dugaan suap yang ancaman hukuman maksimalnya sebagaimana ketentuan UU Tipikor adalah pidana penjara seumur hidup,” ucap Ali. Ia menekankan bahwa semua perkara hasil tangkap tangan yang dilakukan KPK diawali dengan penerapan pasal-pasal terkait dugaan suap. Baca juga: KPK Dalami Dugaan Edhy Prabowo Modifikasi Mobil Pakai Uang Eksportir Benur Ia menyebut, pengembangan terkait kasus tersebut sangat dimungkinkan, seperti penerapan Pasal 2 atau 3 UU Tipikor, bahkan penerapan ketentuan UU lain seperti Tindak Pidana Pencucian Uang (TPPU)."}</p>
<p>{"Kami tegaskan, tentu sejauh ditemukan bukti-bukti permulaan yang cukup untuk penerapan seluruh unsur pasal-pasal dimaksud. Proses penyidikan kedua perkara tersebut sampai saat ini masih terus dilakukan,” kata Ali. “Kami memastikan perkembangan mengenai penyelesaian kedua perkara tangkap tangan KPK dimaksud selalu kami informasikan kepada masyarakat,” ucap dia."}</p>
<p>{"Sebelumnya, Wakil Menteri Hukum dan HAM (Wamenkumham) Edward Omar Sharif Hiariej menilai Edhy Prabowo dan Juliari Batubara layak untuk dituntut dengan ancaman hukuman mati. Menurut Eddy Hiariej, kedua mantan menteri itu layak dituntut hukuman mati karena melakukan praktik korupsi di tengah pandemi Covid-19."}</p>
<p>{"Hal itu disampaikan Eddy Hiariej saat menjadi pembicara dalam seminar nasional bertajuk Telaah Kritis terhadap Arah Pembentukan dan Penegakkan Hukum di Masa Pandemi yang ditayangkan secara daring di akun YouTube Kanal Pengetahuan FH UGM, Selasa (16/2/2021). Kedua mantan menteri ini (Edhy Prabowo dan Juliari Batubara) melakukan perbuatan korupsi yang kemudian terkena OTT KPK. Bagi saya mereka layak dituntut Pasal 2 Ayat 2 Undang-Undang Tindak Pidana Korupsi (Tipikor) yang mana pemberatannya sampai pidana mati, ucap Eddy sebagaimana dikutip dari Tribunnews.com, Selasa."}</p>
<p>{"Baca juga: Cerita Mahfud MD Didatangi Juliari Batubara, Keluhkan Rumitnya Laporan ke BPK Selain itu, korupsi tersebut dilakukan dengan memanfaatkan jabatan yang mereka emban sebagai menteri. Jadi dua yang memberatkan itu dan itu sudah lebih dari cukup dengan Pasal 2 Ayat 2 UU Tipikor, tutur Eddy Hiariej. Adapun ancaman hukuman mati tercantum dalam Pasal 2 Ayat (2) UU Nomor 31 Tahun 1999 tentang Tindak Pidana Korupsi (Tipikor)."}</p>
<p>{"Pasal 2 Ayat (1) UU 31/1999 menyatakan, Setiap orang yang secara melawan hukum melakukan perbuatan memperkaya diri sendiri atau orang lain atau suatu korporasi yang dapat merugikan keuangan negara atau perekonomian negara, dipidana dengan pidana penjara seumur hidup atau pidana penjara paling singkat 4 (empat) tahun dan paling lama 20 (dua puluh) tahun dan denda paling sedikit Rp 200.000.000,00 (dua ratus juta rupiah) dan paling banyak Rp 1.000.000.000,00 (satu miliar rupiah). Baca juga: KPK Terus Kembangkan Kasus Suap Juliari Batubara Sementara itu, Pasal 2 Ayat (2) menyebutkan, Dalam hal tindak pidana korupsi sebagaimana dimaksud dalam ayat (1) dilakukan dalam keadaan tertentu, pidana mati dapat dijatuhkan."}</p>
<p>{"Sedangkan penjelasan Pasal 2 Ayat (2) berbunyi, Yang dimaksud dengan 'keadaan tertentu' dalam ketentuan ini adalah keadaan yang dapat dijadikan alasan pemberatan pidana bagi pelaku tindak pidana korupsi yaitu apabila tindak pidana tersebut dilakukan terhadap dana-dana yang diperuntukkan bagi penanggulangan keadaan bahaya, bencana alam nasional, penanggulangan akibat kerusuhan sosial yang meluas, penanggulangan krisis ekonomi dan moneter, dan pengulangan tindak pidana korupsi."}</p>
    </div>
  )
}

export default ISetting;
