import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CHeader,
  CSubheader,
  CCardGroup,
  CCard,
  CCardBody,
  CButton,
  CCol,
  CContainer,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CImg,
  CRow
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cifAU } from '@coreui/icons';
import {
  TheLoader
} from '../components/index';
var pjson     = require('../../package.json');

const Page500 = () => {
  var vFuncName = Page500.name;
  var vHistori = useHistory();

  //const [isLoading, setLoading] = React.useState(false);

  React.useEffect(()=>{
    document.getElementById("idcontentmsg").innerHTML = pjson.mydefault.msg500page.join(" ");
    //document.getElementById("idlogowatermark").style.visibility = "visible";
  },[]);

  const TestConnection = ()=>{
    vHistori.go(-1);
  }

  return (
    <div className="c-app c-default-layout align-items-center">
      <div className="c-wrapper">
        <CHeader withSubheader className="classheadermain text-dark">
          <CHeaderNav className="ml-3">
            <CHeaderNavLink onClick={() => {vHistori.go(-1);}}>
            <CIcon name="cil-arrow-circle-left" height="25" alt="Kembali" />
            </CHeaderNavLink>
          </CHeaderNav>

          <CHeaderBrand href="/error500" className="ml-1 font-weight-bold classtitle">
            <CImg src={"images/logo192.png"} height="40" alt="Logo" />&nbsp;{pjson.name}
          </CHeaderBrand>

          <CSubheader className="px-3 justify-content-between">
          <h2 className="m-auto">Error 500</h2>
          </CSubheader>
        </CHeader>

        <div className="c-body flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup className="p-0 classborderr1">
              <CCard className="m-0 bg-transparent text-dark text-center">
                <CCardBody className="">
                  <CImg src={pjson.svcpage+"/images/img_error500.png"} height="250" />
                  <div id="idcontentmsg"></div>
                  <CButton color="warning" className="font-weight-bold"
                    onClick={()=>TestConnection()}
                  >
                  <CIcon name="cilArrowCircleLeft" /> Refresh
                  </CButton>
                </CCardBody>
              </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      </div>
    </div>
  )
}

export default Page500;