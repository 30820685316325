import React from 'react';

const TheLoader = props => {
	const {isShow} = props;
	
	if(!isShow) return null;
	
	return (
	<div className="position-fixed classposmidcenter ">
		<div className="spinner-grow text-black-50" style={{width: 40,height: 40}} role="status">
		<span className="sr-only">Loading...</span>
		</div>
	</div>
	);
}	

export default TheLoader;