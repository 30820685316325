import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CInput,
	CInputGroupPrepend,
	CInputGroup,
	CSelect,
	CLabel,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';

var pjson     = require('../../package.json');

const Laplabarugi = (props) => {
	const cComponentname 	= Laplabarugi.name;
	let cFuncname;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});
	const {setLoading,showToast,prosesExpired}	= props;

  	const obBahasa 					= useSelector(state => state.listBahasa);
	const uHTMLstyleTabel			= useSelector(state => state.gHTMLstyleLaporan);
	//const uHTMLstyleGrafik			= (useSelector(state => state.gHTMLstyleGrafik));
	const objToken					= JSON.parse(localStorage.getItem("token"));

	const uTglNowDT							= new Date();
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uBtnTampilDisabledBl,setBtnTampilDisabledBl]	= React.useState(false);

	const [uBulanObj,setBulanObj]	= React.useState([]);
	const [uTahunObj,setTahunObj]	= React.useState([]);

	const [uInpBulanID,setInpBulanID] 	= React.useState(uTglNowDT.getMonth()+1);
	const [uInpTahun,setInpTahun] 		= React.useState(uTglNowDT.getFullYear());

	const [uFilterTitle,setFilterTitle]	= React.useState("");
	const [uTitleSet,setTitleSet]		= React.useState("");

	const [uInitTampilkan,setInitTampilkan] = React.useState(false);

	const [uInitAwal,setInitAwal]			= React.useState("*) "+obBahasa.petunjuk_initlaplabarugi+"..");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uElInpTanggal 	= document.getElementById("inptanggal");
	const uElInpBulanID 	= document.getElementById("inpbulanid");
	const uElInpTahun 		= document.getElementById("inptahun");
	const uFrameExport 		= document.getElementById("ifmcontentstoprint");

	React.useEffect(()=>{ svcInitData(); },[]);
	React.useEffect(()=>{ 
		setActivePage(1);
		setJmlData(uDataObj.length||0); 
	},[uDataObj]);
	React.useEffect(()=>{
		cFuncname 			= "useEffect[uJmlData]";
		//console.log("("+cComponentname+"-"+cFuncname+") uJmlData = "+uJmlData);

		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
	},[uJmlData]);
	React.useEffect(()=>{
		cFuncname 			= "useEffect[uInpBulanID,uInpTahun]";
		let vTitle	= "<div>"+obBahasa["menus_"+props.namaRoute.toLowerCase()]+"</div>";
		
		vTitle += (!UFunc.isEmpty(uElInpBulanID))
			? "<div>"+obBahasa.word_periode+": "+
				UFunc.getOptionSelectTeks(uElInpBulanID)+" "+
				UFunc.getOptionSelectTeks(uElInpTahun)+"</div>"
			: "";

		setFilterTitle(vTitle);
	},[][uInpBulanID,uInpTahun]);

	const hdlKlikReset=async()=>{
		cFuncname 			= hdlKlikReset.name;

		if((parseInt(uJmlData)||0) > 0) {
			if(await Konfirm(obBahasa.confirm_reset)) initReset();
		} else initReset();	//-*/
	}
	const hdlKlikTampil=()=>{
		cFuncname 			= hdlKlikTampil.name;
		
		const vBulanID		= parseInt(uInpBulanID) || 0;
		const vTahun		= parseInt(uInpTahun) || 0;
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);

		if(vBulanID <= 0) {
			if(!UFunc.isEmpty(uElInpBulanID)) uElInpBulanID.focus();
			showToast(obBahasa.word_bulan+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}
		if(vTahun <= 0) {
			if(!UFunc.isEmpty(uElInpTahun)) uElInpTahun.focus();
			showToast(obBahasa.word_tahun+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}

		svcLoadReport();
	}
	const hdlKlikCetak=()=>{
		cFuncname = hdlKlikCetak.name;

		var vContent 	= gnrPrintContent();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel=()=>{
	}

	const initReset=()=> {
		cFuncname 			= initReset.name;
		
		//console.log("("+cComponentname+"-"+cFuncname+") uDataObj : "+JSON.stringify(uDataObj));
		setHTMLError500(null);
		setDataObj([]);
		setInpBulanID(uTglNowDT.getMonth()+1);
		setInpTahun(uTglNowDT.getFullYear());
		setInitTampilkan(false);

		setInitAwal("*) "+obBahasa.petunjuk_initlaplabarugi+"..");
	}
	const gnrPrintContent=()=>{
		cFuncname 		= gnrPrintContent.name;

		let vJenisBefore= "";
		let vSUMLabaRugi= 0;
		let vIdx		= 0; 
		let vSUMSubmain	= 0; 
		let vHTMLs		= uHTMLstyleTabel;

		vHTMLs += `
			<table id="idtabelhtml">
			<caption>`+
			uTitleSet+
			`</caption>`;

		vHTMLs += `<tbody>`;


		uDataObj.forEach(vObj=>{
			vIdx++;
			vSUMLabaRugi = vSUMLabaRugi + (vObj.jenis=="PENDAPATAN" ? parseInt(vObj.nilai) : - parseInt(vObj.nilai));

			let vHMTLSubTotal 	= "";
			let vHMTLHeader 	= "";
			if(vJenisBefore != vObj.jenis) {
				if(vIdx > 1) {
					vHMTLSubTotal	+= `
					<tr>
					<td>&nbsp;</td>
					<td colspan="2" align="right" style="font-weight:bolder;border-top:1px solid silver;">`+UFunc.formatAngka(vSUMSubmain)+`</td>
					</tr>
					`;
				}
				vSUMSubmain = 0;
				vHMTLHeader	+= `
					<tr>
					<td colspan='3' align="left" style="font-weight:bolder">`+(obBahasa["word_"+vObj.jenis.toLowerCase()] || vObj.jenis)+`</td>
					</tr>
				`;
			}
			
			vSUMSubmain = vSUMSubmain + parseInt(vObj.nilai);

			vHTMLs	+= vHMTLSubTotal;
			vHTMLs	+= vHMTLHeader;
			vHTMLs	+= `
			<tr>
			<td align="left">`+vObj.nama_akun+`</td>
			<td align="right">`+UFunc.formatAngka(vObj.nilai)+`</td>
			<td align="right">&nbsp;</td>
			</tr>
			`;
			if(vIdx >= uJmlData) 
				vHTMLs	+= `
					<tr>
					<td>&nbsp;</td>
					<td colspan="2" align="right" style="font-weight:bolder;border-top:1px solid silver;">`+UFunc.formatAngka(vSUMSubmain)+`</td>
					</tr>
				`;

			vJenisBefore = vObj.jenis;
		});

		vHTMLs 	+= `</tbody>`;
		var vCaptionLR		= (vSUMLabaRugi>=0 ? (obBahasa.word_laba||"LABA") : (obBahasa.word_rugi||"RUGI" )).toUpperCase();
		var vTeksValueLR	= (vSUMLabaRugi>=0 ? UFunc.formatAngka(vSUMLabaRugi) : "( "+UFunc.formatAngka(Math.abs(vSUMLabaRugi))+ " )");
		vHTMLs	+= `
			<tfoot style="font-weight:bold;font-size:larger">
			<tr>
			<td align="center">`+vCaptionLR+`</td>
			<td align="right" width="25%">&nbsp;</td>
			<td align="right" width="25%">`+vTeksValueLR+`</td>
			</tr>
			</tfoot>`;
		vHTMLs += `</table>`;

		return vHTMLs;
	}
	const svcInitData=()=>{
		cFuncname = svcInitData.name;

		/*//--TESTING_FRONTEND--/
		//console.log("("+cComponentname+") "+cFuncname+" => cKeywordInit : "+cKeywordInit);
		let vTmpObj	= [
			{id:"1",caption:"JANUARI"},
			{id:"2",caption:"FEBRUARI"},
			{id:"3",caption:"MARET"},
			{id:"4",caption:"APRIL"},
			{id:"5",caption:"MEI"},
			{id:"6",caption:"JUNI"},
			{id:"7",caption:"JULI"},
			{id:"8",caption:"AGUSTUS"},
			{id:"9",caption:"SEPTEMBER"},
			{id:"10",caption:"OKTOBER"},
			{id:"11",caption:"NOVEMBER"},
			{id:"12",caption:"DESEMBER"},
		]
		setBulanObj(vTmpObj);

		vTmpObj	= [
			{value:"2021",caption:"2021"},
			{value:"2020",caption:"2020"},
			{value:"2019",caption:"2019"},
			{value:"2018",caption:"2018"},
			{value:"2017",caption:"2017"},
		]
		setTahunObj(vTmpObj);

		return;
		//--END TESTING_FRONTEND--*/

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_laplabarugi/ld_init";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			//console.log("("+cComponentname+") "+cFuncname+" => output_string : "+JSON.stringify(output_string));
			setLoading(false);
			setBtnTampilDisabledBl(false);
			if(output_string.tampil) {
				setInitTampilkan(true);
				let vTmpObj = []
				if(output_string.bulanobj) {
					vTmpObj = JSON.parse(output_string.bulanobj);
					setBulanObj(vTmpObj);
				}
				if(output_string.tahunobj) {
					vTmpObj = JSON.parse(output_string.tahunobj);
					setTahunObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
		});	
	}
	const svcLoadReport=()=>{
		cFuncname 			= svcLoadReport.name;
		setInitAwal(null);
		setHTMLError500(null);
		setDataObj([]);
		
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);
		/*//--TESTING_FRONTEND--/
		let vTmpObj = [];

		vTmpObj = [
			{nama_akun:"Pendapatan Barang",nilai:"100000000",jenis:"PENDAPATAN"},
			{nama_akun:"Biaya HPP Barang",nilai:"3000000",jenis:"BIAYA"},
			{nama_akun:"Biaya ATK",nilai:"2000000",jenis:"BIAYA"},
			{nama_akun:"Biaya Jasa",nilai:"1500000",jenis:"BIAYA"},
			{nama_akun:"Biaya Operasional",nilai:"1700000",jenis:"BIAYA"},
			{nama_akun:"Biaya Tenaga Kerja",nilai:"2700000",jenis:"BIAYA"},
		];

		setDataObj(vTmpObj);
		setTitleSet(uFilterTitle);
		return;
		//--END TESTING_FRONTEND--*/

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_bulanid: uInpBulanID,
			send_tahun 	: uInpTahun,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_laplabarugi/ld_report";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnTampilDisabledBl(false);
			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setTitleSet(uFilterTitle);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});	
	}

	const loadFilter=()=>{
		cFuncname = loadFilter.name;
		if(uBulanObj.length <= 0) return;

		return (
		<div className="classfakesubheader">
			<CForm className="form-horizontal py-0" id="idformlaporan">
			<CFormGroup row className="px-0 my-0 justify-content-center">
			<CCol sm="12" className="text-center">
			<div className={"p-0 px-1 text-left align-top w-auto d-inline-block"}>
				<CSelect custom 
					value={uInpBulanID}
					onChange={(e)=>setInpBulanID(e.target.value)}
					id="inpbulanid">
					{uBulanObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} 
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_periodebulan}</div>
			</div>
			<div className={"p-0 px-1 text-left align-top w-auto d-inline-block"}>
				<CSelect custom 
					value={uInpTahun}
					onChange={(e)=>setInpTahun(e.target.value)}
					id="inptahun">
					{uTahunObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.value}  
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
				<CButton type="button" color="secondary" 
					color="warning"
					className="text-white font-weight-bold py-1 pt-2"
					disabled={uBtnTampilDisabledBl}
					onClick={hdlKlikTampil} 
					id="btnTampilkan">
					<CIcon name="cil-magnifying-glass" />&nbsp;{obBahasa.word_tampilkan}
				</CButton>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
			&nbsp;&middot;&nbsp;&nbsp;
			<CTooltip content={obBahasa.caption_resetview}>
				<CLink 
					onClick={hdlKlikReset} 
					className="classikon classikonreset"
					id="idklikreset"/>
			</CTooltip>
			</div>
			</CCol>
			</CFormGroup>
			</CForm>
		</div>
		);
	}
	const loadContent=()=>{
		cFuncname = loadContent.name;

		if(uBtnTampilDisabledBl) return (
			<div className="text-center classpetunjuk">{obBahasa.pesan_mohontunggu}</div>
		);

		if(uHTMLError500) return (
			<>{UFunc.renderHTML(uHTMLError500)}</>
		);
		if(uInitAwal) return (
			<div className="font-italic classfontlarger text-info px-4">{UFunc.renderHTML(uInitAwal)}</div>
		);

		if(uJmlData <= 0) return (
			<div className="text-center font-italic classfontlarger text-info px-4">
				&middot;&middot;&middot;{obBahasa.caption_dataempty}&middot;&middot;&middot;
			</div>
		)

		return (
			<div className="table-responsive-sm" xs="12">
			<table className="table" id="idtabeldata">
			<tbody>
			{loadBodyTabel()}
			</tbody>
			{loadFooterTabel()}
			</table>
			</div>
		)
	}
	const loadBodyTabel=()=>{
		cFuncname = loadBodyTabel.name;
		
		if(uJmlData <= 0) return;

		let vJenisBefore= "";
		let vIdx		= 0; 
		let vSUMSubmain	= 0; 

		return uDataObj.map((vItems,vKey)=>{
			const { nama_akun,jenis,nilai } = vItems;
			vIdx++;

			let vHMTLHeader		= "";
			let vHMTLSubTotal	= "";
			let vHMTLSubTotalLast	= "";

			if(vJenisBefore != jenis) {
				if(vIdx > 1) {
					vHMTLSubTotal	+= `
					<td colspan="2"></td>
					<td width="25%" align="right" class="font-weight-bolder">`+UFunc.formatAngka(vSUMSubmain)+`</td>
					`;
					vHMTLSubTotal  = React.createElement("tr", {class:"", dangerouslySetInnerHTML: { __html: vHMTLSubTotal } });
				}
				vSUMSubmain = 0;
				vHMTLHeader	+= `
					<td colspan='3'>`+(obBahasa["word_"+jenis.toLowerCase()] || jenis)+`</td>
				`;
				vHMTLHeader  = React.createElement("tr", {class:"classtabelfakethead", dangerouslySetInnerHTML: { __html: vHMTLHeader } });
			}

			//console.log("("+cComponentname+"-"+cFuncname+") vHMTLHeader : "+vHMTLHeader);

			vJenisBefore = jenis;
			vSUMSubmain = vSUMSubmain + (parseInt(nilai));
			if(vIdx >= uJmlData) {
				vHMTLSubTotalLast	+= `
					<td colspan="2"></td>
					<td width="25%" align="right" class="font-weight-bolder">`+UFunc.formatAngka(vSUMSubmain)+`</td>
				`;
				vHMTLSubTotalLast  = React.createElement("tr", {class:"", dangerouslySetInnerHTML: { __html: vHMTLSubTotalLast } });
				//vHMTLSubTotalLast.classList.add("classtabelfakethead");
			}
			return (
				<>
				{vHMTLSubTotal}
				{vHMTLHeader}
				<tr key={vKey}>
				<td>{nama_akun}</td>
				<td width="25%" className="text-right">{UFunc.formatAngka(nilai)}</td>
				<td width="25%" className="text-right"></td>
				</tr>
				{vHMTLSubTotalLast}
				</>
			);
		});
	}
	const loadFooterTabel=()=>{
		cFuncname = loadFooterTabel.name;
		if(uJmlData <= 0) return null;

		var vSUMLabaRugi	= 0;
		uDataObj.forEach(vObj=>{
			vSUMLabaRugi = vSUMLabaRugi + (vObj.jenis=="PENDAPATAN" ? parseInt(vObj.nilai) : - parseInt(vObj.nilai));
		})
		//vSUMLabaRugi = -1 * vSUMLabaRugi;

		var vCaptionLR		= (vSUMLabaRugi>=0 ? (obBahasa.word_laba||"LABA") : (obBahasa.word_rugi||"RUGI" )).toUpperCase();
		var vTeksValueLR	= (vSUMLabaRugi>=0 ? UFunc.formatAngka(vSUMLabaRugi) : "( "+UFunc.formatAngka(Math.abs(vSUMLabaRugi))+ " )");
		return (
			<tfoot className={"classbordertopdobel classborderbottom classtabeltfootlaporan font-weight-bolder"+
				(vSUMLabaRugi<0?" text-danger":" text-success")}>
			<tr>
			<td colSpan={2} className={"text-center"}>{vCaptionLR}</td>
			<td className="text-right ">{vTeksValueLR}</td>
			</tr>
			</tfoot>
		);//-*/
	}

	return (
		<>
		{loadFilter()}
		<CCard>
		<CCardHeader>
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
		<div className={"card-header-actions text-right"+(uJmlData<=0?" d-none":"")}>
			<CTooltip content={obBahasa.caption_cetaklaporan}>
				<CLink className="" onClick={hdlKlikCetak} className="classikon classikoncetak"/>
			</CTooltip>
			<span className="d-none">&nbsp;&middot;&nbsp;</span>
			<CTooltip content={obBahasa.caption_exportexcel} className="">
				<CLink className="" onClick={hdlKlikExcel} className="classikon classikonexcel d-none"/>
			</CTooltip>
		</div>
		</CCardHeader>
		<CCardBody>
		{loadContent()}		
		</CCardBody>
		</CCard>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}

export default Laplabarugi;
	