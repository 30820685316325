import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CForm,
	CFormGroup,
	CLabel,
	CInputRadio,
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
var pjson     = require('../../package.json');

const ILangs = (props) => {
	const cComponentName 	= ILangs.name;
	const cHistory			= useHistory();
	const cDispatch			= useDispatch();
	
	const {setLoading,showToast,prosesExpired}	= props;

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	const [vBtnSimpanDisabled,setBtnSimpanDisabled]	= React.useState(false);
	const [vBahasaSet,setBahasaSet]			= React.useState("BAHASA");
	const [vBahasaSelect,setBahasaSelect]	= React.useState("BAHASA");
	const [vObjBahasa,setObjBahasa]			= React.useState([]);
	const [vHTMLError500,setHTMLError500]	= React.useState("");
	let vContent;

	React.useEffect(()=>{
		//---LOAD_LIST_BAHASA--/
		svcloadData();
		//---END LOAD_LIST_BAHASA--/
	},[]);
	const hdlKlikSimpan = () => {
		//--VALIDASI--/
		if(vBahasaSet.toUpperCase() == vBahasaSelect.toUpperCase()) {
			return;
		}
		//--END VALIDASI--*/

		svcKlikSimpan();
	}

	const svcloadData = () => {
		var vFuncName = svcloadData.name;
		setHTMLError500("");

		/*//--DATA_UNTUK_TESTING--/
		setBahasaSet("bahasax"); setBahasaSelect("bahasax");
		let vTmpObj	= [
			{value: "bahasa",caption: "BAHASA INDONESIA"},
			{value: "english",caption: "BAHASA INGGRIS"},
			{value: "bahasax",caption: "BAHASA INDONESIA X"},
			{value: "englishx",caption: "BAHASA INGGRIS X"}
		]
		setObjBahasa(vTmpObj);
		return;//-*/

		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_langs/ld_data";

		setLoading(true);
		setBtnSimpanDisabled(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setBtnSimpanDisabled(false);
			setLoading(false);
			if(output_string.tampil) {
				if(output_string.bahasaset) {
					setBahasaSet(output_string.bahasaset);
					setBahasaSelect(output_string.bahasaset);
				}
				if(output_string.listbahasa) {
					setObjBahasa(JSON.parse(output_string.listbahasa));
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnSimpanDisabled(false);
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}
	const svcKlikSimpan = () => {
		var vFuncName = svcKlikSimpan.name;

		var vDATAS    = JSON.stringify({
			send_bahasaselect : vBahasaSelect,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_langs/pr_simpan";

		setLoading(true);
		setBtnSimpanDisabled(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setBtnSimpanDisabled(false);
			setLoading(false);
			if(output_string.sukses) {
				if(output_string.langs) {
					cDispatch({type: "set", listBahasa: JSON.parse(output_string.langs)});
				}
				showToast(obBahasa.pesan_sukses,"SUKSES");
				setTimeout(function(e){ 
					//const vUrl = window.location.href;
					const vUrl = "/";//pjson.homepage;
					//cHistory.go("/?")
					//console.log("("+cComponentName+"-"+vFuncName+") vUrl : "+vUrl);
					window.location.assign(vUrl);
				},300);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnSimpanDisabled(false);
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			cHistory.push("/error500");
		});
	}

	if(vHTMLError500)
		vContent = <div dangerouslySetInnerHTML={{__html: vHTMLError500 }} />;
	else {
		let TMPContent = [];
		vObjBahasa.map((items,idx)=>{
			TMPContent.push(
			<CFormGroup key={idx} variant="checkbox" className="classradiobutton">
				<CInputRadio className="form-check-input" 
					id={"inpbahasa"+items.value.toLowerCase()} 
					name="inpbahasa" 
					defaultChecked={vBahasaSet.toUpperCase() === items.value.toUpperCase()}
					onChange={(e)=>setBahasaSelect(e.target.value)}
					value={items.value} />
				<CLabel variant="checkbox" htmlFor={"inpbahasa"+items.value.toLowerCase()}>{items.caption}</CLabel>
			</CFormGroup>
			);
		});
		vContent = TMPContent;
	}

	return (
	<CRow className="justify-content-center">
		<CCol xs="12" sm="10" lg="8">
		<CCard>
			<CForm>
			<CCardHeader className="font-weight-bolder">{obBahasa.caption_ubahbahasa}</CCardHeader>
			
			<CCardBody>
				<CRow className="justify-content-center">
				<CCol xs="10">
				{vContent}
				</CCol>
				</CRow>
			</CCardBody>

			<CCardFooter>
				<CButton size="md" 
					onClick={()=>hdlKlikSimpan()}
					disabled={vBtnSimpanDisabled}
					color="warning">
					<CIcon name="cil-check-circle" />&nbsp;{""+obBahasa.word_simpan}
				</CButton>
			</CCardFooter>
			</CForm>
		</CCard>
		</CCol>
	</CRow>
	)
}

export default ILangs;