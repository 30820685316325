import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CListGroup,
	CListGroupItem,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { FormHutang,DialogView } from '../components/index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const Hutang = (props) => {
	const cComponentname 	= Hutang.name;
	const {setLoading,showToast,prosesExpired}	= props;

	let cFuncname			= "";
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  	= useSelector(state => state.listBahasa);
	const objToken		= JSON.parse(localStorage.getItem("token"));

	const cElJmlData 	= document.getElementById("idjmldata");
	const cElInpFocus 	= document.getElementById("inptanggal");
	const uElBtnSimpan	= document.getElementById("btnDialogSimpan");

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const uListIDTabelInit					= useSelector(state => state.listTabelID);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_hutang||"");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate(new Date()));
	const [uInpTanggalDt,setInpTanggalDt]	= React.useState(new Date());
	const [uInpNilaiBayar,setInpNilaiBayar]	= React.useState(0);
	const [uShowFormBl,setShowForm]			= React.useState(false);
	const [uIsBtnSimpanDisabledBl,setBtnSimpanDisabledBl]= React.useState(false);

	const [uFormHeader,setFormHeader]		= React.useState(obBahasa.caption_formbayarhutang);
	const [uFormDataSupplier,setFormDataSupplier]	= React.useState("");
	const [uFormDataTglHutang,setFormDataTglHutang]	= React.useState("");
	const [uFormDataTglHutangDb,setFormDataTglHutangDb]	= React.useState("");
	const [uFormDataNoNota,setFormDataNoNota]		= React.useState("");
	const [uFormDataTotalHutang,setFormDataTotalHutang]= React.useState("");
	const [uFormDataSisaHutang,setFormDataSisaHutang]= React.useState("");

	const [uShowDetilBl,setShowDetil]		= React.useState(false);
	const [uDetilHeader,setDetilHeader]		= React.useState("");
	const [uDetilObj,setDetilObj]			= React.useState([]);

	React.useEffect(()=>{ setKeywordInit(uKeywordInitObj.kwd_hutang||""); },[uKeywordInitObj]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{ setJmlData((parseInt(uDataObj.length)||0)); },[uDataObj]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
		if(!UFunc.isEmpty(cElJmlData)) cElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);
	React.useEffect(()=>{ setInpTanggalDt(new Date(uInpTanggal)); },[uInpTanggal]);
	React.useEffect(()=>{ 
		//console.log("("+cComponentname+") useEffect[uIsBtnSimpanDisabledBl] uElBtnSimpan = "+uElBtnSimpan );
		if(!UFunc.isEmpty(uElBtnSimpan)) uElBtnSimpan.disabled = uIsBtnSimpanDisabledBl;
	},[uIsBtnSimpanDisabledBl]);
	React.useEffect(()=>{
		if(!uShowFormBl) return;

		var vTimeOut = setTimeout(function(e){
			if(!UFunc.isEmpty(cElInpFocus)) cElInpFocus.focus();
			clearTimeout(vTimeOut);
		},500);
	},[uShowFormBl]);

	const hdlKlikBayar=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		
		setInpTabelID(_IDTABEL);
		setInpTanggal(UFunc.DbDate(new Date()));
		setInpNilaiBayar(0);

		if(_IDTABEL <= 0) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vObjdx		= UFunc.getObjectIndeks(uDataObj,_IDTABEL);

		//setFormHeader(obBahasa.caption_editdata+": "+vCaptionUpper);
		setFormDataSupplier(uDataObj[vObjdx].supplier_nama||"");
		setFormDataNoNota(uDataObj[vObjdx].no_nota||"");
		setFormDataTglHutang(uDataObj[vObjdx].tgl_hutang||"");
		setFormDataTglHutangDb(uDataObj[vObjdx].tgl_hutangdb||"");
		setFormDataTotalHutang(uDataObj[vObjdx].total_hutang||"");
		setFormDataSisaHutang((uDataObj[vObjdx].total_hutang-uDataObj[vObjdx].terbayar));

		setShowForm(true);
	}
	const hdlKlikDetil=(_IDTABEL)=>{
		cFuncname	= hdlKlikDetil.name;

		_IDTABEL = parseInt(_IDTABEL) || 0;

		setInpTabelID(_IDTABEL);
		if(_IDTABEL <= 0) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vIdx		= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		if(vIdx < 0) return;

		//console.log("("+cComponentname+") "+cFuncname+" => vIdx = "+vIdx);

		const vCaptionUpper	= (uDataObj[vIdx].tgl_hutang+" "+uDataObj[vIdx].supplier_nama+
		((uDataObj[vIdx].no_nota||"")!=""?" ("+uDataObj[vIdx].no_nota+")":""));
		setDetilHeader(obBahasa.caption_detilbayarhutang+": "+vCaptionUpper);

		svcloadDetil(_IDTABEL);
	}
	const hdlFormToggle=()=>{ setShowForm(!uShowFormBl)	}

	const svcloadData=()=>{
		cFuncname = svcloadData.name;
		setHTMLError500("");
		//console.log("("+cComponentname+") "+cFuncname+" => Proses ");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",tgl_hutang:"Kam,10 Nov 2020",tgl_hutangdb:"2020-11-10",no_nota:"XX73655",supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"300000",terbayar:"10000"},
			{id:"2",tgl_hutang:"Jum,10 Nov 2020",tgl_hutangdb:"2020-11-10",no_nota:"XX73654",supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"400000",terbayar:"10000"},
			{id:"3",tgl_hutang:"Sab,10 Des 2020",tgl_hutangdb:"2020-12-10",no_nota:"XX73653",supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"500000",terbayar:"10000"},
			{id:"4",tgl_hutang:"Sen,10 Nov 2020",tgl_hutangdb:"2020-11-09",no_nota:"XX73652",supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"3800000",terbayar:"10000"},
			{id:"5",tgl_hutang:"Sel,10 Des 2020",tgl_hutangdb:"2020-12-10",no_nota:"XX73651",supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"600000",terbayar:"10000"},
			{id:"6",tgl_hutang:"Rab,15 Nov 2020",tgl_hutangdb:"2020-11-15",no_nota:null,supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"3600000",terbayar:"10000"},
			{id:"7",tgl_hutang:"Jum,10 Okt 2020",tgl_hutangdb:"2020-10-10",no_nota:"XX73649",supplier_id:"1",supplier_nama:"PALAWIJA",total_hutang:"600000",terbayar:"10000"},
			{id:"8",tgl_hutang:"Kam,10 Sep 2020",tgl_hutangdb:"2020-09-10",no_nota:"XX73648",supplier_id:"1",supplier_nama:"PALAWIJA C",total_hutang:"1200000",terbayar:"10000"},
			{id:"9",tgl_hutang:"Sen,10 Feb 2021",tgl_hutangdb:"2021-02-10",no_nota:"XX73647",supplier_id:"1",supplier_nama:"PALAWIJA A",total_hutang:"500000",terbayar:"10000"},
			{id:"10",tgl_hutang:"Sel,10 Jan 2021",tgl_hutangdb:"2021-01-10",no_nota:"XX73646",supplier_id:"1",supplier_nama:"PALAWIJA D",total_hutang:"800000",terbayar:"10000"},
		]
		setDataObj(vTmpObj);
		setKeywordLoad(uKeywordInit);

		return;
		//--END TESTING_FRONTEND--*/

		setJmlData(0);
		setDataObj([]);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_hutang/ld_data";

		//console.log("("+cComponentname+"-"+cFuncname+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				//setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}
	const svcProsesSimpan=()=>{
		cFuncname = svcProsesSimpan.name;

		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		if(vIdx < 0) return;

		/*//--TESTING_FRONTEND--/
			setShowForm(!uShowFormBl);

			const vTmpObj		= uDataObj;
			//setDataObj([]);
			let vTotalTrx = (parseInt(vTmpObj[vIdx].total_hutang)||0); 
			let vTerbayar = (parseInt(vTmpObj[vIdx].terbayar)||0) 
				+ (parseInt(uInpNilaiBayar)||0);
			vTmpObj[vIdx].terbayar = vTerbayar;
			if(vTotalTrx - vTerbayar <= 0) {
				vTmpObj.splice(vIdx,1);
				console.log("("+cComponentname+") "+cFuncname+" vTmpObj = "+JSON.stringify(vTmpObj));
				setJmlData(vTmpObj.length);
			}
			var vTimeOut = setTimeout(function(){setDataObj(vTmpObj);clearTimeout(vTimeOut)},200);
			setBodyInit(!uBodyInit);

			return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_hutangid : uInpTabelID,
			send_tanggal : uInpTanggal,
			send_nilaibayar : uInpNilaiBayar,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_hutang/pr_simpan";

		//console.log("("+cComponentname+"-"+cFuncname+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.sukses) {
				setShowForm(!uShowFormBl);

				const vTmpObj		= uDataObj;
				let vTotalTrx = (parseInt(vTmpObj[vIdx].total_hutang)||0) 
				let vTerbayar = (parseInt(vTmpObj[vIdx].terbayar)||0) 
					+ (parseInt(uInpNilaiBayar)||0);
				vTmpObj[vIdx].terbayar = vTerbayar;
				if(vTotalTrx - vTerbayar <= 0) {
					vTmpObj.splice(vIdx,1);
					setJmlData(vTmpObj.length);
				}
				setDataObj(vTmpObj);
				setBodyInit(!uBodyInit);

			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowForm(!uShowFormBl);
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			cHistory.push("/error500");
		});
	}
	const svcloadDetil=(_IDTABEL)=>{
		cFuncname	= svcloadDetil.name;
		_IDTABEL	= parseInt(_IDTABEL) || 0;

		setDetilObj([]);
		if(_IDTABEL <= 0) return;
		
		//console.log("("+cComponentname+") "+cFuncname+" => _IDTABEL = "+_IDTABEL);
		//setShowDetil(true); return;
		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{tgl_bayar:"Sen,10 Jan 2021",nilai_bayar: "150000"},
			{tgl_bayar:"Sen,10 Feb 2021",nilai_bayar: "350000"},
		];
		setDetilObj(vTmpObj);
		setShowDetil(true);
		return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_hutangid : _IDTABEL,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_hutang/ld_detil";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.detilobject) {
					vTmpObj = JSON.parse(output_string.detilobject);
					setDetilObj(vTmpObj);
				}
				setShowDetil(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	const loadContent=()=>{
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return (
			<div className="table-responsive-sm" xs="12">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow className="">
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.word_tanggal}</CCol>
				<CCol sm="12" md="2" lg="1" className="">#</CCol>
				<CCol sm="12" md="2" lg="3" className="text-left px-0">{obBahasa.word_supplier}</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.caption_totaltransaksi} (Rp)</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.word_terbayar} (Rp)</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.caption_sisahutang} (Rp)</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}
	const loadTabelBody=()=>{
		cFuncname	= loadTabelBody.name;
		//console.log("("+cComponentName+") "+cFuncname+" => uMaxData : "+uMaxData);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,tgl_hutang,no_nota,supplier_nama,
				total_hutang,terbayar
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				var vCaptionUpper	= (tgl_hutang+" "+supplier_nama+
					((no_nota||"")!=""?" ("+no_nota+")":"")).toUpperCase();

				var vNotaTanggal	= (tgl_hutang)+
				((no_nota||"")==""?""
					: "<div class='font-weight-bolder'>("+
					   	(uKeywordLoad==""?(no_nota||"")
							: (no_nota||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>")
					   	).toUpperCase()+
					")</div>"
				)+
				"";
				var vSupplier		= uKeywordLoad==""?supplier_nama
					: supplier_nama.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="2" className="text-center p-0 ">{UFunc.renderHTML(vNotaTanggal)}</CCol>
					<CCol sm="12" md="2" lg="1" className="text-center px-0 ">
						<CTooltip html={false} content={"--"+obBahasa.caption_bayarhutang+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikBayar(id)} className="classikon classikonbayar" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_detilbayarhutang+": "+vCaptionUpper}>
						<CLink 
							className="classikon classikonhistoribayar"
							onClick={()=>hdlKlikDetil(id)} />
						</CTooltip>
					</CCol>
					<CCol sm="12" md="2" lg="3" className="text-left px-0 text-primary">{UFunc.renderHTML(vSupplier)}</CCol>
					<CCol sm="12" md="2" className="text-right px-0 font-weight-bolder">{UFunc.formatAngka(total_hutang)}</CCol>
					<CCol sm="12" md="2" className="text-right px-0">{UFunc.formatAngka(terbayar)}</CCol>
					<CCol sm="12" md="2" className="text-right px-0 pr-2 text-success classfontlarger">{UFunc.formatAngka(total_hutang-terbayar)}</CCol>
					</CRow>
					</td>
				</tr>
				)
			}
		});
	}
	/*
	const hdlFormSimpan=()=>{
		cFuncname = hdlFormSimpan.name;

		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		if(vIdx < 0) return;

		//--VALIDASI--/
		if(UFunc.isEmpty(uInpTanggal)) {
			document.getElementById("inptanggal").focus();
			showToast(obBahasa.caption_tglpembelian+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vDateBeyond = UFunc.DateIsBeyond(uInpTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(uInpTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
			var vDateHutangDt	= UFunc.toDate(uDataObj[vIdx].tgl_hutangdb);
			var vSelisih		= Math.round((uInpTanggalDt-vDateHutangDt)/(1000*60*60*24));

			if(vSelisih < 0) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.caption_tglbayar+" "+obBahasa.caption_invalid+"..");
				return;
			}

		}
		if(uInpNilaiBayar <= 0) {
			document.getElementById("inpnilaibayar").focus();
			showToast(obBahasa.caption_nilaibayar+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			const vSisaHutang = uDataObj[vIdx].total_hutang - uDataObj[vIdx].terbayar; 
			if(uInpNilaiBayar > vSisaHutang) {
				document.getElementById("inpnilaibayar").focus();
				showToast(obBahasa.caption_nilaibayar+" "+obBahasa.caption_invalid+"..");
				return;
			}
		}
		//alert("("+cComponentname+") "+cFuncname+" vSelisih = "+vSelisih); return;
		//--END VALIDASI--/

		svcProsesSimpan();
	}
	const loadFormContent=()=>{
		cFuncname 			= loadFormContent.name;

		//---GET_DATA_FORM--/
		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		//---END GET_DATA_FORM--/
		//console.log("("+cComponentname+") "+cFuncname+" => vIdx = "+vIdx);
		//console.log("("+cComponentname+") "+cFuncname+" => uDataObj = "+JSON.stringify(uDataObj));

		if(vIdx < 0)
			return( <></> );

		return(
			<>
			<CCard className="justify-content-center">
			<CCardHeader className="py-1">
			<CRow>
			<CCol sm="12" md="2">
			<div className="text-primary font-weight-bolder">{uDataObj[vIdx].supplier_nama||""}</div>
			<div className="classpetunjuk">{obBahasa.word_supplier}</div>
			</CCol>
			<CCol sm="12" md="3">
			<div>{uDataObj[vIdx].tgl_hutang}</div>
			<div className="classpetunjuk">{obBahasa.caption_tglhutang}</div>
			</CCol>
			<CCol sm="12" md="2">
			<div className="font-weight-bolder classfontsmaller pt-1">{uDataObj[vIdx].no_nota || "-"}</div>
			<div className="classpetunjuk">{obBahasa.caption_nonota}</div>
			</CCol>
			<CCol sm="6" md="2">
			<div className="">Rp {UFunc.formatAngka(uDataObj[vIdx].total_hutang)}</div>
			<div className="classpetunjuk">{obBahasa.caption_totaltransaksi}</div>
			</CCol>
			<CCol sm="6" md="3">
			<div className="font-weight-bolder text-success">Rp {UFunc.formatAngka(uDataObj[vIdx].total_hutang-uDataObj[vIdx].terbayar)}</div>
			<div className="classpetunjuk">{obBahasa.caption_sisahutang}</div>
			</CCol>
			</CRow>
			</CCardHeader>
			<CCardBody>
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_tglbayar}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="10"
						type="date"
						className="form-control"
						value={uInpTanggal}
						onChange={(e) => setInpTanggal(e.target.value)}
						id="inptanggal" />
				</CCol>
				</CFormGroup>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_nilaibayar}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<NumberFormat 
						value={uInpNilaiBayar}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilaiBayar(values.value)}
						renderText={value => (
							<CInput 
								value={value}
								className={"text-right"}
								onFocus={(e)=>e.target.select()}
								onChange={(e) => setInpNilaiBayar(e.target.value)}
								onKeyDown={(e)=>initEnterNextTab(e,"btnDialogSimpan")}
							id="inpnilaibayar"/>
						)} 
					prefix={'Rp'}/>
				</CCol>
				</CFormGroup>
			</CForm>
			</CCardBody>
			</CCard>
			</>
		);
	}//-*/
	const loadDetilContent=()=>{
		cFuncname 			= loadDetilContent.name;

		//---GET_DATA_FORM--/
		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		//---END GET_DATA_FORM--/

		if(vIdx < 0 || !uShowDetilBl) return null;
		//console.log("("+cComponentname+") "+cFuncname+" => uInpTabelID = "+uInpTabelID);

		const vDetilcontent=()=>{
			if(uDetilObj.length <= 0) return (<div className="font-italic text-info">--{obBahasa.pesan_pembayaranempty}--</div>);

			var vTotalBayar	= 0;
			return (
				<CListGroup>
				{uDetilObj.map((vItemDetil,vKey)=>{
					vTotalBayar = vTotalBayar + (parseInt(vItemDetil.nilai_bayar)||0);
					return (
					<CListGroupItem>
					<CRow>
					<CCol sm="1" className="text-right">{vKey+1}.</CCol>
					<CCol sm="7">{obBahasa.word_tanggal+": "+vItemDetil.tgl_bayar}</CCol>
					<CCol sm="4" className="text-right font-weight-bolder text-info">Rp {UFunc.formatAngka(vItemDetil.nilai_bayar)}</CCol>
					</CRow>
					</CListGroupItem>
					)
				})}
					<CListGroupItem>
					<CRow>
					<CCol sm="1"></CCol>
					<CCol sm="7" className="text-right font-weight-bolder">{obBahasa.word_total}</CCol>
					<CCol sm="4" className="text-right font-weight-bolder text-info">Rp {UFunc.formatAngka(vTotalBayar)}</CCol>
					</CRow>
					</CListGroupItem>
				</CListGroup>
			)
		}

		return(
			<>
			<CCard className="justify-content-center">
			<CCardHeader className="py-1">
			<strong>
				{obBahasa.caption_totaltransaksi+": Rp "}
				<span className="classfontlarger text-success">{UFunc.formatAngka(uDataObj[vIdx].total_hutang)}</span>
			</strong>
			</CCardHeader>
			<CCardBody>{vDetilcontent()}
			</CCardBody>
			</CCard>
			</>
		);
	}

	//console.log("("+cComponentname+") "+cFuncname+" => uFormHeader = "+uFormHeader);

	return (
		<>
		<CRow className="p-0 justify-content-center">
		<CCol xs="12" className="p-0">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlData >= uMaxData) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<FormHutang
			pInpTabelID="0"
			pInpTanggal={uInpTanggal}
			pInpNilaiBayar={uInpNilaiBayar}
			onChangeTanggal={(e)=>setInpTanggal(e.target.value)}
			onChangeNilaiBayar={(e)=>setInpNilaiBayar(e.target.value)}
			onChangeNilaiBayarNumber={(values)=>setInpNilaiBayar(values.value)}

			pDataSupplier={uFormDataSupplier}
			pDataTglHutang={uFormDataTglHutang}
			pDataTglHutangDb={uFormDataTglHutangDb}
			pDataNoNota={uFormDataNoNota}
			pDataTotalHutang={uFormDataTotalHutang}
			pDataSisaHutang={uFormDataSisaHutang}

			onProsessimpan={svcProsesSimpan}
			show={uShowFormBl} 
			dialogHeader={uFormHeader}
			toggle={hdlFormToggle}
			{...props}/>

		<DialogView
			options={{size:"lg",centered:false}}
			show={uShowDetilBl} 
			dialogHeader={uDetilHeader}
			toggle={()=>setShowDetil(!uShowDetilBl)}
			renderContent={loadDetilContent()}/>
		</>
	)
}

export default Hutang;
	