import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CInput,
	CInputGroupPrepend,
	CInputGroup,
	CSelect,
	CLabel,
	CCollapse,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { CChartLine,CChartBar } from '@coreui/react-chartjs';
//import ReactExport from "react-export-excel";
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';

var pjson     = require('../../package.json');

const Lappenjualan = (props) => {
	const cComponentname 		= Lappenjualan.name;
	let cFuncname;
	const cDispatch				= useDispatch();
	const cHistory				= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});
	const {setLoading,showToast,prosesExpired}	= props;

	/*const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//-*/

  	const obBahasa 					= useSelector(state => state.listBahasa);
	const uHTMLstyle				= useSelector(state => state.gHTMLstyleLaporan);
	const uHTMLstyleGrafik			= (useSelector(state => state.gHTMLstyleGrafik));
	const objToken	= JSON.parse(localStorage.getItem("token"));

	const uTglNowDT							= new Date();
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uBtnTampilDisabledBl,setBtnTampilDisabledBl]	= React.useState(false);
	//const [uKlikCetak,setKlikCetak]			= React.useState(false);

	const [uBulanObj,setBulanObj]	= React.useState([]);
	const [uTahunObj,setTahunObj]	= React.useState([]);
	const [uBarangObj,setBarangObj]	= React.useState([]);

	const [uInpBulanID,setInpBulanID] 	= React.useState(uTglNowDT.getMonth()+1);
	const [uInpTahun,setInpTahun] 		= React.useState(uTglNowDT.getFullYear());
	const [uInpBarangID,setInpBarangID]	= React.useState(0);
	const [uBarangIDSet,setBarangIDSet]	= React.useState(0);

	const [uInitGrafikShow,setInitGrafikShow]= React.useState(useSelector(state => state.gInitGrafikShow));
	const [uFilterTitle,setFilterTitle]	= React.useState("");
	const [uTitleSet,setTitleSet]		= React.useState("");
	const [uInitSort,setInitSort]		= React.useState({});

	const [uInitAwal,setInitAwal]			= React.useState("*) "+obBahasa.petunjuk_initlappenjualan+"..");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	/*const uElBtnTampil 	= document.getElementById("btnTampilkan");
	const uElIkonReset 	= document.getElementById("idklikreset");
	const uElFormLaporan= document.getElementById("idformlaporan");//-*/
	const uElInpBulanID = document.getElementById("inpbulanid");
	const uElInpTahun 	= document.getElementById("inptahun");
	const uElInpBarangID= document.getElementById("inpbarangid");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");

	React.useEffect(()=>{ svcInitData(); },[]);
	React.useEffect(()=>{ cDispatch({type: "set", gInitGrafikShow: uInitGrafikShow}); },[uInitGrafikShow]);
	React.useEffect(()=>{ 
		setActivePage(1);
		setJmlData(uDataObj.length||0); 
		setInitSort({});
	},[uDataObj]);
	React.useEffect(()=>{
		cFuncname 			= "useEffect[uJmlData]";
		//console.log("("+cComponentname+"-"+cFuncname+") uJmlData = "+uJmlData);

		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
	},[uJmlData]);
	React.useEffect(()=>{
		let vTitle	= "<div>"+obBahasa["menus_"+props.namaRoute.toLowerCase()]+"</div>";
		vTitle +=	(!UFunc.isEmpty(uElInpBulanID))
			? "<div>"+obBahasa.word_periode+": "+
				UFunc.getOptionSelectTeks(uElInpBulanID)+" "+
				UFunc.getOptionSelectTeks(uElInpTahun)+"</div>"
			: "";
		if((parseInt(uInpBarangID)||0) > 0)
			vTitle += (!UFunc.isEmpty(uElInpBarangID)) ? "<div style='font-style:italic'>"+UFunc.getOptionSelectTeks(uElInpBarangID)+"</div>" : "";
		setFilterTitle(vTitle);
	},[uInpBulanID,uInpTahun,uInpBarangID]);

	const hdlKlikReset=async()=>{
		cFuncname 			= hdlKlikReset.name;

		if((parseInt(uJmlData)||0) > 0) {
			if(await Konfirm(obBahasa.confirm_reset)) initReset();
		} else initReset();	//-*/
	}
	const hdlKlikTampil=()=>{
		cFuncname 			= hdlKlikTampil.name;
		
		const vBulanID		= parseInt(uInpBulanID) || 0;
		const vTahun		= parseInt(uInpTahun) || 0;
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);

		if(vBulanID <= 0) {
			if(!UFunc.isEmpty(uElInpBulanID)) uElInpBulanID.focus();
			showToast(obBahasa.word_bulan+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}
		if(vTahun <= 0) {
			if(!UFunc.isEmpty(uElInpTahun)) uElInpTahun.focus();
			showToast(obBahasa.word_tahun+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}

		svcLoadReport();
	}
	const hdlKlikCetak=()=>{
		cFuncname = hdlKlikCetak.name;

		var vContent 	= gnrPrintContent();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikCetakGrafik=()=>{
		cFuncname = hdlKlikCetakGrafik.name;

		//console.log("("+cComponentname+"-"+cFuncname+") chartURL : "+(chartURL));
		var vContent 	= gnrPrintGrafik();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		var vTimeout = setTimeout(function(){ 
			pri.focus();
			pri.print();
			clearTimeout(vTimeout);
		},300);
	}
	const hdlKlikExcel=()=>{
		cFuncname = hdlKlikExcel.name;
	}
	const hdlKlikSort=(_TYPE)=>{
		cFuncname 			= hdlKlikSort.name;
		if(UFunc.isEmpty(_TYPE)) _TYPE = "default";

		//console.log("("+cComponentname+"-"+cFuncname+") _TYPE : "+(_TYPE));

		if(_TYPE.toLowerCase()!="jml" && _TYPE.toLowerCase()!="nilai")
			_TYPE = "default";

		const types = {
			jml 	: "jml",
			nilai 	: "nilai",
			default : "nama_barang",
		};
		let vInitSort		= uInitSort;
		const vLastSORT = vInitSort[_TYPE] || "";

		let vSORT;
		switch((vLastSORT||"").toUpperCase()) {
			case "UP" :
				vSORT = "DOWN"; 
				break;
			case "DOWN" :
				vSORT = "default"; 
				break;
			default: vSORT = "UP";
		}//-*/
		if(UFunc.isEmpty(vSORT)) vSORT = "UP";
		vInitSort = { [_TYPE] : vSORT };
		setInitSort(vInitSort);

		const sortProperty 	= vSORT.toLowerCase()=="default" ? types["default"] : types[_TYPE];

		let vSorted;
		if(vSORT.toLowerCase()=="default") {
			vSorted = uDataObj.sort((a, b) => -1);
		}else {
			if(vSORT.toLowerCase()=="up")
				vSorted = uDataObj.sort((a, b) => b[sortProperty] - a[sortProperty]);
			else
				vSorted = uDataObj.sort((a, b) => a[sortProperty] - b[sortProperty]);
		}
		//console.log("("+cComponentname+"-"+cFuncname+") vSorted : "+JSON.stringify(vSorted));

		setDataObj(vSorted);
		setBodyInit(!uBodyInit);
	}

	const initReset=()=> {
		cFuncname 			= initReset.name;
		
		//console.log("("+cComponentname+"-"+cFuncname+") uDataObj : "+JSON.stringify(uDataObj));
		setHTMLError500(null);
		setDataObj([]);
		setInpBulanID(uTglNowDT.getMonth()+1);
		setInpTahun(uTglNowDT.getFullYear());
		setInpBarangID(0);
		setBarangIDSet(0);

		setInitAwal("*) "+obBahasa.petunjuk_initlappenjualan+"..");
	}
	const gnrPrintContent=()=>{
		var vHTMLs = uHTMLstyle;

		vHTMLs += `
			<table id="idtabelhtml">
			<caption>`+
			uTitleSet+
			`</caption>
			<thead>
			<tr>
			<th width="5%">No</th>`;
		if((parseInt(uBarangIDSet)||0) > 0)
			vHTMLs += `
				<th width="15%">`+obBahasa.word_tanggal+`</th>`;
		vHTMLs += `
			<th align="left">`+obBahasa.word_keterangan+`</th>
			<th width="15%">`+obBahasa.word_jumlah+`</th>
			<th width="20%">`+obBahasa.word_nilai+` (Rp)</th>
			</tr>
			</thead>
			<tbody>`;

		var vSumNilai 	= 0;	
		var vColspan	= 1;
		uDataObj.map((vItems,vKey)=>{
			if((parseInt(uBarangIDSet)||0) > 0){
				const {
					id_item,tanggal,keterangan,jml,satuan,nilai
				} = vItems;
				var vJml 		= UFunc.formatAngka(jml);
				var vNilai 		= UFunc.formatAngka(nilai);
				vSumNilai 		= vSumNilai + (parseInt(nilai)||0);
				vColspan		= 4;

				vHTMLs += `
				<tr key={vKey} valign="top">
						<td align="right">`+(vKey+1)+`.</td>
						<td align="center">`+UFunc.TglAngka(tanggal)+`</td>
						<td align="left" style="font-style:italic;">`+keterangan+`</td>
						<td align="center">`+vJml+` `+satuan+`</td>
						<td align="right" style="font-weight:bolder;">`+vNilai+`</td>
				</tr>`;
			} else {
				const {
					id,nama_barang,jml,satuan,nilai
				} = vItems;
				var vJml 		= UFunc.formatAngka(jml);
				var vNilai 		= UFunc.formatAngka(nilai);
				vSumNilai 		= vSumNilai + (parseInt(nilai)||0);
				vColspan		= 3;

				vHTMLs += `
				<tr key={vKey} valign="top">
						<td align="right">`+(vKey+1)+`.</td>
						<td align="left" style="">`+nama_barang+`</td>
						<td align="center">`+vJml+` `+satuan+`</td>
						<td align="right" style="font-weight:bolder;">`+vNilai+`</td>
				</tr>`;
			}
		});
		vHTMLs 	+= `</tbody>`;
		vHTMLs 	+= `<tfoot>`;
		vHTMLs += `
			<tr key={vKey} valign="top">
			<td align="center" colspan="`+vColspan+`">`+obBahasa.word_jumlah+`</td>
			<td align="right" style="font-weight:bolder;">`+UFunc.formatAngka(vSumNilai)+`</td>
		`;
		vHTMLs 	+= `</tfoot>`;
		vHTMLs 	+= `</table>`;

		return vHTMLs;
	}
	const gnrPrintGrafik=()=>{
		cFuncname 			= gnrPrintGrafik.name;

		var vContent 	= document.getElementById("idgrafiklaporan");
		var chartURL 	= vContent.querySelector("canvas").toDataURL("image/png");
		
		//console.log("("+cComponentname+"-"+cFuncname+") uTitleSet : "+(uTitleSet));
		var vHTMLs		= uHTMLstyleGrafik;
		vHTMLs			+= `
			<h1 style="font-size:150%" align="center">`+uTitleSet+`</h1>
			<center><img src="`+chartURL + `" width="90%" /></center>
		`;

		return vHTMLs;
	}
	const svcInitData=()=>{
		cFuncname = svcInitData.name;

		//--TESTING_FRONTEND--/
		/*//console.log("("+cComponentname+") "+cFuncname+" => cKeywordInit : "+cKeywordInit);
		let vTmpObj	= [
			{id:"1",caption:"JANUARI"},
			{id:"2",caption:"FEBRUARI"},
			{id:"3",caption:"MARET"},
			{id:"4",caption:"APRIL"},
			{id:"5",caption:"MEI"},
			{id:"6",caption:"JUNI"},
			{id:"7",caption:"JULI"},
			{id:"8",caption:"AGUSTUS"},
			{id:"9",caption:"SEPTEMBER"},
			{id:"10",caption:"OKTOBER"},
			{id:"11",caption:"NOVEMBER"},
			{id:"12",caption:"DESEMBER"},
		]
		setBulanObj(vTmpObj);

		vTmpObj	= [
			{value:"2021",caption:"2021"},
			{value:"2020",caption:"2020"},
			{value:"2019",caption:"2019"},
			{value:"2018",caption:"2018"},
			{value:"2017",caption:"2017"},
		]
		setTahunObj(vTmpObj);

		vTmpObj	= [
			{id:"1",caption:"Barang 01"},
			{id:"2",caption:"Barang 02"},
			{id:"3",caption:"Barang 03"},
			{id:"4",caption:"Barang 04"},
			{id:"5",caption:"Barang 05"},
			{id:"6",caption:"Barang 06"},
			{id:"7",caption:"Barang 07"},
			{id:"8",caption:"Barang 08"},
			{id:"9",caption:"Barang 09"},
		]
		setBarangObj(vTmpObj);

		return;
		//--END TESTING_FRONTEND--*/

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}
		//console.log("("+cComponentname+") "+cFuncname+" => userinit : "+objToken.userinit);

		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_lappenjualan/ld_init";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			//console.log("("+cComponentname+") "+cFuncname+" => output_string : "+JSON.stringify(output_string));
			setLoading(false);
			setBtnTampilDisabledBl(false);
			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.bulanobj) {
					vTmpObj = JSON.parse(output_string.bulanobj);
					setBulanObj(vTmpObj);
				}
				if(output_string.tahunobj) {
					vTmpObj = JSON.parse(output_string.tahunobj);
					setTahunObj(vTmpObj);
				}
				if(output_string.barangobj) {
					vTmpObj = JSON.parse(output_string.barangobj);
					setBarangObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});	
	}
	const svcLoadReport=()=>{
		cFuncname 			= svcLoadReport.name;
		setInitAwal(null);
		setHTMLError500(null);
		setDataObj([]);
		
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);
		/*//--TESTING_FRONTEND--/
		let vTmpObj = [];
		if((parseInt(uInpBarangID)||0) <= 0)
		vTmpObj = [
		{id_barang:"1",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"2",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"3",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"4",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"5",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"6",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"7",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"8",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"9",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"10",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"11",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"12",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"13",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"14",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"15",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"16",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"17",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"18",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"19",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"20",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"21",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"22",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"23",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"24",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"25",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"26",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"27",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"28",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"29",nama_barang:"Saldo Sebelum...",jml:"325",satuan:"KG",nilai:"3000000",},
		{id_barang:"30",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"31",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		{id_barang:"32",nama_barang:"BLABAL bla blab bla...",jml:"325",satuan:"KARUNG",nilai:"4500000"},
		];
		else 
			vTmpObj = [
			{id_item:"1",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"2",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"3",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"4",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"5",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"6",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"7",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"8",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"9",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"10",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"12",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"13",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"14",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"15",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"16",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"17",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"18",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"19",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"20",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			{id_item:"21",tgl_penjualan:"2021-03-10",keterangan:"Customer A (213431)",jml:"5",satuan:"KG",nilai:"300000"},
			];
		setDataObj(vTmpObj);
		setBarangIDSet(uInpBarangID);
		setTitleSet(uFilterTitle);
		return;
		//--END TESTING_FRONTEND--*/

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_bulanid : uInpBulanID,
			send_tahun : uInpTahun,
			send_barangid : uInpBarangID,
			send_tokenauth : objToken.userinit
		});
		setBarangIDSet(uInpBarangID);
		var vURLs     = pjson.svcpage+"svc_lappenjualan/ld_report";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnTampilDisabledBl(false);

			/*var vObjFilter 			= uFilterLaporanObj;
			vObjFilter.isKlikTampil = false; 
			vObjFilter.isKlikReset = true; 
			cDispatch({type: "set", gFilterLaporanObj: vObjFilter});//-*/

			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setTitleSet(uFilterTitle);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});	
	}

	const loadFilter=()=>{
		cFuncname	= loadFilter.name;
		if(uBulanObj.length <= 0) return null;

		return (
		<div className="classfakesubheader">
			<CForm className="form-horizontal py-0" id="idformlaporan">
			<CFormGroup row className="px-0 my-0 justify-content-center">
			<CCol sm="12" className="text-center">
			<div className="px-1 text-left align-top w-auto d-inline-block ">
				<CSelect custom 
					value={uInpBulanID}
					onChange={(e)=>setInpBulanID(e.target.value)}
					id="inpbulanid">
					{uBulanObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} 
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_periodelaporan}</div>
			</div>
			<div className="px-1 text-left align-top d-inline-block ">
				<CSelect custom 
					value={uInpTahun}
					onChange={(e)=>setInpTahun(e.target.value)}
					id="inptahun">
					{uTahunObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.value}  
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
			</div>
			<div className="px-1 text-left align-top d-inline-block w-auto" style={{minWidth:"300px"}}>
				<CSelect custom 
					value={uInpBarangID}
					onChange={(e)=>setInpBarangID(e.target.value)}
					id="inpbarangid">
					<option value="0">---{(obBahasa.caption_semuabarang || "SEMUA BARANG").toUpperCase()}</option>
					{uBarangObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} key={vKey}>{vItem.caption} ({vItem.id})</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_pilihbarang}</div>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
				<CButton type="button" color="secondary" 
					color="warning"
					className="text-white font-weight-bold py-1 pt-2"
					disabled={uBtnTampilDisabledBl}
					onClick={hdlKlikTampil} 
					id="btnTampilkan">
					<CIcon name="cil-magnifying-glass" />&nbsp;{obBahasa.word_tampilkan}
				</CButton>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
			&nbsp;&middot;&nbsp;&nbsp;
			<CTooltip content={obBahasa.caption_resetview}>
				<CLink 
					onClick={hdlKlikReset} 
					className="classikon classikonreset"
					id="idklikreset"/>
			</CTooltip>
			</div>
			</CCol>
			</CFormGroup>
			</CForm>
		</div>
		);
	}
	const loadContent=()=>{
		cFuncname = loadContent.name;

		if(uBtnTampilDisabledBl) return (
			<div className="text-center classpetunjuk">{obBahasa.pesan_mohontunggu}</div>
		);
		if(uHTMLError500) return (
			<>{UFunc.renderHTML(uHTMLError500)}</>
		);
		if(uInitAwal) return (
			<div className="font-italic classfontlarger text-info px-4">{UFunc.renderHTML(uInitAwal)}</div>
		);

		return (
			<div className="table-responsive-sm" xs="12">
			<table 
				className="table table-borderless table-striped" 
				id="idtabeldata">
			<thead className="d-sm-down-none thead-light">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			{(parseInt(uBarangIDSet)||0)>0
				? <th width="13%" className="align-top text-center">{obBahasa.word_tanggal}</th>
					: null
			}
			<th className="align-top text-left">{obBahasa.word_keterangan}</th>
			<th width="15%" className="align-top text-center">
				<span className={(uInitSort.jml||"default")!="default"?"text-danger":""}>
					{obBahasa.word_jml}
				</span>
				{" "}
				<CLink onClick={()=>hdlKlikSort("jml")} className={(parseInt(uBarangIDSet)||0)>0?"d-none":""}>
					<CIcon name={(uInitSort.jml||"default")!="UP" ? "cil-arrow-top" : "cil-arrow-bottom"} />
				</CLink>
			</th>
			<th width="20%" className="align-top text-right">
				<span className={(uInitSort.nilai||"default")!="default"?"text-danger":""}>
					{obBahasa.word_nilai} (Rp)
				</span>
				{" "}
				<CLink onClick={()=>hdlKlikSort("nilai")} className={(parseInt(uBarangIDSet)||0)>0?"d-none":""}>
					<CIcon name={(uInitSort.nilai||"default")!="UP"? "cil-arrow-top" : "cil-arrow-bottom"} />
				</CLink>
			</th>
			</tr>
			</thead>
			<tbody>{(parseInt(uBarangIDSet)||0)>0 ? loadBodyDetil() : loadBodyAll()}</tbody>
			{loadFooterTabel()}
			</table>
			</div>
		)
	}
	const loadBodyAll=()=>{
		cFuncname = loadBodyAll.name;

		if(uJmlData <= 0) return (
			<tr>
			<td colSpan="4" className="text-center classpetunjuk">&middot;&middot;&middot;{obBahasa.caption_dataempty}&middot;&middot;&middot;</td>
			</tr>
		)

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		return (uDataObj.map((vItems,vKey)=>{
			const {
				id_barang,nama_barang,jml,satuan,nilai
			} = vItems;

			if(vKey>=vMin && vKey<=vMax) {
				var vJml 			= UFunc.formatAngka(jml);
				var vNilai 			= UFunc.formatAngka(nilai);

				return (
				<tr key={vKey}>
					<td align="right">{(vKey+1)+"."}</td>
					<td align="left" className="font-italic classfontlarger">{nama_barang}</td>
					<td align="center">{vJml} {satuan}</td>
					<td align="right" className="font-weight-bolder classfontlarger">{vNilai}</td>
				</tr>
				)
			}
		}));
	}
	const loadBodyDetil=()=>{
		cFuncname = loadBodyAll.name;

		if(uJmlData <= 0) return (
			<tr>
			<td colSpan="5" className="text-center classpetunjuk">&middot;&middot;&middot;{obBahasa.caption_dataempty}&middot;&middot;&middot;</td>
			</tr>
		)

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		return (uDataObj.map((vItems,vKey)=>{
			const {
				id_item,tgl_penjualan,keterangan,jml,satuan,nilai
			} = vItems;

			if(vKey>=vMin && vKey<=vMax) {
				var vJml 			= UFunc.formatAngka(jml);
				var vNilai 			= UFunc.formatAngka(nilai);

				return (
				<tr key={vKey}>
					<td align="right">{(vKey+1)+"."}</td>
					<td align="center">{UFunc.HariAngka(tgl_penjualan)}</td>
					<td align="left" className="font-italic">{keterangan}</td>
					<td align="center">{vJml} {satuan}</td>
					<td align="right" className="font-weight-bolder classfontlarger">{vNilai}</td>
				</tr>
				)
			}
		}));
	}
	const loadFooterTabel=()=>{
		cFuncname = loadFooterTabel.name;

		if(uJmlData <= 0) return null;

		const vSumNilai = Object.values(uDataObj).reduce((r, { nilai }) => r + (parseInt(nilai)||0), 0);

		return (
			<tfoot className="classtabeltfootlaporan">
			<tr>
			<td colSpan={(parseInt(uBarangIDSet)||0) <= 0 ? 3 : 4}
				align="center">
				{obBahasa.word_jumlah}
			</td>
			<td align="right" className="font-weight-bolder classfontlarger">{UFunc.formatAngka(vSumNilai)}</td>
			</tr>
			</tfoot>
		)

		/*return (
			<tfoot>
			<tr>
			<td colSpan={7}>{JSON.stringify(uDataObj)}</td>
			</tr>
			</tfoot>
		);//-*/
	}
	const loadGrafik=()=>{
		cFuncname = loadGrafik.name;
		if(uJmlData <= 0) return;

		if((parseInt(uBarangIDSet)||0) > 0) return;

		let vLabelX	= [];
		let vValue	= [];
		Object.keys(uDataObj).forEach(function(key) {
			vLabelX.push(uDataObj[key].nama_barang);
			vValue.push(uDataObj[key].nilai);
		});
		const vDatasets	= [{
			label: UFunc.getOptionSelectTeks(uElInpBulanID)+" "+uInpTahun,// obBahasa.word_barang||"Barang",
			backgroundColor: 'rgb(228,102,81,1)',
			fill: false,
			data: vValue
		}];

		return (
			<CRow className="justify-content-center">
			<CCol sm="12">
			<CCard>
			<CCardHeader>
				<strong>Grafik {obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
				<div className="card-header-actions">
				<CTooltip content={obBahasa.caption_cetaklaporangrafik}>
					<CLink onClick={hdlKlikCetakGrafik} 
						className={"classikon classikoncetak"+(uInitGrafikShow?"":" d-none")}/>
				</CTooltip>
				<span className={(uInitGrafikShow?"":" d-none")}>&nbsp;&middot;&nbsp;</span>
				<CLink className="card-header-action" onClick={() => setInitGrafikShow(!uInitGrafikShow)}>
				<CIcon name={uInitGrafikShow ? 'cil-chevron-top':'cil-chevron-bottom'} />
				</CLink>
				</div>
			</CCardHeader>
			<CCollapse show={uInitGrafikShow}>
			<CCardBody>
				<CChartLine
					datasets={vDatasets}
					options={{
						bezierCurve : true,
						responsive: true,
						maintainAspectRatio: true,
						animation: false,
						tooltips: { 
							enabled: true, 
							callbacks: {
								title: function(tooltipItem, data) {
									return data['labels'][tooltipItem[0]['index']];
								},
								label: (tooltipItem, data) => {
									return "Rp"+UFunc.formatAngka(tooltipItem.value)
								},
								afterLabel: function(tooltipItem) {
								}
							}
						},
						scales:{
							yAxes: [{
								ticks : {
									beginAtZero: true,
									callback(value) {
										return UFunc.formatAngka(value)
									}
								},
								scaleLabel: {
									display: true,
									labelString: obBahasa.word_nilai
								},
							}],
							xAxes: [{
								ticks : {
									callback(value) {
										return (value.length <= 10) ? value : (value).substr(0,10)+"..."
									}
								},
								scaleLabel: {
									display: true,
									labelString: obBahasa.caption_barang_nama||"Nama Barang"
								},
							}],
						},
					}}
					labels={vLabelX}
				id="idgrafiklaporan"/>
			</CCardBody>
			</CCollapse>
			</CCard>
			</CCol>
			</CRow>
		);
	}

	return (
		<>
		{loadFilter()}
		{loadGrafik()}
		<CCard>
		<CCardHeader>
		<CRow>
		<CCol sm="12" md="7">
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
		</CCol>
		<CCol sm="12" md="5" className={"text-right"+(uJmlData<=0?" d-none":"")}>
			<CTooltip content={obBahasa.caption_cetaklaporan}>
				<CLink className="" onClick={hdlKlikCetak} className="classikon classikoncetak"/>
			</CTooltip>
			<span className="d-none">&nbsp;&middot;&nbsp;</span>
			<CTooltip content={obBahasa.caption_exportexcel} className="">
				<CLink className="" onClick={hdlKlikExcel} className="classikon classikonexcel d-none"/>
			</CTooltip>
			<span className="">&nbsp;&middot;&nbsp;</span>
			{"Jumlah: "}<strong id="idjmldata">{UFunc.formatAngka(uJmlData)}</strong>{" Data"}
		</CCol>
		</CRow>
		</CCardHeader>
		<CCardBody>
		{loadContent()}		
		</CCardBody>
		{(uJmlData >= uMaxData) && (
		<CCardFooter>
			<CPagination
				activePage={uPageNow}
				pages={uJmlHal}
				onActivePageChange={(i) => setActivePage(i)}
			></CPagination>
		</CCardFooter>
		)}
		</CCard>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}

export default Lappenjualan;
	