import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CListGroup,
	CListGroupItem,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from './index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const FormHutang = (props) => {
	const cComponentName 	= FormHutang.name;
	let cFuncName;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	const {
		show,
		toggle,
		dialogHeader,
		pInpTabelID,
		pInpTanggal,
		pInpNilaiBayar,
		pDataSupplier,
		pDataTglHutang,
		pDataTglHutangDb,
		pDataNoNota,
		pDataTotalHutang,
		pDataSisaHutang,
		onChangeTanggal,
		onChangeNilaiBayar,
		onChangeNilaiBayarNumber,
		onProsessimpan,
		showToast,
		setLoading,
		prosesExpired
	} = props;

	const hdlKlikSimpan=()=>{
		cFuncName = hdlKlikSimpan.name;

		//--VALIDASI--/
		if(UFunc.isEmpty(pInpTanggal)) {
			document.getElementById("inptanggal").focus();
			showToast(obBahasa.caption_tglpembelian+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vTanggalDT	= UFunc.toDate(pInpTanggal)
			var vDateBeyond = UFunc.DateIsBeyond(vTanggalDT);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDT);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
			var vDateHutangDt	= UFunc.toDate(pDataTglHutangDb);
			var vSelisih		= Math.round((vTanggalDT-vDateHutangDt)/(1000*60*60*24));

			if(vSelisih < 0) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.caption_tglbayar+" "+obBahasa.caption_invalid+"..");
				return;
			}

		}
		if(pInpNilaiBayar <= 0) {
			document.getElementById("inpnilaibayar").focus();
			showToast(obBahasa.caption_nilaibayar+" "+obBahasa.caption_invalid+" (1)..");
			return;
		} else {
			const vSisaHutang = parseInt(pDataSisaHutang)||0; 
			if(pInpNilaiBayar > vSisaHutang) {
				document.getElementById("inpnilaibayar").focus();
				showToast(obBahasa.caption_nilaibayar+" "+obBahasa.caption_invalid+" (2)..");
				return;
			}
		}
		//alert("("+cComponentname+") "+cFuncName+" vSelisih = "+vSelisih); return;
		//--END VALIDASI--/

		onProsessimpan();
	}

	const loadFormContent=()=>{
		cFuncName 			= loadFormContent.name;

		const loadNoNota=()=>{
			if((pDataNoNota||"") == "") return null;

			return(
			<CCol sm="12" md="2">
			<div className="font-weight-bolder classfontsmaller pt-1">{pDataNoNota}</div>
			<div className="classpetunjuk">{obBahasa.caption_nonota}</div>
			</CCol>
			);
		}

		return(
			<>
			<CCard className="justify-content-center">
			<CCardHeader className="py-1">
			<CRow>
			<CCol sm="12" md="2">
			<div className="text-primary font-weight-bolder">{pDataSupplier||""}</div>
			<div className="classpetunjuk">{obBahasa.word_supplier}</div>
			</CCol>
			<CCol sm="12" md="3">
			<div>{pDataTglHutang}</div>
			<div className="classpetunjuk">{obBahasa.caption_tglhutang}</div>
			</CCol>
			{loadNoNota()}
			<CCol sm="6" md="2">
			<div className="">Rp {UFunc.formatAngka(pDataTotalHutang)}</div>
			<div className="classpetunjuk">{obBahasa.caption_totaltransaksi}</div>
			</CCol>
			<CCol sm="6" md="3">
			<div className="font-weight-bolder text-success">Rp {UFunc.formatAngka(pDataSisaHutang)}</div>
			<div className="classpetunjuk">{obBahasa.caption_sisahutang}</div>
			</CCol>
			</CRow>
			</CCardHeader>
			<CCardBody>
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_tglbayar}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="10"
						type="date"
						className="form-control"
						value={pInpTanggal}
						onChange={onChangeTanggal}
						id="inptanggal" />
				</CCol>
				</CFormGroup>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_nilaibayar}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<NumberFormat 
						value={pInpNilaiBayar}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={onChangeNilaiBayarNumber}
						renderText={value => (
							<CInput 
								value={value}
								className={"text-right"}
								onFocus={(e)=>e.target.select()}
								onChange={onChangeNilaiBayar}
								onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
							id="inpnilaibayar"/>
						)} 
					prefix={'Rp'}/>
				</CCol>
				</CFormGroup>
			</CForm>
			</CCardBody>
			</CCard>
			</>
		);
	}

	return (
		<>
		<DialogForm
			options={{size:"lg",centered:false}}
			onSimpan={hdlKlikSimpan}
			show={show} 
			dialogHeader={dialogHeader}
			toggle={toggle}
			renderContent={loadFormContent()}/>
		</>
	)
}

						//onValueChange={(values) => onChangeNilaiBayar(values.value)}
export default FormHutang;
