import React, { useState } from "react";
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
} from '@coreui/react';
import PropTypes from 'prop-types';

const TheToaster = props => {
    const { toastData } 		= props;
  	const [toaser,setToaser]	= useState(toastData);

	React.useEffect(() => {
		setToaser([...toastData]);
	},[toastData]);//*/
	
	//console.log("(TheToaster)  toaser = "+toaser.length);
	return (
		<div>
			<CToaster position="bottom-right" key="toasterbottom-right">
			{
			toaser.map((result, key)=>{
				var resultType = null;
				switch(result.type.toLowerCase()) {
					case "info":
					resultType = {
						class: "bg-info",
						title: "Informasi",
					}
					break;

					case "error":
					resultType = {
						class: "bg-danger",
						title: "Error",
					}
					break;

					case "sukses":
					resultType = {
						class: "bg-success",
						title: "Berhasil",
					}
					break;

					default:
					resultType = {
						class: "",
						title: "Informasi",
					}
				}

				const renderHTML = (escapedHTML: string) => 
					React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

				return(
					<CToast
						key={'toaser' + key}
						show={true}
						autohide={true && 5000}
						fade={true}
					>
					<CToastHeader 
						className={resultType.class+" text-white font-weight-bolder py-2"} 
						closeButton={true}>
					{(resultType.title)}
					</CToastHeader>
					<CToastBody>
					{renderHTML(result.teksIsi)}
					</CToastBody>
					</CToast>
				)
			})
			}
			</CToaster>
		</div>
	);
}
TheToaster.propTypes = {
	toastData: PropTypes.array,
}


export default TheToaster;
