import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CListGroup,
	CListGroupItem,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from './index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const FormStokopname = (props) => {
	const cComponentName 	= FormStokopname.name;
	let cFuncName;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	const {
		show,
		toggle,
		dialogHeader,
		pInpTabelID,
		pInpBarangID,
		pInpTanggal,
		pInpJmlStok,
		pInpStatusOpname,
		pInpSatuan,
		pInpKeterangan,
		pInpHPPBeli,

		onChangeTanggal,
		onChangeJmlStok,
		onChangeJmlStokNumber,
		onChangeKeterangan,
		onChangeHPPNumber,
		onChangeHPP,

		pDataHPPTerakhir,
		pDataJmlStokBefore,

		onAftersimpan,
		showToast,
		setLoading,
		prosesExpired
	} = props;

	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	const [uBtnSimpanDisabledBl,setBtnSimpanDisabledBl]= React.useState(false);

	React.useEffect(()=>{
		const vElBtn = document.getElementById("btnDialogSimpan");
		if(UFunc.isEmpty(vElBtn)) return;
		vElBtn.disabled = uBtnSimpanDisabledBl;
	},[uBtnSimpanDisabledBl]);

	const hdlKlikSimpan=()=>{
		cFuncName	= hdlKlikSimpan.name;

		//---VALIDASI--/
		if((parseInt(pInpBarangID)||0) <= 0) {
			showToast("{1} UNDEFINED..");
			return;
		}
		if(pInpTanggal=="") {
			document.getElementById("inptanggal").focus();
			showToast(obBahasa.caption_tglopname+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vTanggalDT	= UFunc.toDate(pInpTanggal);
			var vDateBeyond = UFunc.DateIsBeyond(vTanggalDT);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDT);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
		}
		if((parseInt(pInpHPPBeli)||0) < 0) {
			document.getElementById("inphppbeli").focus();
			showToast(obBahasa.caption_asumsihpp+" "+obBahasa.caption_invalid+"..");
			return;
		}
		if((parseInt(pInpJmlStok)||0) <= 0) {
			document.getElementById("inpjmlstok").focus();
			showToast(obBahasa.caption_jmlopname+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			if(pInpStatusOpname == "KELUAR" && (parseInt(pInpTabelID)||0) <= 0) {
				const vJmlBefore = parseFloat(pDataJmlStokBefore) || 0;
				if((parseFloat(pInpJmlStok)||0) > vJmlBefore) {
					document.getElementById("inpjmlstok").focus();
					showToast(obBahasa.caption_jmlopname+" "+
						obBahasa.caption_invalid+
						" ("+obBahasa.caption_jmlstok+" "+UFunc.formatAngka(pDataJmlStokBefore)+
							" "+pInpSatuan+")..");
					return;
				}
			}
		}
		//---END VALIDASI--/

		svcSimpan();
	}
	const svcSimpan=()=>{
		cFuncName	= svcSimpan.name;

		/*//---TESTING_FRONTEND--/
			onAftersimpan();
			toggle();
			return;
		//---END TESTING_FRONTEND--*/

		if(objToken.userinit == "") {
			setLoading(true);
			prosesExpired();
			return;
		}

		var vDATAS    = JSON.stringify({
			send_tabelid : pInpTabelID,
			send_barangid : pInpBarangID,
			send_statusopname : pInpStatusOpname,
			send_tanggal : pInpTanggal,
			send_hppmasuk : pInpHPPBeli,
			send_jmlopname : pInpJmlStok,
			send_keterangan : pInpKeterangan,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_stokopname/pr_simpan";

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			//console.log("("+cComponentName+"-"+cFuncName+") output_string : "+JSON.stringify(output_string));
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.sukses) {
				onAftersimpan();
				toggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				toggle();
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			var vMsg = pjson.mydefault.msg500str.join(" ");
			showToast(vMsg,"ERROR");
		});
	}
	const loadFormContent=()=>{
		cFuncName	= loadFormContent.name;

		const loadHPPMasuk=()=>{
			if(pInpStatusOpname!="MASUK") return null;

			return (
				<>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_asumsihpp}</CLabel>
				</CCol>
				<CCol xs="12" md="4" className="">
					<NumberFormat 
						value={pInpHPPBeli}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={onChangeHPPNumber}
						renderText={values => (
							<CInput 
								value={values}
								className="text-right"
								onChange={onChangeHPP}
								onFocus={(e)=>e.target.select()}
							id="inphppbeli"/>
						)} 
						prefix="Rp"/>
					<div className="pl-0 classpetunjuk">
					{pDataHPPTerakhir <= 0 ? "":"*) HPP Sebelum: Rp "+UFunc.formatAngka(pDataHPPTerakhir)}
					</div>
				</CCol>
				<CCol xs="12" md="4" className="pl-0 classpetunjuk">
					*) HPP=Harga Pokok Pembelian, Untuk menentukan proyeksi Laba per Barang.. 
				</CCol>
				</CFormGroup>
				</>
			)
		}

		return (
			<>
			<CRow className="justify-content-center">
			<CCol sm="12" md="11">
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_tglopname}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="10"
						type="date"
						className="form-control"
						value={pInpTanggal}
						onChange={onChangeTanggal}
						id="inptanggal"/>
				</CCol>
				</CFormGroup>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_statusopname}</CLabel>
				</CCol>
				<CCol xs="12" md="4" className={"font-weight-bolder "+(pInpStatusOpname=="MASUK"?"text-success":"text-danger")}>{pInpStatusOpname}</CCol>
				</CFormGroup>
				{loadHPPMasuk()}
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_jmlopname+" ("+pInpStatusOpname+")"}</CLabel>
				</CCol>
				<CCol xs="12" md="2" className="pr-1">
					<NumberFormat 
						value={pInpJmlStok}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={onChangeJmlStokNumber}
						renderText={values => (
							<CInput 
								value={values}
								className="text-right"
								onChange={onChangeJmlStok}
								onFocus={(e)=>e.target.select()}
							id="inpjmlstok"/>
						)} 
						prefix=""/>

				</CCol>
				<CCol xs="12" md="2" className="px-0 font-weight-bolder classfontlarger">
					{pInpSatuan}
				</CCol>
				</CFormGroup>

				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.word_keterangan}</CLabel>
				</CCol>
				<CCol xs="12" md="8">
					<CInput maxLength="255"
						type="text"
						className="form-control"
						value={pInpKeterangan}
						onChange={onChangeKeterangan}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="uInpKeterangan"/>
				</CCol>
				</CFormGroup>
			</CForm>
			</CCol>
			</CRow>
			</>
		);
	}

	return (
		<>
		<DialogForm
			options={{size:"lg",centered:false}}
			onSimpan={hdlKlikSimpan}
			show={show} 
			dialogHeader={dialogHeader}
			toggle={toggle}
			renderContent={loadFormContent()}/>
		</>
	)
}

export default FormStokopname;
