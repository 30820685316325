import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CLink,
	CTooltip,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CFormGroup,
	CForm,
	CFormText,
	CInput,
	CLabel,
	CSelect,
	CText,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';

var pjson     = require('../../package.json');

const Biayajenis = (props) => {
	const cComponentName 	= Biayajenis.name;
	const {setLoading,showToast,prosesExpired}	= props;

	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxMaster);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_biayajenis?uKeywordInitObj.kwd_biayajenis:"");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpNamaJenis,setNamaJenis]		= React.useState("");

	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const ElBtnSimpan	= document.getElementById("btnDialogSimpan");
	const toggleForm 	= () => { setShowForm(!isShowForm); 
		document.getElementById("idtabcard").focus();
	}
	const hdlLastFieldKDown = (EV)=>{ if(EV.which == 13) {
		EV.preventDefault();
		ElBtnSimpan.focus();
	}}
	React.useEffect(()=>{
		if(uisKlikTambah) {
			setTabelRowSelect(-1);

			setInpTabelID(0);
			setNamaJenis("");
			setDialogHeader(obBahasa.word_tambah+" "+obBahasa["menus_"+props.namaRoute.toLowerCase()]);

			setShowForm(true);
			cDispatch({type: "set", isKlikTambah: false});
			//document.getElementById("inpnamajenis").focus();
		}
	},[uisKlikTambah]);
	//---END DIALOG_HANDLE--/

	React.useEffect(()=>{

		setKeywordInit(uKeywordInitObj.kwd_biayajenis?uKeywordInitObj.kwd_biayajenis:"");
	},[uKeywordInitObj]);

	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);

	React.useEffect(()=>{

		loadTabelBody();
	},[uBodyInit]);

	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);

		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka( uJmlData);
	},[uJmlData]);

	const svcloadData = () => {
		var vFuncName = svcloadData.name;
		setHTMLError500("");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",nama_biaya_jenis:"Palawija 1"},
			{id:"2",nama_biaya_jenis:"Sembako 1"},
			{id:"3",nama_biaya_jenis:"Tepunga 1"},
			{id:"4",nama_biaya_jenis:"Palawija 2"},
			{id:"5",nama_biaya_jenis:"Sembako 2"},
			{id:"6",nama_biaya_jenis:"Tepunga 2"},
			{id:"7",nama_biaya_jenis:"Palawija 3"},
			{id:"8",nama_biaya_jenis:"Sembako 3"},
			{id:"9",nama_biaya_jenis:"Tepunga 3"},
			{id:"10",nama_biaya_jenis:"Palawija 4"},
			{id:"11",nama_biaya_jenis:"Sembako 4"},
			{id:"12",nama_biaya_jenis:"Tepunga 4"},
		]
		setDataObj(vTmpObj);
		setJmlData((parseInt(vTmpObj.length)||0));
		let vJmlHal	= Math.ceil(vTmpObj.length / uMaxData);
		setJmlHal(vJmlHal);

		console.log("("+vFuncName+") uMaxData = "+uMaxData);
		console.log("("+vFuncName+") vTmpObj.length = "+vTmpObj.length);
		console.log("("+vFuncName+") vJmlHal = "+vJmlHal);

		return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		setJmlData(0);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_biayajenis/ld_data";

		//console.log("("+cComponentName+"-"+vFuncName+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {

				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}

	const hdlSimpan = () => {
		var vFuncName = hdlSimpan.name;

		//---VALIDASI--/
		if(uInpNamaJenis.trim() == "") {
			showToast(obBahasa.caption_jenisbiaya+" "+obBahasa.caption_mustfilled+".."); 
			document.getElementById("inpnamajenis").focus();
			return;
		}
		//---END VALIDASI--/

		/*//---TESTING_FRONTEND--/
		if(uInpTabelID == 0) {
			var vTmpObject	= {
				id: "255",
				nama_biaya_jenis : uInpNamaJenis.trim(),
			}
			var vDataObjNew = [vTmpObject].concat(uDataObj);
		} else {
			var vDataObjNew = uDataObj;
			var vObjdx		= UFunc.getObjectIndeks(vDataObjNew,uInpTabelID);

			vDataObjNew[vObjdx].nama_biaya_jenis 	= uInpNamaJenis.trim();
		}

		setDataObj(vDataObjNew);

		setInpTabelID(0)
		setNamaJenis("");

		if(uInpTabelID > 0) {
			setBodyInit(!uBodyInit);
			toggleForm();
		} else {
			showToast(obBahasa.pesan_sukses,"SUKSES");
			setActivePage(1);
		}

		return;
		//---END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_tabelid : uInpTabelID,
			send_namajenis : uInpNamaJenis.trim(),
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_biayajenis/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			ElBtnSimpan.disabled = false;
			setLoading(false);
			if(output_string.sukses) {
				//---CONCAT_TO_CURRENT_DATA_OBJ--/
				if(uInpTabelID == 0) {
					var vTmpObject	= {
						id: output_string.tabelid || "255",
						nama_biaya_jenis : uInpNamaJenis.trim(),
					}
					var vDataObjNew = [vTmpObject].concat(uDataObj);
				} else {
					var vDataObjNew = uDataObj;
					var vObjdx		= UFunc.getObjectIndeks(vDataObjNew,uInpTabelID);

					vDataObjNew[vObjdx].nama_biaya_jenis 	= uInpNamaJenis.trim();
				}

				setDataObj(vDataObjNew);
				setInpTabelID(0)
				setNamaJenis("");
				//---END CONCAT_TO_CURRENT_DATA_OBJ--/

				if(uInpTabelID > 0) {
					setBodyInit(!uBodyInit);
					toggleForm();
				}  else {
					setJmlData((parseInt(vDataObjNew.length)||0));
					setActivePage(1);
					document.getElementById("inpnamajenis").focus();
					showToast(obBahasa.pesan_sukses,"SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			ElBtnSimpan.disabled = false;
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			//setHTMLError500(pjson.mydefault.msg500str.join(" "));
			cHistory.push("/error500");
		});
	}

	const hdlEditData = async(_IDTABEL) => {
		var vFuncName = hdlEditData.name;

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setInpTabelID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);

		setTabelRowSelect(vRowIdx);
		setNamaJenis(uDataObj[vObjdx].nama_biaya_jenis);
		setDialogHeader(obBahasa.caption_editdata+": "+uDataObj[vObjdx].nama_biaya_jenis.toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}

	const hdlHapusData = async (_IDTABEL) => {
		var vFuncName = hdlHapusData.name;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);

		setTabelRowSelect(vRowIdx);

		const svcFuncNext	= () => {
			
			/*//--TESTING_FRONTEND--/
			uDataObj.splice(vObjdx,1);

			setJmlData((parseInt(uDataObj.length)||0));
			let vJmlHal	= Math.ceil(uDataObj.length / uMaxData);
			setJmlHal(vJmlHal);
			
			setBodyInit(uBodyInit?false:true);
			return;
			//--END TESTING_FRONTEND--*/

			var vDATAS    = JSON.stringify({
				send_tabelid : _IDTABEL,
				send_tokenauth : objToken.userinit
			});
			var vURLs     = pjson.svcpage+"svc_biayajenis/pr_hapus";

			setLoading(true);
			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST", mode: "cors",
				cache: "default", body: vDATAS,
			}).then((response)=> {
				if (response.status === 200) return response.json();
				//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
			}).then((output_string) => {
				setLoading(false);
				if(output_string.sukses) {
					uDataObj.splice(vObjdx,1);
					setTabelRowSelect(-1);
					setJmlData((parseInt(uDataObj.length)||0));
					setBodyInit(!uBodyInit);
				} else if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
					var vMsg;
					if(pjson.mydefault.environment=="development")
						vMsg = output_string.errors;
					else vMsg = pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					prosesExpired(cHistory);
				}
			}).catch((error) =>{
				setLoading(false);
				console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
				cHistory.push("/error500");
			});
		}

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+uDataObj[vObjdx].nama_biaya_jenis.toUpperCase()+"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcFuncNext();
	}

	const loadTabelBody	= () => {
		var vFuncName	= loadTabelBody.name;
		//if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(parseInt(uDataObj.length) || 0);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {id,nama_biaya_jenis } = vItem
			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				let vCaptionUpper	= nama_biaya_jenis.toUpperCase();

				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				let vNamaJenis= uKeywordLoad=="" ? nama_biaya_jenis 
					: nama_biaya_jenis.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				return (
				<tr id={"idtr"+id} className={uTabelRowSelect===vIdxRow?"classrowselect":""}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="9" className="classfontlarger">{UFunc.renderHTML(vNamaJenis)}</CCol>
					<CCol sm="12" md="3" className="text-center">
						<CTooltip html={false} content={"--"+obBahasa.caption_editdata+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlEditData(id)} className="classikontabel classikonedit" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"+(objToken.userhak!="FREEROLE"&&objToken.userhak!="MANAJER" ? " d-none" : "") }
							onClick={()=>hdlHapusData(id)} />
						</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}

	const loadContent = () => {
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return (
			<div className="table-responsive-sm">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="9" className="text-left">{obBahasa.caption_jenisbiaya}</CCol>
				<CCol sm="12" md="3">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}

	const loadContentForm	= () => {
		return (
			<>
			<CRow className="justify-content-center">
			<CCol sm="12">
			<CForm>
				<CRow>
				<CCol xs="12">
					<CFormGroup row>
					<CCol xs="12" md="4">
						<CLabel>{obBahasa.caption_jenisbiaya}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput maxLength="100"
							type="text"
							className="form-control"
							value={uInpNamaJenis}
							onChange={(e) => setNamaJenis(e.target.value)}
							onKeyDown={(e) => hdlLastFieldKDown(e)} 
							id="inpnamajenis" />
					</CCol>
					</CFormGroup>

				</CCol>
				</CRow>
			</CForm>
			</CCol>
			</CRow>
			</>
		);
	}

	return (
		<>
		<CRow className="justify-content-center">
		<CCol sm="12" md="10" lg="8">
		<CCard id="idtabcard">
			<CCardHeader>
			{obBahasa["menus_"+props.namaRoute.toLowerCase()]}
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlHal > 1) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<DialogForm
			dialogHeader={uDialogHeader}
			options={{size:"md",centered:false}}
			onSimpan={()=>hdlSimpan()}
			show={isShowForm} 
			toggle={toggleForm} 
			renderContent={loadContentForm()}/>
		</>
	)
}

export default Biayajenis;
	