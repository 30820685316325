import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CListGroup,
	CListGroupItem,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from './index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const FormPiutang = (props) => {
	const cComponentname 	= FormPiutang.name;
	let cFuncname;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	const obBahasa  		= useSelector(state => state.listBahasa);
	const objToken			= JSON.parse(localStorage.getItem("token"));

	const [uBtnSimpanDisabledBl,setBtnSimpanDisabledBl] = React.useState(false);

	const {
		show,
		toggle,
		dialogHeader,
		pInpPiutangID,
		pInpTabelID,
		pInpTanggal,
		pInpNilaiBayar,
		pDataCustomer,
		pDataTglPiutang,
		pDataNoNota,
		pDataTotalPiutang,
		pDataSisaPiutang,
		onChangeTanggal,
		onChangeNilaiBayar,
		onChangeNilaiBayarNumber,
		onAfterSimpan,
		showToast,
		setLoading,
		prosesExpired
	} = props;

	const hdlKlikSimpan=()=>{
		cFuncname = hdlKlikSimpan.name;

		//--VALIDASI--/
		if(UFunc.isEmpty(pInpTanggal)) {
			document.getElementById("inptanggal").focus();
			showToast(obBahasa.caption_tglpenjualan+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vTanggalDT	= UFunc.toDate(pInpTanggal)
			var vDateBeyond = UFunc.DateIsBeyond(vTanggalDT);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDT);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
			var vDatePiutangDt	= UFunc.toDate(pDataTglPiutang);
			var vSelisih		= Math.round((vTanggalDT-vDatePiutangDt)/(1000*60*60*24));

			if(vSelisih < 0) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.caption_tglbayar+" "+obBahasa.caption_invalid+"..");
				return;
			}

		}
		if(pInpNilaiBayar <= 0) {
			document.getElementById("inpnilaibayar").focus();
			showToast(obBahasa.caption_nilaibayar+" "+obBahasa.caption_invalid+" (1)..");
			return;
		} else {
			const vSisaPiutang = parseInt(pDataSisaPiutang)||0; 
			if(pInpNilaiBayar > vSisaPiutang) {
				document.getElementById("inpnilaibayar").focus();
				showToast(obBahasa.caption_nilaibayar+" "+obBahasa.caption_invalid+" (2)..");
				return;
			}
		}
		//alert("("+cComponentname+") "+cFuncname+" vSelisih = "+vSelisih); return;
		//--END VALIDASI--/

		svcProsesSimpan();
	}

	const svcProsesSimpan=()=>{
		cFuncname = svcProsesSimpan.name;

		/*//--TESTING_FRONTEND--/
			toggle();
			onAfterSimpan();
			return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_piutangid : pInpPiutangID,
			send_bayarid : pInpTabelID,
			send_tanggal : pInpTanggal,
			send_nilaibayar : pInpNilaiBayar,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_piutang/pr_simpan";

		//console.log("("+cComponentname+"-"+cFuncname+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.sukses) {
				toggle();
				onAfterSimpan();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				toggle();
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	const loadFormContent=()=>{
		cFuncname 			= loadFormContent.name;

		const loadNoNota=()=>{
			if((pDataNoNota||"") == "") return null;

			return(
			<CCol sm="12" md="2">
			<div className="font-weight-bolder classfontsmaller pt-1">{pDataNoNota}</div>
			<div className="classpetunjuk">{obBahasa.caption_nonota}</div>
			</CCol>
			);
		}

		return(
			<>
			<CCard className="justify-content-center">
			<CCardHeader className="py-1">
			<CRow>
			<CCol sm="12" md="2">
			<div className="text-primary font-weight-bolder">{pDataCustomer||""}</div>
			<div className="classpetunjuk">{obBahasa.word_customer}</div>
			</CCol>
			<CCol sm="12" md="3">
			<div>{UFunc.HariAngka(pDataTglPiutang)}</div>
			<div className="classpetunjuk">{obBahasa.caption_tglpiutang}</div>
			</CCol>
			{loadNoNota()}
			<CCol sm="6" md="2">
			<div className="">Rp {UFunc.formatAngka(pDataTotalPiutang)}</div>
			<div className="classpetunjuk">{obBahasa.caption_totaltransaksi}</div>
			</CCol>
			<CCol sm="6" md="3">
			<div className="font-weight-bolder text-success">Rp {UFunc.formatAngka(pDataSisaPiutang)}</div>
			<div className="classpetunjuk">{obBahasa.caption_sisapiutang}</div>
			</CCol>
			</CRow>
			</CCardHeader>
			<CCardBody>
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_tglbayar}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="10"
						type="date"
						className="form-control"
						value={pInpTanggal}
						onChange={onChangeTanggal}
						id="inptanggal" />
				</CCol>
				</CFormGroup>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_nilaibayar}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<NumberFormat 
						value={pInpNilaiBayar}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={onChangeNilaiBayarNumber}
						renderText={value => (
							<CInput 
								value={value}
								className={"text-right"}
								onFocus={(e)=>e.target.select()}
								onChange={onChangeNilaiBayar}
								onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
							id="inpnilaibayar"/>
						)} 
					prefix={'Rp'}/>
				</CCol>
				</CFormGroup>
			</CForm>
			</CCardBody>
			</CCard>
			</>
		);
	}

	return (
		<>
		<DialogForm
			options={{size:"lg",centered:false}}
			onSimpan={hdlKlikSimpan}
			show={show} 
			dialogHeader={dialogHeader}
			toggle={toggle}
			renderContent={loadFormContent()}/>
		</>
	)
}

export default FormPiutang;
