import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CListGroup,
	CListGroupItem,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { FormPiutang, DialogView } from '../components/index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const Piutang = (props) => {
	const cComponentname 	= Piutang.name;
	const {setLoading,showToast,prosesExpired}	= props;

	let cFuncname			= "";
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  	= useSelector(state => state.listBahasa);
	const objToken		= JSON.parse(localStorage.getItem("token"));

	const cElJmlData 	= document.getElementById("idjmldata");
	const cElInpFocus 	= document.getElementById("inptanggal");
	const uElBtnSimpan	= document.getElementById("btnDialogSimpan");
	const uFrameExport	= document.getElementById("ifmcontentstoprint");

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uIsKlikCetak						= useSelector(state => state.isKlikCetak);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const uListIDTabelInit					= useSelector(state => state.listTabelID);
	const uHTMLstyleTabel					= useSelector(state => state.gHTMLstyleLaporan);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_piutang||"");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uInitcetak,setInitcetak]			= React.useState("NO");
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uDataCetakobjArr,setDataCetakobjArr]= React.useState([]);

	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate(new Date()));
	const [uInpTanggalDt,setInpTanggalDt]	= React.useState(new Date());
	const [uInpNilaiBayar,setInpNilaiBayar]	= React.useState(0);
	const [uShowFormBl,setShowForm]			= React.useState(false);
	const [uIsBtnSimpanDisabledBl,setBtnSimpanDisabledBl]= React.useState(false);

	const [uFormHeader,setFormHeader]		= React.useState(obBahasa.caption_formbayarpiutang);
	const [uFormDataCustomer,setFormDataCustomer]	= React.useState("");
	const [uFormDataTglPiutang,setFormDataTglPiutang]	= React.useState("");
	const [uFormDataNoNota,setFormDataNoNota]		= React.useState("");
	const [uFormDataTotalPiutang,setFormDataTotalPiutang]= React.useState("");
	const [uFormDataSisaPiutang,setFormDataSisaPiutang]= React.useState("");

	const [uShowDetilBl,setShowDetil]		= React.useState(false);
	const [uDetilHeader,setDetilHeader]		= React.useState("");
	const [uDetilObj,setDetilObj]			= React.useState([]);
	const [uHdlCetak,setHdlCetak]			= React.useState(false);

	React.useEffect(()=>{ setKeywordInit(uKeywordInitObj.kwd_piutang||""); },[uKeywordInitObj]);
	React.useEffect(()=>{
		return ()=>{
			setDataObj([]);
			setDetilObj([]);
		}
	},[]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{
		if(!uIsKlikCetak) return;
		cDispatch({type: 'set', isKlikCetak: false});
		//---CEK_KEYWORD--/
		if(uKeywordLoad.length > 2) {
			setDataCetakobjArr(uDataObj);
			setHdlCetak(true);
			return;
		}
		//---END CEK_KEYWORD--/

		svcloadCetak();
	},[uIsKlikCetak]);
	React.useEffect(()=>{
		if(!uHdlCetak) return false;

		hdlKlikCetak();
	},[uHdlCetak]);
	React.useEffect(()=>{ setJmlData(uDataObj.length ||0); },[uDataObj]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
		if(!UFunc.isEmpty(cElJmlData)) {
			if(uKeywordInit.trim() === "") {
				cElJmlData.innerHTML = "&infin;";
			} else cElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
		}
	},[uJmlData]);
	React.useEffect(()=>{ setInpTanggalDt(new Date(uInpTanggal)); },[uInpTanggal]);
	React.useEffect(()=>{ 
		//console.log("("+cComponentname+") useEffect[uIsBtnSimpanDisabledBl] uElBtnSimpan = "+uElBtnSimpan );
		if(!UFunc.isEmpty(uElBtnSimpan)) uElBtnSimpan.disabled = uIsBtnSimpanDisabledBl;
	},[uIsBtnSimpanDisabledBl]);
	React.useEffect(()=>{
		if(!uShowFormBl) return;

		var vTimeOut = setTimeout(function(e){
			if(!UFunc.isEmpty(cElInpFocus)) cElInpFocus.focus();
			clearTimeout(vTimeOut);
		},500);
	},[uShowFormBl]);

	const hdlKlikBayar=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		
		setInpTabelID(_IDTABEL);
		setInpTanggal(UFunc.DbDate(new Date()));
		setInpNilaiBayar(0);

		if(_IDTABEL <= 0) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vObjdx		= UFunc.getObjectIndeks(uDataObj,_IDTABEL);

		const vCaptionUpper	= (UFunc.HariAngka(uDataObj[vObjdx].tgl_piutang)+" "+
			uDataObj[vObjdx].customer_nama+
			((uDataObj[vObjdx].no_nota||"")!=""?" ("+uDataObj[vObjdx].no_nota+")":""));
		setFormHeader(obBahasa.caption_bayarpiutang+": "+vCaptionUpper);
		setFormDataCustomer(uDataObj[vObjdx].customer_nama||"");
		setFormDataNoNota(uDataObj[vObjdx].no_nota||"");
		setFormDataTglPiutang(uDataObj[vObjdx].tgl_piutang||"");
		setFormDataTotalPiutang(uDataObj[vObjdx].total_piutang||"");
		setFormDataSisaPiutang((uDataObj[vObjdx].total_piutang-uDataObj[vObjdx].terbayar));

		setShowForm(true);
	}
	const hdlKlikDetil=(_IDTABEL)=>{
		cFuncname	= hdlKlikDetil.name;

		_IDTABEL = parseInt(_IDTABEL) || 0;

		setInpTabelID(_IDTABEL);
		if(_IDTABEL <= 0) return;

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vIdx		= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		if(vIdx < 0) return;

		//console.log("("+cComponentname+") "+cFuncname+" => vIdx = "+vIdx);

		const vCaptionUpper	= (UFunc.HariAngka(uDataObj[vIdx].tgl_piutang)+" "+(uDataObj[vIdx].customer_nama||"")+
			((uDataObj[vIdx].no_nota||"")!=""?" ("+uDataObj[vIdx].no_nota+")":""));
		setDetilHeader(obBahasa.caption_detilbayarpiutang+": "+vCaptionUpper);

		svcloadDetil(_IDTABEL);
	}
	const hdlFormToggle=()=>{ setShowForm(!uShowFormBl)	}
	const hdlKlikCetak=()=>{
		cFuncname = hdlKlikCetak.name;
		if(uDataCetakobjArr.length <= 0) return;

		setHdlCetak(false);
		const vContent 	= initHTML();
		const pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	const svcloadData=()=>{
		cFuncname = svcloadData.name;
		setHTMLError500("");
		//console.log("("+cComponentname+") "+cFuncname+" => Proses ");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",tgl_piutang:"2020-11-10",no_nota:"XX73655",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"300000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"2",tgl_piutang:"2020-11-10",no_nota:"XX73654",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"400000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"3",tgl_piutang:"2020-12-10",no_nota:"XX73653",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"500000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"4",tgl_piutang:"2020-11-09",no_nota:"XX73652",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"3800000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"5",tgl_piutang:"2020-12-10",no_nota:"XX73651",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"600000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"6",tgl_piutang:"2020-11-15",no_nota:null,customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"3600000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"7",tgl_piutang:"2020-10-10",no_nota:"XX73649",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"600000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"8",tgl_piutang:"2020-09-10",no_nota:"XX73648",customer_id:"1",customer_nama:"PALAWIJA C",total_piutang:"1200000",terbayar:"10000",keterangan:null},
			{id:"9",tgl_piutang:"2021-02-10",no_nota:"XX73647",customer_id:"1",customer_nama:"PALAWIJA A",total_piutang:"500000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"10",tgl_piutang:"2021-01-10",no_nota:"XX73646",customer_id:"1",customer_nama:"PALAWIJA D",total_piutang:"800000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
		]
		setDataObj(vTmpObj);
		setKeywordLoad(uKeywordInit);

		return;
		//--END TESTING_FRONTEND--*/

		setJmlData(0);
		setDataObj([]);
		setDataCetakobjArr([]);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_piutang/ld_data";

		//console.log("("+cComponentname+"-"+cFuncname+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				//setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const svcloadCetak=()=>{
		cFuncname = svcloadCetak.name;
		//console.log("("+cComponentname+") "+cFuncname+" => Proses ");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",tgl_piutang:"2020-11-10",no_nota:"XX73655",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"300000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"2",tgl_piutang:"2020-11-10",no_nota:"XX73654",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"400000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"3",tgl_piutang:"2020-12-10",no_nota:"XX73653",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"500000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"4",tgl_piutang:"2020-11-09",no_nota:"XX73652",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"3800000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"5",tgl_piutang:"2020-12-10",no_nota:"XX73651",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"600000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"6",tgl_piutang:"2020-11-15",no_nota:null,customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"3600000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"7",tgl_piutang:"2020-10-10",no_nota:"XX73649",customer_id:"1",customer_nama:"PALAWIJA",total_piutang:"600000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"8",tgl_piutang:"2020-09-10",no_nota:"XX73648",customer_id:"1",customer_nama:"PALAWIJA C",total_piutang:"1200000",terbayar:"10000",keterangan:null},
			{id:"9",tgl_piutang:"2021-02-10",no_nota:"XX73647",customer_id:"1",customer_nama:"PALAWIJA A",total_piutang:"500000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
			{id:"10",tgl_piutang:"2021-01-10",no_nota:"XX73646",customer_id:"1",customer_nama:"PALAWIJA D",total_piutang:"800000",terbayar:"10000",keterangan:"jd jgsd jhsgdf"},
		]
		setDataObj(vTmpObj);
		setKeywordLoad(uKeywordInit);

		return;
		//--END TESTING_FRONTEND--*/

		setDataCetakobjArr([]);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_iscetak : "YES",
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_piutang/ld_data";

		console.log("("+cComponentname+"-"+cFuncname+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataCetakobjArr(vTmpObj);
				}
				setHdlCetak(true);
				//setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "));
		});
	}
	const svcloadDetil=(_IDTABEL)=>{
		cFuncname	= svcloadDetil.name;
		_IDTABEL	= parseInt(_IDTABEL) || 0;

		setDetilObj([]);
		if(_IDTABEL <= 0) return;
		
		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{tgl_bayar:"2021-02-10",nilai_bayar: "150000"},
			{tgl_bayar:"2021-03-11",nilai_bayar: "350000"},
		];
		setDetilObj(vTmpObj);
		setShowDetil(true);
		return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_piutangid : _IDTABEL,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_piutang/ld_detil";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentname+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.detilobject) {
					vTmpObj = JSON.parse(output_string.detilobject);
					setDetilObj(vTmpObj);
				}
				setShowDetil(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	const initAfterSimpan=()=>{
		cFuncname	= initAfterSimpan.name;

		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		if(vIdx < 0) return;

		const vTmpObj		= uDataObj;
		let vTotalTrx = (parseInt(vTmpObj[vIdx].total_piutang||0)); 
		let vTerbayar = (parseInt(vTmpObj[vIdx].terbayar)||0) + (parseInt(uInpNilaiBayar)||0);
		vTmpObj[vIdx].terbayar = vTerbayar;
		/*if(vTotalTrx - vTerbayar <= 0) {
			vTmpObj.splice(vIdx,1);
			setJmlData(vTmpObj.length);
		}//-*/
		setDataObj(vTmpObj);
		setBodyInit(!uBodyInit);
	}
	const initHTML=()=>{
		let vHTMLstring 	= uHTMLstyleTabel;

		const vTitle		= obBahasa["menus_"+props.namaRoute.toLowerCase()]+
			"<BR><small>"+UFunc.HariAngka()+"</small>"+
			(uKeywordInit.trim()!=="" ? "<BR><small>(Keyword: "+uKeywordInit.toUpperCase()+")</small>":"")+
		"";

		vHTMLstring += `
			<table id="idtabelhtml">
			<caption>`+vTitle+`</caption>
			<thead>
			<tr>
			<th width="7%">No</th>
			<th width="15%">`+obBahasa.word_tanggal+`</th>
			<th align="left">`+obBahasa.word_customer+`</th>
			<th width="15%"><small>`+obBahasa.caption_totaltransaksi+`<BR>(Rp)</small></th>
			<th width="15%"><small>`+obBahasa.word_terbayar+`<BR>(Rp)</small></th>
			<th width="20%"><small>`+obBahasa.caption_sisapiutang+`<BR>(Rp)</small></th>
			</tr>
			</thead>
			<tbody>`;
		let vJumlahtotal	= 0;
		uDataCetakobjArr.map((vItems,vKeys)=>{
			const{
				id,no_nota,customer_nama,nilai_bayar,total_piutang,tgl_piutang,keterangan
			} = vItems;

			const vSisapiutang 	= (parseInt(total_piutang)||0) - (parseInt(nilai_bayar)||0);
			vJumlahtotal 		+= vSisapiutang;

			vHTMLstring	+= `
			<tr key={vKeys} valign="top">
			<td align="right">`+(vKeys+1)+`.</td>
			<td align="center">
				<small>`+UFunc.TglAngka(tgl_piutang)+`</small>
				`+((no_nota||"")!=="" ? `
				<div><small>(`+(no_nota||"").toUpperCase()+`)</small></div>
				` :``)+`
			</td>
			<td align="left">`+(customer_nama||"UNDF")+`
				`+((keterangan||"")!=="" ? `
				<div style="font-style:italic"><small>`+(keterangan||"")+`</small></div>
				` :``)+`
			</td>
			<td align="right"><small>`+UFunc.formatAngka(total_piutang)+`</small></td>
			<td align="right"><small>`+UFunc.formatAngka(nilai_bayar)+`</small></td>
			<td align="right">`+UFunc.formatAngka(vSisapiutang)+`</td>
			</tr>
			`;
		});
		vHTMLstring += `
			</tbody>
			`;
		vHTMLstring += `
			<tfoot>
			<td colspan="5">`+obBahasa.caption_totalpiutang+` (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vJumlahtotal)+`</td>
			</tfoot>
			`;
		vHTMLstring += `
			</table>
			`;
		return vHTMLstring;
	}

	const loadContent=()=>{
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return (
			<div className="table-responsive-sm" xs="12">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow className="">
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.word_tanggal}</CCol>
				<CCol sm="12" md="2" lg="1" className="">#</CCol>
				<CCol sm="12" md="2" lg="3" className="text-left px-0">{obBahasa.word_customer}</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.caption_totaltransaksi} (Rp)</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.word_terbayar} (Rp)</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.caption_sisapiutang} (Rp)</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}
	const loadTabelBody=()=>{
		cFuncname	= loadTabelBody.name;
		//console.log("("+cComponentName+") "+cFuncname+" => uMaxData : "+uMaxData);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,tgl_piutang,no_nota,customer_nama,
				total_piutang,terbayar,keterangan
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				var vCaptionUpper	= (UFunc.HariAngka(tgl_piutang)+" "+customer_nama+
					((no_nota||"")!=""?" ("+no_nota+")":"")).toUpperCase();

				var vNotaTanggal	= UFunc.HariAngka(tgl_piutang)+
				((no_nota||"")==""?""
					: "<div class='font-weight-bolder'>("+
					   	(uKeywordLoad==""?(no_nota||"")
							: (no_nota||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>")
					   	).toUpperCase()+
					")</div>"
				)+
				"";
				var vCustomer		= uKeywordLoad==""?(customer_nama||"-")
					: (customer_nama||"-").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				var vKeterangan		= uKeywordLoad==""?(keterangan||"")
					: (keterangan||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol md="2" className="text-center px-0">{UFunc.renderHTML(vNotaTanggal)}</CCol>
					<CCol md="2" lg="1" className="text-center px-0 ">
						<CTooltip html={false} content={"--"+obBahasa.caption_bayarpiutang+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikBayar(id)} className="classikon classikonbayar" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_detilbayarpiutang+": "+vCaptionUpper}>
						<CLink 
							className="classikon classikonhistoribayar"
							onClick={()=>hdlKlikDetil(id)} />
						</CTooltip>
					</CCol>
					<CCol md="2" lg="3" className="text-left px-1">
						<div className="text-primary">{UFunc.renderHTML(vCustomer)}</div>
						<div className="font-italic classfontsmaller">{UFunc.renderHTML(vKeterangan)}</div>
					</CCol>
					<CCol md="2" className="text-right px-0 font-weight-bolder">{UFunc.formatAngka(total_piutang)}</CCol>
					<CCol md="2" className="text-right px-0">{UFunc.formatAngka(terbayar)}</CCol>
					<CCol md="2" className="text-right px-0 pr-2 text-success classfontlarger">{UFunc.formatAngka(total_piutang-terbayar)}</CCol>
					</CRow>
					</td>
				</tr>
				)
			}
		});
	}
	const loadDetilContent=()=>{
		cFuncname 			= loadDetilContent.name;

		//---GET_DATA_FORM--/
		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		//---END GET_DATA_FORM--/

		if(vIdx < 0 || !uShowDetilBl) return null;
		//console.log("("+cComponentname+") "+cFuncname+" => uInpTabelID = "+uInpTabelID);

		const vDetilcontent=()=>{
			if(uDetilObj.length <= 0) return (<div className="font-italic text-info">--{obBahasa.pesan_pembayaranempty}--</div>);

			var vTotalBayar	= 0;
			return (
				<CListGroup>
				{uDetilObj.map((vItemDetil,vKey)=>{
					vTotalBayar = vTotalBayar + (parseInt(vItemDetil.nilai_bayar)||0);
					return (
					<CListGroupItem>
					<CRow>
					<CCol sm="1" className="text-right">{vKey+1}.</CCol>
					<CCol sm="7">{obBahasa.word_tanggal+": "+UFunc.HariAngka(vItemDetil.tgl_bayar)}</CCol>
					<CCol sm="4" className="text-right font-weight-bolder text-info">Rp {UFunc.formatAngka(vItemDetil.nilai_bayar)}</CCol>
					</CRow>
					</CListGroupItem>
					)
				})}
					<CListGroupItem>
					<CRow>
					<CCol sm="1"></CCol>
					<CCol sm="7" className="text-right font-weight-bolder">{obBahasa.word_total}</CCol>
					<CCol sm="4" className="text-right font-weight-bolder text-info">Rp {UFunc.formatAngka(vTotalBayar)}</CCol>
					</CRow>
					</CListGroupItem>
				</CListGroup>
			)
		}

		return(
			<>
			<CCard className="justify-content-center">
			<CCardHeader className="py-1">
			<strong>
				{obBahasa.caption_totaltransaksi+": Rp "}
				<span className="classfontlarger text-success">{UFunc.formatAngka(uDataObj[vIdx].total_piutang)}</span>
			</strong>
			</CCardHeader>
			<CCardBody>{vDetilcontent()}
			</CCardBody>
			</CCard>
			</>
		);
	}
	//console.log("("+cComponentname+") "+cFuncname+" => uFormHeader = "+uFormHeader);

	return (
		<>
		<CRow className="p-0 justify-content-center">
		<CCol xs="12" className="p-0">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlData >= uMaxData) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<FormPiutang
			pInpTabelID="0"
			pInpPiutangID={uInpTabelID}
			pInpTanggal={uInpTanggal}
			pInpNilaiBayar={uInpNilaiBayar}
			onChangeTanggal={(e)=>setInpTanggal(e.target.value)}
			onChangeNilaiBayar={(e)=>setInpNilaiBayar(e.target.value)}
			onChangeNilaiBayarNumber={(values)=>setInpNilaiBayar(values.value)}

			pDataCustomer={uFormDataCustomer}
			pDataTglPiutang={uFormDataTglPiutang}
			pDataNoNota={uFormDataNoNota}
			pDataTotalPiutang={uFormDataTotalPiutang}
			pDataSisaPiutang={uFormDataSisaPiutang}

			onAfterSimpan={initAfterSimpan}
			show={uShowFormBl} 
			dialogHeader={uFormHeader}
			toggle={hdlFormToggle}
			{...props}/>

		<DialogView
			options={{size:"lg",centered:false}}
			show={uShowDetilBl} 
			dialogHeader={uDetilHeader}
			toggle={()=>setShowDetil(!uShowDetilBl)}
			renderContent={loadDetilContent()}/>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}

export default Piutang;
	