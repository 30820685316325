import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
//import navigation from './_nav';

var pjson     = require('../../package.json');

// sidebar nav config

const TheSidebar = (props) => {
  const vFuncName   = TheSidebar.name;

  const dispatch    = useDispatch();
  const show        = useSelector(state => state.sidebarShow);
  const navigation  = useSelector(state => state.listNav);

  const setLocalToast = (_TEXT) => { props.showToast ? props.showToast(_TEXT) : alert(_TEXT); }
  //React.useEffect(()=>{ setLocalToast(vFuncName); },[]);

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}>
      <CSidebarBrand className="d-md-down-none" to="/" style={{textDecoration:"none"}}>
        <CIcon
          className="c-sidebar-brand-full"
          src={"images/logo192.png"}
          height={40}/>
        <div className="c-sidebar-brand-full ml-1 classtitle classtitlesidebar" >{pjson.name}</div>
        <CIcon
          className="c-sidebar-brand-minimized"
          src={"images/logo192.png"}
          height={40}/>
      </CSidebarBrand>

      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  );
}

export default React.memo(TheSidebar);
