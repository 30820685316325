import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { FormStokopname } from '../components/index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const Stokopname = (props) => {
	const cComponentName 	= Stokopname.name;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});

	let cFuncName;
	const {setLoading,showToast,prosesExpired}	= props;

	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_stokopname || "");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uFormHeader,setFormHeader]		= React.useState("");
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uBtnSimpanDisabledBl,setBtnSimpanDisabledBl]= React.useState(false);

	const [uInpBarangID,setInpBarangID]		= React.useState(0);
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate(new Date()));
	const [uInpTanggalDt,setInpTanggalDt]	= React.useState(new Date());
	const [uInpJmlStok,setInpJmlStok]		= React.useState(0);
	const [uInpHPPBeli,setInpHPPBeli]		= React.useState(0);
	const [uInpSatuan,setInpSatuan]			= React.useState("");
	const [uInpStatusOpname,setInpStatusOpname]	= React.useState("MASUK");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uFormHPPTerakhir,setFormHPPTerakhir]= React.useState("");
	const [uFormJmlStokBefore,setFormJmlStokBefore]= React.useState("");

	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const hdlFormToggle=()=>{ setShowForm(!isShowForm); }
	React.useEffect(()=>{
		if(!isShowForm) return;

		var vTimeout = setTimeout(function(e){
			const vElFocus = document.getElementById("inptanggal");
			if(!UFunc.isEmpty(vElFocus)) vElFocus.focus();
			clearTimeout(vTimeout);
		},200);
	},[isShowForm]);
	React.useEffect(()=>{
		const vElBtn = document.getElementById("btnDialogSimpan");
		if(UFunc.isEmpty(vElBtn)) return;
		vElBtn.disabled = uBtnSimpanDisabledBl;
	},[uBtnSimpanDisabledBl]);
	React.useEffect(()=>{ setInpTanggalDt(UFunc.toDate(uInpTanggal)); },[uInpTanggal]);
	//---END DIALOG_HANDLE:Harus Selalu ADA--/

	React.useEffect(()=>{ setKeywordInit(uKeywordInitObj.kwd_stokopname || ""); },[uKeywordInitObj]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{ loadTabelBody(); },[uBodyInit]);
	React.useEffect(()=>{ setJmlData(uDataObj.length) },[uDataObj]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);

	const hdlKlikTambahStok=(_IDTABEL)=>{
		cFuncName	= hdlKlikTambahStok.name;
		initResetForm();
		_IDTABEL	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setInpBarangID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setFormHeader(obBahasa.caption_setstoktambah+": "+uDataObj[vObjdx].nama_barang.toUpperCase());
		setInpStatusOpname("MASUK");
		setInpSatuan(uDataObj[vObjdx].satuan.toUpperCase());
		setInpHPPBeli(uDataObj[vObjdx].hpp_terakhir);

		setFormHPPTerakhir(uDataObj[vObjdx].hpp_terakhir);
		setFormJmlStokBefore(uDataObj[vObjdx].jml_stok);

		setShowForm(true);
	}
	const hdlKlikKurangStok=(_IDTABEL)=>{
		cFuncName	= hdlKlikKurangStok.name;
		initResetForm();
		_IDTABEL	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setInpBarangID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setFormHeader(obBahasa.caption_setstokkurang+": "+uDataObj[vObjdx].nama_barang.toUpperCase());
		setInpStatusOpname("KELUAR");
		setInpSatuan(uDataObj[vObjdx].satuan.toUpperCase());

		setFormHPPTerakhir(uDataObj[vObjdx].hpp_terakhir);
		setFormJmlStokBefore(uDataObj[vObjdx].jml_stok);
		
		setShowForm(true);
	}

	const initResetForm=()=>{
		setInpBarangID(0);
		setInpTanggal(UFunc.DbDate(new Date()));
		setInpJmlStok(0);
		setInpHPPBeli(0);
		setInpSatuan("");
		setInpKeterangan("");
		setInpStatusOpname("MASUK");
	}
	const initAfterSimpan=()=>{
		let vTmpObj	= uDataObj;
		var vObjdx	= UFunc.getObjectIndeks(vTmpObj,uInpBarangID);

		var vJmlStok = parseFloat(vTmpObj[vObjdx].jml_stok);
		if(uInpStatusOpname == "MASUK")
			vJmlStok += parseFloat(uInpJmlStok)||0;
		else
			vJmlStok -= parseFloat(uInpJmlStok)||0;

		vTmpObj[vObjdx].jml_stok = vJmlStok;
		setDataObj(vTmpObj);
		
		setBodyInit(!uBodyInit);
	}
	const svcloadData=()=>{
		cFuncName	= svcloadData.name;
		setHTMLError500("");

		//console.log("("+cComponentName+") "+cFuncName+" => Proses ");

		/*//--TESTING_FRONTEND--/
		setKeywordLoad(uKeywordInit);
		let vTmpObj	= [
			{id:"1",nama_barang:"Barang 01",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"2",nama_barang:"Barang 02",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"3",nama_barang:"Barang 03",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"4",nama_barang:"Barang 04",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"5",nama_barang:"Barang 05",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"6",nama_barang:"Barang 06",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"7",nama_barang:"Barang 07",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"8",nama_barang:"Barang 08",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
			{id:"8",nama_barang:"Barang 09",jenis_barang:"Tepung Terigu",jml_stok:"50",satuan:"KG",hpp_terakhir:"50000"},
		]
		setDataObj(vTmpObj);
		return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_stokopname/ld_data";

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnSimpanDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}

	const loadContent=()=>{
		if(uHTMLError500)
			return ( <>{UFunc.renderHTML(uHTMLError500)}</> );

		return (
			<div className="table-responsive-sm">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="5" className="text-left">{obBahasa.caption_barangnama}</CCol>
				<CCol sm="12" md="2" lg="3" className="text-center px-1 ">{obBahasa.caption_barangjenis}</CCol>
				<CCol sm="12" md="3" className="px-1 ">{obBahasa.caption_jmlstok}</CCol>
				<CCol sm="12" md="2" lg="1" className="px-1 ">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}
	const loadTabelBody=()=>{
		cFuncName	= loadTabelBody.name;
		//console.log("("+cComponentName+") "+cFuncName+" => uKeywordLoad : "+uKeywordLoad);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,nama_barang,jenis_barang,jml_stok,satuan,
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				let vCaptionUpper	= (nama_barang||"").toUpperCase();

				let vBarangNama		= uKeywordLoad=="" ? nama_barang
					: (nama_barang||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vBarangJenis		= uKeywordLoad=="" ? jenis_barang
					: (jenis_barang||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vSatuan		= uKeywordLoad=="" ? satuan
					: (satuan||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vJmlStok	= UFunc.formatAngka(jml_stok);

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="5" className="text-primary classfontlarger text-left px-1">
						{UFunc.renderHTML(vBarangNama)}
					</CCol>
					<CCol sm="12" md="2" lg="3" className="text-center px-0">{UFunc.renderHTML(vBarangJenis)}</CCol>
					<CCol sm="12" md="3" className="text-center px-1">
						<span className="text-success font-weight-bolder classfontlarger">{vJmlStok}</span>
						{" "}
						{UFunc.renderHTML(vSatuan)}
					</CCol>
					<CCol sm="12" md="2" lg="1" className="text-center px-0">
						<CTooltip html={false} content={"--"+obBahasa.caption_setstoktambah+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikTambahStok(id)} className="classikon classikontambahstok" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_setstokkurang+": "+vCaptionUpper}>
						<CLink 
							className={"classikon classikonkurangstok"}
							onClick={()=>hdlKlikKurangStok(id)} />
						</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}

	/*const hdlKlikSimpan=()=>{
		cFuncName	= hdlKlikSimpan.name;

		//---VALIDASI--/
		if((parseInt(uInpBarangID)||0) <= 0) {
			showToast("{1} UNDEFINED..");
			return;
		}
		if(uInpTanggal=="") {
			document.getElementById("inptanggal").focus();
			showToast(obBahasa.caption_tglopname+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vDateBeyond = UFunc.DateIsBeyond(uInpTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(uInpTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
		}
		if((parseInt(uInpHPPBeli)||0) < 0) {
			document.getElementById("inphppbeli").focus();
			showToast(obBahasa.caption_asumsihpp+" "+obBahasa.caption_invalid+"..");
			return;
		}
		if((parseInt(uInpJmlStok)||0) <= 0) {
			document.getElementById("inpjmlstok").focus();
			showToast(obBahasa.caption_jmlopname+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			if(uInpStatusOpname == "KELUAR") {
				var vObjdx	= UFunc.getObjectIndeks(uDataObj,uInpBarangID);
				const vJmlBefore = parseFloat(uDataObj[vObjdx].jml_stok) || 0;
				if((parseFloat(uInpJmlStok)||0) > vJmlBefore) {
					document.getElementById("inpjmlstok").focus();
					showToast(obBahasa.caption_jmlopname+" "+obBahasa.caption_invalid+"..");
					return;
				}
			}
		}
		//---END VALIDASI--/

		svcSimpan();
	}
	const svcSimpan=()=>{
		cFuncName	= svcSimpan.name;

		var vDATAS    = JSON.stringify({
			send_barangid : uInpBarangID,
			send_statusopname : uInpStatusOpname,
			send_tanggal : uInpTanggal,
			send_hppmasuk : uInpHPPBeli,
			send_jmlopname : uInpJmlStok,
			send_keterangan : uInpKeterangan,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_stokopname/pr_simpan";

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			//console.log("("+cComponentName+"-"+cFuncName+") response.json() : "+(response.json()));
			//if (response.status === 200) return response.json();
			  if (response.ok) {
			    return response.json();
			  } else {
			    throw new Error('Something went wrong');
			  }
		}).then((output_string) => {
			//console.log("("+cComponentName+"-"+cFuncName+") output_string : "+JSON.stringify(output_string));
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.sukses) {
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				toggleForm();
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
		//}).catch((xhr, ajaxOptions, thrownError) => {
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			var vMsg = pjson.mydefault.msg500str.join(" ");
			showToast(vMsg,"ERROR");
			//cHistory.push("/error500");
		});
	}
	const loadContentForm=()=>{
		cFuncName	= loadContentForm.name;

		if(!isShowForm) return (<></>);

		const loadHPPMasuk=()=>{
			if(uInpStatusOpname!="MASUK") return null;

			var vObjdx	= UFunc.getObjectIndeks(uDataObj,uInpBarangID);
			return (
				<>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_asumsihpp}</CLabel>
				</CCol>
				<CCol xs="12" md="4" className="">
					<NumberFormat 
						value={uInpHPPBeli}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpHPPBeli(values.value)}
						renderText={values => (
							<CInput 
								value={values}
								className="text-right"
								onChange={(e) => setInpHPPBeli(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inphppbeli"/>
						)} 
						prefix="Rp"/>
					<div className="pl-0 classpetunjuk">
					*) HPP Sebelum: Rp {UFunc.formatAngka(uDataObj[vObjdx].hpp_terakhir)}
					</div>
				</CCol>
				<CCol xs="12" md="4" className="pl-0 classpetunjuk">
					*) HPP=Harga Pokok Pembelian, Untuk menentukan proyeksi Laba per Barang.. 
				</CCol>
				</CFormGroup>
				</>
			)
		}

		return (
			<>
			<CRow className="justify-content-center">
			<CCol sm="12" md="11">
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_tglopname}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="10"
						type="date"
						className="form-control"
						value={uInpTanggal}
						onChange={(e) => setInpTanggal(e.target.value)}
						id="inptanggal"/>
				</CCol>
				</CFormGroup>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_statusopname}</CLabel>
				</CCol>
				<CCol xs="12" md="4" className={"font-weight-bolder "+(uInpStatusOpname=="MASUK"?"text-success":"text-danger")}>{uInpStatusOpname}</CCol>
				</CFormGroup>
				{loadHPPMasuk()}
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_jmlopname+" ("+uInpStatusOpname+")"}</CLabel>
				</CCol>
				<CCol xs="12" md="2" className="pr-1">
					<NumberFormat 
						value={uInpJmlStok}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpJmlStok(values.value)}
						renderText={values => (
							<CInput 
								value={values}
								className="text-right"
								onChange={(e) => setInpJmlStok(e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpjmlstok"/>
						)} 
						prefix=""/>

				</CCol>
				<CCol xs="12" md="2" className="px-0 font-weight-bolder classfontlarger">
					{uInpSatuan}
				</CCol>
				</CFormGroup>

				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.word_keterangan}</CLabel>
				</CCol>
				<CCol xs="12" md="8">
					<CInput maxLength="255"
						type="text"
						className="form-control"
						value={uInpKeterangan}
						onChange={(e) => setInpKeterangan(e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="uInpKeterangan"/>
				</CCol>
				</CFormGroup>
			</CForm>
			</CCol>
			</CRow>
			</>
		);
	}//-*/

	return (
		<>
		<CRow className="justify-content-center">
		<CCol xs="12">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
			</CCardHeader>
			<CCardBody className="px-2">
			{loadContent()}
			</CCardBody>
			{(uJmlData >= uMaxData) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<FormStokopname
			pInpTabelID="0"
			pInpBarangID={uInpBarangID}
			pInpTanggal={uInpTanggal}
			pInpJmlStok={uInpJmlStok}
			pInpStatusOpname={uInpStatusOpname}
			pInpSatuan={uInpSatuan}
			pInpKeterangan={uInpKeterangan}
			pInpHPPBeli={uInpHPPBeli}
			
			onChangeTanggal={(e)=>setInpTanggal(e.target.value)}
			onChangeJmlStok={(e)=>setInpJmlStok(e.target.value)}
			onChangeJmlStokNumber={(values)=>setInpJmlStok(values.value)}
			onChangeKeterangan={(e)=>setInpKeterangan(e.target.value)}
			onChangeHPPNumber={(values)=>setInpHPPBeli(values.value)}
			onChangeHPP={(e)=>setInpHPPBeli(e.target.value)}
			
			pDataHPPTerakhir={uFormHPPTerakhir}
			pDataJmlStokBefore={uFormJmlStokBefore}

			onAftersimpan={initAfterSimpan}
			show={isShowForm} 
			dialogHeader={uFormHeader}
			toggle={hdlFormToggle}
			{...props}/>
		</>
	)
}

export default Stokopname;
	