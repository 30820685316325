import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CDataTable,
	CBadge,
	CRow,
	CPagination,
	CLink,
	CTooltip,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CFormGroup,
	CForm,
	CFormText,
	CInput,
	CLabel,
	CSelect,
	CText,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';

var pjson     = require('../../package.json');

const User = (props) => {
	const cComponentName 	= User.name;
	const {setLoading,showToast,prosesExpired}	= props;

	React.useEffect(()=>{
		if(objToken.userhak=="ENTRI") {
			cHistory.push("/iprofile");
		}
	},[]);

	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxMaster);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_user?uKeywordInitObj.kwd_user:"");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uHakObj,setHakObj]				= React.useState(
		[{"value":"MANAJER","caption":"MANAJER"},{"value":"ENTRI","caption":"KARYAWAN"}]
		);
	const [uPageNow,setActivePage] 			= React.useState(1);
	
	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uInitForm,setInitForm]			= React.useState("USER");

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpUsername,setInpUsername]		= React.useState("");
	const [uInpUsercaption,setInpUsercaption]= React.useState("");
	const [uInpUserPasswd1,setInpUserpasswd1]= React.useState("");
	const [uInpUserPasswd2,setInpUserpasswd2]= React.useState("");
	const [uInpUserHak,setInpUserHak]		= React.useState("ENTRI");

	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const ElBtnSimpan	= document.getElementById("btnDialogSimpan");
	const toggleForm 	= () => { setShowForm(!isShowForm); }
	const hdlLastField = (EV)=>{ if(EV.which == 13) {
		EV.preventDefault();
		ElBtnSimpan.focus();
	}}
	React.useEffect(()=>{
		if(uisKlikTambah) {
			setInitForm("USER");
			setTabelRowSelect(-1);

			setInpTabelID(0);
			setInpUsername("");
			setInpUsercaption("");
			setInpUserpasswd1("");
			setInpUserpasswd2("");
			setInpUserHak("ENTRI");
			setDialogHeader(obBahasa.word_tambah+" "+obBahasa["menus_"+props.namaRoute.toLowerCase()]);

			setShowForm(true);
			cDispatch({type: "set", isKlikTambah: false});
			//document.getElementById("inpusername").focus();
		}
	},[uisKlikTambah]);
	//---END DIALOG_HANDLE--/

	//console.log("("+cComponentName+") uKeywordInitObj => uKeywordInit = "+uKeywordInit);
	React.useEffect(()=>{
		setKeywordInit(uKeywordInitObj.kwd_user?uKeywordInitObj.kwd_user:"");
	},[uKeywordInitObj]);

	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);

	React.useEffect(()=>{

		loadTabelBody();
	},[uBodyInit]);

	React.useEffect(()=>{

		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);
		//console.log("("+cComponentName+") useEffect => uJmlData = "+uJmlData);

		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka( uJmlData);
	},[uJmlData]);

	const svcloadData = () => {
		var vFuncName = svcloadData.name;
		setHTMLError500("");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",username:"admin1",usercaption:"ADMIN SATOE",userhak:"FREEROLE",userstatus:"ONLINE"},
			{id:"2",username:"manajer1",usercaption:"MANAJER SATOE",userhak:"MANAJER",userstatus:"OFFLINE"},
			{id:"3",username:"entri1",usercaption:"ENTRI SATOE",userhak:"ENTRI",userstatus:"OFFLINE"},
			{id:"4",username:"admin1",usercaption:"ADMIN SATOE",userhak:"FREEROLE",userstatus:"ONLINE"},
			{id:"5",username:"manajer1",usercaption:"MANAJER SATOE",userhak:"MANAJER",userstatus:"OFFLINE"},
			{id:"6",username:"entri1",usercaption:"ENTRI SATOE",userhak:"ENTRI",userstatus:"OFFLINE"},
			{id:"7",username:"admin1",usercaption:"ADMIN SATOE",userhak:"FREEROLE",userstatus:"ONLINE"},
			{id:"8",username:"manajer1",usercaption:"MANAJER SATOE",userhak:"MANAJER",userstatus:"OFFLINE"},
			{id:"9",username:"entri1",usercaption:"ENTRI SATOE",userhak:"ENTRI",userstatus:"OFFLINE"},
			{id:"10",username:"admin1",usercaption:"ADMIN SATOE",userhak:"FREEROLE",userstatus:"ONLINE"},
			{id:"11",username:"manajer1",usercaption:"MANAJER SATOE",userhak:"MANAJER",userstatus:"OFFLINE"},
			{id:"12",username:"entri1",usercaption:"ENTRI SATOE",userhak:"ENTRI",userstatus:"OFFLINE"},
			{id:"13",username:"admin1",usercaption:"ADMIN SATOE",userhak:"FREEROLE",userstatus:"ONLINE"},
			{id:"14",username:"manajer1",usercaption:"MANAJER SATOE",userhak:"MANAJER",userstatus:"OFFLINE"},
			{id:"15",username:"entri1",usercaption:"ENTRI SATOE",userhak:"ENTRI",userstatus:"OFFLINE"},
		]
		setDataObj(vTmpObj);
		setJmlData((parseInt(vTmpObj.length)||0));
		let vJmlHal	= Math.ceil(vTmpObj.length / uMaxData);
		setJmlHal(vJmlHal);

		console.log("("+vFuncName+") uMaxData = "+uMaxData);
		console.log("("+vFuncName+") vTmpObj.length = "+vTmpObj.length);
		console.log("("+vFuncName+") vJmlHal = "+vJmlHal);

		return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		setJmlData(0);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_user/ld_data";

		//console.log("("+cComponentName+"-"+vFuncName+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {

				if(output_string.hakobject) {
					let vTmpObj = JSON.parse(output_string.hakobject);
					setHakObj(vTmpObj);
				}

				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
					//if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka((parseInt(vTmpObj.length)||0));
				}
				setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}

	const hdlSimpan	= () => {
		if(uInitForm=="USER") hdlProsesUser();
		else hdlProsesRole();
	}

	const hdlProsesRole = () => {
		alert("YES");

		toggleForm();
	}

	const hdlProsesUser = () => {
		var vFuncName = hdlProsesUser.name;

		//---VALIDASI--/
		if(uInpUsername.trim() == "") {
			showToast(obBahasa.word_username+" "+obBahasa.caption_mustfilled+".."); 
			document.getElementById("inpusername").focus();
			return;
		}
		if(uInpUsercaption.trim() == "") {
			showToast(obBahasa.caption_usercaption+" "+obBahasa.caption_mustfilled+".."); 
			document.getElementById("inpusercaption").focus();
			return;
		}
		if(uInpTabelID <= 0) {
			if(uInpUserPasswd1 == "") {
				showToast(obBahasa.word_password+" "+obBahasa.caption_mustfilled+".."); 
				document.getElementById("inppasswd1").focus();
				return;
			}
			if(uInpUserPasswd2 == "") {
				showToast(obBahasa.caption_passwdconfirm+" "+obBahasa.caption_mustfilled+".."); 
				document.getElementById("inppasswd2").focus();
				return;
			}
		}
		if(uInpUserPasswd1 != uInpUserPasswd2) {
			showToast(obBahasa.word_password+" / "+obBahasa.caption_passwdconfirm+" "+
				obBahasa.caption_mustsame+".."); 
			document.getElementById("inppasswd1").focus();
			return;
		}
		if(uInpUserHak.trim() == "") {
			showToast(obBahasa.caption_hakakses+" "+obBahasa.caption_mustchoosed+".."); 
			document.getElementById("inpusercaption").focus();
			return;
		}
		//---END VALIDASI--/

		/*//---TESTING_FRONTEND--/
		if(uInpTabelID == 0) {
			var vTmpObject	= {
				id: "255",
				username : uInpUsername.trim(),
				usercaption : uInpUsercaption.trim(),
				userhak : uInpUserHak,				
				userstatus : "OFFLINE",				
			}
			var vDataObjNew = [vTmpObject].concat(uDataObj);
		} else {
			var vDataObjNew = uDataObj;
			var vObjdx		= UFunc.getObjectIndeks(vDataObjNew,uInpTabelID);

			vDataObjNew[vObjdx].username 	= uInpUsername.trim();
			vDataObjNew[vObjdx].usercaption = uInpUsercaption.trim();
			vDataObjNew[vObjdx].userhak 	= uInpUserHak;
		}

		setDataObj(vDataObjNew);

		setInpTabelID(0)
		setInpUsername("");
		setInpUsercaption("");
		setInpUserpasswd1("");
		setInpUserpasswd2("");
		setInpUserHak("ENTRI");

		if(uInpTabelID > 0) {
			setBodyInit(!uBodyInit);
			toggleForm();
		} else {
			showToast(obBahasa.pesan_sukses,"SUKSES");
			setActivePage(1);
		}

		return;
		//---END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_tabelid : uInpTabelID,
			send_username : uInpUsername.trim(),
			send_usercaption : uInpUsercaption.trim(),
			send_passwd1 : uInpUserPasswd1,
			send_passwd2 : uInpUserPasswd2,
			send_hak : uInpUserHak,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_user/pr_simpan";

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			ElBtnSimpan.disabled = false;
			setLoading(false);
			if(output_string.sukses) {
				//---CONCAT_TO_CURRENT_DATA_OBJ--/
				if(uInpTabelID == 0) {
					var vTmpObject	= {
						id: output_string.tabelid || "255",
						username : uInpUsername.trim(),
						usercaption : uInpUsercaption.trim(),
						userhak : uInpUserHak,				
						userstatus : "OFFLINE",				
					}
					var vDataObjNew = [vTmpObject].concat(uDataObj);
				} else {
					var vDataObjNew = uDataObj;
					var vObjdx		= UFunc.getObjectIndeks(vDataObjNew,uInpTabelID);

					vDataObjNew[vObjdx].username 	= uInpUsername.trim();
					vDataObjNew[vObjdx].usercaption = uInpUsercaption.trim();
					vDataObjNew[vObjdx].userhak 	= uInpUserHak;
				}

				setDataObj(vDataObjNew);
				setInpTabelID(0)
				setInpUsername("");
				setInpUsercaption("");
				setInpUserpasswd1("");
				setInpUserpasswd2("");
				setInpUserHak("ENTRI");
				//---END CONCAT_TO_CURRENT_DATA_OBJ--/

				if(uInpTabelID > 0) {
					setBodyInit(!uBodyInit);
					toggleForm();
				}  else {
					setJmlData((parseInt(vDataObjNew.length)||0));
					showToast(obBahasa.pesan_sukses,"SUKSES");
					setActivePage(1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			ElBtnSimpan.disabled = false;
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			//setHTMLError500(pjson.mydefault.msg500str.join(" "));
			cHistory.push("/error500");
		});
	}

	const hdlEditData = async(_IDTABEL) => {
		var vFuncName = hdlEditData.name;

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setInitForm("USER");
		setInpTabelID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);

		setTabelRowSelect(vRowIdx);

		setInpUsername(uDataObj[vObjdx].username);
		setInpUsercaption(uDataObj[vObjdx].usercaption);
		setInpUserHak(uDataObj[vObjdx].userhak);
		setDialogHeader(obBahasa.caption_editdata+": "+(uDataObj[vObjdx].usercaption || "").toUpperCase());
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}

	const hdlSetRole = (_IDTABEL) => {
		var vFuncName 	= hdlSetRole.name;
		_IDTABEL		= parseInt(_IDTABEL) || 0;

		setInpTabelID(_IDTABEL);
		setInitForm("ROLE");
		setShowForm(true);

		//cHistory.go("/user_setrole");
	}

	const hdlHapusData = async (_IDTABEL) => {
		var vFuncName = hdlHapusData.name;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);

		setTabelRowSelect(vRowIdx);

		//console.log("vRowIdx = "+vRowIdx);
		//console.log("vObjdx = "+vObjdx);
		//console.log("uTabelRowSelect = "+uTabelRowSelect);

		const svcFuncNext	= () => {
			/*//--TESTING_FRONTEND--/
			uDataObj.splice(vObjdx,1);

			let vJmlHal	= Math.ceil(uDataObj.length / uMaxData);
			setJmlHal(vJmlHal);
			
			setBodyInit(uBodyInit?false:true);
			return;
			//--END TESTING_FRONTEND--*/

			var vDATAS    = JSON.stringify({
				send_tabelid : _IDTABEL,
				send_tokenauth : objToken.userinit
			});
			var vURLs     = pjson.svcpage+"svc_user/pr_hapus";

			setLoading(true);
			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST", mode: "cors",
				cache: "default", body: vDATAS,
			}).then((response)=> {
				if (response.status === 200) return response.json();
				//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
			}).then((output_string) => {
				setLoading(false);
				if(output_string.sukses) {
					uDataObj.splice(vObjdx,1);
					setTabelRowSelect(-1);
					setJmlData((parseInt(uDataObj.length)||0));
					setBodyInit(!uBodyInit);
				} else if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
					var vMsg;
					if(pjson.mydefault.environment=="development")
						vMsg = output_string.errors;
					else vMsg = pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					prosesExpired(cHistory);
				}
			}).catch((error) =>{
				setLoading(false);
				console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
				//setHTMLError500(pjson.mydefault.msg500str.join(" "));
				cHistory.push("/error500");
			});
		}

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+uDataObj[vObjdx].username.toUpperCase()+"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcFuncNext();
	}

	const hdlInitOffline = async (_IDTABEL) => {
		var vFuncName = hdlInitOffline.name;

		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vInitId	= parseInt(uDataObj[vObjdx].userinitid) || 0;

		//console.log("("+cComponentName+") "+vFuncName+" => uDataObj "+JSON.stringify(uDataObj));
		//alert(uDataObj[vObjdx].userinitid);
		//return;

		const svcFuncNext = ()=>{
			/*//---TESTING_FRONTEND--/
			uDataObj[vObjdx].userstatus = uDataObj[vObjdx].userstatus=="ONLINE"?"OFFLINE":uDataObj[vObjdx].userstatus; 
			setBodyInit(uBodyInit?false:true);
			return;
			//---END TESTING_FRONTEND--*/

			var vDATAS    = JSON.stringify({
				send_tabelid : _IDTABEL,
				send_initid : vInitId,
				send_tokenauth : objToken.userinit
			});
			var vURLs     = pjson.svcpage+"svc_user/pr_status";

			setLoading(true);
			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST", mode: "cors",
				cache: "default", body: vDATAS,
			}).then((response)=> {
				if (response.status === 200) return response.json();
				//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
			}).then((output_string) => {
				setLoading(false);
				if(output_string.sukses) {
					uDataObj[vObjdx].userstatus = uDataObj[vObjdx].userstatus=="ONLINE"
						?"OFFLINE":uDataObj[vObjdx].userstatus; 
					setBodyInit(!uBodyInit);
				} else if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
					var vMsg;
					if(pjson.mydefault.environment=="development")
						vMsg = output_string.errors;
					else vMsg = pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					prosesExpired(cHistory);
				}
			}).catch((error) =>{
				setLoading(false);
				console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
				//setHTMLError500(pjson.mydefault.msg500str.join(" "));
				cHistory.push("/error500");
			});
		}

		let vConfirm = obBahasa.confirm_yakin;
		if(await Konfirm(vConfirm)) svcFuncNext();
	}

	const loadTabelBody	= () => {
		var vFuncName	= loadTabelBody.name;
		//if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(parseInt(uDataObj.length) || 0);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {id,userstatus,username,usercaption,userhak } = vItem
			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				let vCaptionUpper	= username.toUpperCase();
				var vReplace 		= new RegExp(uKeywordLoad,"ig");

				let vUserName= uKeywordLoad=="" ? username 
					: username.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vUsercaption= uKeywordLoad=="" ? usercaption 
					: usercaption.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				return (
				<tr id={"idtr"+id} className={uTabelRowSelect===vIdxRow?"classrowselect":""}>
					<td align="right">{(vKey+1)+"."}</td>
					<td align="center">
					<CTooltip content={"--"+obBahasa.caption_statusonline+": "+
						vCaptionUpper+" ("+obBahasa.petunjuk_settooffline+")"}>
					<CLink className="classikontabel classikononline"
						style={{display: userstatus=="ONLINE" ? 'block' : 'none' }}
						onClick={()=>hdlInitOffline(id)} />
					</CTooltip>
					<CTooltip content={"--"+obBahasa.caption_statusoffline+": "+vCaptionUpper}>
						 <div className="classikontabel classikonoffline"
						 	style={{display: userstatus=="ONLINE" ? 'none' : 'block' }}/>
					</CTooltip>
					</td>
					<td>
					<CRow>
					<CCol sm="12" md="3" className="font-weight-bolder text-primary">{UFunc.renderHTML(vUserName)}</CCol>
					<CCol sm="12" md="5">{UFunc.renderHTML(vUsercaption)}</CCol>
					<CCol sm="12" md="2" className="text-sm-left text-md-center">{obBahasa["hakuser_"+userhak.toLowerCase()]}</CCol>
					<CCol sm="12" md="2" className="text-sm-left text-md-center">
					<CTooltip content={"--"+obBahasa.caption_setrole+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlSetRole(id)} className="classikontabel classikonsetrole d-none" />
					</CTooltip>
					<CTooltip content={"--"+obBahasa.caption_editdata+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlEditData(id)} className="classikontabel classikonedit" />
					</CTooltip>
					<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlHapusData(id)} className="classikontabel classikonhapus" />
					</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}

	const loadContent = () => {
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return (
			<div className="table-responsive-sm">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th width="5%" className="align-top text-center">Status</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="3" className="text-left">Username</CCol>
				<CCol sm="12" md="5" className="text-left">Nama Pengguna</CCol>
				<CCol sm="12" md="2">Hak Akses</CCol>
				<CCol sm="12" md="2">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}

	const loadContentForm = () => {
		if(uInitForm=="USER") return loadFormUser();
		return loadFormRole();
	}

	const loadFormUser	= () => {
		return (
			<>
			<CRow className="justify-content-center">
			<CCol xs="9">
			<CForm>
				<CRow>
				<CCol xs="12">
					<CFormGroup row>
					<CCol xs="12" md="4">
						<CLabel>{obBahasa.word_username}</CLabel>
					</CCol>
					<CCol xs="12" md="4">
						<CInput maxLength="30"
							type="text"
							className="form-control"
							value={uInpUsername}
							onChange={(e) => setInpUsername(e.target.value)}
							id="inpusername" />
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol xs="12" md="4">
						<CLabel>{obBahasa.caption_usercaption}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput maxLength="50"
							type="text"
							className="form-control"
							value={uInpUsercaption}
							onChange={(e) => setInpUsercaption(e.target.value)}
							id="inpusercaption" />
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol xs="12" md="4">
						<CLabel>{obBahasa.word_password}</CLabel>
					</CCol>
					<CCol xs="12" xs="12" md="4">
						<CInput maxLength="30"
							type="password"
							className="form-control"
							value={uInpUserPasswd1}
							onChange={(e) => setInpUserpasswd1(e.target.value)}
							id="inppasswd1" />
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol xs="12" md="4">
						<CLabel>{obBahasa.caption_passwdconfirm}</CLabel>
					</CCol>
					<CCol xs="12" md="4">
						<CInput
							type="password"
							value={uInpUserPasswd2}
							onChange={(e) => setInpUserpasswd2(e.target.value)}
							id="inppasswd2"/>
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol xs="12" md="4">
						<CLabel>{obBahasa.caption_hakakses}</CLabel>
					</CCol>
					<CCol xs="12" md="4">
						<CSelect custom name="inpuserhak" id="inpuserhak"
							onChange={(e)=>setInpUserHak(e.target.value)} 
							onKeyDown={(e) => hdlLastField(e)}>
						{uHakObj.map((vItem,vKey)=>{
							return(
							<option value={vItem.value} selected={uInpUserHak==vItem.value?true:false}>{vItem.caption}</option>
							)
						})}
						</CSelect>
					</CCol>
					</CFormGroup>
				</CCol>
				</CRow>
			</CForm>
			</CCol>
			</CRow>
			</>
		);
	}

	const loadFormRole	= () => {

	}

	return (
		<>
		<CRow className="justify-content-center">
		<CCol sm="12">
		<CCard>
			<CCardHeader>
			{obBahasa["menus_"+props.namaRoute.toLowerCase()]}
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlHal > 1) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<DialogForm
			dialogHeader={uDialogHeader}
			onSimpan={()=>hdlSimpan()}
			show={isShowForm} 
			toggle={toggleForm} 
			renderContent={loadContentForm()}/>
		</>
	)
}

export default User;
	