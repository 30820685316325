import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CFormGroup,
	CForm,
	CFormText,
	CSelect,
	CInput,
	CLabel,
	CTextarea,
	CText,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const Biaya = (props) => {
	const cComponentName 	= Biaya.name;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});

	let cFuncName;
	const {setLoading,showToast,prosesExpired}	= props;

	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);

	const uBiayaJenisEmptyObj 				= {id:"0",caption:"--"+(obBahasa.caption_silahkanpilih||"").toUpperCase()+"--"};
	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_biaya || "");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uBiayaJenisObj,setBiayaJenisObj]= React.useState([uBiayaJenisEmptyObj]);

	const [uFormHeader,setFormHeader]		= React.useState("");
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uBtnSimpanDisabledBl,setBtnSimpanDisabledBl]= React.useState(false);

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate(new Date()));
	const [uInpTanggalDt,setInpTanggalDt]	= React.useState(new Date());
	const [uInpJenisID,setInpJenisID]		= React.useState(0);
	const [uInpJenisTeks,setInpJenisTeks]	= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpNoNota,setInpNoNota]			= React.useState("");
	const [uInpNilai,setInpNilai]			= React.useState(0);

	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const toggleForm 	= () => { setShowForm(!isShowForm); }
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		if(!uisKlikTambah) return;

		initResetForm();

		setFormHeader(obBahasa.word_input+" "+obBahasa["menus_"+props.namaRoute.toLowerCase()]);
		cDispatch({type: "set", isKlikTambah: false});

		setShowForm(true);
	},[uisKlikTambah]);
	React.useEffect(()=>{
		if(!isShowForm) return;

		var vTimeout = setTimeout(function(e){
			const vElFocus = document.getElementById("inptanggal");
			if(!UFunc.isEmpty(vElFocus)) vElFocus.focus();
			clearTimeout(vTimeout);
		},200);
	},[isShowForm]);
	React.useEffect(()=>{
		const vElBtn = document.getElementById("btnDialogSimpan");
		if(UFunc.isEmpty(vElBtn)) return;
		vElBtn.disabled = uBtnSimpanDisabledBl;
	},[uBtnSimpanDisabledBl]);
	//---END DIALOG_HANDLE:Harus Selalu ADA--/

	React.useEffect(()=>{ setKeywordInit(uKeywordInitObj.kwd_biaya || ""); },[uKeywordInitObj]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{ loadTabelBody(); },[uBodyInit]);
	React.useEffect(()=>{ setJmlData((parseInt(uDataObj.length)||0)) },[uDataObj]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);
	React.useEffect(()=>{ setInpTanggalDt(UFunc.toDate(uInpTanggal)); },[uInpTanggal]);
	React.useEffect((e)=>{ 
		if((parseInt(uInpJenisID)||0)<=0) {setInpJenisTeks(""); return}

		var vObjdx	= UFunc.getObjectIndeks(uBiayaJenisObj,uInpJenisID);
		if(vObjdx >= 0) { setInpJenisTeks(uBiayaJenisObj[vObjdx].caption); }
	},[uInpJenisID]);

	const hdlKlikEdit=(_IDTABEL)=>{
		cFuncName	= hdlKlikEdit.name;
		_IDTABEL	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setInpTabelID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setFormHeader(obBahasa.caption_editdata+": "+uDataObj[vObjdx].tgl_biaya.toUpperCase());

		setInpTanggal(uDataObj[vObjdx].tgl_biayadb);
		setInpJenisID(uDataObj[vObjdx].id_biaya_jenis);
		setInpKeterangan(uDataObj[vObjdx].keterangan);
		setInpNoNota(uDataObj[vObjdx].no_nota);
		setInpNilai(uDataObj[vObjdx].nilai_biaya);

		setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		cFuncName = hdlKlikHapus.name;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		setTabelRowSelect(vRowIdx);
		
		const svcFuncNext=()=>{
			/*//--TESTING_FRONTEND--/
			var vTmpObj = uDataObj;
			vTmpObj.splice(vObjdx,1);
			setDataObj(vTmpObj);
			setJmlData(vTmpObj.length);
			setBodyInit(!uBodyInit);
			return;
			//--END TESTING_FRONTEND--*/

			var vDATAS    = JSON.stringify({
				send_tabelid : _IDTABEL,
				send_tokenauth : objToken.userinit
			});
			var vURLs     = pjson.svcpage+"svc_biaya/pr_hapus";

			setLoading(true);
			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST", mode: "cors",
				cache: "default", body: vDATAS,
			}).then((response)=> {
				if (response.status === 200) return response.json();
			}).then((output_string) => {
				setLoading(false);
				if(output_string.sukses) {
					var vTmpObj = uDataObj;
					vTmpObj.splice(vObjdx,1);
					setDataObj(vTmpObj);
					setJmlData(vTmpObj.length);
					setBodyInit(!uBodyInit);
				} else if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
					var vMsg;
					if(pjson.mydefault.environment=="development")
						vMsg = output_string.errors;
					else vMsg = pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true);
					prosesExpired(cHistory);
				}
			}).catch((error) =>{
				setLoading(false);
				console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
				showToast(pjson.mydefault.msgFetchError.join(" "),"ERROR");
			});
		}

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+(uDataObj[vObjdx].biaya_jenis+" "+uDataObj[vObjdx].tgl_biaya).toUpperCase()+"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcFuncNext();
	}
	const hdlKlikSimpan=()=>{
		cFuncName	= hdlKlikSimpan.name;

		//---VALIDASI--/
		if(uInpTanggal=="") {
			document.getElementById("inptanggal").focus();
			showToast(obBahasa.word_tanggal+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vDateBeyond = UFunc.DateIsBeyond(uInpTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(uInpTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptanggal").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
		}
		if((parseInt(uInpJenisID)||0) <= 0){
			document.getElementById("inpbiayajenisid").focus();
			showToast(obBahasa.caption_biayajenis+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}
		if((parseInt(uInpNilai)||0) <= 0){
			document.getElementById("inpnilaibiaya").focus();
			showToast(obBahasa.caption_biayanilai+" "+obBahasa.caption_invalid+"..");
			return;
		}
		//---END VALIDASI--/

		svcSimpan();
	}
	const initResetForm=()=>{
		setInpTabelID(0);
		setInpTanggal(UFunc.DbDate(new Date()));
		setInpJenisID(0);
		setInpJenisTeks("");
		setInpKeterangan("");
		setInpNoNota("");
		setInpNilai(0);
	}
	const svcloadData=()=>{
		cFuncName = svcloadData.name;
		setHTMLError500("");

		//console.log("("+cComponentName+") "+cFuncName+" => Proses ");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",tgl_biaya:"Kam,02 Jan 2021",tgl_biayadb:"2021-01-02",biaya_jenis:"Biaya ATK",id_biaya_jenis:"1",no_nota:"XX455674",keterangan:"Aku Seorang Kapiten",nilai_biaya:"550000"},
			{id:"2",tgl_biaya:"Kam,03 Feb 2021",tgl_biayadb:"2021-02-03",biaya_jenis:"Biaya Administrasi",id_biaya_jenis:"2",no_nota:null,keterangan:"Aku Seorang Kapiten",nilai_biaya:"150000"},
			{id:"3",tgl_biaya:"Kam,04 Mar 2021",tgl_biayadb:"2021-03-04",biaya_jenis:"Biaya Gaji",id_biaya_jenis:"3",no_nota:"XX455675",keterangan:"Aku Seorang Kapiten",nilai_biaya:"1550000"},
			{id:"4",tgl_biaya:"Kam,05 Jan 2021",tgl_biayadb:"2021-01-05",biaya_jenis:"Biaya Listrik/Air/Telepon",id_biaya_jenis:"4",no_nota:"XX455676",keterangan:"Aku Seorang Kapiten",nilai_biaya:"250000"},
			{id:"5",tgl_biaya:"Kam,06 Feb 2021",tgl_biayadb:"2021-02-06",biaya_jenis:"Biaya Jasa Angkut",id_biaya_jenis:"5",no_nota:"",keterangan:"Aku Seorang Kapiten",nilai_biaya:"350000"},
			{id:"6",tgl_biaya:"Kam,07 Mar 2021",tgl_biayadb:"2021-03-07",biaya_jenis:"Biaya ATK",id_biaya_jenis:"1",no_nota:"XX455678",keterangan:"Aku Seorang Kapiten",nilai_biaya:"250000"},
			{id:"7",tgl_biaya:"Kam,08 Jan 2021",tgl_biayadb:"2021-01-08",biaya_jenis:"Biaya Administrasi",id_biaya_jenis:"2",no_nota:"XX455679",keterangan:"Aku Seorang Kapiten",nilai_biaya:"555000"},
			{id:"8",tgl_biaya:"Kam,09 Feb 2021",tgl_biayadb:"2021-02-09",biaya_jenis:"Biaya Gaji",id_biaya_jenis:"3",no_nota:null,keterangan:null,nilai_biaya:"3550000"},
			{id:"9",tgl_biaya:"Kam,10 Mar 2021",tgl_biayadb:"2021-03-10",biaya_jenis:"Biaya Listrik/Air/Telepon",id_biaya_jenis:"4",no_nota:"XX455681",keterangan:"Aku Seorang Kapiten",nilai_biaya:"250000"},
			{id:"10",tgl_biaya:"Kam,11 Apr 2021",tgl_biayadb:"2021-04-11",biaya_jenis:"Biaya Jasa Angkut",id_biaya_jenis:"5",no_nota:"XX455682",keterangan:"Aku Seorang Kapiten",nilai_biaya:"150000"},
			{id:"11",tgl_biaya:"Kam,12 Jan 2021",tgl_biayadb:"2021-01-12",biaya_jenis:"Biaya ATK",id_biaya_jenis:"1",no_nota:"XX455683",keterangan:"Aku Seorang Kapiten",nilai_biaya:"1750000"},
			{id:"12",tgl_biaya:"Kam,13 Feb 2021",tgl_biayadb:"2021-02-13",biaya_jenis:"Biaya Administrasi",id_biaya_jenis:"2",no_nota:"XX455684",keterangan:null,nilai_biaya:"250000"},
		]
		setDataObj(vTmpObj);

		vTmpObj = [uBiayaJenisEmptyObj];
		vTmpObj = vTmpObj.concat([
			{id:"1",caption:"Biaya ATK"},
			{id:"2",caption:"Biaya Administrasi"},
			{id:"3",caption:"Biaya Gaji"},
			{id:"4",caption:"Biaya Listrik/Air/Telepon"},
			{id:"5",caption:"Biaya Jasa Angkut"},
		]);
		setBiayaJenisObj(vTmpObj);
		return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		setBiayaJenisObj([uBiayaJenisEmptyObj]);
		setJmlData(0);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_biaya/ld_data";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [uBiayaJenisEmptyObj];
				if(output_string.jenisobject) {
					vTmpObj = vTmpObj.concat(JSON.parse(output_string.jenisobject));
				}
				setBiayaJenisObj(vTmpObj);

				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}
	const svcSimpan=()=>{
		cFuncName = svcSimpan.name;

		/*//--TESTING_FRONTEND--/
		let vNewDataObj	= uDataObj;
		if((parseInt(uInpTabelID)||0) <= 0) {
			let vAddedObj	= [{
				id:"255",
				tgl_biaya:UFunc.TglAngka(uInpTanggalDt),//-->PROSES_DIBACKEND--
				tgl_biayadb:uInpTanggal,
				biaya_jenis:uInpJenisTeks,
				id_biaya_jenis:uInpJenisID,
				no_nota:uInpNoNota||null,
				keterangan:uInpKeterangan||null,
				nilai_biaya:uInpNilai
			}]
			vNewDataObj = vAddedObj.concat(uDataObj);
			setActivePage(1);
			showToast(obBahasa.pesan_sukses+"..","SUKSES");
		} else {
			var vObjdx	= UFunc.getObjectIndeks(vNewDataObj,uInpTabelID);

			vNewDataObj[vObjdx].tgl_biaya 	= UFunc.TglAngka(uInpTanggalDt);//-->PROSES_DIBACKEND--
			vNewDataObj[vObjdx].tgl_biayadb	= uInpTanggal;
			vNewDataObj[vObjdx].biaya_jenis = uInpJenisTeks;
			vNewDataObj[vObjdx].id_biaya_jenis= uInpJenisID;
			vNewDataObj[vObjdx].no_nota 	= uInpNoNota||null;
			vNewDataObj[vObjdx].keterangan 	= uInpKeterangan||null;
			vNewDataObj[vObjdx].nilai_biaya = uInpNilai;
			toggleForm();
		}
		setDataObj(vNewDataObj);
		initResetForm();
		setBodyInit(!uBodyInit);
		return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_tabelid : uInpTabelID,
			send_tanggal : uInpTanggal,
			send_nonota : uInpNoNota,
			send_jenisid : uInpJenisID,
			send_keterangan : uInpKeterangan,
			send_nilai : uInpNilai,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_biaya/pr_simpan";

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.sukses) {

				let vNewDataObj	= uDataObj;
				if((parseInt(uInpTabelID)||0) <= 0) {
					let vAddedObj	= [{
						id:output_string.newtabelid,
						tgl_biaya:output_string.newtanggal,//-->PROSES_DIBACKEND--
						tgl_biayadb:uInpTanggal,
						biaya_jenis:uInpJenisTeks,
						id_biaya_jenis:uInpJenisID,
						no_nota:uInpNoNota||null,
						keterangan:uInpKeterangan||null,
						nama_user:objToken.usercaption||"-",
						nilai_biaya:uInpNilai
					}];
					vNewDataObj = vAddedObj.concat(uDataObj);
					showToast(obBahasa.pesan_sukses+"..","SUKSES");
					setActivePage(1);
				} else {
					var vObjdx	= UFunc.getObjectIndeks(vNewDataObj,uInpTabelID);

					vNewDataObj[vObjdx].tgl_biaya 	= output_string.newtanggal;//-->PROSES_DIBACKEND--
					vNewDataObj[vObjdx].tgl_biayadb	= uInpTanggal;
					vNewDataObj[vObjdx].biaya_jenis = uInpJenisTeks;
					vNewDataObj[vObjdx].id_biaya_jenis= uInpJenisID;
					vNewDataObj[vObjdx].no_nota 	= uInpNoNota||null;
					vNewDataObj[vObjdx].keterangan 	= uInpKeterangan||null;
					vNewDataObj[vObjdx].nilai_biaya = uInpNilai;
					
					toggleForm();
				}
				setDataObj(vNewDataObj);
				initResetForm();
				setBodyInit(!uBodyInit);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				toggleForm();
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnSimpanDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			showToast(pjson.mydefault.msgFetchError.join(" "),"ERROR");
		});
	}

	const loadContent=()=>{
		if(uHTMLError500)
			return ( <>{UFunc.renderHTML(uHTMLError500)}</> );

		return (
			<div className="table-responsive-sm">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="2" className="text-left">{obBahasa.word_tanggal}</CCol>
				<CCol sm="12" md="2" className="text-center"></CCol>
				<CCol sm="12" md="4" className="text-left">{obBahasa.caption_biayajenis}</CCol>
				<CCol sm="12" md="2" className="text-center">{obBahasa.caption_biayanilai} (Rp)</CCol>
				<CCol sm="12" md="2">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}
	const loadTabelBody=()=>{
		cFuncName	= loadTabelBody.name;
		//console.log("("+cComponentName+") "+cFuncName+" => uPageNow : "+uPageNow);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,tgl_biaya,tgl_biayadb,no_nota,biaya_jenis,
				id_biaya_jenis,keterangan,nilai_biaya,nama_user
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				let vCaptionUpper	= (tgl_biaya+((no_nota||"")!=""?(" ("+no_nota+")"):"")).toUpperCase();

				let vNoNota= UFunc.isEmpty(no_nota) ? "" : "<div class='font-weight-bolder'>("+ ( uKeywordLoad=="" ? (no_nota) 
					: (no_nota||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>")
				)+")</div>";
				let vTanggalNota = tgl_biaya+(vNoNota||"");
				let vBiayaJenis= biaya_jenis!="" 
					? "<div class='classfontlarger text-primary'>"+(uKeywordLoad=="" ? biaya_jenis : biaya_jenis.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>"))+"</div>"
					: "";
				let vKeterangan= !UFunc.isEmpty(keterangan)
					? "<div class='text-info font-italic'>"+(uKeywordLoad=="" ? keterangan : keterangan.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>"))+"</div>"
					: "";
				let vInputer	= uKeywordLoad=="" ? (nama_user||"")
					: (nama_user||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vBiayaNilai	= UFunc.formatAngka(nilai_biaya);

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="2" className="text-left px-1">{UFunc.renderHTML(vTanggalNota)}</CCol>
					<CCol sm="12" md="2" className="text-center text-danger classfontsmaller">[{UFunc.renderHTML(vInputer)}]</CCol>
					<CCol sm="12" md="4" className="text-left px-1">
						{UFunc.renderHTML(vBiayaJenis)}
						{UFunc.renderHTML(vKeterangan)}
					</CCol>
					<CCol sm="12" md="2" className="text-right text-success font-weight-bolder px-1">{vBiayaNilai}</CCol>
					<CCol sm="12" md="2" className="text-center px-1">
						<CTooltip html={false} content={"--"+obBahasa.caption_editdata+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"+(objToken.userhak!="FREEROLE"&&objToken.userhak!="MANAJER" ? " d-none" : "") }
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}
	const loadContentForm=()=>{
		cFuncName = loadContentForm.name;
		return (
			<>
			<CRow className="justify-content-center">
			<CCol sm="12" md="11">
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.word_tanggal}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="10"
						type="date"
						className="form-control"
						value={uInpTanggal}
						onChange={(e) => setInpTanggal(e.target.value)}
						id="inptanggal"/>
				</CCol>
				</CFormGroup>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_nonota}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CInput maxLength="30"
						type="text"
						className="form-control"
						value={uInpNoNota}
						onChange={(e) => setInpNoNota(e.target.value.toUpperCase())}
						style={{textTransform:"uppercase"}}
						id="inpnonota"/>
				</CCol>
				</CFormGroup>

				<CFormGroup row >
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_biayajenis}</CLabel>
				</CCol>
				<CCol xs="12" md="6">
					<CSelect custom name="inpbiayajenisid" id="inpbiayajenisid"
						value={uInpJenisID}
						onChange={(e) => setInpJenisID(e.target.value)}>
						{uBiayaJenisObj.map((vItemJenis,vKeyJenis)=>{
							//console.log("("+cComponentName+") "+cFuncName+" => vItemJenis.caption : "+vItemJenis.caption);
							return (
							<option value={vItemJenis.id} key={vKeyJenis}>{vItemJenis.caption.toUpperCase()}</option>
							)
						})}
					</CSelect>
				</CCol>
				</CFormGroup>

				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.word_keterangan}</CLabel>
				</CCol>
				<CCol xs="12" md="8">
					<CInput maxLength="255"
						type="text"
						value={uInpKeterangan}
						onChange={(e) => setInpKeterangan(e.target.value)}
						id="uInpKeterangan"/>
				</CCol>
				</CFormGroup>

				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_biayanilai}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<NumberFormat 
						value={uInpNilai}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpNilai(values.value)}
						renderText={values => (
							<CInput 
								value={values}
								className="text-right"
								onChange={(e) => setInpNilai(e.target.value)}
								onFocus={(e)=>e.target.select()}
								onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
							id="inpnilaibiaya"/>
						)} 
						prefix={'Rp'}/>
				</CCol>
				</CFormGroup>

			</CForm>
			</CCol>
			</CRow>
			</>
		);
	}

	//console.log("("+cComponentName+") "+cFuncName+" => xxx : "+xxx);
	return (
		<>
		<CRow className="justify-content-center">
		<CCol xs="12">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa.caption_biayalist}</strong>
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlData >= uMaxData) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<DialogForm
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlKlikSimpan()}
			show={isShowForm} 
			dialogHeader={uFormHeader}
			toggle={toggleForm}
			renderContent={loadContentForm()}/>
		</>
	)
}

export default Biaya;
	