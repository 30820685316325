import React from 'react';
import { CFooter } from '@coreui/react';
var pjson     = require('../../package.json');

const TheFooter = (props) => {
  const vFuncName     = TheFooter.name;

  /*const setLocalToast = (_TEXT) => { props.showToast(_TEXT) }
  React.useEffect(()=>{ setLocalToast(vFuncName); },[]);//-*/

  return (
    <CFooter fixed={false} className="px-3 text-black-50 bg-transparent text-right ">
      <div className="my-0 " style={{fontSize:11,opacity:0.5}}>
        <span>Copyright v{pjson.version} &copy; {pjson.mydefault.prodyear} </span>
        <a href="https://www.svarna-dwipa.com" target="_blank" rel="noopener noreferrer">SvarnadwiPa</a>
        <span className="">, All Rights Reserved</span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter);
