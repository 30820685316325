import { createStore } from 'redux';

const initialState = {
  sidebarShow: "",
  listToast: [],
  listBahasa: [],
  listNav: [],
  listRoute: [],
  profileRoute:[
    {"path" : "/ilangs","exact" : true,"name":"iLangs"},
    {"path" : "/iprofile","exact" : true,"name":"iProfile"},
    {"path" : "/isetting","exact" : true,"name":"iSetting"},
  ],
  listKeyword: {},
  listTabelID: {},
  gInitGrafikShow: true,
  isHeaderExecute: false,
  isKlikTambah: false,
  isKlikCetak: false,
  activeRoute: "",
  gTeksAutoSelect: "",
  gMaxLaporan: 100,
  gMaxMaster: 15,
  gHTMLstyleGrafik: `
    <style>
    @media print {
      @page {
        size: landscape;
      }
    }
    </style>
  `,
  gHTMLstyleLaporan: `
    <style>
    @media print {
      table#idtabelhtml {
        font-family: Calibri,Arial,Sans Serif;/**/
        width:100%;
        margin: 0 auto;
        border-spacing: 0;
        padding: 0;
        text-align: center;
        display: table;
      }
      table#idtabelhtml caption {
        padding:10px 3px;
        font-weight:bolder;
        font-size:larger;
      }
      table#idtabelhtml thead tr th {
        border-top: 2px solid grey;border-bottom: 1px solid grey;
        padding: 10px 7px;
      }
      table#idtabelhtml tbody td {
        padding: 3px; border-bottom: 1px solid #f0f0f0;
        overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all;
        word-break: break-all; word-break: break-word;
        -ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;
        hyphens: auto;
      }
      table#idtabelhtml tbody td .classpetunjuk {
        font-size:smaller;font-style:italic;margin-top:3px;
        border-top:1px dashed #DCDCDC;padding:3px 7px;
        padding-bottom:0;
      }
      table#idtabelhtml tfoot td  {
        border-bottom: 2px solid grey;border-top: 1px solid grey;
        padding: 5px 3px;
        font-weight:bolder;
      }
    }
    </style>`,
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState);
export default store;