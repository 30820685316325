import React from 'react';

const TheLoaderEls = props => {
	const {isShow} = props;
	
	if(!isShow) return null;
	
	return (
	<div className="my-3 d-flex justify-content-center align-items-center">
		<div className="spinner-border spinner-border-sm text-warning" role="status" />
	</div>
	);
}	

export default TheLoaderEls;