import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CWidgetProgress,
	CWidgetSimple,
	CWidgetProgressIcon,
	CProgress,
	CImg,
} from "@coreui/react";
import { CChartLine,CChartBar } from '@coreui/react-chartjs';
import CIcon from '@coreui/icons-react';
import { getStyle, hexToRgba } from '@coreui/utils';
import { UFunc } from "../helpers/functions";
import { TheLoaderEls } from '../components/index';

var pjson     = require('../../package.json');

const Dashboard = (props) => {
	const cComponentname 	= Dashboard.name;
	let cFuncname;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});
	const {setLoading,showToast,prosesExpired}	= props;

  	const obBahasa 					= useSelector(state => state.listBahasa);
	const objToken					= JSON.parse(localStorage.getItem("token"));
	const uTglNowDT					= new Date();
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl		= useSelector(state => state.isHeaderExecute);
	const uKeywordObj				= useSelector(state => state.listKeyword);
	const [uSelectValue,setSelectValue] 	= React.useState(uKeywordObj.val_dashboard||"");

	const [uPendapatanObj,setPendapatanObj]	= React.useState({});
	const [uTransaksiObj,setTransaksiObj]	= React.useState({});
	const [uPiutangObj,setPiutangObj]		= React.useState([]);
	const [uHutangObj,setHutangObj]			= React.useState([]);

	const [uLoadPendapatanBl,setLoadPendapatanBl] = React.useState(false);
	const [uLoadTransaksiBl,setLoadTransaksiBl] = React.useState(false);
	const [uLoadPiutangBl,setLoadPiutangBl] = React.useState(false);
	const [uLoadHutangBl,setLoadHutangBl] = React.useState(false);

	const uElInpFilterID	= document.getElementById("inpfiltertglsatu");

	React.useEffect(()=>{ setSelectValue(uKeywordObj.val_dashboard||""); },[uKeywordObj.val_dashboard]);
	React.useEffect(()=>{ if(uSelectValue=="") return; svcLoadData(); },[uisHeaderExecuteBl]);//-*/

	if(UFunc.isEmpty(objToken.userinit)) {
		setLoading(true);
		prosesExpired();
		return null;
	}

	const initResPendapatan=(output)=>{
		cFuncname	= initResPendapatan.name;

		setLoadPendapatanBl(false);
		if(output.tampil) {
			if(output.dataobject) {
				const vTmpObj = JSON.parse(output.dataobject);
				setPendapatanObj(vTmpObj);
			}
		} else if(output.info) {
			showToast("("+cFuncname+") "+output.info);
		} else if(output.errors) {
			console.log("("+cComponentname+"-"+cFuncname+") output.errors : "+output.errors);
			var vMsg = "("+cFuncname+") ";
			if(pjson.mydefault.environment=="development")
				vMsg += output.errors;
			else vMsg += pjson.mydefault.msgFetchError;
			showToast(vMsg,"ERROR");
		} else if(output.expired) {
			setLoading(true);
			prosesExpired(cHistory);
		}
	}
	const initResTransaksi=(output)=>{
		cFuncname	= initResTransaksi.name;

		setLoadTransaksiBl(false);
		if(output.tampil) {
			if(output.dataobject) {
				const vTmpObj = JSON.parse(output.dataobject);
				setTransaksiObj(vTmpObj);
			}
		} else if(output.info) {
			showToast("("+cFuncname+") "+output.info);
		} else if(output.errors) {
			console.log("("+cComponentname+"-"+cFuncname+") output.errors : "+output.errors);
			var vMsg = "("+cFuncname+") ";
			if(pjson.mydefault.environment=="development")
				vMsg += output.errors;
			else vMsg += pjson.mydefault.msgFetchError;
			showToast(vMsg,"ERROR");
		} else if(output.expired) {
		}
	}
	const initResPiutang=(output)=>{
		cFuncname	= initResPiutang.name;

		setLoadPiutangBl(false);
		if(output.tampil) {
			if(output.dataobject) {
				const vTmpObj = JSON.parse(output.dataobject);
				setPiutangObj(vTmpObj);
			}
		} else if(output.info) {
			console.log("("+cComponentname+"-"+cFuncname+") output.info : "+output.info);
			showToast("("+cFuncname+") "+output.info);
		} else if(output.errors) {
			console.log("("+cComponentname+"-"+cFuncname+") output.errors : "+output.errors);
			var vMsg = "("+cFuncname+") ";
			if(pjson.mydefault.environment=="development")
				vMsg += output.errors;
			else vMsg += pjson.mydefault.msgFetchError;
			showToast(vMsg,"ERROR");
		} else if(output.expired) {
		}
	}
	const initResHutang=(output)=>{
		cFuncname	= initResHutang.name;

		setLoadHutangBl(false);
		if(output.tampil) {
			if(output.dataobject) {
				const vTmpObj = JSON.parse(output.dataobject);
				setHutangObj(vTmpObj);
			}
		} else if(output.info) {
			console.log("("+cComponentname+"-"+cFuncname+") output.info : "+output.info);
			showToast("("+cFuncname+") "+output.info);
		} else if(output.errors) {
			console.log("("+cComponentname+"-"+cFuncname+") output.errors : "+output.errors);
			var vMsg = "("+cFuncname+") ";
			if(pjson.mydefault.environment=="development")
				vMsg += output.errors;
			else vMsg += pjson.mydefault.msgFetchError;
			showToast(vMsg,"ERROR");
		} else if(output.expired) {
		}
	}
	const svcLoadData=()=>{
		cFuncname	= svcLoadData.name;

		if(UFunc.isEmpty(objToken.userinit)) {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		setHTMLError500(undefined);
		setPendapatanObj({});
		setTransaksiObj({});
		setPiutangObj([]);
		setHutangObj([]);
		/*//--TESTING_FRONTEND--/
			let vTmpObj;
			vTmpObj	= {
				datagrafik : [
					{tanggal:"2021-03-01",nilai:"1250000",},
					{tanggal:"2021-03-10",nilai:"3500000",},
					{tanggal:"2021-03-11",nilai:"2500000",},
					{tanggal:"2021-03-12",nilai:"1500000",},
					{tanggal:"2021-03-13",nilai:"4500000",},
					{tanggal:"2021-03-14",nilai:"7500000",},
					{tanggal:"2021-03-15",nilai:"4500000",},
					{tanggal:"2021-03-16",nilai:"2500000",},
					{tanggal:"2021-03-17",nilai:"0",},
					{tanggal:"2021-03-20",nilai:"6500000",},
					{tanggal:"2021-03-22",nilai:"1500000",},
					{tanggal:"2021-03-23",nilai:"6500000",},
					{tanggal:"2021-03-25",nilai:"3500000",},
				],
				datavalue : {
					todayvalue: "500000",
					todaypersen: "25",
					todaytanggal: "2021-03-05",
					monthvalue: "1500000",
					monthpersen: "50"
				}
			}
			setPendapatanObj(vTmpObj);

			vTmpObj	= {
				produk : { nama_barang: "Ayam Panggang Dicampur Babi", nilai: "3000000", },
				biaya: { nama_biaya: "Biaya Gaji Karyawan",nilai:"373466000"},
			}
			setTransaksiObj(vTmpObj);

			vTmpObj	= [
				{id:"1",nama_customer:"Customer Satoe",nilai:"6000000",persen:"90"},
				{id:"2",nama_customer:"Customer Doea",nilai:"5000000",persen:"75"},
				{id:"3",nama_customer:"Customer Tiga",nilai:"4000000",persen:"60"},
				{id:"4",nama_customer:"Customer Empat",nilai:"3000000",persen:"55"},
				{id:"5",nama_customer:"Customer Lima",nilai:"1000000",persen:"30"},
			]
			setPiutangObj(vTmpObj);

			vTmpObj	= [
				{id:"1",nama_supplier:"Supplier Satoe",nilai:"6000000",persen:"90"},
				{id:"2",nama_supplier:"Supplier Doea",nilai:"5000000",persen:"75"},
				{id:"3",nama_supplier:"Supplier Tiga",nilai:"4000000",persen:"60"},
				{id:"4",nama_supplier:"Supplier Empat",nilai:"3000000",persen:"55"},
				{id:"5",nama_supplier:"Supplier Lima",nilai:"1000000",persen:"30"},
			]
			setHutangObj(vTmpObj);
			return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_tanggal: uSelectValue,
			send_tokenauth : objToken.userinit
		});
		const vURLs     = pjson.svcpage+"svc_dashboard/";
		const vHeader	= {
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}

		setLoadPendapatanBl(true);
		setLoadTransaksiBl(true);
		setLoadPiutangBl(true);
		setLoadHutangBl(true);
		Promise.all([
			fetch(vURLs+"ld_pendapatan",vHeader),
			fetch(vURLs+"ld_transaksi",vHeader),
			fetch(vURLs+"ld_piutang",vHeader),
			fetch(vURLs+"ld_hutang",vHeader),
		])
		.then(([res_pendapatan, res_transaksi, res_piutang, res_hutang]) => Promise.all([
			res_pendapatan.json(), 
			res_transaksi.json(),
			res_piutang.json(),
			res_hutang.json()
		]))
		.then(async([
			output_pendapatan,
			output_transaksi,
			output_piutang,
			output_hutang
		]) => {
			await initResPendapatan(output_pendapatan);
			await initResTransaksi(output_transaksi);
			await initResPiutang(output_piutang);
			await initResHutang(output_hutang);
		})
		.catch((error1)=>{
			setLoadPendapatanBl(false);
			setLoadTransaksiBl(false);
			setLoadPiutangBl(false);
			setLoadHutangBl(false);
			if(error1) console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error1);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}

	const loadPendapatan=()=>{
		cFuncname	= loadPendapatan.name;

		if(uLoadPendapatanBl) return ( <TheLoaderEls isShow={true}/> );

		if(uHTMLError500) return (
			<>{UFunc.renderHTML(uHTMLError500)}</>
		);

		let vLabelX		= [];
		let vValue		= [];
		let vRataArr	= [];
		let vSUMTotal	= 0;
		const vGrafikObj= uPendapatanObj.datagrafik || [];
		const vDataObj	= uPendapatanObj.datavalue || {}

		Object.keys(vGrafikObj).forEach(function(key) {
			vLabelX.push(vGrafikObj[key].tanggal);
			vValue.push(vGrafikObj[key].nilai);
			vSUMTotal = vSUMTotal + (parseInt(vGrafikObj[key].nilai));
		});

		const vRata2	= Math.floor(vSUMTotal/(vGrafikObj.length));
		Object.keys(vGrafikObj).forEach(function(key) {
			vRataArr.push(vRata2);
		});

		const vDatasets	= [
		{
			label: obBahasa.word_pendapatan||"Pendapatan",
			borderColor: getStyle("primary") || "#0000FF",
			backgroundColor: hexToRgba((getStyle("info") || "#0000FF"),10),
			pointHoverBackgroundColor: getStyle("info") || "#0000FF",
        	borderWidth: 2,
			data: vValue
		},
		{
			label: obBahasa.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
			borderColor: getStyle("danger") || "#FF0000",
			pointHoverBackgroundColor: getStyle("danger") || "#FF0000",
			backgroundColor: getStyle("danger") || "#FF0000",
        	borderWidth: 1,
        	fill: false,
			borderDash: [8, 5],
			data: vRataArr
		},
		];

		return(
			<CRow>
			<CCol sm="12" md="8">
				<CChartLine
					datasets={vDatasets}
					options={{
						maintainAspectRatio: true,
						legend: { display: false },
						elements: {
							point: {
								radius: 0,
								hitRadius: 10,
								hoverRadius: 4,
								hoverBorderWidth: 3
							}
						},
						tooltips:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: function(tooltipItem, data) {
									return UFunc.HariAngka(data['labels'][tooltipItem.datasetIndex]);
								},
								label: (tooltipItem, data) => {
									//-->tooltipItem={"xLabel":"2021-03-17","yLabel":3519230,"label":"2021-03-17","value":"3519230","index":8,"datasetIndex":1,"x":425.25463541666664,"y":154.0320283733333}
									//-->data=
									/*console.log(data);
									console.log(tooltipItem);
									console.log(data.datasets[tooltipItem.datasetIndex].label);//-*/

									var vlabels = data.datasets[tooltipItem.datasetIndex].label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += "Rp"+UFunc.formatAngka(tooltipItem.value);
			                        }

									return vlabels;//-*/
								},
							},
						},
						scales:{
							yAxes: [{
								ticks : {
									beginAtZero: true,
									maxTicksLimit: 5,
									stepSize: Math.ceil(250 / 5),
									callback(value) {
										return UFunc.formatAngka(Math.floor(value/1000))+"K"
									}
								},
								scaleLabel: { display: false, },
								gridLines: { display: true, },
							}],
							xAxes: [{
								ticks : {
									callback(value) {
										return (UFunc.TglAngka(value)).substr(0,5)
									}
								},
								gridLines: { drawOnChartArea: false }
							}],
						},
					}}
					labels={vLabelX}
				id="idgrafiklaporan"/>
			</CCol>
			<CCol sm="12" md="4">
				<CRow>
				<CCol sm="12">
					<CWidgetProgress 
						inverse
						className="text-white"
						color="gradient-primary"
						header={UFunc.renderHTML("<span class='classfontsmaller'>Rp</span>"+
							"<span class='classfontlarger'>"+UFunc.formatAngka(vDataObj.todayvalue || "0")+"</span>")}
						text={obBahasa.caption_pendapatanlastday || "Tanggal Hari Ini"}
						value={(vDataObj.todaypersen || "0")}
						footer={(obBahasa.caption_lastinput||"Last Input")+": "+
							(UFunc.isEmpty(vDataObj.todaytanggal) ? "{UNDEFINED}" : UFunc.HariAngka(vDataObj.todaytanggal))}
						/>
				</CCol>
				<CCol sm="12">
					<CWidgetProgress 
						inverse
						className="text-white"
						color="gradient-primary"
						header={UFunc.renderHTML("<span class='classfontsmaller'>Rp</span>"+
							"<span class='classfontlarger'>"+UFunc.formatAngka(vDataObj.monthvalue || "")+"</span>")}
						text={obBahasa.caption_pendapatanmonth || "Total Pendapatan"}
						value={(vDataObj.monthpersen || "0")}
						/>
					</CCol>
				</CRow>
			</CCol>
			</CRow>
		)
	}
	const loadProduk=()=>{
		cFuncname	= loadProduk.name;

		if(uLoadTransaksiBl) return ( <TheLoaderEls isShow={true}/> );
		if(uHTMLError500) return null;

		const vDataObj	= uTransaksiObj.produk || {}

		return(
			<CLink to={"/lappenjualan"} active={false} style={{textDecoration:"none"}}>
			<CWidgetProgress inverse
				color="info"
				text={obBahasa.caption_penjualantertinggi || "Penjualan Paling Tinggi"} 
				header={(vDataObj.nama_barang || "{UNDEFINED}")} 
				value="95"
				footer={obBahasa.word_nilai+" Rp"+UFunc.formatAngka(vDataObj.nilai || "0")}/>
			</CLink>
		)
	}
	const loadBiaya=()=>{
		cFuncname	= loadBiaya.name;

		if(uLoadTransaksiBl) return ( <TheLoaderEls isShow={true}/> );
		if(uHTMLError500) return null;

		const vDataObj	= uTransaksiObj.biaya || {}

		return(
			<CLink to={"/biaya"} active={false} style={{textDecoration:"none"}}>
			<CWidgetProgress
        		inverse
				text={obBahasa.caption_biayatotal || "Total Biaya"} 
				footer={(vDataObj.nama_biaya || "{UNDEFINED}")+" ("+(obBahasa.caption_tertinggi||"Tertinggi")+")"}
				header={UFunc.renderHTML("<span class='classfontsmaller'>Rp</span>"+
					"<span class=''>"+UFunc.formatAngka(vDataObj.nilai || "")+"</span>")}
				value="95"
				color="info">
          		{/*<CIcon src={pjson.svcpage+"images/menus_biaya.png"} height="25"/>*/}
			</CWidgetProgress>
			</CLink>
		)
	}
	const loadPiutang=()=>{
		cFuncname	= loadPiutang.name;

		if(uLoadPiutangBl) return ( <TheLoaderEls isShow={true}/> );
		if(uHTMLError500) return (<>{"{UNDEFINED}"}</>);

		const vPiutangObj	= uPiutangObj;

		if(vPiutangObj.length <= 0)
			return (<div className="text-info font-italic classfontlarger">*) {obBahasa.caption_dataempty}...</div>)

		return vPiutangObj.map((vItem,vKey) => {
			const {id,nama_customer,nilai,persen} = vItem;

			return (
				<div key={vKey} className="progress-group">
				<div className="progress-group-header">
					<CIcon className="progress-group-icon" name="cil-user" />
					<span className="title font-weight-bold">{nama_customer || ""}</span>
					<span className="ml-auto">
						Rp{UFunc.formatAngka(nilai)}
						{" "}
						<span className="text-muted small">({persen}%)</span>
					</span>
				</div>
				<div className="progress-group-bars">
					<CProgress className="progress-xs" color="success" value={persen} />
				</div>
				</div>
			)
		});
	}
	const loadHutang=()=>{
		cFuncname	= loadHutang.name;

		if(uLoadHutangBl) return ( <TheLoaderEls isShow={true}/> );

		if(uHTMLError500) return (<>{"{UNDEFINED}"}</>);
		
		const vHutangObj	= uHutangObj;

		if(vHutangObj.length <= 0)
			return (<div className="text-info font-italic classfontlarger">*) {obBahasa.caption_dataempty}...</div>)

		return vHutangObj.map((vItem,vKey) => {
			const {id,nama_supplier,nilai,persen} = vItem;

			return (
				<div key={vKey} className="progress-group">
				<div className="progress-group-header">
					<CIcon className="progress-group-icon" name="cil-user" />
					<span className="title font-weight-bold">{nama_supplier || ""}</span>
					<span className="ml-auto">
						Rp{UFunc.formatAngka(nilai)}
						{" "}
						<span className="text-muted small">({persen}%)</span>
					</span>
				</div>
				<div className="progress-group-bars">
					<CProgress className="progress-xs" color="warning" value={persen} />
				</div>
				</div>
			)
		});
	}

	return (
		<>
		<CRow>
		<CCol sm="12">
		<CCard>
		<CCardHeader>
			<CImg src={pjson.svcpage+"images/menus_lappendapatan.png"} height="30" />&nbsp;&middot;&nbsp;
			<strong>{obBahasa.word_pendapatan}</strong>
			<div className={"card-header-actions text-right"}>
				<CLink to={("/lappendapatan")}>
					<span className="text-muted classfontsmaller">{obBahasa.caption_lihatdetil || "View More"}</span>
					<CIcon name="cil-arrow-right" />
				</CLink>
			</div>
		</CCardHeader>
		<CCardBody> {loadPendapatan()}</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<CRow className="justify-content-center">
		<CCol sm="12" md="6">
			{loadProduk()}
		</CCol>
		<CCol sm="12" md="6">
			{loadBiaya()}
		</CCol>
		<CCol sm="12" md="6">
			<CCard>
			<CCardHeader>
				<CImg src={pjson.svcpage+"images/menus_piutang.png"} height="30" />&nbsp;&middot;&nbsp;
				<strong>{obBahasa.caption_toppiutang||"Top 5 Piutang"}</strong>
				<div className={"card-header-actions text-right"}>
					<CLink to={("/piutang")}>
						<span className="text-muted classfontsmaller">{obBahasa.caption_lihatdetil || "View More"}</span>
						<CIcon name="cil-arrow-right" />
					</CLink>
				</div>
			</CCardHeader>
			<CCardBody>{loadPiutang()}</CCardBody>
			</CCard>
		</CCol>
		<CCol sm="12" md="6">
			<CCard>
			<CCardHeader>
				<CImg src={pjson.svcpage+"images/menus_hutang.png"} height="30" />&nbsp;&middot;&nbsp; 
				<strong>{obBahasa.caption_tophutang||"Top 5 Hutang"}</strong>
				<div className={"card-header-actions text-right"}>
					<CLink to={("/hutang")}>
						<span className="text-muted classfontsmaller">{obBahasa.caption_lihatdetil || "View More"}</span>
						<CIcon name="cil-arrow-right" />
					</CLink>
				</div>
			</CCardHeader>
			<CCardBody>{loadHutang()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		</>
	)
}

export default Dashboard;
	