import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CHeader,
	CSubheader,
	CFooter,
	CHeaderNav,
	CHeaderNavItem,
	CHeaderNavLink,
	CHeaderBrand,
	CButton,
	CButtonGroup,
	CCard,
	CCardGroup,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CImg,
	CForm,
	CFormGroup,
	CInput,
	CInputRadio,
	CLabel,
	CSelect,
} from "@coreui/react";
import { MAutocomplete } from '../components/index';
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';
import NumberFormat from 'react-number-format';

var pjson     = require('../../package.json');

const IPenjualan = (props) => {
	const cComponentName 	= IPenjualan.name;
	let cFuncname;
	const {setLoading,showToast,prosesExpired}	= props;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	
	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa 			= useSelector(state => state.listBahasa);
	const objToken			= JSON.parse(localStorage.getItem("token"));
	const uIDInitObj 		= useSelector(state => state.listTabelID);
	const uTextAutoSelect	= useSelector(state => state.gTeksAutoSelect);
	const [uTitleEdit,setTitleEdit]					= React.useState("");

	const [uIDTabelInit,setIDTabelInit] = React.useState(parseInt(uIDInitObj.initidpenjualan)||0);
	const [uDataNotaObj,setDataNotaObj]				= React.useState([]);
	
	const [uItemObj,setItemObj]						= React.useState([]);
	const [uTabelRowSelect,setTabelRowSelect]		= React.useState(-1);
	const [uInitTabelBody,setInitTabelBody]			= React.useState(false);
	const [uTotalBruto,setTotalBruto]				= React.useState(0);
	const [uJmlItemInit,setJmlItemInit]				= React.useState(0);
	const [uItemOldValue,setItemOldValue]			= React.useState(0);
	const [uItemHapusIDArr,setItemHapusIDArr]		= React.useState([]);

	//---HEADER_FORM--/
	const [uFirstTanggal,setFirstTanggal]			= React.useState(UFunc.DbDate(new Date()));
	const [uFirstNonota,setFirstNonota]				= React.useState("");
	const [uFirstCustomerLamaID,setFirstCustomerLamaID]	= React.useState(0);
	const [uFirstCustomerBaru,setFirstCustomerBaru]	= React.useState("");
	const [uFirstJmlItemInit,setFirstJmlItemInit]	= React.useState(0);
	const [uFirstDiskon,setFirstDiskon]				= React.useState(0);
	const [uFirstTerbayar,setFirstTerbayar]			= React.useState(0);
	const [uFirstKeterangan,setFirstKeterangan]		= React.useState("");

	const [uCustomerObj,setCustomerObj]				= React.useState([]);
	const [uInpTanggal,setInpTanggal]				= React.useState(UFunc.DbDate(new Date()));
	const [uInpNonota,setInpNonota]					= React.useState("");
	const [uInpCustomerJenis,setInpCustomerJenis]	= React.useState("LAMA");
	const [uInpCustomerLamaID,setInpCustomerLamaID]	= React.useState(0);
	const [uInpCustomerLamaTeks,setInpCustomerLamaTeks]	= React.useState("");
	const [uInpCustomerBaru,setInpCustomerBaru]		= React.useState("");
	//---END HEADER_FORM--/

	//---FOOTER--/
	const [uInpKeterangan,setInpKeterangan]			= React.useState("");
	const [uInpTerbayar,setInpTerbayar]				= React.useState(0);
	const [uInpDiskon,setInpDiskon]					= React.useState(0);
	const [uTombolSimpanDisabled,setTombolSimpanDisabled]= React.useState(false);
	const [uInitFormUpdatedBl,setInitFormUpdatedBl]	= React.useState(false);
	//---END FOOTER--/

	//---LOOKUP_BARANG--/
	const [uBarangObj,setBarangObj]					= React.useState([]);
	const [uBarangNamaArr,setBarangNamaArr]			= React.useState([]);
	const [uBarangIDArr,setBarangIDArr]			 	= React.useState([]);
	const [uBarangSatuanArr,setBarangSatuanArr]		= React.useState([]);
	const [uBarangHargaBeliArr,setBarangHargaBeliArr]= React.useState([]);
	const [uInpBarangBlur,setInpBarangBlur]			= React.useState(false);

	const [uInpBarangID,setInpBarangID]				= React.useState(0);
	const [uInpBarangnama,setInpBarangnama]			= React.useState("");
	const [uInpBarangjml,setInpBarangjml]			= React.useState(0);
	const [uInpBarangharga,setInpBarangharga]		= React.useState(0);
	const [uInpBarangSatuan,setInpBarangSatuan]		= React.useState("");
	const [uTombolitemDisabled,setTombolitemDisabled]= React.useState(false);
	//---END LOOKUP_BARANG--/

	let uElAutocomplete								= document.getElementById("idautocomplete");
	const btnSimpan 								= document.getElementById("btnSimpan")

	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	React.useEffect(()=>{
		cDispatch({type: 'set', gTeksAutoSelect: ""});

		svcLoadInit();

		return ()=>{
			setCustomerObj([]);
			setDataNotaObj([]);
			setBarangObj([]);
			setItemObj([]);
		}
	},[]);
	React.useEffect(()=>{
		//if(UFunc.isEmpty(uDataNotaObj))  return;
		setItemHapusIDArr([]);

		setFirstTanggal(uDataNotaObj.tgl_penjualan || UFunc.DbDate(new Date()));
		setFirstNonota(uDataNotaObj.no_nota || "");
		setFirstCustomerLamaID(parseInt(uDataNotaObj.customer_id)||0);
		setFirstDiskon(parseInt(uDataNotaObj.diskon)||0);
		setFirstTerbayar(parseInt(uDataNotaObj.terbayar)||0);
		setFirstJmlItemInit(parseInt(uItemObj.length) || 0);
		setFirstKeterangan(uDataNotaObj.keterangan || "");

		setInpTanggal(uDataNotaObj.tgl_penjualan || UFunc.DbDate(new Date()));
		setInpNonota(uDataNotaObj.no_nota || "");
		setInpCustomerLamaID(parseInt(uDataNotaObj.customer_id)||0);
		setInpDiskon(parseInt(uDataNotaObj.diskon)||0);
		setInpTerbayar(parseInt(uDataNotaObj.terbayar)||0);
		setInpKeterangan(uDataNotaObj.keterangan || "");

		var vTitle	= !UFunc.isEmpty(uDataNotaObj.tgl_penjualan)?": "+
			UFunc.HariAngka(new Date(uDataNotaObj.tgl_penjualan) || new Date()):"";
		if(!UFunc.isEmpty(uDataNotaObj.customer_id)) {
			var vNamaCustomer	= "";
			uCustomerObj.map((vItem,vKey)=>{
				if((parseInt(vItem.value)||0)==(parseInt(uDataNotaObj.customer_id)||0))
					vNamaCustomer = vItem.caption;
			});
			vTitle += vNamaCustomer!=""?" "+vNamaCustomer:"";
		}
		vTitle		+= !UFunc.isEmpty(uDataNotaObj.no_nota)?" ("+uDataNotaObj.no_nota+")":"";
		setTitleEdit(vTitle);

		//console.log("("+cComponentName+") useEffect[uDataNotaObj] => customerid = "+uDataNotaObj.customer_id);
	},[uDataNotaObj]);

	//--FORM_HEADER--/
	React.useEffect(()=>{
		if(uInpCustomerJenis.toUpperCase() == "BARU") {
			document.getElementById("idcustomerlama").style.display = "none";
			document.getElementById("idcustomerbaru").style.display = "inline-block";
			document.getElementById("inpcustomerlamaid").disabled = true;
		} else {
			document.getElementById("idcustomerlama").style.display = "inline-block";
			document.getElementById("idcustomerbaru").style.display = "none";
			document.getElementById("inpcustomerlamaid").disabled = false;
		}
	},[uInpCustomerJenis]);
	React.useEffect(()=>{
		if((parseInt(uInpCustomerLamaID)||0) <= 0) {
			setInpCustomerLamaTeks("");
			return;
		}
		const vEls = document.getElementById("inpcustomerlamaid");
		setInpCustomerLamaTeks(vEls.options[vEls.selectedIndex].text);
	},[uInpCustomerLamaID]);
	//--END FORM_HEADER--/

	//---LOOKUP_BARANG--/
	React.useEffect(()=>{
		let vBarangNamaArr	= [];
		let vBarangIDArr	= [];
		let vBarangSatuanArr= [];
		let vBarangHargaArr= [];
		uBarangObj.map((vItem,VKey)=>{
			vBarangNamaArr.push(vItem.nama_barang);
			vBarangIDArr.push(vItem.id);
			vBarangSatuanArr.push(vItem.satuan);
			vBarangHargaArr.push(vItem.h_jual && vItem.h_jual);
		});

		//console.log("("+cComponentName+") "+cFuncname+" uBarangObj = "+JSON.stringify(uBarangObj));

		setBarangNamaArr(vBarangNamaArr);
		setBarangIDArr(vBarangIDArr);
		setBarangSatuanArr(vBarangSatuanArr);
		setBarangHargaBeliArr(vBarangHargaArr);
	},[uBarangObj]);
	React.useEffect(()=>{
		if(!UFunc.isEmpty(uElAutocomplete))  {
			var vClear =setTimeout(function(e){
				uElAutocomplete.style.display = "none";
				clearTimeout(vClear);
			},500);
		}
	},[uInpBarangBlur]);
	React.useEffect(()=>{
		setInpBarangnama(uTextAutoSelect);
		const vIndeksSelect = uBarangNamaArr.indexOf((uTextAutoSelect||"").trim());
		setInpBarangID((parseInt(uBarangIDArr[vIndeksSelect])||0));
		setInpBarangSatuan(uBarangSatuanArr[vIndeksSelect] || "");
		setInpBarangharga((parseInt(uBarangHargaBeliArr[vIndeksSelect])||0));

		//console.log("("+cComponentName+") "+cFuncname+" uTextAutoSelect = "+uTextAutoSelect);
		//console.log("("+cComponentName+") "+cFuncname+" vIndeksSelect = "+vIndeksSelect);
		//console.log("("+cComponentName+") "+cFuncname+" uBarangIDArr[vIndeksSelect] = "+uBarangIDArr[vIndeksSelect]);
	},[uTextAutoSelect]);
	//---END LOOKUP_BARANG--/

	//---GRID_ITEM--/
	React.useEffect(()=>{ initSetTotal(); },[uItemObj]);
	React.useEffect(()=>{ loadItemTabel(); },[uInitTabelBody]);
	//---END IdGRID_ITEM--/

	//--FORM_FOOTER--/
	React.useEffect(()=>{ 
		if((parseInt(uTotalBruto)||0)<=0) {
			setInpDiskon(0);
			setInpTerbayar(0);
		} 
	},[uTotalBruto]);
	//--END FORM_FOOTER--/

	React.useEffect(()=>{
		if(uInpTanggal == uFirstTanggal //UFunc.DbDate(uTglNowDt) 
			&& uInpNonota == uFirstNonota //""
			&& (parseInt(uInpCustomerLamaID)||0) == (parseInt(uFirstCustomerLamaID)||0)
			&& (parseInt(uJmlItemInit)||0) == (parseInt(uFirstJmlItemInit)||0)
			&& (parseInt(uInpDiskon)||0) == (parseInt(uFirstDiskon)||0)
			&& (parseInt(uInpTerbayar)||0) == (parseInt(uFirstTerbayar)||0)
			&& uInpCustomerBaru == uFirstCustomerBaru
			&& uInpKeterangan == uFirstKeterangan
		) { setInitFormUpdatedBl(false); }
		else { setInitFormUpdatedBl(true); }

		//console.log("("+cComponentName+") useEffect[setInitFormUpdatedBl] => "+uInpNonota+" === "+uFirstNonota);
		//console.log("("+cComponentName+") useEffect[setInitFormUpdatedBl] => "+uInitFormUpdatedBl);
	},[
	uInpTanggal,
	uInpNonota,
		uInpCustomerLamaID,
		uInpCustomerBaru,
		uInpDiskon,
		uInpTerbayar,
		uJmlItemInit,
		uInpKeterangan
	]);


	const hdlKDownDocument=(_EV)=>{
		//alert("_EV.which == "+_EV.which); return;
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			hdlKlikSimpan();
		}
	}
	const hdlKlikKembali=async()=>{
		const FuncNext=()=>{
			cHistory.go(-1);
		}

		let vConfirm = obBahasa.pesan_formupdated+".."+
		"<BR>"+obBahasa.pesan_datanotsave+
		"<BR><BR><B>"+obBahasa.confirm_lanjut+"</B>"+
		"";
		if(uInitFormUpdatedBl) {
			if(await Konfirm(vConfirm)) FuncNext();
		} else {
			FuncNext();
		}
	}
	const hdlKlikReset=async()=>{
		cFuncname = hdlKlikReset.name;	

		/*//->uInpTanggal,uInpNonota,uInpSupplierLamaID,uInpSupplierBaru,uInpDiskon,uInpTerbayar,uJmlItemInit
		console.log("("+cComponentName+") "+cFuncname+" => uInitFormUpdatedBl === "+uInitFormUpdatedBl);//-*/
		const FuncNext = ()=>{
			initResetForm();
			svcLoadInit();
		}

		let vConfirm = obBahasa.pesan_formupdated+".."+
		"<BR><BR><B>"+obBahasa.confirm_reset+"</B>"+
		"";
		if(uInitFormUpdatedBl) {
			if(await Konfirm(vConfirm)) FuncNext();
		} else {
			FuncNext();
		}
	}
	const hdlKlikSimpan=async()=>{
		cFuncname = hdlKlikSimpan.name;
		initItemEditView();

		cDispatch({type: 'set', gTeksAutoSelect: ""});
		setInpBarangjml(0);
		setInpBarangharga(0);
		setInpBarangSatuan("");

		//---VALIDATION--/
		var vTanggalDt	= UFunc.toDate(uInpTanggal);
		if(UFunc.isEmpty(uInpTanggal)) {
			document.getElementById("inptglpenjualan").focus();
			showToast(obBahasa.caption_tglpenjualan+" "+obBahasa.caption_invalid+"..");
			return;
		} else {
			var vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
			if(vDateBeyond) {
				document.getElementById("inptglpenjualan").focus();
				showToast(obBahasa.pesan_tglisbeyond+"..");
				return;
			}
			var vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
			if(vDateExceededBl) {
				document.getElementById("inptglpenjualan").focus();
				showToast(obBahasa.pesan_tglisexceeded+"..");
				return;
			}
			//alert("("+cComponentName+") "+cFuncname+" vDateExceededBl = "+vDateExceededBl); return;
		}

		let vPiutang 	= (parseInt(uTotalBruto)||0) - (parseInt(uInpDiskon)||0) - (parseInt(uInpTerbayar)||0);

		if((parseInt(uInpCustomerLamaID)||0) <= 0
			&& uInpCustomerJenis == "LAMA"
			&& vPiutang > 0
		) {
			document.getElementById("inpcustomerlamaid").focus();
			showToast(obBahasa.pesan_customerharusada+"..");
			return;
		}
		if(uInpCustomerBaru.trim() == ""
			&& uInpCustomerJenis == "BARU"
			&& vPiutang > 0
		) {
			document.getElementById("inpcustomerbaru").focus();
			showToast(obBahasa.pesan_customerharusada+"..");
			return;
		}
		if((parseInt(uJmlItemInit)||0) <= 0) {
			showToast(obBahasa.word_item+" "+obBahasa.caption_stillempty+"..");
			return;
		}
		//---END VALIDATION--/
		
		let vConfirm	= 
		"<table width='100%' cellpadding='7' cellspacing='3'>"+
		"<tbody>"+
		(uInpNonota.trim()!= "" ?
		"<tr>"+
		"<td class='font-italic'>"+obBahasa.caption_nonota+"</td>"+
		"<td class='classborderbottom classfontlarger'>"+(uInpNonota||"").toUpperCase()+"</td>"+
		"</tr>" : ""
		)+
		"<tr>"+
		"<td width='45%' class='font-italic'>"+obBahasa.caption_tglpenjualan+"</td>"+
		"<td class='classborderbottom classfontlarger'>"+UFunc.HariAngka(vTanggalDt)+"</td>"+
		"</tr>"+
		"<tr>"+
		"<td class='font-italic'>"+obBahasa.word_customer+"</td>"+
		"<td class='classborderbottom classfontlarger'>"+
			(uInpCustomerLamaTeks == "" && uInpCustomerBaru == "" ? "-" 
				: (uInpCustomerJenis=="LAMA"?uInpCustomerLamaTeks:uInpCustomerBaru)
			)+"</td>"+
		"</tr>"+
		"<tr>"+
		"<td class='font-italic'>"+obBahasa.caption_jumlahbruto+" (Rp)</td>"+
		"<td class='classborderbottom classfontlarger text-right'>"+UFunc.formatAngka(uTotalBruto)+"</td>"+
		"</tr>"+
		((parseInt(uInpDiskon)||0)> 0 ?
		"<tr class='text-danger'>"+
		"<td class='font-italic'>"+obBahasa.word_diskon+" (Rp)</td>"+
		"<td class='classborderbottom classfontlarger text-right'>"+UFunc.formatAngka(uInpDiskon)+"</td>"+
		"</tr>"+
		"<tr>"+
		"<td class='font-italic'>"+obBahasa.caption_wajibbayar+" (Rp)</td>"+
		"<td class='classborderbottom classfontlarger text-right'>"+UFunc.formatAngka(uTotalBruto - uInpDiskon)+"</td>"+
		"</tr>" : ""
		)+
		"<tr class='classfontlarger text-primary font-weight-bolder'>"+
		"<td class='font-italic'>"+obBahasa.caption_nilaibayar+" (Rp)</td>"+
		"<td class='classborderbottom classfontlarger text-right'>"+UFunc.formatAngka(uInpTerbayar)+"</td>"+
		"</tr>"+
		"<tr class='"+(uTotalBruto - uInpDiskon - uInpTerbayar > 0?"text-danger":"text-success" )+"'>"+
		"<td class='font-italic'>"+
		(uTotalBruto - uInpDiskon - uInpTerbayar > 0?obBahasa.caption_sisapiutang:obBahasa.caption_kembalian )+
		" (Rp)</td>"+
		"<td class='classborderbottom classbordertop classfontlarger text-right'>"+
			UFunc.formatAngka(Math.abs(uTotalBruto - uInpDiskon - uInpTerbayar))+
			"</td>"+
		"</tr>"+
		"</tbody>"+
		"</table>"+
		"<BR><BR><B>"+obBahasa.confirm_yakin+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcProsesSimpan();
	}

	const hdlBlurLookupInpBarang=()=>{
		cFuncname = hdlBlurLookupInpBarang.name;
		setInpBarangBlur(!uInpBarangBlur);
	}
	const hdlKlikTambahLookup=()=>{	
		cFuncname = hdlKlikTambahLookup.name;

		//console.log("("+cComponentName+") "+cFuncname+" uTextAutoSelect = "+uTextAutoSelect);
		//alert ("("+cComponentName+") "+cFuncname+" uInpBarangID = "+uInpBarangID);return;

		//---VALIDASI--/
		if((uInpBarangnama||"").trim() == "" && (parseFloat(uInpBarangjml)||0) <= 0) {
			return;
		}
		if((uInpBarangnama||"").trim() == "") {
			document.getElementById("inpbarangnama").focus();
			showToast(obBahasa.caption_barangnama+" "+obBahasa.caption_mustfilled+" (1)..");
			return;
		} else {
			if((parseInt(uInpBarangID)|| 0) <= 0) {
				document.getElementById("inpbarangnama").focus();
				showToast(obBahasa.caption_barangnama+" "+obBahasa.caption_mustchoosed+" (2)..");
				return;
			}
		}
		if((parseFloat(uInpBarangjml)||0) <= 0) {
			document.getElementById("inpbarangjml").focus();
			showToast(obBahasa.word_jml+" "+obBahasa.caption_invalid+" ..");
			return;
		}
		if((parseInt(uInpBarangharga)||0) < 0) {
			document.getElementById("inpbarangharga").focus();
			showToast(obBahasa.word_harga+" "+obBahasa.caption_invalid+" ..");
			return;
		}

		//---CEK_BARANG_ID_SUDAH_ADA--/
		let vBarangIDAdaBl = false;
		uItemObj.map((vItem,vKey)=>{
			var vItemIDBarang	= parseInt(vItem.barang_id) || 0;
			var vSelectIDBarang	= parseInt(uInpBarangID) || 0;
			if(vItemIDBarang == vSelectIDBarang) {
				vBarangIDAdaBl = true;
			}
		});
		if(vBarangIDAdaBl) {
			document.getElementById("inpbarangnama").focus();
			showToast(obBahasa.pesan_itempenjualanexist+"..");
			cDispatch({type: 'set', gTeksAutoSelect: ""});
			setInpBarangSatuan("");
			document.getElementById("inpbarangnama").value = "";
			return;
		}
		//---END CEK_BARANG_ID_SUDAH_ADA--/

		setTombolitemDisabled(true);
		//---END VALIDASI--/

		//---PUT_OBJECT--/
		//-->CONTOH: {id:"0",barang_id:"5",barang_nama:"Barang Item 05",jml:"10",satuan:"KG",h_jual:"200000"},
		var vTmpObject	= {
			id: "0",
			barang_id: uInpBarangID.toString(),
			barang_nama : uInpBarangnama.trim(),
			satuan : uInpBarangSatuan.trim(),
			jml : uInpBarangjml,
			h_jual : uInpBarangharga,
		}
		var vDataObjNew = [vTmpObject].concat(uItemObj);
		setItemObj(vDataObjNew);
		setInitTabelBody(!uInitTabelBody);
		//---END PUT_OBJECT--/

		cDispatch({type: 'set', gTeksAutoSelect: ""});
		setInpBarangjml(0);
		setInpBarangharga(0);
		setInpBarangSatuan("");

		document.getElementById("formitem").reset();
		document.getElementById("inpbarangnama").focus();

		var vClear =setTimeout(function(e){
			setTombolitemDisabled(false);
			document.getElementById("inpbarangnama").value = "";
			clearTimeout(vClear);
		},200);
	}

	const hdlItemEdit=(_EV,_FIELDEDIT)=>{
		cFuncname 		= hdlItemEdit.name;

		const vTabel		= document.getElementById("idtabeldata");
		const vTabelBody	= vTabel.getElementsByTagName("tbody")[0];
		const vTabelTR		= vTabelBody.getElementsByTagName("tr");

		const vIdxRow 	= _EV.target.parentNode.closest("tr").rowIndex - 1;
		setTabelRowSelect(vIdxRow );
		initItemEditView();

		if(_FIELDEDIT.toUpperCase() == "JUMLAH") {
			setItemOldValue(parseFloat(uItemObj[vIdxRow].jml) || 0);

			console.log("("+cComponentName+") "+cFuncname+" => uItemOldValue =  "+uItemOldValue);

			vTabelTR[vIdxRow].querySelector("#iditemjumlahview").style.display = "none";
			vTabelTR[vIdxRow].querySelector("#iditemjumlahfield").style.display = "";
			vTabelTR[vIdxRow].querySelector("#inpitemjml").focus();
			//_EV.target.parentNode.closest("tr")[vIdxRow].querySelector("#iditemjumlahfield").style.display = "block";
		} else if(_FIELDEDIT.toUpperCase() == "HARGA") {
			setItemOldValue(parseInt(uItemObj[vIdxRow].h_jual) || 0);

			vTabelTR[vIdxRow].querySelector("#iditemhargaview").style.display = "none";
			vTabelTR[vIdxRow].querySelector("#iditemhargafield").style.display = "";
			vTabelTR[vIdxRow].querySelector("#inpitemharga").focus();
		} else {
			//alert("("+cComponentName+") "+cFuncname+" => _FIELDEDIT =  "+_FIELDEDIT);
		}
	}
	const hdlItemChange=(_KEY,_VALUE,_FIELDEDIT)=>{
		cFuncname = hdlItemChange.name;
		//console.log("("+cComponentName+") "+cFuncname+" => _VALUE = "+_VALUE);

		if(_FIELDEDIT.toUpperCase() == "JUMLAH") {
			uItemObj[_KEY].jml 		= _VALUE;
		} else if(_FIELDEDIT.toUpperCase() == "HARGA") {
			uItemObj[_KEY].h_jual 	= _VALUE;
		}
		setInitTabelBody(!uInitTabelBody);
	}
	const hdlItemKeydown=(_EV,_FIELDEDIT)=>{
		cFuncname = hdlItemKeydown.name;

		const vIdxRow 	= _EV.target.parentNode.closest("tr").rowIndex - 1;

		if(_EV.which==13) {
			_EV.preventDefault();
			prosesItemEdit(_EV,_FIELDEDIT);
		} else if(_EV.which==27){
			if(_FIELDEDIT.toUpperCase() == "JUMLAH") {
				uItemObj[vIdxRow].jml 		= uItemOldValue;
			} else if(_FIELDEDIT.toUpperCase() == "HARGA") {
				uItemObj[vIdxRow].h_jual 	= uItemOldValue;
			}

			console.log("("+cComponentName+") "+cFuncname+" => uItemOldValue = "+uItemOldValue);

			setInitTabelBody(!uInitTabelBody);
			initItemEditView();
		}
	}
	const hdlItemChecked=(_EV,_FIELDEDIT)=>{
		cFuncname = hdlItemChecked.name;
		prosesItemEdit(_EV,_FIELDEDIT);
	}
	const hdlItemHapus=async(_EV)=>{
		cFuncname 			= hdlItemHapus.name;

		const vIdxRow 		= _EV.target.parentNode.closest("tr").rowIndex - 1;
		setTabelRowSelect(vIdxRow);
		initItemEditView();

		let vItemObj = uItemObj;

		const FuncNext=()=>{
			if (vIdxRow < 0) return;

			if((parseInt(uItemObj[vIdxRow].id)||0) > 0)
				setItemHapusIDArr([...uItemHapusIDArr,uItemObj[vIdxRow].id]);

			setItemObj([]);

			vItemObj.splice(vIdxRow,1);
			setItemObj(vItemObj);

			setInitTabelBody(!uInitTabelBody);
		}

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+uItemObj[vIdxRow].barang_nama.toUpperCase()+"</B>"+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) FuncNext();
	}

	const initResetForm=()=>{
		//---RESET_FORM_HEADER--/
		setInpTanggal(uFirstTanggal);
		setInpNonota(uFirstNonota);
		setInpCustomerJenis("LAMA");
		setInpCustomerLamaID(uFirstCustomerLamaID);
		setInpCustomerLamaTeks("");
		setInpCustomerBaru(uFirstCustomerBaru);
		setInpKeterangan(uFirstKeterangan);
		setInitFormUpdatedBl(false);
		//---END RESET_FORM_HEADER--/

		//---RESET_FORM_ITEM--/
		cDispatch({type: 'set', gTeksAutoSelect: ""});
		setInpBarangjml(0);
		setInpBarangharga(0);
		setInpBarangSatuan("");
		document.getElementById("inpbarangnama").value = "";

		setItemHapusIDArr([]);
		//---END RESET_FORM_ITEM--/

		//--RESET_FORM_FOOTER--/
		setInpDiskon(0);
		setInpTerbayar(0);
		//--END RESET_FORM_FOOTER--/
	}
	const initItemEditView=()=>{
		const vTabel		= document.getElementById("idtabeldata");
		const vTabelBody	= vTabel.getElementsByTagName("tbody")[0];
		const vTabelTR		= vTabelBody.getElementsByTagName("tr");
		for(var i=0;i<vTabelTR.length;i++) {
			vTabelTR[i].querySelector("#iditemjumlahview").style.display = "";
			vTabelTR[i].querySelector("#iditemjumlahfield").style.display = "none";
			vTabelTR[i].querySelector("#iditemhargaview").style.display = "";
			vTabelTR[i].querySelector("#iditemhargafield").style.display = "none";
		}
	}
	const prosesItemEdit=(_EV,_FIELDEDIT)=>{
		cFuncname = prosesItemEdit.name;

		const vIdxRow 		= _EV.target.parentNode.closest("tr").rowIndex - 1;
		const vTabel		= document.getElementById("idtabeldata");
		const vTabelBody	= vTabel.getElementsByTagName("tbody")[0];
		const vTabelTR		= vTabelBody.getElementsByTagName("tr");

		//var vValue	= vTabelTR[vIdxRow].querySelector("#inpitemjml").value;
		//console.log("("+cComponentName+") "+cFuncname+" => vValue =  "+vValue);

		setInitFormUpdatedBl(true);
		initItemEditView();
		initSetTotal();

		setInitTabelBody(!uInitTabelBody);
	}
	const initSetTotal=()=>{
		var vTotalNilai		= 0;
		uItemObj.map((vItem,vKey)=>{
			vTotalNilai = vTotalNilai + ((parseInt(vItem.h_jual)||0) * (parseFloat(vItem.jml)||0));
		});
		setJmlItemInit(uItemObj.length);
		setTotalBruto(vTotalNilai);
	}
	const svcLoadInit=()=>{
		cFuncname = svcLoadInit.name;

		setCustomerObj([]);
		setDataNotaObj([]);
		setBarangObj([]);
		setItemObj([]);

		/*//---FRONTEND_TESTING---/
		let vTmpObj = [
			{id:"1",caption:"Customer 01"},
			{id:"2",caption:"Customer 02"},
			{id:"3",caption:"Customer 03"},
			{id:"4",caption:"Customer 04"},
			{id:"5",caption:"Customer 06"},
			{id:"6",caption:"Customer 06"},
			{id:"7",caption:"Customer 07"},
		];
		setCustomerObj(vTmpObj);

		vTmpObj = [
			{id:"0",barang_id:"1",barang_nama:"Barang Item 01",jml:"30",satuan:"PCS",h_jual:"245000"},
			{id:"0",barang_id:"2",barang_nama:"Barang Item 02",jml:"45",satuan:"TON",h_jual:"125000"},
			{id:"0",barang_id:"3",barang_nama:"Barang Item 03",jml:"50",satuan:"KG",h_jual:"205000"},
			{id:"0",barang_id:"4",barang_nama:"Barang Item 04",jml:"30",satuan:"KG",h_jual:"355000"},
			{id:"0",barang_id:"5",barang_nama:"Barang Item 05",jml:"10",satuan:"KG",h_jual:"200000"},
			{id:"0",barang_id:"6",barang_nama:"Barang Item 06",jml:"13",satuan:"PCS",h_jual:"145000"},
		];
		setItemObj(vTmpObj);

		vTmpObj = [
			{id:"1",nama_barang:"Nama Barang 01",satuan:"PCS"},
			{id:"2",nama_barang:"Nama Barang 02",satuan:"KG"},
			{id:"3",nama_barang:"Nama Barang 03",satuan:"TON"},
			{id:"4",nama_barang:"Nama Barang 04",satuan:"GRAM"},
			{id:"5",nama_barang:"Nama Barang 06",satuan:"KG"},
			{id:"6",nama_barang:"Nama Barang 06",satuan:"PCS"},
			{id:"7",nama_barang:"Nama Barang 07",satuan:"LITER"},
		];
		setBarangObj(vTmpObj);
		return;
		//---END FRONTEND_TESTING---*/

		var vDATAS    = JSON.stringify({
			send_tabelid 	: uIDTabelInit,
			send_tokenauth 	: objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_penjualan/ld_detilform";

		//console.log("("+cComponentName+") "+cFuncname+" => output_string "+output_string);

		setTombolSimpanDisabled(true);
		setTombolitemDisabled(true);
		if(uIDTabelInit > 0) setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setTombolSimpanDisabled(false);
			setTombolitemDisabled(false);
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.customerobject) {
					vTmpObj = JSON.parse(output_string.customerobject);
					setCustomerObj(vTmpObj);
				}
				if(output_string.barangobject) {
					vTmpObj = JSON.parse(output_string.barangobject);
					setBarangObj(vTmpObj);
				}
				if(output_string.itemobject) {
					vTmpObj = JSON.parse(output_string.itemobject);
					setItemObj(vTmpObj);
				}
				if(output_string.datanota) {
					setDataNotaObj(output_string.datanota);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.emptydata) {
				showToast(obBahasa.pesan_datanolongerexist);
				cHistory.go("-1");
			} else if(output_string.errors) {
				console.log("("+cComponentName+") "+cFuncname+" => output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setTombolSimpanDisabled(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			setTombolSimpanDisabled(false);
			setTombolitemDisabled(false);
			console.log("("+cComponentName+") "+cFuncname+" => catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const svcProsesSimpan=()=>{
		cFuncname	= svcProsesSimpan.name;

		/*//---FRONTEND_TESTING--/
				if((parseInt("255")||0) > 0) {
					const vNewCustomerObj	= {value:("255"),caption:(uInpCustomerBaru||"").toUpperCase()}
					const vNewCustomerArr	= [...uCustomerObj,vNewCustomerObj];

					setCustomerObj(vNewCustomerArr);
				}
				return;
			if((parseInt(uIDTabelInit)||0) <= 0) {
				initResetForm();
				setItemObj([]);
				setInitTabelBody(!uInitTabelBody);

				showToast(obBahasa.pesan_sukses,"SUKSES");
			} else {
				setLoading(true);
				initResetForm();
				cHistory.go("-1");
			}

			return;
		//---END FRONTEND_TESTING--*/

		var vDATAS    = JSON.stringify({
			send_tabelid : uIDTabelInit,
			send_tanggal : uInpTanggal,
			send_nonota : uInpNonota,
			send_customerlamaid : uInpCustomerLamaID,
			send_customerbaru : uInpCustomerBaru,
			send_customerjenis : uInpCustomerJenis,
			send_itemlist : JSON.stringify(uItemObj),
			send_itemhapusidlist : JSON.stringify(uItemHapusIDArr),
			send_totalbruto : uTotalBruto,
			send_diskon : uInpDiskon,
			send_terbayar : uInpTerbayar,
			send_keterangan : uInpKeterangan,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_penjualan/pr_simpan";

		//console.log("("+cComponentName+") "+cFuncname+" => [0] uKeywordInit "+uKeywordInit);

		setTombolSimpanDisabled(true);
		setTombolitemDisabled(true);
		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setTombolSimpanDisabled(false);
			setTombolitemDisabled(false);
			setLoading(false);
			if(output_string.sukses) {
				if((parseInt(uIDTabelInit)||0) <= 0) {
					initResetForm();
					setItemObj([]);
					setInitTabelBody(!uInitTabelBody);

					showToast(obBahasa.pesan_sukses,"SUKSES");
				} else {
					setLoading(true);
					initResetForm();
					cHistory.go("-1");
				}

				if((parseInt(output_string.newcustomerid||"0")||0) > 0) {
					const vNewCustomerObj	= {value:(output_string.newcustomerid||"0"),caption:(uInpCustomerBaru||"").toUpperCase()}
					const vNewCustomerArr	= [...uCustomerObj,vNewCustomerObj];

					setCustomerObj(vNewCustomerArr);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+") "+cFuncname+" => output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				setTombolSimpanDisabled(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			setTombolSimpanDisabled(false);
			setTombolitemDisabled(false);
			console.log("("+cComponentName+") "+cFuncname+" => catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	const loadItemTabel=()=>{
		cFuncname	= loadItemTabel.name;
		//console.log("("+cComponentName+") "+cFuncname+" => uItemObj = "+uItemObj.length);

		var vIdxRow			= -1;
		return uItemObj.map((vItem,vKey)=>{
			const {id,barang_nama,jml,satuan,h_jual} = vItem;
			var vCaptionUpper = barang_nama.toUpperCase();

			vIdxRow++;
			return (
				<tr id={"idtr"+id} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")} key={vKey}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="4" className="text-left classfontlarger">{barang_nama}</CCol>

					{/*---LIST_ITEM_JUMLAH--*/}
					<CCol sm="12" md="2">
						<CRow id="iditemjumlahview" className="justify-content-center text-right">
							<CCol sm="8" className="px-0"><strong>{UFunc.formatAngka( jml)}</strong> {satuan}</CCol>
							<CCol sm="4" className="px-1 text-right">
								<CTooltip content={"--"+obBahasa.caption_editjumlah+": "+vCaptionUpper}>
								<CLink 
									className={"classikontabel classikonedit"}
									onClick={(e)=>hdlItemEdit(e,"JUMLAH")} />
								</CTooltip>
							</CCol>
						</CRow>
						<CRow id="iditemjumlahfield" className="justify-content-end" style={{display:"none"}}>
							<CCol sm="6" className="px-0">
								<NumberFormat 
									value={jml}
									displayType={'text'} 
									thousandSeparator={"."} 
									decimalSeparator={","}
									key={vKey}
									onValueChange={(values) => hdlItemChange(vKey,values.value,"JUMLAH")}
									renderText={value => (
										<CInput
											value={value}
											className="text-right"
											onFocus={(e)=>e.target.select()}
											onKeyDown={(e)=>hdlItemKeydown(e,"JUMLAH")}
											onChange={(e)=> hdlItemChange(vKey,e.target.value,"JUMLAH")}
											id="inpitemjml"/>
									)} 
								prefix=""/>
							</CCol>
							<CCol sm="3" className="px-2 text-left">
								<CLink 
									className={"classikontabel classikonchecked"}
									onClick={(e)=>hdlItemChecked(e,"JUMLAH")} />
							</CCol>
						</CRow>
						<CRow  id="iditemjumlahloading" className="justify-content-center" style={{display:"none"}}>
							<CCol sm="12" className="text-center">
								<CImg
									className={"classikontabel classikonloading"}
									fluid/>
							</CCol>
						</CRow>
					</CCol>
					{/*---END LIST_ITEM_JUMLAH--*/}

					{/*---LIST_ITEM_HARGA--*/}
					<CCol sm="12" md="2">
						<CRow id="iditemhargaview" className="justify-content-center text-right">
							<CCol sm="8" className="px-0 text-success">{UFunc.formatAngka(h_jual)}</CCol>
							<CCol sm="4" className="px-1 text-right ">
								<CTooltip content={"--"+obBahasa.caption_editharga+": "+vCaptionUpper}>
								<CLink 
									className={"classikontabel classikonedit"}
									onClick={(e)=>hdlItemEdit(e,"HARGA")} />
								</CTooltip>
							</CCol>
						</CRow>
						<CRow id="iditemhargafield" className="justify-content-end" style={{display:"none"}}>
							<CCol sm="6" className="px-0">
								<NumberFormat 
									value={h_jual}
									displayType={'text'} 
									thousandSeparator={"."} 
									decimalSeparator={","}
									key={vKey}
									onValueChange={(values) => hdlItemChange(vKey,values.value,"HARGA")}
									renderText={value => (
										<CInput
											value={value}
											className="text-right"
											onFocus={(e)=>e.target.select()}
											onKeyDown={(e)=>hdlItemKeydown(e,"HARGA")}
											onChange={(e)=> hdlItemChange(vKey,e.target.value,"HARGA")}
											id="inpitemharga"/>
									)} 
								prefix=""/>
							</CCol>
							<CCol sm="3" className="px-2 text-left">
								<CLink 
									className={"classikontabel classikonchecked"}
									onClick={(e)=>hdlItemChecked(e,"HARGA")} />
							</CCol>
						</CRow>
						<CRow  id="iditemhargaloading" className="justify-content-center" style={{display:"none"}}>
							<CCol sm="12" className="text-center">
								<CImg
									className={"classikontabel classikonloading"}
									fluid/>
							</CCol>
						</CRow>
					</CCol>
					{/*---END LIST_ITEM_HARGA--*/}
					
					<CCol sm="12" md="3" className="text-right font-weight-bold">{UFunc.formatAngka((h_jual * jml))}</CCol>
					<CCol sm="12" md="1" className="text-center">
						<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"}
							onClick={(e)=>hdlItemHapus(e)} />
						</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
			);
		});
	}

	return (
		<div className="c-app c-default-layout align-items-center">
		<div className="c-wrapper">
		<CHeader withSubheader className="classheadermain text-dark">
			<CHeaderNav className="ml-3">
			<CHeaderNavLink onClick={hdlKlikKembali}>
			<CIcon name="cil-arrow-circle-left" height="25" alt="Kembali" />
			</CHeaderNavLink>
			</CHeaderNav>

			<CHeaderBrand className="ml-1 font-weight-bold classtitle">
			<CImg src={pjson.svcpage+"images/menus_penjualan.png"} height="40" alt={obBahasa.menus_penjualan} />&nbsp;{obBahasa.caption_formpenjualan}
			{uTitleEdit}
			</CHeaderBrand>

			<CSubheader className="justify-content-center">
			<CRow className="w-100 m-0 p-2 justify-content-center">
			<CCol sm="12" md="7" className="">
				<CCardGroup className="">
				<CCard sm="12" className="m-0">
				<CCardBody className="p-2">
				<CForm>
				<CFormGroup row className="py-0">
				<CCol sm="12" md="4">
					<CInput
						type="date"
						value={uInpTanggal}
						onChange={(e)=>setInpTanggal(e.target.value)}
						id="inptglpenjualan"/>
					<div className="classpetunjuk">{obBahasa.caption_tglpenjualan}</div>
				</CCol>
				<CCol sm="12" md="2"></CCol>
				<CCol sm="12" md="4">
					<CInput
						type="text"
						maxLength="30"
						value={uInpNonota}
						onChange={(e)=>setInpNonota(e.target.value)}
						className="text-uppercase"
						id="inpnonota"/>
					<div className="classpetunjuk">{obBahasa.caption_nonota}</div>
				</CCol>
				</CFormGroup>

				<CFormGroup row className="py-0 mb-0">
				<CCol sm="12" md="6">
					<CFormGroup variant="checkbox" 
						className="classradiobutton d-inline-block mr-2">
					<CInputRadio className="form-check-input" 
						checked={uInpCustomerJenis==="LAMA"} 
						onChange={(e)=> setInpCustomerJenis(e.target.value)}
						value="LAMA"
						name="inpcustomerjenis" 
						id="inpcustomerjenislama"/>
					<CLabel variant="checkbox" 
						htmlFor={"inpcustomerjenislama"} className="font-weight-bolder">
						{obBahasa.caption_customerlama}
					</CLabel>
					</CFormGroup>
					<CFormGroup variant="checkbox" className="classradiobutton d-inline-block">
					<CInputRadio className="form-check-input" 
						checked={uInpCustomerJenis==="BARU"} 
						onChange={(e)=> setInpCustomerJenis(e.target.value)}
						value="BARU" 
						name="inpcustomerjenis"  
						id="inpcustomerjenisbaru"/>
					<CLabel variant="checkbox" 
						htmlFor={"inpcustomerjenisbaru"} className="font-weight-bolder">
						{obBahasa.caption_customerbaru}
					</CLabel>
					</CFormGroup>
				</CCol>

				{/*---CUSTOMER--*/}
				<CCol sm="12" md="6" id="idcustomerlama">
					<CSelect custom
						value={uInpCustomerLamaID}
						onChange={(e)=> setInpCustomerLamaID(e.target.value) }
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpbarangnama")}
						id="inpcustomerlamaid">
					<option value="0">---{(obBahasa.caption_pilihcustomer||"").toUpperCase()}</option>
					{
						uCustomerObj.map((vItem,vKey)=>{
							return (
								<option value={vItem.id} key={vKey}>{vItem.caption}</option>
							);
						})
					}
					</CSelect>
					<div className="classpetunjuk">{obBahasa.word_customer}</div>
				</CCol>
				<CCol sm="12" md="6" className="" id="idcustomerbaru">
					<CInput
						type="text"
						value={uInpCustomerBaru}
						maxLength="100"
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpbarangnama")}
						onChange={(e)=>setInpCustomerBaru(e.target.value)}
						id="inpcustomerbaru"/>
					<div className="classpetunjuk font-weight-bolder text-danger">{obBahasa.caption_customernama}</div>
				</CCol>
				{/*---END CUSTOMER--*/}

				</CFormGroup>

				</CForm>
				</CCardBody>
				</CCard>
				</CCardGroup>
			</CCol>
			<CCol sm="12" md="5" className="">
				<CCardGroup className="">
				<CCard sm="12" className="p-0">
				<CCardBody className="px-2 py-0 text-right text-success font-weight-bolder classfontlarger" 
					style={{fontSize:"350%"}}>
					{UFunc.formatAngka(uTotalBruto - uInpDiskon)}
				</CCardBody>
				</CCard>
				</CCardGroup>
				<CCardGroup className="mt-2">
				<CCard sm="12" className="p-2">
				<div>
					<CTooltip content={obBahasa.caption_resetview}>
						<CLink className="" onClick={()=>hdlKlikReset()} className="classikon classikonreset"/>
					</CTooltip>
					&nbsp;&middot;&nbsp;&nbsp;
					<span className="">
					{"Jumlah: "}<strong id="idjmldata">{UFunc.formatAngka(uJmlItemInit)}</strong>{" Item"}
					</span>
				</div>
				</CCard>
				</CCardGroup>
			</CCol>
			</CRow>
			</CSubheader>
		</CHeader>

		<div className="c-body py-2 px-4">
			<CRow>
			<CCol sm="12">
				<CCardGroup>
				<CCard>
				<CCardBody className="pt-2 pb-1 pl-3 pr-1">
				<CForm id="formitem" name="formitem">
					<CFormGroup row className="mb-0">
					<CCol sm="4">
						<MAutocomplete
							options={uBarangNamaArr}
							onBlur={hdlBlurLookupInpBarang}
							onPilih={()=>document.getElementById("inpbarangjml").focus()}
							value={uInpBarangnama}
							id="inpbarangnama"/>
						<div className="classpetunjuk">{obBahasa.caption_barangnama}</div>
					</CCol>
					
					<CCol sm="1" className="px-1">
						<NumberFormat 
							value={uInpBarangjml}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInpBarangjml(values.value)}
							renderText={value => (
								<CInput
									value={value}
									onFocus={(e)=>e.target.select()}
									onChange={(e) => setInpBarangjml(e.target.value)}
									id="inpbarangjml"/>
							)} 
						prefix=""/>
						<div className="classpetunjuk">{obBahasa.word_jml}</div>
					</CCol>

					<CCol sm="0" className="px-0 pr-2"><strong>{uInpBarangSatuan}</strong></CCol>
					
					<CCol sm="2" className="px-1 pr-0 ">
						<NumberFormat 
							value={uInpBarangharga}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInpBarangharga(values.value)}
							renderText={value => (
								<CInput
									value={value}
									onFocus={(e)=>e.target.select()}
									onChange={(e) => setInpBarangharga(e.target.value)}
									className="text-right"
									id="inpbarangharga"/>
							)} 
						prefix=""/>
						<div className="classpetunjuk">{obBahasa.word_harga} (Rp)</div>
					</CCol>

					<CCol sm="0" className="px-0 py-0 ">
					<CTooltip content={obBahasa.caption_tambahitem}>
						<CButton
							size="xs"
							onClick={()=>hdlKlikTambahLookup()}
							disabled={uTombolitemDisabled}>
							<div className="classikon classikontambahitem" height={40} />
						</CButton>
					</CTooltip>
					</CCol>
					
					</CFormGroup>
				</CForm>
				</CCardBody>
				</CCard>
				</CCardGroup>
			</CCol>
			</CRow>

			<CRow className="mt-2">
			<CCol sm="12">
				<CCardGroup>
				<CCard>
				<CCardBody className="py-2 px-3">
				<div className="table-responsive-sm" xs="12">
				<table className="table table-borderless table-striped table-hover" id="idtabeldata">
				<thead className="d-sm-down-none">
				<tr>
				<th width="5%" className="align-top text-center">No</th>
				<th className="align-top text-center">
					<CRow>
					<CCol sm="12" md="4" className="text-left">{obBahasa.caption_barangnama}</CCol>
					<CCol sm="12" md="2" className="text-center">{obBahasa.word_jml} ({obBahasa.word_satuan})</CCol>
					<CCol sm="12" md="2" className="text-center">{obBahasa.word_harga} (Rp)</CCol>
					<CCol sm="12" md="3" className="text-center">{obBahasa.word_total} (Rp)</CCol>
					<CCol sm="12" md="1" className="text-center">#</CCol>
					</CRow>
				</th>
				</tr>
				</thead>
				<tbody>{loadItemTabel()}</tbody>

				{uItemObj.length > 0 && (
				<tfoot className="classtabeltfoot">
				<tr className="font-weight-bolder">
				<td className="py-1 align-top">&nbsp;</td>
				<td className="py-1 align-top">
					<CRow>
					<CCol sm="12" md="8" className="text-right pt-2">{obBahasa.caption_jumlahbruto} (Rp)</CCol>
					<CCol sm="12" md="3" className="text-right pt-2" style={{borderTop:"1px solid black"}}>{UFunc.formatAngka(uTotalBruto)}</CCol>
					<CCol sm="12" md="1" className="text-center pt-2">&nbsp;</CCol>
					</CRow>
				</td>
				</tr>
				
				<tr className="text-danger">
				<td className="py-1 align-top">&nbsp;</td>
				<td className="py-1 align-top">
					<CRow>
					<CCol sm="12" md="8" className="text-right">{obBahasa.word_diskon} (Rp)</CCol>
					<CCol sm="12" md="1" className="text-right">
					</CCol>
					<CCol sm="12" md="2" className="text-right">
						<NumberFormat 
							value={uInpDiskon}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInpDiskon(values.value)}
							renderText={value => (
								<CInput
									value={value}
									className="text-right"
									onFocus={(e)=>e.target.select()}
									onChange={(e) => setInpDiskon(e.target.value)}
									onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpterbayar")}
									id="inpdiskon"/>
							)} 
						prefix=""/>
					</CCol>
					<CCol sm="12" md="1" className="text-center">&nbsp;</CCol>
					</CRow>
				</td>
				</tr>

				<tr className="font-weight-bolder">
				<td className="py-1 align-top">&nbsp;</td>
				<td className="py-1 align-top">
					<CRow>
					<CCol sm="12" md="8" className="text-right pt-2">{obBahasa.caption_wajibbayar} (Rp)</CCol>
					<CCol sm="12" md="3" className="text-right pt-2" style={{borderTop:"1px solid black"}}>{UFunc.formatAngka(uTotalBruto - uInpDiskon)}</CCol>
					<CCol sm="12" md="1" className="text-center pt-2">&nbsp;</CCol>
					</CRow>
				</td>
				</tr>
				
				<tr className="">
				<td className="py-1 align-top">&nbsp;</td>
				<td className="py-1 align-top">
					<CRow>
					<CCol sm="12" md="8" className="text-right">{obBahasa.caption_nilaibayar} (Rp)</CCol>
					<CCol sm="12" md="1" className="text-right">
					</CCol>
					<CCol sm="12" md="2" className="text-right border-top-2">
						<NumberFormat 
							value={uInpTerbayar}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInpTerbayar(values.value)}
							renderText={value => (
								<CInput
									value={value}
									className="text-right"
									onFocus={(e)=>e.target.select()}
									onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"inpketerangan")}
									onChange={(e) => setInpTerbayar(e.target.value)}
									id="inpterbayar"/>
							)} 
						prefix=""/>
					</CCol>
					<CCol sm="12" md="1" className="text-center">&nbsp;</CCol>
					</CRow>
				</td>
				</tr>

				<tr className={"font-weight-bolder classfontlarger "+(uTotalBruto - uInpDiskon - uInpTerbayar > 0 ? "text-danger" : "text-success")}>
				<td className="py-1 align-top">&nbsp;</td>
				<td className="py-1 align-top">
					<CRow>
					<CCol sm="12" md="8" className="text-right pt-2">{(uTotalBruto - uInpDiskon - uInpTerbayar > 0 ? obBahasa.caption_sisapiutang : obBahasa.caption_kembalian)} (Rp)</CCol>
					<CCol sm="12" md="3" className="text-right pt-2" style={{borderTop:"1px solid black"}}>{
						UFunc.formatAngka(Math.abs(uTotalBruto - uInpDiskon - uInpTerbayar))
					}</CCol>
					<CCol sm="12" md="1" className="text-center pt-2">&nbsp;</CCol>
					</CRow>
				</td>
				</tr>

				<tr className="">
				<td className="py-1 align-top">&nbsp;</td>
				<td className="py-1 align-top">
					<CRow>
					<CCol sm="12" md="8" className="text-right pt-2">{obBahasa.word_keterangan}</CCol>
					<CCol sm="12" md="3" className="text-left pt-2 pb-1 px-0">
					<CInput
						type="text"
						value={uInpKeterangan}
						onChange={(e)=>setInpKeterangan(e.target.value)}
						onFocus={(e)=>{
							window.scrollTo({ top: document.body.offsetHeight, behavior: "smooth" });
						}}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						id="inpketerangan"/>
					<div className="classpetunjuk">*) Keterangan Pembayaran Bilyet, dsb..</div>
					</CCol>
					<CCol sm="12" md="1" className="text-center pt-2">&nbsp;</CCol>
					</CRow>
				</td>
				</tr>

				</tfoot>
				)}
				</table>
				</div>
				</CCardBody>
				</CCard>
				</CCardGroup>
			</CCol>
			</CRow>
		</div>
		
    	<CFooter fixed={true} className="p-0 classbgwhite">
			<CRow className="w-100 m-0 text-center justify-content-center">
			<CCol sm="12" className="">
				<CButton
					size="lg"
					onClick={()=>hdlKlikSimpan()}
					disabled={uTombolSimpanDisabled}
					className="py-1 px-5 font-weight-bolder text-white"
					color="warning"
					id="btnSimpan">
					{(obBahasa.word_simpan||"").toUpperCase()+" [F9]"}
				</CButton>
			</CCol>
			</CRow>
		</CFooter>
		</div>
		</div>
	)
}

export default IPenjualan;
	