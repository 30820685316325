import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CHeader,
	CSubheader,
	CFooter,
	CCardGroup,
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CContainer,
	CHeaderBrand,
	CHeaderNav,
	CHeaderNavItem,
	CHeaderNavLink,
	CImg,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CSelect,
} from "@coreui/react";
import { Subipiutangedit } from '../components/subnav/index';
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { FormPiutang } from '../components/index';

var pjson     = require('../../package.json');

const IPiutangedit = (props) => {
	const cComponentname 	= IPiutangedit.name;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});

	let cFuncname;
	const {setLoading,showToast,prosesExpired}	= props;

	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const [uTitleEdit,setTitleEdit]			= React.useState(obBahasa.caption_editpembayaranpiutang || "Edit Pembayaran Piutang");

	const cKeywordInit						= uKeywordInitObj.kwd_piutangedit || "";
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpPiutangID,setInpPiutangID]		= React.useState(0);
	const [uInpTanggal,setInpTanggal]		= React.useState(UFunc.DbDate(new Date()));
	const [uInpNilaiBayar,setInpNilaiBayar]	= React.useState(0);
	const [uShowFormBl,setShowForm]			= React.useState(false);
	const [uBtnSimpanDisabledBl,setBtnSimpanDisabledBl]= React.useState(false);

	const [uFormHeader,setFormHeader]		= React.useState(obBahasa.caption_formbayarpiutang);
	const [uFormDataCustomer,setFormDataCustomer]		= React.useState("");
	const [uFormDataTglPiutang,setFormDataTglPiutang]	= React.useState("");
	const [uFormDataNoNota,setFormDataNoNota]			= React.useState("");
	const [uFormDataTotalPiutang,setFormDataTotalPiutang]= React.useState("");
	const [uFormDataSisaPiutang,setFormDataSisaPiutang]	= React.useState("");

	//console.log("("+cComponentname+") "+cFuncname+" => uKeywordInitObj "+JSON.stringify(uKeywordInitObj));
	
	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const hdlFormToggle=()=>{ setShowForm(!uShowFormBl); }
	React.useEffect(()=>{
		if(!uShowFormBl) return;

		var vTimeout = setTimeout(function(e){
			const vElFocus = document.getElementById("inptanggal");
			if(!UFunc.isEmpty(vElFocus)) vElFocus.focus();
			clearTimeout(vTimeout);
		},200);
	},[uShowFormBl]);
	React.useEffect(()=>{
		const vElBtn = document.getElementById("btnDialogSimpan");
		if(UFunc.isEmpty(vElBtn)) return;
		vElBtn.disabled = uBtnSimpanDisabledBl;
	},[uBtnSimpanDisabledBl]);
	//---END DIALOG_HANDLE:Harus Selalu ADA--/

	React.useEffect(()=>{
		setTabelRowSelect(-1);
		//console.log("("+cComponentname+") useEffect => uisHeaderExecuteBl : "+uisHeaderExecuteBl);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{ loadBodyTabel(); },[uBodyInit]);
	React.useEffect(()=>{ setJmlData(uDataObj.length||0) },[uDataObj]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);

	const hdlKembaliKlik=()=>{ cHistory.go("-1"); }
	const hdlKlikEdit=(_IDTABEL)=>{
		cFuncname	= hdlKlikEdit.name;
		_IDTABEL	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setInpTabelID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		let vCaptionUpper	= (UFunc.HariAngka(uDataObj[vObjdx].tgl_bayar||"")+" "+(uDataObj[vObjdx].nama_customer||"")).toUpperCase();

		setFormHeader(obBahasa.caption_editdata+": "+vCaptionUpper);
		setFormDataCustomer(uDataObj[vObjdx].nama_customer||"");
		setFormDataTglPiutang(uDataObj[vObjdx].tgl_piutang||"");
		setFormDataTotalPiutang(uDataObj[vObjdx].total_piutang||"");
		setFormDataSisaPiutang(uDataObj[vObjdx].sisa_piutang||"");

		setInpTanggal((uDataObj[vObjdx].tgl_bayar));
		setInpNilaiBayar(uDataObj[vObjdx].nilai_bayar||"0");
		setInpPiutangID(uDataObj[vObjdx].id_piutang||"0");

		setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		cFuncname = hdlKlikHapus.name;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		setTabelRowSelect(vRowIdx);

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+(UFunc.HariAngka(uDataObj[vObjdx].tgl_bayar)+" "+uDataObj[vObjdx].nama_customer).toUpperCase()+"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcProsesHapus(_IDTABEL);
	}

	const svcloadData=()=>{
		cFuncname	= svcloadData.name;
		setHTMLError500("");

		/*//--TESTING_FRONTEND--/
		setKeywordLoad(cKeywordInit);
		//console.log("("+cComponentname+") "+cFuncname+" => cKeywordInit : "+cKeywordInit);
		let vTmpObj	= [
			{id:"1",tgl_bayar:"2021-02-03",id_piutang:"1",nama_customer:"Customer 01",total_piutang:"100000",sisa_piutang:"100000",tgl_piutang:"2021-01-02",nilai_bayar:"50000",nama_user:"TheUsers"},
			{id:"2",tgl_bayar:"2021-02-04",id_piutang:"1",nama_customer:"Customer 02",total_piutang:"200000",sisa_piutang:"200000",tgl_piutang:"2021-01-03",nilai_bayar:"60000",nama_user:"TheUsers"},
			{id:"3",tgl_bayar:"2021-02-05",id_piutang:"1",nama_customer:"Customer 03",total_piutang:"300000",sisa_piutang:"300000",tgl_piutang:"2021-01-04",nilai_bayar:"70000",nama_user:"TheUsers"},
			{id:"4",tgl_bayar:"2021-02-06",id_piutang:"1",nama_customer:"Customer 04",total_piutang:"400000",sisa_piutang:"400000",tgl_piutang:"2021-01-05",nilai_bayar:"80000",nama_user:"TheUsers"},
			{id:"5",tgl_bayar:"2021-02-07",id_piutang:"1",nama_customer:"Customer 05",total_piutang:"500000",sisa_piutang:"500000",tgl_piutang:"2021-01-06",nilai_bayar:"90000",nama_user:"TheUsers"},
			{id:"6",tgl_bayar:"2021-02-08",id_piutang:"1",nama_customer:"Customer 06",total_piutang:"600000",sisa_piutang:"600000",tgl_piutang:"2021-01-07",nilai_bayar:"100000",nama_user:"TheUsers"},
			{id:"7",tgl_bayar:"2021-02-09",id_piutang:"1",nama_customer:"Customer 07",total_piutang:"700000",sisa_piutang:"700000",tgl_piutang:"2021-01-08",nilai_bayar:"110000",nama_user:"TheUsers"},
			{id:"8",tgl_bayar:"2021-02-10",id_piutang:"1",nama_customer:"Customer 08",total_piutang:"800000",sisa_piutang:"800000",tgl_piutang:"2021-01-09",nilai_bayar:"120000",nama_user:"TheUsers"},
			{id:"9",tgl_bayar:"2021-02-11",id_piutang:"1",nama_customer:"Customer 09",total_piutang:"900000",sisa_piutang:"900000",tgl_piutang:"2021-01-10",nilai_bayar:"130000",nama_user:"TheUsers"},
			{id:"10",tgl_bayar:"2021-02-12",id_piutang:"1",nama_customer:"Customer 10",total_piutang:"1000000",sisa_piutang:"1000000",tgl_piutang:"2021-01-11",nilai_bayar:"140000",nama_user:"TheUsers"},
			{id:"11",tgl_bayar:"2021-02-13",id_piutang:"1",nama_customer:"Customer 11",total_piutang:"1100000",sisa_piutang:"1100000",tgl_piutang:"2021-01-12",nilai_bayar:"150000",nama_user:"TheUsers"},
			{id:"12",tgl_bayar:"2021-02-14",id_piutang:"1",nama_customer:"Customer 12",total_piutang:"1200000",sisa_piutang:"1200000",tgl_piutang:"2021-01-13",nilai_bayar:"160000",nama_user:"TheUsers"},
		]
		setDataObj(vTmpObj);
		return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		var vDATAS    = JSON.stringify({
			send_keyword : cKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(cKeywordInit);
		var vURLs     = pjson.svcpage+"svc_piutang/ld_bayar";

		setLoading(true);
		setBtnSimpanDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnSimpanDisabledBl(false);
			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnSimpanDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});	
	}
	const svcProsesHapus=(_IDTABEL)=>{
		cFuncname = svcProsesHapus.name;

		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		if(vObjdx < 0) return;

		/*//--TESTING_FRONTEND--/
			var vTmpObj = uDataObj;
			vTmpObj.splice(vObjdx,1);
			setDataObj(vTmpObj);
			setJmlData(vTmpObj.length);
			setBodyInit(!uBodyInit);
			return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_piutang/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			if(output_string.sukses) {
				var vTmpObj = uDataObj;
				vTmpObj.splice(vObjdx,1);
				setDataObj(vTmpObj);
				setJmlData(vTmpObj.length);
				setBodyInit(!uBodyInit);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const initAfterSimpan=()=>{

		const vIdx		= UFunc.getObjectIndeks(uDataObj,uInpTabelID);
		if(vIdx < 0) return;

		const vTmpObj				= uDataObj;
		vTmpObj[vIdx].tgl_bayar 	= uInpTanggal;
		vTmpObj[vIdx].nilai_bayar 	= (parseInt(uInpNilaiBayar)||0);
		setDataObj(vTmpObj);
		setBodyInit(!uBodyInit);
	}

	const loadContent=()=>{
		if(uHTMLError500)
			return ( <>{UFunc.renderHTML(uHTMLError500)}</> );

		return (
			<div className="table-responsive-sm" xs="12">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="3" className="text-left">{obBahasa.caption_tglbayar}</CCol>
				<CCol sm="12" md="5" className="text-left">{obBahasa.caption_detilpiutang}</CCol>
				<CCol sm="12" md="2" className="text-center">{obBahasa.caption_nilaibayar} (Rp)</CCol>
				<CCol sm="12" md="2" className="text-center">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>{loadBodyTabel()}</tbody>
			</table>
			</div>
		);
	}
	const loadBodyTabel=()=>{
		cFuncname	= loadBodyTabel.name;
		//console.log("("+cComponentname+") "+cFuncname+" => uKeywordLoad : "+uKeywordLoad);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,tgl_bayar,nama_customer,total_piutang,tgl_piutang,nilai_bayar,nama_user,
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				let vCaptionUpper	= ((tgl_bayar||"")+" "+(nama_customer||"")).toUpperCase();

				let vCustomerNama	= uKeywordLoad=="" ? (nama_customer||"")
					: (nama_customer||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vInputer	= uKeywordLoad=="" ? (nama_user||"")
					: (nama_user||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vTotalPiutang	= UFunc.formatAngka(total_piutang);
				let vNilaiBayar		= UFunc.formatAngka(nilai_bayar);

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="3" className="text-left">
						<div>{UFunc.HariAngka(tgl_bayar)}</div>
						<div className="text-danger classfontsmaller">[{UFunc.renderHTML(vInputer)}]</div>
					</CCol>
					<CCol sm="12" md="5" className="text-left px-1">
					<div className="classfontlarger text-primary">{UFunc.renderHTML(vCustomerNama)}</div>
					<div className="classfontsmaller font-italic">{obBahasa.caption_tglpiutang}: {UFunc.HariAngka(tgl_piutang)}</div>
					<div>{obBahasa.caption_totalpiutang}: <span className="text-success">Rp {vTotalPiutang}</span></div>
					</CCol>
					<CCol sm="12" md="2" className="text-right px-1">
						<span className="text-success font-weight-bolder classfontlarger">{vNilaiBayar}</span>
					</CCol>
					<CCol sm="12" md="2" className="text-center px-1">
						<div className={(objToken.userhak!="FREEROLE"&&objToken.userhak!="MANAJER" ? " d-none" : "") }>
						<CTooltip html={false} content={"--"+obBahasa.caption_setstoktambah+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_setstokkurang+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"}
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
						</div>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}

	return (
		<div className="c-app c-default-layout align-items-center">
		<div className="c-wrapper">
		<CHeader withSubheader className="classheadermain text-dark">
			<CHeaderNav className="ml-3">
			<CHeaderNavLink onClick={hdlKembaliKlik}>
			<CIcon name="cil-arrow-circle-left" height="25" alt="Kembali" />
			</CHeaderNavLink>
			</CHeaderNav>

			<CHeaderBrand className="ml-1 font-weight-bold classtitle">
			<CImg src={pjson.svcpage+"images/menus_piutang.png"} height="40" alt={obBahasa.menus_piutang} />
			&nbsp;{uTitleEdit}
			</CHeaderBrand>

			<Subipiutangedit {...props} />
		</CHeader>

		<div className="c-body py-2 px-4">
			<CRow className="">
			<CCol sm="12" md="10" className="m-auto">
				<CCardGroup>
				<CCard>
				<CCardHeader><strong>{obBahasa.caption_bayartpiutanglist}</strong></CCardHeader>
				<CCardBody className="py-2 px-3">
				{loadContent()}
				</CCardBody>
				{(uJmlData >= uMaxData) && (
				<CCardFooter>
					<CPagination
						activePage={uPageNow}
						pages={uJmlHal}
						onActivePageChange={(i) => setActivePage(i)}
					></CPagination>
				</CCardFooter>
				)}
				</CCard>
				</CCardGroup>
			</CCol>
			</CRow>
		</div>

		<CFooter fixed={false} className="px-3 text-black-50 bg-transparent text-right ">
			<div className="my-0 " style={{fontSize:11,opacity:0.5}}>
			<span>Copyright v{pjson.version} &copy; {pjson.mydefault.prodyear} </span>
			<a href="https://www.svarna-dwipa.com" target="_blank" rel="noopener noreferrer">SvarnadwiPa</a>
			<span className="">, All Rights Reserved</span>
			</div>
		</CFooter>

		<FormPiutang
			pInpTabelID={uInpTabelID}
			pInpPiutangID={uInpPiutangID}
			pInpTanggal={uInpTanggal}
			pInpNilaiBayar={uInpNilaiBayar}
			onChangeTanggal={(e)=>setInpTanggal(e.target.value)}
			onChangeNilaiBayar={(e)=>setInpNilaiBayar(e.target.value)}
			onChangeNilaiBayarNumber={(values)=>setInpNilaiBayar(values.value)}

			pDataCustomer={uFormDataCustomer}
			pDataTglPiutang={uFormDataTglPiutang}
			pDataNoNota={uFormDataNoNota}
			pDataTotalPiutang={uFormDataTotalPiutang}
			pDataSisaPiutang={uFormDataSisaPiutang}

			onAfterSimpan={initAfterSimpan}
			show={uShowFormBl} 
			dialogHeader={uFormHeader}
			toggle={hdlFormToggle}
			{...props}/>

		</div>
		</div>
	)
}

export default IPiutangedit;
	