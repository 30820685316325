import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CRow
} from '@coreui/react';
import PropTypes from 'prop-types';
import { UFunc } from "../helpers/functions";

const DialogView = props => {
	const cComponentname	= DialogView.name;

	const { show, toggle, renderContent, dialogHeader, options } = props;
	const obBahasa  = useSelector(state => state.listBahasa);
	const [uInitConfirmLoad,setInitConfirmLoad] = React.useState(true);

	const vElBtnOK	= document.getElementById("btnDialogViewOK");
	React.useEffect(()=>{
		if(!show) return;
		if(!UFunc.isEmpty(vElBtnOK)) {
			var vFocus = setTimeout(function(e){
				vElBtnOK.focus();
				clearTimeout(vFocus);
			},200);	
		}
	},[show]);

	//console.log("("+cComponentname+") useEffect[vElBtnOK] (1) = "+vElBtnOK);
	return (
		<CModal color="info" 
			size={options.hasOwnProperty("size")?options.size:"lg"}
			focus={true}
			centered={options.hasOwnProperty("centered")?options.centered:true}
			show={show} 
			onClose={toggle}>
		<CModalHeader closeButton>
			<CModalTitle>{dialogHeader || DialogView.defaultProps.dialogHeader}</CModalTitle>
		</CModalHeader>

		<CModalBody style={{maxHeight:(window.innerHeight - 185),overflowY:"auto",overflowX:"hidden"}}>
			{renderContent || DialogView.defaultProps.renderContent}
		</CModalBody>
		<CModalFooter>
		<CButton 
			color="info" 
			onClick={toggle}
			id="btnDialogViewOK"> 
		{obBahasa.word_ok}
		</CButton>
		</CModalFooter>
		</CModal>
	)
}
DialogView.defaultProps = {
	show: false,
	renderContent: "{UNDEFINED}..",
	dialogHeader: "Data Detil",
	options: {centered:true,size:"lg"},
};

DialogView.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	renderContent: PropTypes.element, 
	dialogHeader: PropTypes.string,
	options: PropTypes.object,
}

export default DialogView;
