import { createConfirmation } from 'react-confirm';
import TheDialog from '../components/TheDialog';
 
// create confirm function
export const dialogForm = createConfirmation(TheDialog);
 
// This is optional. But wrapping function makes it easy to use.
export const Konfirm=(dialogContent)=>{
	return dialogForm({ dialogContent });
}
	