import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { 
	BrowserRouter,
	Redirect,
	Route,
	Switch,
	useHistory
} from 'react-router-dom';
import {
	CButton,
	CCard,
	CCardBody,
	CCardGroup,
	CRow,

	CModal,CModalHeader,CModalTitle,CModalBody,CModalFooter,
} from '@coreui/react';
import "./assets/css/coreui.scss";
import { CIcon } from '@coreui/icons-react';
import useToken from './helpers/useToken';
import { TheToaster, TheLoader } from './components/index';
import { UFunc } from "./helpers/functions";

//--PAGES--/
import { 
	Login,
	Page500,
	Layout,
	IPembelian,
	IHutangedit,
	IStokopnameedit,
	IPenjualan,
	IPiutangedit,
} from "./pages/index";
//--END PAGES--/

var pjson     = require('../package.json');

const App = (props) => {
	var cComponentName    				= App.name;
	const { token, setToken } 			= useToken();
	
	const dispatch    					= useDispatch();
	const vHistori            			= useHistory();

	const listToast           			= useSelector(state => state.listToast);
	const [istoTopShow, setistoTopShow]	= React.useState(false);
	const [obBahasa, setBahasa ]        = React.useState([]);
	const [isLoading, setLoading ]      = React.useState(false);
	const [isApploading,setApploading]	= React.useState(false);

	const [uRoutes,setRoutes]			= React.useState(JSON.parse(localStorage.getItem("routes")||"[]"));
	const uRouteProfileArr				= useSelector(state => state.profileRoute); 

	//---GLOBAL_PROP_FUNCTION--/
	const showToast   = (_PESAN, _TIPE="INFO") => {
		var vDATAS  = { type : _TIPE, teksIsi : _PESAN };
		dispatch({type: "set", listToast: [...listToast, vDATAS]});
	};
	const prosesExpired = () => {
		showToast((!UFunc.isEmpty(obBahasa)) ? obBahasa.pesan_hasexpired : pjson.mydefault.msgExpired);
		setToken("");
		setLoading(false);
	}
	//---END GLOBAL_PROP_FUNCTION--/

	React.useEffect(() => {
		document.addEventListener("keydown", function (event: any) {
			if (event.keyCode === 13 && (event.target.nodeName === "INPUT"
				|| event.target.nodeName === "SELECT"
				|| event.target.nodeName === "TEXTAREA")
			){
				if (event.ctrlKey && event.target.nodeName === "TEXTAREA") {
					event.preventDefault();
					event.stopPropagation();
					var content = event.target.value;
					var start   = event.target.selectionStart;
					var substr1 = content.slice(0, start);
					var substr2 = content.slice(event.target.selectionEnd);
					//alert(event.target.selectionEnd);
					event.target.value  = substr1 + '\n' + substr2;

					return;
				}

				var form      = event.target.form;
				if(UFunc.isEmpty(form)) return;

				var index     = Array.prototype.indexOf.call(form, event.target);
				var vJmlLoop  = 0;
				var vFocusedBl= false;
				do{
					index++;
					vJmlLoop++;
					var vElement  = form.elements[index];
					if(vElement && !vElement.disabled 
						&& vElement.style.visibility != "hidden"
						&& vElement.style.display != "none"
					) {
						vFocusedBl = true;
						vElement.focus();
					}
					/*console.log("("+cComponentName+") addEventListener => ID "+(vElement.id && vElement.id));
					console.log("("+cComponentName+") addEventListener => visibility : "+(vElement.style.visibility));
					console.log("("+cComponentName+") addEventListener => display : "+(vElement.style.display));//-*/
				} while(!vFocusedBl && vJmlLoop <= 5)
				event.preventDefault();
			}
		});

		document.addEventListener("scroll", ()=>{
			if (window.pageYOffset > 300) setistoTopShow(true);
			else setistoTopShow(false);
		});

		//---LOAD_BAHASA--/
		svcLoadInit();
		//---END LOAD_BAHASA--/
	},[]);
	React.useEffect(() => {
		dispatch({type: "set", listBahasa: obBahasa});
		//console.log("("+cComponentName+") useEffect => obBahasa : "+JSON.stringify(obBahasa) );
	},[obBahasa]);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	const svcLoadInit=()=>{

		//if(UFunc.isEmpty(token)) return;

		var vDATAS    = JSON.stringify({ send_tokenauth : token, });
		var vURLs     = pjson.svcpage+"svc_init/ld_langs";

		setApploading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json();
		}).then((output_string) => {
			setApploading(false);

			if(output_string.langs) {
				//console.log("("+cComponentName+") output_string: "+JSON.stringify(output_string.langs) );
				setBahasa(JSON.parse(output_string.langs));
			}

			if(output_string.tampil) {
				if(output_string.nav) {
					dispatch({type: "set", listNav: JSON.parse(output_string.nav)});
				}
				if(output_string.routes) {
					//dispatch({type: "set", listRoute: JSON.parse(output_string.routes)});

					//console.log("("+cComponentName+") output_string.routes: "+(output_string.routes) );

					const cRouteLoadArr = JSON.parse(output_string.routes||"[]");
					const vNewRoutes	= [...uRouteProfileArr,...cRouteLoadArr];
					localStorage.setItem("routes", JSON.stringify(vNewRoutes)); 
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				if(token) { 
					showToast((!UFunc.isEmpty(obBahasa)) ? obBahasa.pesan_hasexpired : pjson.mydefault.msgExpired);
					setToken(""); 
				}
			}
		}).catch((error) =>{
			setApploading(false);
			console.log("("+cComponentName+") catch-error: "+error);
		});
	}

	//---FIRSTLOADING--/
	if(isApploading) {
		return (
			<TheLoader isShow={isApploading} />
		);
	}
	//---END FIRSTLOADING--/

	//---NO_TOKEN_AUTH--/
	if(!token) {
		return (
			<BrowserRouter>
			<TheLoader isShow={isLoading} />
			<TheToaster toastData={listToast} />
			<Switch>
			<Route path="/error500" render={props => 
				<Page500 showToast={showToast} setLoading={setLoading}
				{...props}/>
			}/>
			<Route path="/login" render={props => 
				<Login setToken={setToken} showToast={showToast} setLoading={setLoading}
				{...props}/>
			}/>
			<Redirect from="/" to="/login" />{/**/}
			</Switch>
			</BrowserRouter>
		);
	} 
	//---END NO_TOKEN_AUTH--/

	return (
		<BrowserRouter>
		<TheLoader isShow={isLoading} />
		<TheToaster toastData={listToast} />
		<Switch>
			<Route path="/error500" render={props => 
			<Page500 
				showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
			<Route path="/ipembelian" render={props => 
				<IPembelian
				setToken={setToken} showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
			<Route path="/ihutangedit" render={props => 
				<IHutangedit
				setToken={setToken} showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
			<Route path="/ipiutangedit" render={props => 
				<IPiutangedit
				setToken={setToken} showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
			<Route path="/istokopnameedit" render={props => 
				<IStokopnameedit
				setToken={setToken} showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
			<Route path="/ipenjualan" render={props => 
				<IPenjualan
				setToken={setToken} showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
			<Route path="/" render={props => 
				<Layout
				setToken={setToken} showToast={showToast} setLoading={setLoading} prosesExpired={prosesExpired}
				{...props} />
			}/>
		</Switch>

		{istoTopShow &&
			<div onClick={()=>scrollToTop()} className="text-warning classToTop" >
			<CIcon name="cil-arrow-circle-top" height="30" />
			</div>
		}
		</BrowserRouter>
	);
}

export default App;
	