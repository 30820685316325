import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CHeader,
	CSubheader,
	CFooter,
	CCardGroup,
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CContainer,
	CHeaderBrand,
	CHeaderNav,
	CHeaderNavItem,
	CHeaderNavLink,
	CImg,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { Subistokopnameedit } from '../components/subnav/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { FormStokopname } from '../components/index';

var pjson     = require('../../package.json');

const IStokopnameedit = (props) => {
	const cComponentname 	= IStokopnameedit.name;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});

	let cFuncname;
	const {setLoading,showToast,prosesExpired}	= props;

	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const [uTitleEdit,setTitleEdit]			= React.useState(obBahasa.caption_editstokopname || "Edit Stok Opname");

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_stokopnameedit || "");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uShowFormBl,setShowForm]			= React.useState(false);
	const [uFormHeader,setFormHeader]		= React.useState("");

	const [uInpTabelID,setInpTabelID]		= React.useState("");
	const [uInpBarangID,setInpBarangID]		= React.useState("");
	const [uInpTanggal,setInpTanggal]		= React.useState("");
	const [uInpJmlStok,setInpJmlStok]		= React.useState("");
	const [uInpStatusOpname,setInpStatusOpname]= React.useState("");
	const [uInpSatuan,setInpSatuan]			= React.useState("");
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpHPPBeli,setInpHPPBeli]		= React.useState("");
	const [uFormJmlStokBefore,setFormJmlStokBefore]= React.useState("");

	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const hdlFormToggle=()=>{ setShowForm(!uShowFormBl); }
	React.useEffect(()=>{
		if(!uShowFormBl) return;
		var vTimeout = setTimeout(function(e){
			const vElFocus = document.getElementById("inptanggal");
			if(!UFunc.isEmpty(vElFocus)) vElFocus.focus();
			clearTimeout(vTimeout);
		},200);
	},[uShowFormBl]);
	//React.useEffect(()=>{ setInpTanggalDt(UFunc.toDate(uInpTanggal)); },[uInpTanggal]);
	//---END DIALOG_HANDLE:Harus Selalu ADA--/

	React.useEffect(()=>{ setKeywordInit(uKeywordInitObj.kwd_stokopnameedit || ""); },[uKeywordInitObj]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{ loadTabelBody(); },[uBodyInit]);
	React.useEffect(()=>{ setJmlData(uDataObj.length);},[uDataObj]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);

	const hdlKembaliKlik=()=>{ cHistory.go("-1")}
	const hdlKlikEdit=(_IDTABEL)=>{
		cFuncname	= hdlKlikEdit.name;
		_IDTABEL	= parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setInpTabelID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		let vCaptionUpper	= ((uDataObj[vObjdx].tgl_proses||"")+
			" "+(uDataObj[vObjdx].barang_nama||"")).toUpperCase();

		setFormHeader(obBahasa.caption_editstokopname+": "+uDataObj[vObjdx].tgl_proses+" "+uDataObj[vObjdx].barang_nama);

		setInpBarangID(uDataObj[vObjdx].barang_id);
		setInpTanggal(uDataObj[vObjdx].tgl_prosesdb);
		setInpJmlStok(uDataObj[vObjdx].jml_opname);
		setInpStatusOpname(uDataObj[vObjdx].status_opname);
		setInpSatuan(uDataObj[vObjdx].satuan);
		setInpKeterangan(uDataObj[vObjdx].keterangan);
		setInpHPPBeli(uDataObj[vObjdx].hpp_opname);

		setFormJmlStokBefore(uDataObj[vObjdx].stok_sebelum);

		setShowForm(true);
	}
	const hdlKlikHapus=async(_IDTABEL)=>{
		cFuncname	= hdlKlikHapus.name;
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		if(vObjdx < 0) return;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+(uDataObj[vObjdx].tgl_proses+" "+uDataObj[vObjdx].barang_nama).toUpperCase()+"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcProsesHapus(_IDTABEL);
	}

	const initAfterSimpan=()=>{
		cFuncname	= initAfterSimpan.name;
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,uInpTabelID);

		if(vObjdx < 0) return;

		uDataObj[vObjdx].tgl_prosesdb	= uInpTanggal;
		uDataObj[vObjdx].tgl_proses		= UFunc.HariAngka(UFunc.toDate(uInpTanggal));
		uDataObj[vObjdx].keterangan		= uInpKeterangan;
		uDataObj[vObjdx].jml_opname		= uInpJmlStok;
		uDataObj[vObjdx].hpp_opname		= uInpHPPBeli;

		setBodyInit(!uBodyInit);
	}
	const svcloadData=()=>{
		cFuncname	= svcloadData.name;
		setHTMLError500("");

		//console.log("("+cComponentname+") "+cFuncname+" => Proses ");

		/*//--TESTING_FRONTEND--/
			setKeywordLoad(uKeywordInit);
			let vTmpObj	= [
				{id:"1",tgl_proses:"Kam,03 Feb 2021",tgl_prosesdb:"2021-02-03",barang_id:"1",barang_nama:"Barang 01",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"15",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"2",tgl_proses:"Kam,04 Feb 2021",tgl_prosesdb:"2021-02-04",barang_id:"2",barang_nama:"Barang 02",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"16",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"3",tgl_proses:"Kam,05 Feb 2021",tgl_prosesdb:"2021-02-05",barang_id:"3",barang_nama:"Barang 03",status_opname:"MASUK",nama_user:"Users",hpp_opname:"100000",jml_opname:"17",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"4",tgl_proses:"Kam,06 Feb 2021",tgl_prosesdb:"2021-02-06",barang_id:"4",barang_nama:"Barang 04",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"18",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"5",tgl_proses:"Kam,07 Feb 2021",tgl_prosesdb:"2021-02-07",barang_id:"5",barang_nama:"Barang 05",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"19",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"6",tgl_proses:"Kam,08 Feb 2021",tgl_prosesdb:"2021-02-08",barang_id:"1",barang_nama:"Barang 01",status_opname:"MASUK",nama_user:"Users",hpp_opname:"100000",jml_opname:"11",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"7",tgl_proses:"Kam,09 Feb 2021",tgl_prosesdb:"2021-02-09",barang_id:"2",barang_nama:"Barang 02",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"12",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"8",tgl_proses:"Kam,10 Feb 2021",tgl_prosesdb:"2021-02-10",barang_id:"3",barang_nama:"Barang 03",status_opname:"MASUK",nama_user:"Users",hpp_opname:"100000",jml_opname:"13",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"9",tgl_proses:"Kam,11 Feb 2021",tgl_prosesdb:"2021-02-11",barang_id:"4",barang_nama:"Barang 04",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"14",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"10",tgl_proses:"Kam,12 Feb 2021",tgl_prosesdb:"2021-02-12",barang_id:"5",barang_nama:"Barang 05",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"25",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"11",tgl_proses:"Kam,13 Feb 2021",tgl_prosesdb:"2021-02-13",barang_id:"1",barang_nama:"Barang 01",status_opname:"MASUK",nama_user:"Users",hpp_opname:"100000",jml_opname:"26",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"12",tgl_proses:"Kam,14 Feb 2021",tgl_prosesdb:"2021-02-14",barang_id:"2",barang_nama:"Barang 02",status_opname:"MASUK",nama_user:"Users",hpp_opname:"100000",jml_opname:"27",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
				{id:"13",tgl_proses:"Kam,15 Feb 2021",tgl_prosesdb:"2021-02-15",barang_id:"3",barang_nama:"Barang 03",status_opname:"KELUAR",nama_user:"Users",hpp_opname:"100000",jml_opname:"28",keterangan:"Aku Seorang KApitan",satuan:"KG",hpp_sebelum:"0",stok_sebelum:"5"},
			]
			setDataObj(vTmpObj);
			setActivePage(1); 
			return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_stokopname/ld_input";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setActivePage(1); 
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});	
	}
	const svcProsesHapus=(_IDTABEL)=>{
		cFuncname	= svcProsesHapus.name;

		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		if(vObjdx < 0) return;

		/*//--TESTING_FRONTEND--/
			var vTmpObj = uDataObj;
			vTmpObj.splice(vObjdx,1);
			setDataObj(vTmpObj);
			setJmlData(vTmpObj.length);
			setBodyInit(!uBodyInit);
			return;
		//--END TESTING_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_stokopname/pr_hapus";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			if(output_string.sukses) {
				var vTmpObj = uDataObj;
				vTmpObj.splice(vObjdx,1);
				setDataObj(vTmpObj);
				setJmlData(vTmpObj.length);
				setBodyInit(!uBodyInit);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	const loadContent=()=>{
		if(uHTMLError500)
			return ( <>{UFunc.renderHTML(uHTMLError500)}</> );

		return (
			<div className="table-responsive-sm" xs="12">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="2" className="text-left">{obBahasa.word_tanggal}</CCol>
				<CCol sm="12" md="4" lg="5" className="text-left">{obBahasa.caption_barangnama}</CCol>
				<CCol sm="12" md="1" className="text-center">{obBahasa.caption_statusopname}</CCol>
				<CCol sm="12" md="1" className="text-center">{obBahasa.caption_jmlopname}</CCol>
				<CCol sm="12" md="2" className="text-center">{obBahasa.caption_asumsihpp} (Rp)</CCol>
				<CCol sm="12" md="2" lg="1" className="text-center">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>{loadTabelBody()}</tbody>
			</table>
			</div>
		);
	}
	const loadTabelBody=()=>{
		cFuncname	= loadTabelBody.name;
		//console.log("("+cComponentname+") "+cFuncname+" => uKeywordLoad : "+uKeywordLoad);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,tgl_proses,barang_nama,hpp_opname,satuan,jml_opname,nama_user,status_opname,keterangan
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				let vCaptionUpper	= ((tgl_proses||"")+" "+(barang_nama||"")).toUpperCase();

				let vBarangNama	= uKeywordLoad=="" ? barang_nama
					: (barang_nama||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vKeterangan	= uKeywordLoad=="" ? (keterangan||"")
					: (keterangan||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vStatusOpname	= uKeywordLoad=="" ? (status_opname||"")
					: (status_opname||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vSatuan	= uKeywordLoad=="" ? (satuan||"")
					: (satuan||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vInputer	= uKeywordLoad=="" ? (nama_user||"")
					: (nama_user||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vHPPAsumsi		= UFunc.formatAngka(hpp_opname);
				let vJmlOpname		= UFunc.formatAngka(jml_opname);

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="2" className="text-left">
						<div>{tgl_proses}</div>
						<div className="text-danger classfontsmaller">[{UFunc.renderHTML(vInputer)}]</div>
					</CCol>
					<CCol sm="12" md="4" lg="5" className="text-left px-1">
						<div className="classfontlarger text-primary">{UFunc.renderHTML(vBarangNama)}</div>
						<div className="classfontsmaller font-italic">{UFunc.renderHTML(vKeterangan)}</div>
					</CCol>
					<CCol sm="12" md="1" className={"text-center px-1 font-weight-bold"+(status_opname=="KELUAR"?" text-warning":"")}>{UFunc.renderHTML(vStatusOpname)}</CCol>
					<CCol sm="12" md="1" className="text-center px-1">
						<strong className="text-success classfontlarger">{vJmlOpname}</strong> {UFunc.renderHTML(vSatuan)}
					</CCol>
					<CCol sm="12" md="2" className="text-right px-1">{vHPPAsumsi}</CCol>
					<CCol sm="12" md="2" lg="1" className="text-center px-1">
						<div className={(objToken.userhak!="FREEROLE"&&objToken.userhak!="MANAJER" ? " d-none" : "") }>
						<CTooltip html={false} content={"--"+obBahasa.caption_editdata+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"}
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
						</div>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}

	return (
		<div className="c-app c-default-layout align-items-center">
		<div className="c-wrapper">
		<CHeader withSubheader className="classheadermain text-dark">
			<CHeaderNav className="ml-3">
			<CHeaderNavLink onClick={hdlKembaliKlik}>
			<CIcon name="cil-arrow-circle-left" height="25" alt="Kembali" />
			</CHeaderNavLink>
			</CHeaderNav>

			<CHeaderBrand className="ml-1 font-weight-bold classtitle">
			<CImg src={pjson.svcpage+"images/menus_stokopname.png"} height="40" alt={obBahasa.menus_stokopname} />
			&nbsp;{uTitleEdit}
			</CHeaderBrand>

			<Subistokopnameedit {...props} />
		</CHeader>

		<div className="c-body py-2 px-4">
			<CRow className="">
			<CCol sm="12" md="12" className="m-auto">
				<CCardGroup>
				<CCard>
				<CCardHeader><strong>{obBahasa.caption_stokopnamelist}</strong></CCardHeader>
				<CCardBody className="py-2 px-3">
				{loadContent()}
				</CCardBody>
				{(uJmlData >= uMaxData) && (
				<CCardFooter>
					<CPagination
						activePage={uPageNow}
						pages={uJmlHal}
						onActivePageChange={(i) => setActivePage(i)}
					></CPagination>
				</CCardFooter>
				)}
				</CCard>
				</CCardGroup>
			</CCol>
			</CRow>
		</div>

		<CFooter fixed={false} className="px-3 text-black-50 bg-transparent text-right ">
			<div className="my-0 " style={{fontSize:11,opacity:0.5}}>
			<span>Copyright v{pjson.version} &copy; {pjson.mydefault.prodyear} </span>
			<a href="https://www.svarna-dwipa.com" target="_blank" rel="noopener noreferrer">SvarnadwiPa</a>
			<span className="">, All Rights Reserved</span>
			</div>
		</CFooter>

		<FormStokopname
			pInpTabelID={uInpTabelID}
			pInpBarangID={uInpBarangID}
			pInpTanggal={uInpTanggal}
			pInpJmlStok={uInpJmlStok}
			pInpStatusOpname={uInpStatusOpname}
			pInpSatuan={uInpSatuan}
			pInpKeterangan={uInpKeterangan}
			pInpHPPBeli={uInpHPPBeli}
			
			onChangeTanggal={(e)=>setInpTanggal(e.target.value)}
			onChangeJmlStok={(e)=>setInpJmlStok(e.target.value)}
			onChangeJmlStokNumber={(values)=>setInpJmlStok(values.value)}
			onChangeKeterangan={(e)=>setInpKeterangan(e.target.value)}
			onChangeHPPNumber={(values)=>setInpHPPBeli(values.value)}
			onChangeHPP={(e)=>setInpHPPBeli(e.target.value)}
			
			pDataHPPTerakhir="0"
			pDataJmlStokBefore={uFormJmlStokBefore}

			onAftersimpan={initAfterSimpan}
			show={uShowFormBl} 
			dialogHeader={uFormHeader}
			toggle={hdlFormToggle}
			{...props}/>

		</div>
		</div>
	)
}

export default IStokopnameedit;
	