import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
	CFormGroup,
	CForm,
	CFormText,
	CInput,
	CLabel,
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CProgress,
	CRow,
	CCallout
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
var pjson     = require('../../package.json');

const IProfile = (props) => {
	const vFuncName = IProfile.name;

	const {setToken,setLoading,showToast,prosesExpired}	= props;

	const cHistory	= useHistory();
	const cDispatch	= useDispatch();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	//console.log("("+vFuncName+") props.namaRoute= "+props.namaRoute);

	const [vUsername,setUsername]		= React.useState(objToken.username);
	const [vUsercaption,setUsercaption]	= React.useState(objToken.usercaption);
	const [vUserpasswd1,setUserpasswd1]	= React.useState('');
	const [vUserpasswd2,setUserpasswd2]	= React.useState('');
	const [vUserpasswdold,setUserpasswdold]	= React.useState('');
	const [vBtnSimpanDisabled,setBtnSimpanDisabled]	= React.useState(false);

	/*const setLocalToast = (_TEXT) => { props.showToast ? props.showToast(_TEXT) : alert(_TEXT); }
	React.useEffect(()=>{ setLocalToast(vFuncName); },[]);//-*/

	React.useEffect(()=>{ 
		if(objToken.username.toUpperCase() == "SUPERADMIN") {
			cHistory.push("/user");
		}
	},[]);//-*/

	const hdlKlikSimpan = () => {

		//---CEK_VALIDASI--/
		if(vUsername == "") {
			showToast(obBahasa.word_username+" "+obBahasa.caption_mustfilled+".."); return;
		}
		if(vUsercaption == "") {
			showToast(obBahasa.caption_usercaption+" "+obBahasa.caption_mustfilled+".."); return;
		}
		if(vUserpasswd1 != vUserpasswd2) {
			showToast(obBahasa.word_password+" / "+obBahasa.caption_passwdconfirm+" "+obBahasa.caption_mustsame+".."); return;
		} else {
			if(vUserpasswd1 != "" || vUserpasswd2 != "")
				if(vUserpasswdold == "") {
					showToast(obBahasa.caption_passwdold+" "+obBahasa.caption_mustfilled+".."); return;
				}
		}
		//---END CEK_VALIDASI--/

		svcProsesSimpan();
	}

	const svcProsesSimpan = () => {
		//---PROSES_SIMPAN--/
		var vDATAS    = JSON.stringify({
			send_username : vUsername,
			send_usercaption : vUsercaption,
			send_passwd1 : vUserpasswd1,
			send_passwd2 : vUserpasswd2,
			send_passwdold : vUserpasswdold,
			send_tokenauth : objToken.userinit,
		});
		var vURLs     = pjson.svcpage+"svc_init/pr_simpanprofile";

		setLoading(true);
		setBtnSimpanDisabled(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+vFuncName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setBtnSimpanDisabled(false);
			setLoading(false);
			if(output_string.sukses) {
				if(output_string.token) {
					var vTokenOutput =  (JSON.parse(output_string.token));
					setToken(vTokenOutput);
				}
				showToast(obBahasa.pesan_sukses,"SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnSimpanDisabled(false);
			setLoading(false);
			console.log("("+vFuncName+") catch-error: "+error);
			cHistory.push("/error500");
		});
		//---END PROSES_SIMPAN--/
	}

	return (
		<CRow className="justify-content-center">
        <CCol xs="12" sm="10" lg="8">
		<CCard>
			<CForm >

			<CCardHeader className="font-weight-bolder">{obBahasa.caption_personalinfo}</CCardHeader>

			<CCardBody>
				<CRow>
				<CCol xs="12">
					<CFormGroup row>
					<CCol md="4">
						<CLabel>{obBahasa.word_username}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput maxLength="30"
							type="text"
							className="form-control"
							value={vUsername}
							onChange={(e) => setUsername(e.target.value)}
							id="inpusername" name="inpusername" />
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol md="4">
						<CLabel>{obBahasa.caption_usercaption}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput maxLength="50"
							value={vUsercaption}
							onChange={(e) => setUsercaption(e.target.value)}
							id="inpusercaption" name="inpusercaption" />
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol md="4">
						<CLabel>{obBahasa.word_password}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput
							autoComplete="off"
							onChange={(e) => setUserpasswd1(e.target.value)}
							id="inppasswd1" type="password" />
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol md="4">
						<CLabel>{obBahasa.caption_passwdconfirm}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput
							autoComplete="off"
							onChange={(e) => setUserpasswd2(e.target.value)}
							id="inppasswd2" type="password" />
						<div className="classpetunjuk">*) {obBahasa.petunjuk_inputpasswd}</div>
					</CCol>
					</CFormGroup>

					<CFormGroup row>
					<CCol md="4">
						<CLabel>{obBahasa.caption_passwdold}</CLabel>
					</CCol>
					<CCol xs="12" md="8">
						<CInput
							autoComplete="off"
							onChange={(e) => setUserpasswdold(e.target.value)}
							id="inppasswdold" type="password" />
						<div className="classpetunjuk">*) {obBahasa.petunjuk_passwdold}</div>
					</CCol>
					</CFormGroup>

				</CCol>
				</CRow>
			</CCardBody>

            <CCardFooter>
              <CButton size="md" 
              	onClick={()=>hdlKlikSimpan()}
              	disabled={vBtnSimpanDisabled}
              	color="warning">
              	<CIcon name="cil-check-circle" />&nbsp;{""+obBahasa.word_simpan}
              </CButton>
            </CCardFooter>
			</CForm>
		</CCard>
		</CCol>
		</CRow>
	)
}

export default IProfile;