import React from 'react';

const cHariPendek 	= ["Min","Sen","Sel","Rab","Kam","Jum","Sab"];
const cBulanPanjang = [
	"Januari",
	"Februari",
	"Maret",
	"April",
	"Mei",
	"Juni",
	"Juli",
	"Agustus",
	"September",
	"Oktober",
	"November",
	"Desember"
];

const UFunc = {
handleErrors: function(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
},
formatAngka: function(_VARS){
	_VARS = parseFloat(_VARS) || 0;
	return _VARS.toLocaleString("ID", {maximumFractionDigits:2}); // "1,234.57"
},
isEmpty: function (val){
    return (val === undefined
        || typeof(val) === "undefined"
        || val == null
        || val == "NaN"
        || val === NaN
        || val.length <= 0) ? true : false;
},
nl2br: function(str) {
	if(UFunc.isEmpty(str)) return "";
    return str.replace(/\n/g,"<BR>");
},
br2nl: function(str) {
	if(UFunc.isEmpty(str)) return "";
	
    var HASIL    = str;
    HASIL        = HASIL.replace(/<br\s*\/?>/mg,"\n");
    HASIL        = HASIL.replace(/<BR\s*\/?>/mg,"\n");

    return HASIL;
},
capitalize: function(str){
	return str.charAt(0).toUpperCase() + str.slice(1);
},
getObjectIndeks: function(_OBJECTDATA,_IDTABEL){
	let vHasil = -1;

	_IDTABEL = parseInt(_IDTABEL) || 0;
	if(_IDTABEL <= 0) return vHasil;
	if(_OBJECTDATA===null) return vHasil;

	for(var i=0;i<_OBJECTDATA.length;i++) {
		let vIDTabel	= parseInt(_OBJECTDATA[i].id) || 0;
		if(vIDTabel == _IDTABEL) {
			vHasil = i; break;
		}
	}
	return vHasil;
},
getTabelRowIndeks: function(_TABELID,_TABELTR) {
	var rows = document.getElementById(_TABELID).getElementsByTagName("tbody")[0].getElementsByTagName("tr");
	for (var i=0; i<rows.length; i++) {
		if(rows[i].getAttribute("id").toUpperCase() == _TABELTR.toUpperCase()) {
			return i;
		}
	}
},
getOptionSelectTeks:function(_ELEMENT) {
	if(UFunc.isEmpty(_ELEMENT)) return "";

	return (_ELEMENT.options[_ELEMENT.selectedIndex].text);
},
renderHTML: function(escapedHTML: string) { 
	return React.createElement("span", { dangerouslySetInnerHTML: { __html: escapedHTML } });
},
leadZero: function(str, max) {
    max = parseInt(max)||0;
    if(max<2)max=2;

    str = str.toString();
    var vDigits = max - (str.length);//-> 2 - 1 = 1
    for(var i=0;i <= vDigits - 1;i++)
	    str = "0" + str.toString();

    return str.toString();
},
toDate: function(_STRDATE) {
    var DatesCHK = _STRDATE;//---2011-04-21
    const [year, month, day] = [...DatesCHK.split('-')];
    return new Date(year, (month-1), day);//-->INDEX_MONTH_0_11;
},
DbDate: function(_DATE) {
	_DATE = (UFunc.isEmpty(_DATE)) ? new Date() : _DATE;

	const vHasil = (_DATE.getFullYear() + "-" +UFunc.leadZero(_DATE.getMonth() + 1) + "-" + 
		UFunc.leadZero(_DATE.getDate()));
	return vHasil.toString();
},
SelisihHari: function(_TGLAWAL, _TGLAKHIR) {
    var vDateAwal   = UFunc.toDate(_TGLAWAL);
    var vDateAkhir  = UFunc.toDate(_TGLAKHIR);

    return Math.round(Math.abs(vDateAkhir-vDateAwal)/(1000*60*60*24));
},
DateIsBeyond: function(_TGLCEKDt) {
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vTglCek = "+vTglCek);

	if(vSelisih > 0) return true; else return false;
},
DateIsExceededYear: function(_TGLCEKDt) {
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

	const vTglTodayDt= new Date();
	const vYearNow	= vTglTodayDt.getFullYear();

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vYearNow+"-01-01");

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vSelisih = "+vSelisih);

	if(vSelisih < 0) return true; else return false;
},
TglAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }
	//console.log("(TglAngka) _DATE = "+_DATE);
    return UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
HariAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }

    return cHariPendek[ vTANGGAL.getDay() ]+","+ UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
hdlKeyDownInput: function(_EV,_NEXTFIELD) {
	if(_EV.which==13) {
		_EV.preventDefault();
		const ElNext	= document.getElementById(_NEXTFIELD);
		if(!UFunc.isEmpty(ElNext)) ElNext.focus();
	}
},

}
export {UFunc,cBulanPanjang};