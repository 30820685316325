import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Konfirm } from '../helpers/onConfirm';
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CImg,
  CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
//import { TheLoader, TheToaster } from '../components/index';

var pjson     = require('../../package.json');

const Login = ({ setToken,setLoading,showToast,confirmDialog }) => {
  const vHistori  = useHistory();
  const dispatch  = useDispatch();

  const [gUsername, setUsername]  = React.useState('');
  const [gPasswd, setPasswd]      = React.useState('');
  const [gButtonDisabled,setButtonDisabled] = React.useState(false); 

  const [gIsiToaster,setIsiToaster]= React.useState('');
  const [idToast,setidToast]      = React.useState(0);

  const [uRoutes,setRoutes]     = React.useState(JSON.parse(localStorage.getItem("routes")||"[]"));
  const uRouteProfileArr        = useSelector(state => state.profileRoute); 

  React.useEffect(() => {
      //document.getElementById("idlogowatermark").style.visibility = "hidden";
  },[]);//-->kalo TIDAK ada nilainya: dijalankan satu kali pada awal saja--/

  React.useEffect(() => {
    if(gUsername.trim() == "" || gPasswd.trim() == "") {
      setButtonDisabled(false);
    } else setButtonDisabled(true);
  },[gUsername,gPasswd]);//-->kalo ada nilainya: dijalankan setiap vars berubah--/

  const BuatTest = () => {
    setLoading(true);
    showToast("Hurray","SUKSES");
    var vTimeout = setTimeout(function(){ setLoading(false); clearTimeout(vTimeout);},3000);
  }

  const LoginTmp = async () => {
    if(await Konfirm({dialogContent: "Yakin Ga Loe ?",options:{tipe:"FORM"}})) BuatTest();
  }

  const LoginOnclick = () => {
    if(gUsername.trim() == "" || gPasswd.trim() == "") return;

    var vFuncName = LoginOnclick.name;
    var vDATAS    = JSON.stringify({
      send_username : gUsername,
      send_passwd : gPasswd,
    });
    var vURLs     = pjson.svcpage+"svc_login/pr_login";

    setLoading(true);
    setButtonDisabled(false);
    fetch(vURLs,{
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      method: "POST", mode: "cors",
      cache: "default", body: vDATAS,
    }).
    then((response)=> {
      if (response.status === 200) return response.json();
      //else { console.log("("+vFuncName+") [1]: response "+response.statusText); }
    }).
    then((output_string) => {
      setLoading(false);
      setButtonDisabled(true);

        //console.log("("+vFuncName+") token : "+output_string.token);
        if(output_string.langs) {
          dispatch({type: "set", listBahasa: JSON.parse(output_string.langs)});
        }//-*/
        if(output_string.nav) {
          dispatch({type: "set", listNav: JSON.parse(output_string.nav)});
        }//-*/
        if(output_string.routes) {
          //console.log("("+vFuncName+") routes : "+output_string.routes);
          //dispatch({type: "set", listRoute: JSON.parse(output_string.routes )});

          const cRouteLoadArr = JSON.parse(output_string.routes||"[]");
          const vNewRoutes  = [...uRouteProfileArr,...cRouteLoadArr];
          localStorage.setItem("routes", JSON.stringify(vNewRoutes)); 
        }//-*/

      if(output_string.sukses) {
        //document.getElementById("idlogowatermark").style.visibility = "visible";
        if(output_string.token) {
          var vTokenOutput =  (JSON.parse(output_string.token));
          setToken(vTokenOutput);
        }
      } else if(output_string.info) {
        showToast(output_string.info);
      } else if(output_string.errors) {
        console.log("("+vFuncName+") output_string.errors : "+output_string.errors);
        var vMsg;
        if(pjson.mydefault.environment=="development")
          vMsg = output_string.errors;
        else
          vMsg = pjson.mydefault.msgFetchError;
          
        showToast(vMsg,"ERROR");
      } else if(output_string.expired) {
      }
    })
    .catch((error) =>{
      //var vTimeout = setTimeout(function(){setLoading(false); clearTimeout(vTimeout);},5000);
      setButtonDisabled(true);
      setLoading(false);
      console.log("("+vFuncName+") catch-error: "+error);
      vHistori.push("/error500");
    });
  }//-*/

  return (
    <div className="c-app c-default-layout flex-row align-items-center classbglogin">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup className="classborderr1">
              <CCard className="m-0 p-3">
                <CCardBody className="px-2">
                  <CForm>
                    <div className="d-lg-none text-center">
                    <CImg src={"images/logo192.png"} height="80" />
                    </div>
                    <h2 className="text-center classtitlelogin">{pjson.name} Login</h2>
                    <p className=" text-center text-muted classfontsmaller">Silahkan Login dengan Akun Anda</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cilUser" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" onChange={(e)=>{setUsername(e.target.value);}} placeholder="Username" autoComplete="off" required />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" onChange={(e)=>{setPasswd(e.target.value);}} placeholder="Password" autoComplete="current-password" required />
                    </CInputGroup>
                    <CRow className="pl-3">
                        <CButton color="warning" className="px-5 font-weight-bolder text-white" 
                          disabled={!gButtonDisabled} 
                          onClick={()=>LoginOnclick()}
                        >Login</CButton>
                    </CRow>
                    <CRow className="pl-3 pt-2">
                        <p className="m-0 font-italic classfontsmaller text-info">
                        &middot;&middot; Jika Belum mempunyai Akun, Hubungi ADMINISTRATOR &middot;&middot;
                        </p>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-transparent d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="align-items-center text-center">
                  <CContainer className="pt-3 m-auto">
                  <CImg src={"images/logo512.png"} className="" width="225" alt="Main Logo" />
                  </CContainer>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
          <CCol md="8">
          <div className="text-right classfontsmaller text-secondary">
          Copyright &copy; {pjson.mydefault.prodyear} v{pjson.version} SvarnadwiPa. All Rights Reserved.
          </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default Login;
