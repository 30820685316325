import IProfile from './IProfile';
import ISetting from './ISetting';
import ILangs from './ILangs';
import Dashboard from './Dashboard';

export {
  ISetting,
  ILangs,
  IProfile,
  Dashboard,
}