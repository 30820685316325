import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CTooltip,
	CLink,
	CForm,
	CFormGroup,
	CInput,
	CInputGroupPrepend,
	CInputGroup,
	CSelect,
	CLabel,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
//import ReactExport from "react-export-excel";
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';

var pjson     = require('../../package.json');

const Lapmutasistok = (props) => {
	const cComponentname 		= Lapmutasistok.name;
	let cFuncname;
	const cDispatch				= useDispatch();
	const cHistory				= useHistory();
	cDispatch({type: "set", activeRoute: props.namaRoute});
	const {setLoading,showToast,prosesExpired}	= props;

	/*const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//-*/

  	const obBahasa 					= useSelector(state => state.listBahasa);
	const uHTMLstyle				= useSelector(state => state.gHTMLstyleLaporan);
	//const uFilterLaporanObj			= (useSelector(state => state.gFilterLaporanObj));
	const objToken	= JSON.parse(localStorage.getItem("token"));

	const uTglNowDT							= new Date();
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uBtnTampilDisabledBl,setBtnTampilDisabledBl]	= React.useState(false);
	//const [uKlikCetak,setKlikCetak]			= React.useState(false);

	const [uBulanObj,setBulanObj]	= React.useState([]);
	const [uTahunObj,setTahunObj]	= React.useState([]);
	const [uBarangObj,setBarangObj]	= React.useState([]);

	const [uInpBulanID,setInpBulanID] 	= React.useState(uTglNowDT.getMonth()+1);
	const [uInpTahun,setInpTahun] 		= React.useState(uTglNowDT.getFullYear());
	const [uInpBarangID,setInpBarangID]	= React.useState(0);
	const [uFilterTitle,setFilterTitle]	= React.useState("");
	const [uTitleSet,setTitleSet]		= React.useState("");

	const [uInitAwal,setInitAwal]			= React.useState("*) "+obBahasa.petunjuk_initlapmutasistok+"..");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	/*const uElBtnTampil 	= document.getElementById("btnTampilkan");
	const uElIkonReset 	= document.getElementById("idklikreset");
	const uElFormLaporan= document.getElementById("idformlaporan");//-*/
	const uElInpBulanID = document.getElementById("inpbulanid");
	const uElInpTahun 	= document.getElementById("inptahun");
	const uElInpBarangID= document.getElementById("inpbarangid");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");


	React.useEffect(()=>{ svcInitData(); },[]);
	React.useEffect(()=>{ 
		setActivePage(1);
		setJmlData(uDataObj.length||0); 
	},[uDataObj]);
	React.useEffect(()=>{
		cFuncname 			= "useEffect[uJmlData]";
		/*const vObjFilter	= uFilterLaporanObj;
		vObjFilter.jml_data = uJmlData;
		cDispatch({type: "set", gFilterLaporanObj: vObjFilter});//-*/

		console.log("("+cComponentname+"-"+cFuncname+") uJmlData = "+uJmlData);

		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);
	},[uJmlData]);
	React.useEffect(()=>{
		let vTitle	= "";
		vTitle +=	(!UFunc.isEmpty(uElInpBulanID))
			? "<div>"+obBahasa.word_periode+": "+
				UFunc.getOptionSelectTeks(uElInpBulanID)+" "+
				UFunc.getOptionSelectTeks(uElInpTahun)+"</div>"
			: "";
		if((parseInt(uInpBarangID)||0) > 0)
			vTitle += (!UFunc.isEmpty(uElInpBarangID)) ? "<div style='font-style:italic'>"+UFunc.getOptionSelectTeks(uElInpBarangID)+"</div>" : "";
		setFilterTitle(vTitle);

		/*setHTMLError500(null);
		setDataObj([]);
		setInitAwal("*) "+obBahasa.petunjuk_initlapmutasistok+"..");//--*/
	},[uInpBulanID,uInpTahun,uInpBarangID]);

	const hdlKlikReset=async()=>{
		cFuncname 			= hdlKlikReset.name;

		if((parseInt(uJmlData)||0) > 0) {
			if(await Konfirm(obBahasa.confirm_reset)) initReset();
		} else initReset();	//-*/
	}
	const hdlKlikTampil=()=>{
		cFuncname 			= hdlKlikTampil.name;
		
		const vBarangID		= parseInt(uInpBarangID) || 0;
		const vBulanID		= parseInt(uInpBulanID) || 0;
		const vTahun		= parseInt(uInpTahun) || 0;
		console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);

		if(vBulanID <= 0) {
			if(!UFunc.isEmpty(uElInpBulanID)) uElInpBulanID.focus();
			showToast(obBahasa.word_bulan+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}
		if(vTahun <= 0) {
			if(!UFunc.isEmpty(uElInpTahun)) uElInpTahun.focus();
			showToast(obBahasa.word_tahun+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}
		if(vBarangID <= 0) {
			if(!UFunc.isEmpty(uElInpBarangID)) uElInpBarangID.focus();
			showToast(obBahasa.caption_barangnama+" "+obBahasa.caption_mustchoosed+"..");
			return;
		}

		svcLoadReport();
	}
	const hdlKlikCetak=()=>{
		cFuncname = hdlKlikCetak.name;

		var vContent 	= gnrPrintContent();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel=()=>{
		cFuncname = hdlKlikExcel.name;
	}

	const initReset=()=> {
		cFuncname 			= initReset.name;
		
		//console.log("("+cComponentname+"-"+cFuncname+") uDataObj : "+JSON.stringify(uDataObj));
		setHTMLError500(null);
		setDataObj([]);
		setInpBulanID(uTglNowDT.getMonth()+1);
		setInpTahun(uTglNowDT.getFullYear());
		setInpBarangID(0);

		setInitAwal("*) "+obBahasa.petunjuk_initlapmutasistok+"..");
	}
	const gnrPrintContent=()=>{

		var vHTMLs = uHTMLstyle;

		vHTMLs += `
			<table id="idtabelhtml">
			<caption>
			<div>`+obBahasa["menus_"+props.namaRoute.toLowerCase()]+`</div>`+
			uTitleSet+
			`</caption>
			<thead>
			<tr>
			<th width="5%">No</th>
			<th width="15%">`+obBahasa.word_tanggal+`</th>
			<th align="left">`+obBahasa.word_keterangan+`</th>
			<th width="10%">`+obBahasa.word_status+`</th>
			<th width="10%">`+obBahasa.caption_jmlawal+`</th>
			<th width="8%">`+obBahasa.caption_jmlmutasi+`</th>
			<th width="10%">`+obBahasa.word_saldo+`</th>
			</tr>
			</thead>
			<tbody>`
		uDataObj.map((vItems,vKey)=>{
			const {
				id,tanggal,keterangan,status_mutasi,jml_awal,jml_mutasi,jml_saldo
			} = vItems;
			var vTanggal 		= (parseInt(id)||0)<= 0?"-":UFunc.HariAngka(tanggal);
			var vStatusMutasi 	= (parseInt(id)||0)<= 0?"-":status_mutasi;
			var vJmlAwal 		= (parseInt(id)||0)<= 0?"-":UFunc.formatAngka(jml_awal);
			var vJmlMutasi 		= (parseInt(id)||0)<= 0?"-":UFunc.formatAngka(jml_mutasi);
			var vJmlSaldo 		= UFunc.formatAngka(jml_saldo);
			var vKeterangan 	= (parseInt(id)||0)<= 0?"~"+obBahasa.caption_saldosebelumnya:keterangan;

			vHTMLs += `
			<tr key={vKey} valign="top">
					<td align="right">`+(vKey+1)+`.</td>
					<td align="center">`+vTanggal+`</td>
					<td align="left" style="font-style:italic;">`+vKeterangan+`</td>
					<td align="center">`+vStatusMutasi+`</td>
					<td align="center">`+vJmlAwal+`</td>
					<td align="center">`+vJmlMutasi+`</td>
					<td align="center" style="font-weight:bolder;font-size:larger">`+vJmlSaldo+`</td>
			</tr>`;
		});
		vHTMLs 	+= `
			</tbody>
			</table>
		`

		return vHTMLs;
	}
	const svcInitData=()=>{
		cFuncname = svcInitData.name;

		/*//--TESTING_FRONTEND--/
		//console.log("("+cComponentname+") "+cFuncname+" => cKeywordInit : "+cKeywordInit);
		let vTmpObj	= [
			{id:"1",caption:"JANUARI"},
			{id:"2",caption:"FEBRUARI"},
			{id:"3",caption:"MARET"},
			{id:"4",caption:"APRIL"},
			{id:"5",caption:"MEI"},
			{id:"6",caption:"JUNI"},
			{id:"7",caption:"JULI"},
			{id:"8",caption:"AGUSTUS"},
			{id:"9",caption:"SEPTEMBER"},
			{id:"10",caption:"OKTOBER"},
			{id:"11",caption:"NOVEMBER"},
			{id:"12",caption:"DESEMBER"},
		]
		setBulanObj(vTmpObj);

		vTmpObj	= [
			{value:"2021",caption:"2021"},
			{value:"2020",caption:"2020"},
			{value:"2019",caption:"2019"},
			{value:"2018",caption:"2018"},
			{value:"2017",caption:"2017"},
		]
		setTahunObj(vTmpObj);

		vTmpObj	= [
			{id:"1",caption:"Barang 01"},
			{id:"2",caption:"Barang 02"},
			{id:"3",caption:"Barang 03"},
			{id:"4",caption:"Barang 04"},
			{id:"5",caption:"Barang 05"},
			{id:"6",caption:"Barang 06"},
			{id:"7",caption:"Barang 07"},
			{id:"8",caption:"Barang 08"},
			{id:"9",caption:"Barang 09"},
		]
		setBarangObj(vTmpObj);

		return;
		//--END TESTING_FRONTEND--*/

		if(objToken.userinit == "") {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_lapmutasistok/ld_init";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			//console.log("("+cComponentname+") "+cFuncname+" => output_string : "+JSON.stringify(output_string));
			setLoading(false);
			setBtnTampilDisabledBl(false);
			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.bulanobj) {
					vTmpObj = JSON.parse(output_string.bulanobj);
					setBulanObj(vTmpObj);
				}
				if(output_string.tahunobj) {
					vTmpObj = JSON.parse(output_string.tahunobj);
					setTahunObj(vTmpObj);
				}
				if(output_string.barangobj) {
					vTmpObj = JSON.parse(output_string.barangobj);
					setBarangObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
		});	
	}
	const svcLoadReport=()=>{
		cFuncname 			= svcLoadReport.name;
		setInitAwal(null);
		setHTMLError500(null);
		setDataObj([]);
		
		//console.log("("+cComponentname+"-"+cFuncname+") vBarangID : "+vBarangID);
		/*//--TESTING_FRONTEND--/
		let vTmpObj = [
		{id:"1",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"2",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"3",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"4",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"5",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"6",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"7",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"8",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"9",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"10",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"11",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"12",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"13",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"14",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"15",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"16",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"17",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"18",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"19",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"20",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"21",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"22",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"23",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"24",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"25",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"26",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"27",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"28",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"29",tanggal:"",keterangan:"Saldo Sebelum...",status_mutasi:"KELUAR",jml_awal:"0",jml_mutasi:"0",jml_saldo:"10"},
		{id:"30",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"31",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		{id:"32",tanggal:"2021-04-01",keterangan:"BLABAL bla blab bla...",status_mutasi:"KELUAR",jml_awal:"10",jml_mutasi:"30",jml_saldo:"45"},
		];
		setDataObj(vTmpObj);
		return;
		//--END TESTING_FRONTEND--*/

		if(objToken.userinit == "") {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}

		var vDATAS    = JSON.stringify({
			send_bulanid : uInpBulanID,
			send_tahun : uInpTahun,
			send_barangid : uInpBarangID,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_lapmutasistok/ld_report";

		setLoading(true);
		setBtnTampilDisabledBl(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			setBtnTampilDisabledBl(false);

			/*var vObjFilter 			= uFilterLaporanObj;
			vObjFilter.isKlikTampil = false; 
			vObjFilter.isKlikReset = true; 
			cDispatch({type: "set", gFilterLaporanObj: vObjFilter});//-*/

			if(output_string.tampil) {
				let vTmpObj = []
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setTitleSet(uFilterTitle);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentname+"-"+cFuncname+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setBtnTampilDisabledBl(false);
			setLoading(false);
			console.log("("+cComponentname+"-"+cFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});	
	}

	const loadContent=()=>{
		cFuncname = loadContent.name;

		if(uBtnTampilDisabledBl) return (
			<div className="text-center classpetunjuk">{obBahasa.pesan_mohontunggu}</div>
		);
		if(uHTMLError500) return (
			<>{UFunc.renderHTML(uHTMLError500)}</>
		);
		if(uInitAwal) return (
			<div className="font-italic classfontlarger text-info px-4">{UFunc.renderHTML(uInitAwal)}</div>
		);

				//border={1} cellSpacing={1}
		return (
			<div className="table-responsive-sm" xs="12">
			<table 
				className="table table-borderless table-striped" 
				id="idtabeldata">
			<thead className="d-sm-down-none thead-light">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th width="10%" className="align-top text-center">{obBahasa.word_tanggal}</th>
			<th className="align-top text-left">{obBahasa.word_keterangan}</th>
			<th width="10%" className="align-top text-center">{obBahasa.word_status}</th>
			<th width="10%" className="align-top text-center">{obBahasa.caption_jmlawal}</th>
			<th width="8%" className="align-top text-center">{obBahasa.caption_jmlmutasi}</th>
			<th width="10%" className="align-top text-center">{obBahasa.word_saldo}</th>
			</tr>
			</thead>
			<tbody>{loadBodyTabel()}</tbody>
			{loadFooterTabel()}
			</table>
			</div>
		)
	}
	const loadBodyTabel=()=>{
		cFuncname = loadBodyTabel.name;

		if(uJmlData <= 0) return (
			<tr>
			<td colSpan="7" className="text-center classpetunjuk">&middot;&middot;&middot;{obBahasa.caption_dataempty}&middot;&middot;&middot;</td>
			</tr>
		)

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		return (uDataObj.map((vItems,vKey)=>{
			const {
				id,tanggal,keterangan,status_mutasi,jml_awal,jml_mutasi,jml_saldo
			} = vItems;

			if(vKey>=vMin && vKey<=vMax) {
				var vTanggal 		= (parseInt(id)||0)<= 0?"-":UFunc.HariAngka(tanggal);
				var vStatusMutasi 	= (parseInt(id)||0)<= 0?"-":status_mutasi;
				var vJmlAwal 		= (parseInt(id)||0)<= 0?"-":UFunc.formatAngka(jml_awal);
				var vJmlMutasi 		= (parseInt(id)||0)<= 0?"-":UFunc.formatAngka(jml_mutasi);
				var vJmlSaldo 		= UFunc.formatAngka(jml_saldo);
				var vKeterangan 	= (parseInt(id)||0)<= 0?"~"+obBahasa.caption_saldosebelumnya:keterangan;

				return (
				<tr key={vKey}>
					<td align="right">{(vKey+1)+"."}</td>
					<td align="center">{vTanggal}</td>
					<td align="left" className="font-italic classfontlarger">{vKeterangan}</td>
					<td align="center" className={vStatusMutasi=="KELUAR"?"text-danger":"text-primary"}>{vStatusMutasi}</td>
					<td align="center">{vJmlAwal}</td>
					<td align="center" className="text-success">{vJmlMutasi}</td>
					<td align="center" className="font-weight-bolder classfontlarger">{vJmlSaldo}</td>
				</tr>
				)
			}
		}));
	}
	const loadFooterTabel=()=>{
		cFuncname = loadFooterTabel.name;
		return null;

		return (
			<tfoot>
			<tr>
			<td colSpan={7}>{JSON.stringify(uDataObj)}</td>
			</tr>
			</tfoot>
		);
	}

	return (
		<>
		<div className="classfakesubheader">
			<CForm className="form-horizontal py-0" id="idformlaporan">
			<CFormGroup row className="px-0 my-0 justify-content-center">
			<CCol sm="12" className="text-center">
			<div className="px-1 text-left align-top w-auto d-inline-block ">
				<CSelect custom 
					value={uInpBulanID}
					onChange={(e)=>setInpBulanID(e.target.value)}
					id="inpbulanid">
					{uBulanObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} 
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_periodelaporan}</div>
			</div>
			<div className="px-1 text-left align-top d-inline-block ">
				<CSelect custom 
					value={uInpTahun}
					onChange={(e)=>setInpTahun(e.target.value)}
					id="inptahun">
					{uTahunObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.value}  
								key={vKey}>{vItem.caption}</option>
						);
					})}
				</CSelect>
			</div>
			<div className="px-1 text-left align-top d-inline-block w-auto" style={{minWidth:"300px"}}>
				<CSelect custom 
					value={uInpBarangID}
					onChange={(e)=>setInpBarangID(e.target.value)}
					id="inpbarangid">
					<option value="0">---SILAHKAN PILIH NAMA BARANG</option>
					{uBarangObj.map((vItem,vKey)=>{
						return (
							<option value={vItem.id} key={vKey}>{vItem.caption} ({vItem.id})</option>
						);
					})}
				</CSelect>
				<div className="classpetunjuk">{obBahasa.caption_pilihbarang}</div>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
				<CButton type="button" color="secondary" 
					color="warning"
					className="text-white font-weight-bold py-1 pt-2"
					disabled={uBtnTampilDisabledBl}
					onClick={hdlKlikTampil} 
					id="btnTampilkan">
					<CIcon name="cil-magnifying-glass" />&nbsp;{obBahasa.word_tampilkan}
				</CButton>
			</div>
			<div className="px-1 text-left align-middle d-inline-block ">
			&nbsp;&middot;&nbsp;&nbsp;
			<CTooltip content={obBahasa.caption_resetview}>
				<CLink 
					onClick={hdlKlikReset} 
					className="classikon classikonreset"
					id="idklikreset"/>
			</CTooltip>
			</div>
			</CCol>
			</CFormGroup>
			</CForm>
		</div>
		<CCard>
		<CCardHeader>
		<CRow>
		<CCol sm="12" md="7">
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
		</CCol>
		<CCol sm="12" md="5" className={"text-right"+(uJmlData<=0?" d-none":"")}>
			<CTooltip content={obBahasa.caption_cetaklaporan}>
				<CLink className="" onClick={hdlKlikCetak} className="classikon classikoncetak"/>
			</CTooltip>
			<span className="d-none">&nbsp;&middot;&nbsp;</span>
			<CTooltip content={obBahasa.caption_exportexcel} className="">
				<CLink className="" onClick={hdlKlikExcel} className="classikon classikonexcel d-none"/>
			</CTooltip>
			<span className="">&nbsp;&middot;&nbsp;</span>
			{"Jumlah: "}<strong id="idjmldata">{UFunc.formatAngka(uJmlData)}</strong>{" Data"}
		</CCol>
		</CRow>
		</CCardHeader>
		<CCardBody>
		{loadContent()}		
		</CCardBody>
		{(uJmlData >= uMaxData) && (
		<CCardFooter>
			<CPagination
				activePage={uPageNow}
				pages={uJmlHal}
				onActivePageChange={(i) => setActivePage(i)}
			></CPagination>
		</CCardFooter>
		)}
		</CCard>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}

export default Lapmutasistok;
	