import React from "react";
import { useSelector,useDispatch } from 'react-redux';
import { 
	useHistory,
} from 'react-router-dom';
import {
	CRow,
	CCol,
	CBreadcrumbRouter,
	CSubheader,
	CForm,
	CFormGroup,
	CSelect,
	CInputGroupPrepend,
	CInputGroup,
	CButton,
	CLink,
	CTooltip
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Konfirm } from '../../helpers/onConfirm';
import { UFunc,cBulanPanjang } from "../../helpers/functions";

var pjson     = require('../../../package.json');

const Subdashboard	= (props) => {
	const cComponentname 	= Subdashboard.name;
	let cFuncname;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();
	const {setLoading,showToast,prosesExpired}	= props;

  	const obBahasa 			= useSelector(state => state.listBahasa);
	const uKeywordObj		= useSelector(state => state.listKeyword);
	const objToken			= JSON.parse(localStorage.getItem("token"));

	const uTglNowDT						= new Date();
	const [uIsExecuteBl,setExecute]		= React.useState(useSelector(state => state.isHeaderExecute));
	const [uSelectValue,setSelectValue] = React.useState(uKeywordObj.val_dashboard||"");

	const [uDataObj,setDataObj]			= React.useState([]);
	
	React.useEffect(() => { initData() },[]);
	React.useEffect(() => {
		if(uDataObj.length <= 0) return;

		var vTglNow1 = uTglNowDT.getFullYear()+"-"+UFunc.leadZero(uTglNowDT.getMonth()+1)+"-01";

		const valObjKeyword	= { val_dashboard : vTglNow1 };
		cDispatch({type: "set", listKeyword: valObjKeyword});
		setSelectValue(vTglNow1);
	},[uDataObj]);
	React.useEffect(() => {
		if(uDataObj.length <= 0) return;
		if(uSelectValue == "") return;

		cDispatch({type: 'set', isHeaderExecute: !uIsExecuteBl});
		setExecute(!uIsExecuteBl);
	},[uSelectValue]);

	const hdlChangeFilter=(e)=>{
		const valObjKeyword	= { val_dashboard : e.target.value };
		cDispatch({type: "set", listKeyword: valObjKeyword});
		setSelectValue(e.target.value);
	}

	const initData=()=>{
		cFuncname 	= initData.name;
		var vTmpObj	= [];

		var vBulanNow	= uTglNowDT.getMonth() + 1;
		var vTahunNow	= uTglNowDT.getFullYear();

		for (var idx=vBulanNow-1;idx>=0;idx--) {
			vTmpObj.push({
				value: vTahunNow+"-"+UFunc.leadZero((idx+1))+"-01",
				caption:cBulanPanjang[idx].toUpperCase()+" "+vTahunNow,
			});
		}

		setDataObj(vTmpObj);
	};

	if(uDataObj.length <= 0) return (<></>);

	return (
		<CSubheader className="px-3 justify-content-center">
		<CRow className="w-100 align-items-center">
		<CCol sm="12" lg="4" className="">
			<strong>{obBahasa.word_dashboard || "Dashboard"}</strong>
		</CCol>

		<CCol sm="12" md="6" lg="4" className="d-md-down-none"/>
		<CCol sm="12" md="6" lg="4" className="d-flex flex-nowrap justify-content-end py-1">
			<CForm className="form-horizontal ">
			<CFormGroup row className="p-0 my-1">
			<CCol>
				<CInputGroup>
				<CSelect 
					value={uSelectValue} 
					onChange={hdlChangeFilter} 
					size="sm" 
					id="inpfiltertglsatu">
					{uDataObj.map((vItem,vKey)=>{
						return (<option key={vKey} value={vItem.value}>{vItem.caption}</option>)
					})}
				</CSelect>
				</CInputGroup>
			</CCol>
			</CFormGroup>
			</CForm>
		</CCol>
		</CRow>
		</CSubheader>
	);
}

export default Subdashboard;
	