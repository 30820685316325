import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CLink,
	CTooltip,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CFormGroup,
	CForm,
	CFormText,
	CInput,
	CLabel,
	CInputCheckbox,
	CText,
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

var pjson     = require('../../package.json');

const Role = (props) => {
	const cComponentName 	= Role.name;
	const {setLoading,showToast,prosesExpired}	= props;

	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);

	const uValueRole						= uKeywordInitObj.val_role && uKeywordInitObj.val_role;
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uSelectObj,setSelectObj]			= React.useState([]);
	const [uDisabledCheck,setDisabledCheck]	= React.useState(true);
	const [uHandleSelect,setHandleSelect]	= React.useState(false);

	React.useEffect(()=>{
		svcloadData();
	},[]);
	React.useEffect(()=>{
		setDisabledCheck(uValueRole==="0" || UFunc.isEmpty(uValueRole));
	},[uValueRole]);
	React.useEffect(()=>{
		svcloadValue(uValueRole);
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{
		//console.log("useEffect => uSelectObj: "+uSelectObj);
		if(uHandleSelect) svcProsesValue();
	},[uHandleSelect]);//*/

	const hdlSelectRole = (_EVN) => {
		_EVN.persist();
		const vIdx = uSelectObj.indexOf(_EVN.target.value.toString());

		if(_EVN.target.checked) {
			if(vIdx >= 0) return;
			if(uSelectObj== "") setSelectObj([_EVN.target.value]);
    		else setSelectObj([...uSelectObj, _EVN.target.value]);
		} else {
			if(vIdx < 0) return;
			setSelectObj(uSelectObj.filter((char, i) => i !== vIdx));
		}
		setHandleSelect(true);

		//console.log("hdlSelectRole => uSelectObj = "+uSelectObj);
	}
	const svcloadData = () => {
		var vFuncName = svcloadData.name;
		setHTMLError500();

		/*//---TEST_FRONTEND--/
		let vTmpObj = [
			{id:"1",name:"mainpembelian",subs: [{id:"4",name:"pembelian"},{id:"5",name:"hutang"},{id:"6",name:"biaya"}]},
			{id:"2",name:"mainpenjualan",subs: [{id:"7",name:"penjualan"},{id:"8",name:"piutang"},{id:"9",name:"stokopname"}]},
			{id:"3",name:"mainlaporan",subs: [{id:"10",name:"lappembelian"},{id:"11",name:"lappembelian"},{id:"12",name:"laplabarugi"}]},
		];
		setDataObj(vTmpObj);

		return;
		//---END TEST_FRONTEND--*/

		setDataObj([]);
		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_role/ld_datamenu";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {

				if(output_string.dataobject) {
					let vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}

			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const svcloadValue = (_VALUEROLE) => {
		var vFuncName = svcloadValue.name;

		console.log("("+cComponentName+") "+vFuncName+" ==> _VALUEROLE "+_VALUEROLE);
		setSelectObj([]);
		if(_VALUEROLE === "0" || UFunc.isEmpty(_VALUEROLE)) return;

		/*//---TEST_FRONTEND--/
		let vTmpObj;
		if(_VALUEROLE=="MANAJER") 
			vTmpObj = ["1","3","5","7","9"];
		else
			vTmpObj = ["2","4","6","8","10"];

		setSelectObj(vTmpObj);
		return;
		//---END TEST_FRONTEND--*/

		var vDATAS    = JSON.stringify({
			send_hakselect: _VALUEROLE,
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_role/ld_dataselect";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				if(output_string.valueselect) {
					let vTmpObj = JSON.parse(output_string.valueselect);
					setSelectObj(vTmpObj);
				}

			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			//cHistory.push("/error500");
		});
	}
	const svcProsesValue = () => {
		var vFuncName = svcloadData.name;

		if(!uHandleSelect) return;

		//console.log("("+vFuncName+") => uSelectObj: "+uSelectObj);
		//console.log("("+vFuncName+") => uSelectObj.join: "+uSelectObj.join(","));
		var vDATAS    = JSON.stringify({
			send_hakselect: uValueRole,
			send_valueselectlist: uSelectObj.join(","),
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_role/pr_dataselect";

		setDisabledCheck(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
		}).then((output_string) => {
			setDisabledCheck(false);
			setHandleSelect(false);
			if(output_string.sukses) {
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		}).catch((error) =>{
			setHandleSelect(false);
			setDisabledCheck(false);
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			cHistory.push("/error500");
		});
	}

	const loadTabelBody = ()=>{
		var vFuncName	= loadTabelBody.name;
		//console.log("loadTabelBody => uSelectObj: "+uSelectObj);
		return uDataObj.map((vItem,vKey)=>{
			return (
				<tr>
					<td align="right">{(vKey+1)+"."}</td>
					<td className="align-top">
					<CRow>
					<CCol sm="12" md="3" className="">
					<CFormGroup row key={vKey} variant="checkbox" className="mx-1 classradiobutton">
					<CInputCheckbox className="form-check-input" 
						name="inprole" 
						id={"inprole"+vItem.id} 
						disabled={uDisabledCheck}
						onChange={hdlSelectRole}
						checked={uSelectObj.includes(vItem.id)}
						value={vItem.id} />
					<CLabel variant="checkbox" 
						htmlFor={"inprole"+vItem.id} className="font-weight-bolder">
						{obBahasa["menus_"+vItem.name.toLowerCase()]}
					</CLabel>
					</CFormGroup>
					</CCol>
					
					<CCol sm="12" md="9" className="">
						{
						vItem.subs.map((vItemSub,vKeySub)=>{
							let vCheckedBl = uSelectObj.includes(vItemSub.id.toString());
							return (
								<CFormGroup row key={vKeySub} variant="checkbox" className="classradiobutton d-inline-block mr-4">
								<CInputCheckbox className="form-check-input" 
									name="inprole" 
									id={"inprole"+vItemSub.id} 
									disabled={uDisabledCheck}
									onChange={hdlSelectRole}
									checked={vCheckedBl}
									value={vItemSub.id} />
								<CLabel variant="checkbox" 
									htmlFor={"inprole"+vItemSub.id}>
								{obBahasa["menus_"+vItemSub.name.toLowerCase()]}
								</CLabel>
								</CFormGroup>
							)
						})
						}
					</CCol>
					</CRow>
					</td>
				</tr>
			);
		})
	}

	const loadContent = ()=>{
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return(
			<div className="table-responsive-sm">
			<table className="table" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top">
				<CRow>
				<CCol sm="12" md="3" className="">{obBahasa.caption_listmenu}</CCol>
				<CCol sm="12" md="9">&nbsp;</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}

	return (
		<>
		<CRow className="justify-content-center">
		<CCol sm="12">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa["menus_"+props.namaRoute.toLowerCase()]}</strong>
			</CCardHeader>
			<CCardBody>
				{loadContent()}
			</CCardBody>
			<CCardFooter>
				<div className="classpetunjuk">
				<span className="d-inline-block classikontabel classikoninfo"></span> 
				{obBahasa.petunjuk_role }
				</div>
			</CCardFooter>
		</CCard>
		</CCol>
		</CRow>
		</>
	);
}

export default Role;
