import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CText,
	CLink,
	CTooltip,
	CListGroup,
	CListGroupItem,
	CInput,
	CLabel,
	CButton,
	CButtonGroup,
	CBadge,
} from "@coreui/react";
import NumberFormat from 'react-number-format';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';

var pjson     = require('../../package.json');

const Pembelian = (props) => {
	const cComponentName 	= Pembelian.name;
	let cFuncName;
	const {setLoading,showToast,prosesExpired}	= props;

	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);
	const uListIDTabelInit					= useSelector(state => state.listTabelID);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_pembelian?uKeywordInitObj.kwd_pembelian:"");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);

	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);


	React.useEffect(()=>{ setKeywordInit(uKeywordInitObj.kwd_pembelian?uKeywordInitObj.kwd_pembelian:""); },[uKeywordInitObj]);
	React.useEffect(()=>{
		return ()=>{ setDataObj(null); }
	},[]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{ loadTabelBody(); },[uBodyInit]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setActivePage(uPageNow<=0?1:uPageNow);

		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);

	const svcloadData = () => {
		cFuncName = svcloadData.name;
		setHTMLError500("");

		//console.log("("+cComponentName+") "+cFuncName+" => Proses ");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",tgl_pembelian:"2020-11-10",no_nota:"XX73653",supplier_id:"1",supplier_nama:"PALAWIJA",total_bruto:"300000",diskon:"10000",
				items:[
				{nama_barang:"Barang 01",jml:"3",satuan:"KG",h_beli:"50000"},
				{nama_barang:"Barang 02",jml:"2",satuan:"KG",h_beli:"30000"},
				{nama_barang:"Barang 03",jml:"1",satuan:"KG",h_beli:"40000"}
				]},
		]
		setDataObj(vTmpObj);
		setJmlData((parseInt(vTmpObj.length)||0));
		setKeywordLoad(uKeywordInit);

		return;
		//--END TESTING_FRONTEND--*/

		setDataObj([]);
		setJmlData(0);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_pembelian/ld_data";

		//console.log("("+cComponentName+"-"+cFuncName+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}
	const hdlEditData = async(_IDTABEL) => {
		cFuncName = hdlEditData.name;

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		let vTmpObj	= uListIDTabelInit;
		vTmpObj["initidpembelian"]	= _IDTABEL;
		
		cDispatch({type: "set", listTabelID: vTmpObj});

		cHistory.push("/ipembelian");
		//console.log("("+cComponentName+"-"+cFuncName+") satuan = "+uDataObj[vObjdx].satuan);
		//---END INIT_FORM_EDIT--/
	}
	const hdlHapusData = async (_IDTABEL) => {
		cFuncName = hdlHapusData.name;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);

		setTabelRowSelect(vRowIdx);

		const svcFuncNext	= () => {
			/*//--TESTING_FRONTEND--/
			uDataObj.splice(vObjdx,1);
			setJmlData((parseInt(uDataObj.length)||0));
			
			setBodyInit(uBodyInit?false:true);
			return;
			//--END TESTING_FRONTEND--*/

			var vDATAS    = JSON.stringify({
				send_tabelid : _IDTABEL,
				send_tokenauth : objToken.userinit
			});
			var vURLs     = pjson.svcpage+"svc_pembelian/pr_hapus";

			setLoading(true);
			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST", mode: "cors",
				cache: "default", body: vDATAS,
			}).then((response)=> {
				if (response.status === 200) return response.json();
				//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
			}).then((output_string) => {
				setLoading(false);
				if(output_string.sukses) {
					uDataObj.splice(vObjdx,1);
					setTabelRowSelect(-1);
					setJmlData((parseInt(uDataObj.length)||0));
					setBodyInit(!uBodyInit);
				} else if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
					var vMsg;
					if(pjson.mydefault.environment=="development")
						vMsg = output_string.errors;
					else vMsg = pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					prosesExpired(cHistory);
				}
			}).catch((error) =>{
				setLoading(false);
				console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
				cHistory.push("/error500");
			});
		}

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+
			(uDataObj[vObjdx].tgl_pembelian+(uDataObj[vObjdx].no_nota!=""?" ("+uDataObj[vObjdx].no_nota+")":"")).toUpperCase()+
			"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcFuncNext();
	}

	const loadTabelBody	= () => {
		cFuncName	= loadTabelBody.name;
		//console.log("("+cComponentName+") "+cFuncName+" => uMaxData : "+uMaxData);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {
				id,tgl_pembelian,no_nota,supplier_nama,
				total_bruto,diskon,items,nama_user,
				status_hutang,terbayar
			} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				let vCaptionUpper	= (tgl_pembelian+
					((no_nota||"") !=="" ? " ("+no_nota+")" : "")
					).toUpperCase();

				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				const vSupplier= uKeywordLoad=="" ? (supplier_nama||"") 
					: (supplier_nama||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				let vTotalBruto	= "<div class='classfontlarger'>"+UFunc.formatAngka((parseInt(total_bruto)||0)-(parseInt(diskon)||0))+"</div>";
				let vDiskon		= 
					parseInt(diskon)||0 > 0 
					? ("<div class='text-danger classfontsmaller'>("+obBahasa.word_diskon+": Rp"+UFunc.formatAngka(diskon)+")</div>") : "";

				const vTanggal	= uKeywordLoad === "" ? (tgl_pembelian)
					: (tgl_pembelian||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				const vNonota	= uKeywordLoad === "" ? (no_nota||"")
					: (no_nota||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				const vInputer	= uKeywordLoad=="" ? (nama_user||"")
					: (nama_user||"").replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");

				let vStatusHutang	= (<div className="text-center"><CBadge color="success">{obBahasa.word_lunas}</CBadge></div>);
				if((status_hutang||"").toUpperCase() != "LUNAS") {
					vStatusHutang = UFunc.formatAngka(terbayar);
				}

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="2" className="text-center p-0 classfontsmaller">
						{UFunc.renderHTML(vTanggal)}
						<div className="text-danger ">[{UFunc.renderHTML(vInputer)}]</div>
					</CCol>
					<CCol sm="12" md="2" className="px-1">
						<big className="text-primary">{UFunc.renderHTML(vSupplier)}</big>
						{(no_nota||"")!=="" && (
						<strong>({UFunc.renderHTML(vNonota)})</strong>
						)}
					</CCol>
					<CCol sm="12" md="3" lg="4" className="classfontsmaller px-1 classfontitalic text-left">
					<CListGroup className="list-group-flush">{
						items.map((vItemdetil,vKeyDetil) => {
							let vBarangNama= uKeywordLoad=="" ? vItemdetil.nama_barang 
								: vItemdetil.nama_barang.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
							return (
								<CListGroupItem className="classlireguler" key={vKeyDetil}>
								<CRow>
								<CCol md="1" className="text-right px-0 pr-1">{(vKeyDetil+1)}.</CCol>
								<CCol md="11" className="px-0">
								{UFunc.renderHTML(vBarangNama)} (<strong>{vItemdetil.jml} {vItemdetil.satuan}</strong> x Rp
								<span className="text-success"> {UFunc.formatAngka(vItemdetil.h_beli)}</span>)
								</CCol>
								</CRow>
								</CListGroupItem>
							);
						})
					}
					</CListGroup>
					</CCol>
					<CCol sm="12" md="2" className="text-right px-1">{UFunc.renderHTML(vTotalBruto+vDiskon)}</CCol>
					<CCol sm="12" md="1" className="text-right text-success px-1">{vStatusHutang}</CCol>
					<CCol sm="12" md="2" lg="1" className="text-center px-1">
						<CTooltip html={false} content={"--"+obBahasa.caption_editdata+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlEditData(id)} className="classikontabel classikonedit" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"+(objToken.userhak!="FREEROLE"&&objToken.userhak!="MANAJER" ? " d-none" : "") }
							onClick={()=>hdlHapusData(id)} />
						</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}
	const loadContent = () => {
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return (
			<div className="table-responsive-sm" xs="12">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.caption_tglpembelian}</CCol>
				<CCol sm="12" md="2" className="text-left px-0">{obBahasa.word_supplier}</CCol>
				<CCol sm="12" md="3" lg="4" className="text-left px-0">{obBahasa.caption_detilpembelian}</CCol>
				<CCol sm="12" md="2" className="text-center px-0">{obBahasa.word_total} (Rp)</CCol>
				<CCol sm="12" md="1" className="text-center px-0">{obBahasa.word_terbayar} (Rp)</CCol>
				<CCol sm="12" md="2" lg="1">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}

	return (
		<>
		<CRow className="p-0 justify-content-center">
		<CCol xs="12" className="p-0">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa.caption_listpembelian}</strong>
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlData >= uMaxData) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>
		</>
	)
}

export default Pembelian;
