import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	CButton,
	CButtonGroup,
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCol,
	CRow,
	CPagination,
	CLink,
	CTooltip,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CFormGroup,
	CForm,
	CFormText,
	CSelect,
	CInput,
	CLabel,
	CTextarea,
	CText,
} from "@coreui/react";
import NumberFormat from 'react-number-format';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { DialogForm } from '../components/index';


var pjson     = require('../../package.json');

const Barang = (props) => {
	const cComponentName 	= Barang.name;
	let cFuncName;
	const {setLoading,showToast,prosesExpired}	= props;

	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	cDispatch({type: "set", activeRoute: props.namaRoute});
	const obBahasa  = useSelector(state => state.listBahasa);
	const objToken	= JSON.parse(localStorage.getItem("token"));

	let vElJmlData = document.getElementById("idjmldata");
	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const uisHeaderExecuteBl				= useSelector(state => state.isHeaderExecute);
	const uisKlikTambah						= useSelector(state => state.isKlikTambah);
	const uKeywordInitObj					= useSelector(state => state.listKeyword);
	const uMaxData							= useSelector(state => state.gMaxLaporan);

	const [uKeywordInit,setKeywordInit]		= React.useState(uKeywordInitObj.kwd_barang?uKeywordInitObj.kwd_barang:"");
	const [uKeywordLoad,setKeywordLoad]		= React.useState("");
	const [uDataObj,setDataObj]				= React.useState([]);
	const [uJenisBarangObj,setJenisBarangObj]= React.useState([{id:"0",caption:"--"+(obBahasa.caption_dataempty||"").toUpperCase()+"--"}]);

	const [uDialogHeader,setDialogHeader]	= React.useState("");
	const [uPageNow,setActivePage] 			= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uBodyInit,setBodyInit]			= React.useState(false);
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);

	const [uJenisIDDefault,setJenisIDDefault]= React.useState("0");
	const [uJenisCaptionDefault,setJenisCaptionDefault]= React.useState("");

	const [uInpTabelID,setInpTabelID]		= React.useState(0);
	const [uInpNamabarang,setInpNamabarang]	= React.useState("");
	const [uInpSatuan,setInpSatuan]			= React.useState("");
	const [uInpJenisID,setInpJenisID]		= React.useState(0);
	const [uInpJenisBarang,setInpJenisBarang]= React.useState("");
	const [uInpHargajual,setInpHargajual]	= React.useState("");
	const [uInpHargabeli,setInpHargabeli]	= React.useState("");

	//---DIALOG_HANDLE:Harus Selalu ADA--/
	const ElBtnSimpan	= document.getElementById("btnDialogSimpan");
	const toggleForm 	= () => { setShowForm(!isShowForm); }
	const hdlLastFieldKDown = (EV)=>{ if(EV.which == 13) {
		EV.preventDefault();
		ElBtnSimpan.focus();
	}}
	React.useEffect(()=>{
		if(uisKlikTambah) {
			setTabelRowSelect(-1);

			setInpTabelID(0);
			setInpNamabarang("");
			setInpSatuan("");
			setInpJenisID(uJenisIDDefault);
			setInpJenisBarang(uJenisCaptionDefault);
			setInpHargajual("");
			setInpHargabeli("");

			setDialogHeader(obBahasa.word_tambah+" "+obBahasa["menus_"+props.namaRoute.toLowerCase()]);
			cDispatch({type: "set", isKlikTambah: false});

			setShowForm(true);
			//document.getElementById("inpnamabarang").focus();
		}
	},[uisKlikTambah]);
	//---END DIALOG_HANDLE--/

	React.useEffect(()=>{
		return ()=>{
			setDataObj([]);
		}
	},[]);
	React.useEffect(()=>{

		setKeywordInit(uKeywordInitObj.kwd_barang?uKeywordInitObj.kwd_barang:"");
	},[uKeywordInitObj]);
	React.useEffect(()=>{
		setTabelRowSelect(-1);
		svcloadData();
	},[uisHeaderExecuteBl]);
	React.useEffect(()=>{

		loadTabelBody();
	},[uBodyInit]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		setActivePage(uPageNow<=0?1:uPageNow);

		//console.log("("+cComponentName+") useEffect => uJmlData : "+uJmlData);
		if(vElJmlData) vElJmlData.innerHTML = UFunc.formatAngka(uJmlData);
	},[uJmlData]);
	React.useEffect(()=>{
		cFuncName = "useEffect";
		//---CEK_JENIS_BARANG--/
		setJenisIDDefault(uJenisBarangObj[0].id && uJenisBarangObj[0].id);
		setJenisCaptionDefault(uJenisBarangObj[0].caption && uJenisBarangObj[0].caption);
		//---END CEK_JENIS_BARANG--/

		//console.log("("+cComponentName+") "+cFuncName+" => uJenisBarangObj : "+uJenisBarangObj[0].id);
	},[uJenisBarangObj]);

	const svcloadData = () => {
		cFuncName = svcloadData.name;
		setHTMLError500("");

		//console.log("("+cComponentName+") "+cFuncName+" => Proses ");

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",nama_barang:"Palawija 1",satuan:"PCS",id_barang_jenis:"1",barang_jenis:"PALAWIJA",harga_beli:"30000",harga_jual:"50000"},
			{id:"2",nama_barang:"Sembako 1",satuan:"PCS",id_barang_jenis:"2",barang_jenis:"SEMBAKO",harga_beli:"30000",harga_jual:"50000"},
			{id:"3",nama_barang:"Tepunga 1",satuan:"KG",id_barang_jenis:"3",barang_jenis:"TEPUNG",harga_beli:"30000",harga_jual:"50000"},
			{id:"4",nama_barang:"Palawija 2",satuan:"TON",id_barang_jenis:"1",barang_jenis:"PALAWIJA",harga_beli:"30000",harga_jual:"50000"},
			{id:"5",nama_barang:"Sembako 2",satuan:"GRAM",id_barang_jenis:"2",barang_jenis:"SEMBAKO",harga_beli:"30000",harga_jual:"50000"},
			{id:"6",nama_barang:"Tepunga 2",satuan:"KG",id_barang_jenis:"3",barang_jenis:"TEPUNG",harga_beli:"30000",harga_jual:"50000"},
			{id:"7",nama_barang:"Palawija 3",satuan:"KG",id_barang_jenis:"1",barang_jenis:"PALAWIJA",harga_beli:"30000",harga_jual:"50000"},
			{id:"8",nama_barang:"Sembako 3",satuan:"GRAM",id_barang_jenis:"2",barang_jenis:"SEMBAKO",harga_beli:"30000",harga_jual:"50000"},
			{id:"9",nama_barang:"Tepunga 3",satuan:"KG",id_barang_jenis:"3",barang_jenis:"TEPUNG",harga_beli:"30000",harga_jual:"50000"},
			{id:"10",nama_barang:"Palawija 4",satuan:"GRAM",id_barang_jenis:"1",barang_jenis:"PALAWIJA",harga_beli:"30000",harga_jual:"50000"},
			{id:"11",nama_barang:"Sembako 4",satuan:"KG",id_barang_jenis:"2",barang_jenis:"SEMBAKO",harga_beli:"30000",harga_jual:"50000"},
			{id:"12",nama_barang:"Tepunga 4",satuan:"KG",id_barang_jenis:"3",barang_jenis:"TEPUNG",harga_beli:"30000",harga_jual:"50000"},
		]
		setDataObj(vTmpObj);
		setJmlData((parseInt(vTmpObj.length)||0));

		vTmpObj = [
			{id:"1",caption:"Palawija"},
			{id:"2",caption:"Sembako"},
			{id:"3",caption:"Tepung"},
		];
		setJenisBarangObj(vTmpObj);

		return;
		//--END TESTING_FRONTEND--*/

		if(objToken.userinit == "") {
			setLoading(true);
			prosesExpired();
			return;
		}

		setDataObj([]);
		setJmlData(0);
		var vDATAS    = JSON.stringify({
			send_keyword : uKeywordInit,
			send_tokenauth : objToken.userinit
		});
		setKeywordLoad(uKeywordInit);
		var vURLs     = pjson.svcpage+"svc_barang/ld_data";

		//console.log("("+cComponentName+"-"+cFuncName+") [0] uKeywordInit "+uKeywordInit);

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			if(output_string.tampil) {
				if(output_string.jenisobject) {
					let vTmpObjs = JSON.parse(output_string.jenisobject);
					setJenisBarangObj(vTmpObjs);
				}

				let vTmpObj = [];
				if(output_string.dataobject) {
					vTmpObj = JSON.parse(output_string.dataobject);
					setDataObj(vTmpObj);
				}
				setJmlData((parseInt(vTmpObj.length)||0));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			setLoading(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
			//cHistory.push("/error500");
		});
	}
	const hdlSimpan = () => {
		cFuncName = hdlSimpan.name;

		//---VALIDASI--/
		if(uInpNamabarang.trim() == "") {
			showToast(obBahasa.caption_barangnama+" "+obBahasa.caption_mustfilled+".."); 
			document.getElementById("inpnamabarang").focus();
			return;
		}
		//---END VALIDASI--/

		/*//---TESTING_FRONTEND--/
		if(uInpTabelID == 0) {
			var vTmpObject	= {
				id: "255",
				nama_barang : uInpNamabarang.trim(),
				satuan : uInpSatuan.trim(),
				barang_jenis : UFunc.nl2br(uInpJenisBarang.trim()),
				id_barang_jenis : uInpJenisID || "0",
				harga_beli : uInpHargabeli,
				harga_jual : uInpHargajual,
			}
			var vDataObjNew = [vTmpObject].concat(uDataObj);
		} else {
			var vDataObjNew = uDataObj;
			var vObjdx		= UFunc.getObjectIndeks(vDataObjNew,uInpTabelID);

			vDataObjNew[vObjdx].nama_barang 	= uInpNamabarang.trim();
			vDataObjNew[vObjdx].satuan 			= uInpSatuan.trim();
			vDataObjNew[vObjdx].barang_jenis 	= UFunc.nl2br(uInpJenisBarang.trim());
			vDataObjNew[vObjdx].id_barang_jenis = uInpJenisID || "0";
			vDataObjNew[vObjdx].harga_beli 		= uInpHargabeli;
			vDataObjNew[vObjdx].harga_jual 		= uInpHargajual;
		}

		console.log("("+cComponentName+") "+cFuncName+" => uInpHargajual : "+uInpHargajual);
		setDataObj(vDataObjNew);

		setInpTabelID(0)
		setInpNamabarang("");
		setInpSatuan("");
		setInpJenisBarang(uJenisCaptionDefault);
		setInpJenisID(uJenisIDDefault);
		setInpHargajual("");
		setInpHargabeli("");

		if(uInpTabelID > 0) {
			setBodyInit(!uBodyInit);
			toggleForm();
		} else {
			showToast(obBahasa.pesan_sukses,"SUKSES");
			setActivePage(1);
		}

		return;
		//---END TESTING_FRONTEND--*/

		if(objToken.userinit == "") {
			setLoading(true);
			prosesExpired(cHistory);
			return;
		}
		
		var vDATAS    = JSON.stringify({
			send_tabelid 	: uInpTabelID,
			send_namabarang : uInpNamabarang,
			send_satuan 	: uInpSatuan,
			send_jenisteks 	: uInpJenisBarang,
			send_jenisid	: uInpJenisID,
			send_hargajual	: uInpHargajual,
			send_hargabeli	: uInpHargabeli,
			send_tokenauth 	: objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_barang/pr_simpan";

		//console.log("("+cComponentName+") "+cFuncName+" => uInpJenisID : "+uInpJenisID); return;

		setLoading(true);
		ElBtnSimpan.disabled = true;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			ElBtnSimpan.disabled = false;
			setLoading(false);
			if(output_string.sukses) {
				//---CONCAT_TO_CURRENT_DATA_OBJ--/
				if(uInpTabelID == 0) {
					var vTmpObject	= {
						id: output_string.tabelid || "255",
						nama_barang : uInpNamabarang.trim(),
						satuan : uInpSatuan.trim().toUpperCase(),
						barang_jenis : UFunc.nl2br(uInpJenisBarang.trim()),
						id_barang_jenis : uInpJenisID || "0",
						harga_beli : uInpHargabeli,
						harga_jual : uInpHargajual,
					}
					var vDataObjNew = [vTmpObject].concat(uDataObj);
				} else {
					var vDataObjNew = uDataObj;
					var vObjdx		= UFunc.getObjectIndeks(vDataObjNew,uInpTabelID);

					vDataObjNew[vObjdx].nama_barang	= uInpNamabarang.trim();
					vDataObjNew[vObjdx].satuan 		= uInpSatuan.trim();
					vDataObjNew[vObjdx].barang_jenis= UFunc.nl2br(uInpJenisBarang.trim());
					vDataObjNew[vObjdx].id_barang_jenis= uInpJenisID || "0";
					vDataObjNew[vObjdx].harga_beli 		= uInpHargabeli;
					vDataObjNew[vObjdx].harga_jual 		= uInpHargajual;
				}

				setDataObj(vDataObjNew);
				setInpTabelID(0)
				setInpNamabarang("");
				setInpSatuan("");
				setInpJenisID(uJenisIDDefault);
				setInpJenisBarang(uJenisCaptionDefault);
				setInpHargajual("");
				setInpHargabeli("");
				//---END CONCAT_TO_CURRENT_DATA_OBJ--/

				if(uInpTabelID > 0) {
					setBodyInit(!uBodyInit);
					toggleForm();
				}  else {
					setJmlData((parseInt(vDataObjNew.length)||0));
					setActivePage(1);
					document.getElementById("inpnamabarang").focus();
					showToast(obBahasa.pesan_sukses,"SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				ElBtnSimpan.disabled = true;
				prosesExpired(cHistory);
			}
		}).catch((error) =>{
			ElBtnSimpan.disabled = false;
			setLoading(false);
			console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
			//setHTMLError500(pjson.mydefault.msg500str.join(" "));
			cHistory.push("/error500");
		});
	}
	const hdlEditData = async(_IDTABEL) => {
		cFuncName = hdlEditData.name;

		//---INIT_FORM_EDIT--/
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		setInpTabelID(_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);
		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		setDialogHeader(obBahasa.caption_editdata+": "+uDataObj[vObjdx].nama_barang.toUpperCase());

		//console.log("("+cComponentName+"-"+cFuncName+") satuan = "+uDataObj[vObjdx].satuan);

		setInpSatuan(uDataObj[vObjdx].satuan);
		setInpNamabarang(uDataObj[vObjdx].nama_barang);
		setInpJenisID((uDataObj[vObjdx].id_barang_jenis=="0")?uJenisIDDefault:uDataObj[vObjdx].id_barang_jenis);
		setInpJenisBarang(UFunc.br2nl(uDataObj[vObjdx].barang_jenis));
		setInpHargabeli(uDataObj[vObjdx].harga_beli);
		setInpHargajual((uDataObj[vObjdx].harga_jual));
		//---END INIT_FORM_EDIT--/

		await setShowForm(true);
	}
	const hdlHapusData = async (_IDTABEL) => {
		cFuncName = hdlHapusData.name;

		var vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		var vObjdx	= UFunc.getObjectIndeks(uDataObj,_IDTABEL);

		setTabelRowSelect(vRowIdx);

		const svcFuncNext	= () => {
			/*//--TESTING_FRONTEND--/
			uDataObj.splice(vObjdx,1);
			setJmlData((parseInt(uDataObj.length)||0));
			
			setBodyInit(uBodyInit?false:true);
			return;
			//--END TESTING_FRONTEND--*/

			if(objToken.userinit == "") {
				setLoading(true);
				prosesExpired(cHistory);
				return;
			}

			var vDATAS    = JSON.stringify({
				send_tabelid : _IDTABEL,
				send_tokenauth : objToken.userinit
			});
			var vURLs     = pjson.svcpage+"svc_barang/pr_hapus";

			setLoading(true);
			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST", mode: "cors",
				cache: "default", body: vDATAS,
			}).then((response)=> {
				if (response.status === 200) return response.json();
				//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
			}).then((output_string) => {
				setLoading(false);
				if(output_string.sukses) {
					uDataObj.splice(vObjdx,1);
					setTabelRowSelect(-1);
					setJmlData((parseInt(uDataObj.length)||0));
					setBodyInit(!uBodyInit);
				} else if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+cComponentName+"-"+cFuncName+") output_string.errors : "+output_string.errors);
					var vMsg;
					if(pjson.mydefault.environment=="development")
						vMsg = output_string.errors;
					else vMsg = pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					prosesExpired(cHistory);
				}
			}).catch((error) =>{
				setLoading(false);
				console.log("("+cComponentName+"-"+cFuncName+") catch-error: "+error);
				cHistory.push("/error500");
			});
		}

		let vConfirm	= 
		obBahasa.caption_hapusdata+": <B>"+uDataObj[vObjdx].nama_barang.toUpperCase()+"</B>"+
		"<BR>"+obBahasa.petunjuk_hapus+
		"<BR><BR><B>"+obBahasa.confirm_hapus+"</B>"+
		"";

		if(await Konfirm(vConfirm)) svcFuncNext();
	}

	const loadTabelBody	= () => {
		cFuncName	= loadTabelBody.name;
		//console.log("("+cComponentName+") "+cFuncName+" => uPageNow : "+uPageNow);
		//console.log("("+cComponentName+") "+cFuncName+" => uMaxData : "+uMaxData);

		var vMin= (uPageNow-1)*uMaxData;
		var vMax= (vMin+uMaxData)-1;
		var vIdxRow	= -1;
		return uDataObj.map((vItem,vKey)=>{
			const {id,nama_barang,satuan,barang_jenis,id_barang_jenis,harga_beli,harga_jual} = vItem;

			if(vKey>=vMin && vKey<=vMax) {
				vIdxRow++;
				let vCaptionUpper	= nama_barang.toUpperCase();

				var vReplace 		= new RegExp(uKeywordLoad,"ig");
				let vNamaBarang= uKeywordLoad=="" ? nama_barang 
					: nama_barang.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>");
				let vSatuan= satuan != "" 
					? (uKeywordLoad=="" ? satuan : satuan.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>"))
					: "-";

				let vBarangJenis=
					barang_jenis!= "" 
					? (uKeywordLoad=="" ? barang_jenis : barang_jenis.replace(vReplace,"<strong><U>"+uKeywordLoad.toUpperCase()+"</U></strong>"))
					: "-";
				vBarangJenis = id_barang_jenis==="0"?"-":vBarangJenis;

				return (
				<tr id={"idtr"+id} key={vKey} className={(uTabelRowSelect===vIdxRow?"classrowselect":"")}>
					<td align="right">{(vKey+1)+"."}</td>
					<td>
					<CRow>
					<CCol sm="12" md="3" className="text-primary">{UFunc.renderHTML(vNamaBarang)}</CCol>
					<CCol sm="12" md="2" className="text-center">{UFunc.renderHTML(vBarangJenis.toUpperCase())}</CCol>
					<CCol sm="12" md="1" className="classfontsmaller text-center">{(vSatuan)}</CCol>
					<CCol sm="12" md="2" className="text-right">{UFunc.formatAngka(harga_beli)}</CCol>
					<CCol sm="12" md="2" className="text-right text-success font-weight-bolder">{UFunc.formatAngka(harga_jual)}</CCol>
					<CCol sm="12" md="2" className="text-center">
						<CTooltip html={false} content={"--"+obBahasa.caption_editdata+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlEditData(id)} className="classikontabel classikonedit" />
						</CTooltip>
						<CTooltip content={"--"+obBahasa.caption_hapusdata+": "+vCaptionUpper}>
						<CLink 
							className={"classikontabel classikonhapus"+(objToken.userhak!="FREEROLE"&&objToken.userhak!="MANAJER" ? " d-none" : "") }
							onClick={()=>hdlHapusData(id)} />
						</CTooltip>
					</CCol>
					</CRow>
					</td>
				</tr>
				)
			}

		});
	}
	const loadContent = () => {
		if(uHTMLError500)
			return ( <div dangerouslySetInnerHTML={{__html: uHTMLError500 }} /> );

		return (
			<div className="table-responsive-sm">
			<table className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead className="d-sm-down-none">
			<tr>
			<th width="5%" className="align-top text-center">No</th>
			<th className="align-top text-center">
				<CRow>
				<CCol sm="12" md="3" className="text-left">{obBahasa.caption_barangnama}</CCol>
				<CCol sm="12" md="2" className="text-center">{obBahasa.caption_barangjenis}</CCol>
				<CCol sm="12" md="1" className="">{obBahasa.word_satuan}</CCol>
				<CCol sm="12" md="2" className="text-center">{obBahasa.caption_hargabeli} (Rp)</CCol>
				<CCol sm="12" md="2" className="text-center">{obBahasa.caption_hargajual} (Rp)</CCol>
				<CCol sm="12" md="2">#</CCol>
				</CRow>
			</th>
			</tr>
			</thead>
			<tbody>
			{loadTabelBody()}
			</tbody>
			</table>
			</div>
		);
	}
	const loadContentForm	= () => {
		return (
			<>
			<CRow className="justify-content-center">
			<CCol sm="12" md="11">
			<CForm>
				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_barangnama}</CLabel>
				</CCol>
				<CCol xs="12" md="8">
					<CInput maxLength="100"
						type="text"
						className="form-control"
						value={uInpNamabarang}
						onChange={(e) => setInpNamabarang(e.target.value)}
						onKeyDown={(e) => hdlLastFieldKDown(e)} 
						id="inpnamabarang" />
					<div className="classpetunjuk">*) {obBahasa.petunjuk_namabarang}</div>
				</CCol>
				</CFormGroup>

				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.word_satuan}</CLabel>
				</CCol>
				<CCol xs="12" md="2">
					<CInput maxLength="10"
						type="text"
						value={uInpSatuan}
						style={{textTransform:"uppercase"}}
						onChange={(e) => setInpSatuan(e.target.value)}
						id="inpsatuan" />
				</CCol>
				</CFormGroup>

				<CFormGroup row >
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_barangjenis}</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<CSelect custom name="inpjenisbarangid" id="inpjenisbarangid"
						value={uInpJenisID}
						onChange={(e) => {
							var vSelectId = e.target.value;
							var vIdx = UFunc.getObjectIndeks(uJenisBarangObj,vSelectId);

							setInpJenisID(vSelectId);
							setInpJenisBarang(vIdx>=0 && uJenisBarangObj[vIdx].caption);
						}}>
						{uJenisBarangObj.map((vItemJenis,vKeyJenis)=>{
							return (
							<option value={vItemJenis.id} key={vKeyJenis}>{vItemJenis.caption.toUpperCase()}</option>
							)
						})}
					</CSelect>
				</CCol>
				</CFormGroup>

				<CFormGroup row className={uInpTabelID<=0?"d-none":""}>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_hargabeli} (Rp)</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<NumberFormat 
						value={uInpHargabeli}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpHargabeli(values.value)}
						renderText={value => (
							<CInput 
								value={value}
								className={"text-right"}
								onFocus={(e)=>e.target.select()}
								disabled={uInpTabelID.toString()==="0"}
								onChange={(e) => setInpHargabeli(e.target.value)}
							id="inphargabeli"/>
						)} 
					prefix={'Rp'}/>
				</CCol>
				</CFormGroup>

				<CFormGroup row>
				<CCol xs="12" md="4">
					<CLabel>{obBahasa.caption_hargajual} (Rp)</CLabel>
				</CCol>
				<CCol xs="12" md="4">
					<NumberFormat 
						value={uInpHargajual}
						displayType={'text'} 
						thousandSeparator={"."} 
						decimalSeparator={","}
						onValueChange={(values) => setInpHargajual(values.value)}
						renderText={values => (
							<CInput 
								value={values}
								className="text-right"
								onChange={(e) => setInpHargajual(e.target.value)}
								onFocus={(e)=>e.target.select()}
								onKeyDown={(e) => hdlLastFieldKDown(e)}
							id="inphargajual"/>
						)} 
						prefix={'Rp'}/>
				</CCol>
				</CFormGroup>

			</CForm>
			</CCol>
			</CRow>
			</>
		);
	}

	return (
		<>
		<CRow className="justify-content-center">
		<CCol sm="12">
		<CCard id="idtabcard">
			<CCardHeader>
			<strong>{obBahasa["menus_"+(props.namaRoute||"").toLowerCase()]}</strong>
			</CCardHeader>
			<CCardBody>
			{loadContent()}
			</CCardBody>
			{(uJmlData >= uMaxData) && (
			<CCardFooter>
				<CPagination
					activePage={uPageNow}
					pages={uJmlHal}
					onActivePageChange={(i) => setActivePage(i)}
				></CPagination>
			</CCardFooter>
			)}
		</CCard>
		</CCol>
		</CRow>

		<DialogForm
			options={{size:"lg",centered:false}}
			onSimpan={()=>hdlSimpan()}
			show={isShowForm} 
			dialogHeader={uDialogHeader}
			toggle={toggleForm}
			renderContent={loadContentForm()}/>
		</>
	)
}

export default Barang;
	