import React from 'react';
import { useDispatch } from 'react-redux';
import { confirmable } from 'react-confirm';
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CRow
} from '@coreui/react';
import PropTypes from 'prop-types';
import { UFunc } from "../helpers/functions";

const TheDialog = props => {
	const cComponentname	= TheDialog.name;
	const { show, proceed, dialogContent, dialogHeader,options } = props;

	let vBtnExecute	= options.tipe=="KONFIRM" ? "Ok" : "Simpan";
	let vTeksHeader	= (dialogHeader=="" || options.tipe=="KONFIRM") ? "Konfirmasi" : dialogHeader;
	let vTeksIsi	= dialogContent=="" ? "{UNDEFINED}" : dialogContent;
	const [uInitConfirmLoad,setInitConfirmLoad] = React.useState(true);

	const vElBtnOK	= document.getElementById("btnconfirmok");

	React.useEffect(()=>{
		if(uInitConfirmLoad) { setInitConfirmLoad(!uInitConfirmLoad); } 
	},[uInitConfirmLoad]);
	React.useEffect(()=>{
		if(!UFunc.isEmpty(vElBtnOK)) {
			var vFocus = setTimeout(function(e){
				vElBtnOK.focus();
				clearTimeout(vFocus);
			},200);	
		}
	},[vElBtnOK]);//-*/

	const HdlClose=()=>{ proceed(false); }

	const renderHTML = (escapedHTML: string) => 
		React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

	return (
		<CModal color={options.tipe=="KONFIRM"? "warning" : "info"} 
			show={show} 
			onClose={HdlClose}>
		<CModalHeader closeButton>
			<CModalTitle>{vTeksHeader}</CModalTitle>
		</CModalHeader>

		<CModalBody>{renderHTML(vTeksIsi)}</CModalBody>
		<CModalFooter>
		<CButton color={options.tipe=="KONFIRM"? "warning" : "info"} 
			id="btnconfirmok"
			onClick={()=>proceed(true)}
			className="font-weight-bolder">{vBtnExecute}</CButton>{' '}
		<CButton color="secondary" onClick={HdlClose}>Cancel</CButton>
		</CModalFooter>
		</CModal>
	);
}

TheDialog.defaultProps = {
  dialogContent: "{UNDEFINED}..",
  dialogHeader: "Konfirmasi",
  options: {tipe:"KONFIRM"},
};

TheDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  dialogContent: PropTypes.string,  // arguments of your confirm function
  dialogHeader: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(TheDialog);