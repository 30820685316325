import React,{ Fragment } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import {
	CInput,
	CListGroup,
	CListGroupItem,
} from '@coreui/react';
 
const MAutocomplete = props => {
	const cComponentName 	= MAutocomplete.name;

	const { options, onPilih } 				= props;
	const cDispatch							= useDispatch();
	const uTextAutoSelect					= useSelector(state => state.gTeksAutoSelect);

	const [activeOption,setactiveOption]	= React.useState(0);
	const [filteredOptions,setfilteredOptions]= React.useState([]);
	const [showOptions,setshowOptions]		= React.useState(false);
	const [userInput,setuserInput]			= React.useState(uTextAutoSelect && uTextAutoSelect||"");
	//const [uInitKlik,setInitKlik]			= React.useState(uTextAutoSelect && uTextAutoSelect||"");

	const HdlChange = (e) => {
		const vuserInput = e.currentTarget.value;

		const vfilteredOptions = options.filter(
			(option) => option.toLowerCase().indexOf(vuserInput.toLowerCase()) > -1
		);

		setactiveOption(0);
		setfilteredOptions(vfilteredOptions);
		setshowOptions(true);
		//setInitKlik(false);

		setuserInput(e.currentTarget.value);
		cDispatch({type: 'set', gTeksAutoSelect: e.currentTarget.value});
	}

	const HdlKlik = (e) => {
		//setInitKlik(true);
		setuserInput(e.currentTarget.innerText);
		cDispatch({type: 'set', gTeksAutoSelect: e.currentTarget.innerText});

		//console.log("("+cComponentName+") HdlKlik = "+e.currentTarget.innerText);

		onPilih();

		setactiveOption(0);
		setfilteredOptions([]);
		setshowOptions(false);
	}

	const HdlKeyDown = (e) => {
		//console.log("("+cComponentName+") HdlKeyDown = "+e.keyCode);

		if (e.keyCode === 13) {
			if(userInput == "") {
				setactiveOption(0);
				setshowOptions(false);	
				setfilteredOptions([]);
				return;
			}
			setuserInput(filteredOptions[activeOption]);
			cDispatch({type: 'set', gTeksAutoSelect: filteredOptions[activeOption]});

			setactiveOption(0);
			setshowOptions(false);
			setfilteredOptions([]);
		} else if (e.keyCode === 38) {
			if (activeOption === 0) { return; }
			setactiveOption(activeOption - 1);
		} else if (e.keyCode === 27) {
			setactiveOption(0);
			setshowOptions(false);
			setfilteredOptions([]);
		} else if (e.keyCode === 40) {
			if (activeOption - 1 === filteredOptions.length) { return; }
			setactiveOption(activeOption + 1);
		}
	}
	const HdlBlur = (e)=>{
		//console.log("("+cComponentName+") HdlBlur uInitKlik(1) = "+uInitKlik);

		console.log("("+cComponentName+") HdlBlur userInput = "+userInput);
		console.log("("+cComponentName+") HdlBlur activeOption = "+activeOption);
		console.log("("+cComponentName+") HdlBlur filteredOptions[activeOption] = "+filteredOptions[activeOption]);

		return;

		//setshowOptions(false);
		//HdlKlik(e);
	}

	let optionsListComponent;

	//console.log("("+cComponentName+") options = "+options);

	if (showOptions && userInput) {
		if (filteredOptions.length) {
			optionsListComponent = (
				<CListGroup className="classautocomplete" id="idautocomplete">
				{filteredOptions.map((option, index) => {

					let vColor;
					if (index === activeOption) {
						vColor = "warning";
					}

					return (
						<CListGroupItem className="py-2 font-weight-bold classautocompletelist"
							color={vColor} key={index}
							onClick={HdlKlik}>
						{option}
						</CListGroupItem>
					);
				})}
				</CListGroup>
			);
		} else {
			optionsListComponent = (
				<div className="classautocomplete" id="idautocomplete">
				<em>No options!</em>
				</div>
			);
		}
	}

	return (
		<Fragment>
		<CInput
			type="text"
			onChange={HdlChange}
			onKeyDown={HdlKeyDown}
			value={userInput}
			{...props}/>
		{optionsListComponent}
		</Fragment>
	);
}
MAutocomplete.defaultProps = {
	options: [],
	onPilih: ()=>{},
};
MAutocomplete.propTypes = {
	options: PropTypes.instanceOf(Array),
	onPilih: PropTypes.func,
}
 
export default MAutocomplete;
	