import React from "react";
import { useSelector,useDispatch } from 'react-redux';
import { 
	useHistory,
} from 'react-router-dom';
import {
	CRow,
	CCol,
	CBreadcrumbRouter,
	CSubheader,
	CForm,
	CFormGroup,
	CInput,
	CInputGroupPrepend,
	CInputGroup,
	CButton,
	CLink,
	CSelect
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { konfirm } from '../../helpers/onConfirm';
import { UFunc } from "../../helpers/functions";

var pjson     = require('../../../package.json');

const Subrole	= (props) => {
	const cComponentName 	= Subrole.name;
	const {showToast,prosesExpired}		= props;
	const cDispatch			= useDispatch();
	const cHistory			= useHistory();

	const objToken			= JSON.parse(localStorage.getItem("token"));
	const obBahasa 			= useSelector(state => state.listBahasa);

	const uKeywordObj		= useSelector(state => state.listKeyword);
	const [uIsExecuteBl,setExecute]	= React.useState(useSelector(state => state.isHeaderExecute));

	const [uDataRoleObj,setDataRoleObj] = React.useState([]);
	const [uInprole,setInprole] 		= React.useState("");

	React.useEffect(()=>{
		svcLoadRole();
	},[]);

	React.useEffect(() => {
		const valObjKeyword	= (uInprole != "0") ? { val_role : uInprole } : {};

		cDispatch({type: "set", listKeyword: valObjKeyword});

		cDispatch({type: 'set', isHeaderExecute: !uIsExecuteBl});
		setExecute(!uIsExecuteBl);
	},[uInprole]);

	const svcLoadRole = () => {
		var vFuncName = svcLoadRole.name;

		//---TEST_FRONTEND--/
		let vTmpObj = [
			{value:"MANAJER",caption:"ROLE: "+obBahasa.["hakuser_"+"manajer"]},
			{value:"ENTRI",caption:"ROLE: "+obBahasa.["hakuser_"+"entri"]},
		];
		setDataRoleObj(vTmpObj);

		return;
		//---END TEST_FRONTEND--*/

		setDataRoleObj([]);
		var vDATAS    = JSON.stringify({
			send_tokenauth : objToken.userinit
		});
		var vURLs     = pjson.svcpage+"svc_role/ld_role";

		//console.log("("+cComponentName+"-"+vFuncName+") [0] uKeywordInit "+uKeywordInit);

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			//else { console.log("("+cComponentName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			if(output_string.tampil) {

				if(output_string.dataobject) {
					let vTmpObj = JSON.parse(output_string.dataobject);
					setDataRoleObj(vTmpObj);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+cComponentName+"-"+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		}).catch((error) =>{
			console.log("("+cComponentName+"-"+vFuncName+") catch-error: "+error);
			//cHistory.push("/error500");
		});
	}

	return (
		<CSubheader className="px-3 justify-content-center">
		<CRow className="w-100 align-items-center">
		<CCol sm="12" lg="4" className="d-md-down-none " />

		<CCol sm="12" md="6" lg="4" className="justify-content-center ">
			<CForm className="form-horizontal ">
			<CFormGroup row className="p-0 my-1">
			<CCol>
				<CInputGroup>
				<CSelect custom id="inprole" onChange={(e)=>setInprole(e.target.value)}>
					<option value="0">{"---"+(obBahasa.petunjuk_selectpilihrole || "").toUpperCase()+".."}</option>
					{uDataRoleObj.map((vItem,vKey)=>{
						return(
							<option value={vItem.value}>{vItem.caption}</option>
						)
					})}
				</CSelect>
				</CInputGroup>
			</CCol>
			</CFormGroup>
			</CForm>
		</CCol>
		</CRow>
		</CSubheader>
	);
}

export default Subrole;
