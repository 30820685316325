import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {
	Subuser,
	Subbarangjenis, 
	Subsupplier, 
	Subcustomer, 
	Subbiayajenis,
	Subrole,
	Subbarang,
	Subpembelian,
	Subhutang,
	Subbiaya,
	Substokopname,
	Subpenjualan,
	Subpiutang,
	Subdashboard,
} from './subnav/index';

const TheHeaderSubNav = (props) => {
	const cComponentName 	= TheHeaderSubNav.name;
	const cNamaroute		= useSelector(state => state.activeRoute);

	//console.log("("+cComponentName+") cNamaroute = "+cNamaroute);
	const loadContent = () => {
		switch((cNamaroute||"").toUpperCase()) {
		case "USER" :
			return ( <Subuser {...props} /> ); 
			break;
		case "BARANGJENIS" :
			return ( <Subbarangjenis {...props} /> ); 
			break;
		case "SUPPLIER" :
			return ( <Subsupplier {...props} /> ); 
			break;
		case "CUSTOMER" :
			return ( <Subcustomer {...props} /> ); 
			break;
		case "BIAYAJENIS" :
			return ( <Subbiayajenis {...props} /> ); 
			break;
		case "ROLE" :
			return ( <Subrole {...props} /> ); 
			break;
		case "BARANG" :
			return ( <Subbarang {...props} /> ); 
			break;
		case "PEMBELIAN" :
			return ( <Subpembelian {...props} /> ); 
			break;
		case "HUTANG" :
			return ( <Subhutang {...props} /> ); 
			break;
		case "BIAYA" :
			return ( <Subbiaya {...props} /> ); 
			break;
		case "STOKOPNAME" :
			return ( <Substokopname {...props} /> ); 
			break;
		case "PENJUALAN" :
			return ( <Subpenjualan {...props} /> ); 
			break;
		case "PIUTANG" :
			return ( <Subpiutang {...props} /> ); 
			break;
		case "DASHBOARD" :
			return ( <Subdashboard {...props} /> ); 
			break;//-*/
		default: return null;
		}
	}

	return (
		<>
		{loadContent()}
		</>
	);
}

export default TheHeaderSubNav;
