import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Konfirm } from '../helpers/onConfirm';
import {
	CBadge,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CRow,CCol,
	CImg,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
var pjson     = require('../../package.json');

const TheHeaderUserprofile = (props) => {
	var vFuncName	= TheHeaderUserprofile.name;

	var vHistori	= useHistory();
	var dispatch 	= useDispatch();
  	const obBahasa 	= useSelector(state => state.listBahasa);
    const { setLoading, showToast, setToken } 		= props;
	const tokenObj = JSON.parse(localStorage.getItem("token"));

	const ProsesLogout = () => {
		var vDATAS    = JSON.stringify({
			send_tokenauth : tokenObj.userinit,
			send_device	: pjson.name
		});
		var vURLs     = pjson.svcpage+"svc_login/pr_logout";

		setLoading(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> {
			if (response.status === 200) return response.json();
			else { console.log("("+vFuncName+") [1]: response "+response.statusText); }
		}).then((output_string) => {
			setLoading(false);
			//console.log("("+vFuncName+") output_string 2 "+(output_string));
			console.log("("+vFuncName+") output_string 1 "+JSON.stringify(output_string));
			if(output_string.sukses) {
				//dispatch({type: "set", msgStore: obBahasa.pesan_hasloggedout});
				//localStorage.setItem("msgApp",obBahasa.pesan_hasloggedout);
				//localStorage.removeItem("token");
				showToast(obBahasa.pesan_hasloggedout);
				setToken("");
				//vHistori.go("/");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+vFuncName+") output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment=="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
			}
		}) .catch((error) =>{
			setLoading(false);
			console.log("("+vFuncName+") catch-error: "+error);
			vHistori.push("/error500");
		});
	}

	const hLogoutClick = async() => {
		if(await Konfirm(obBahasa.confirm_logout)) ProsesLogout();
	}

	return (
	<CDropdown
		inNav
		className="c-header-nav-items mx-1"
		direction="down"
	>
	<CDropdownToggle className="c-header-nav-link" caret={false}>
	<div className="c-avatar">
	<CTooltip content={"&middot;&middot; "+(tokenObj.usercaption||"")+" [ "+(obBahasa["hakuser_"+(tokenObj.userhak||"").toLowerCase()]||"").toUpperCase()+" ] &middot;&middot;"}>
	<div className={"c-avatar-img classikonavatar classikon"+(tokenObj.userhak||"").toLowerCase()}/>
	</CTooltip>
	<CDropdownMenu className="pt-0" placement="bottom-end">
		<CDropdownItem
			header
			tag="div"
			color="light"
			className="text-center">
			<CRow className="justify-content-center mb-2">
          		<CIcon name="cil-user" height={30} />
			</CRow>
			<strong style={{fontSize:"larger"}}>{tokenObj && (tokenObj.usercaption.toUpperCase())}</strong>
			<div>{"Hak Akses: "+(obBahasa["hakuser_"+(tokenObj.userhak||"").toLowerCase()]||"").toUpperCase()}</div>
		</CDropdownItem>
        <CDropdownItem divider />

        <CDropdownItem to="/iprofile">
          <CIcon name="cil-user" className="mfe-2" />{obBahasa.caption_profileanda}
        </CDropdownItem>
        {/*<CDropdownItem to="/isetting">
          <CIcon name="cil-settings" className="mfe-2" />{obBahasa.word_setting}
        </CDropdownItem>{/**/}
        <CDropdownItem to="/ilangs">
          <CIcon name="cil-speech" className="mfe-2" />{obBahasa.caption_ubahbahasa}
        </CDropdownItem>

        <CDropdownItem divider />
        <CDropdownItem onClick={()=> hLogoutClick()}>
			<CImg src="images/icon_logout.png" height="20" className="mfe-2" />{obBahasa.caption_logout}
		</CDropdownItem>
	</CDropdownMenu>
	</div>
    </CDropdownToggle>
    
    </CDropdown>
    );
}

export default TheHeaderUserprofile;